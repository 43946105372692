<template>
  <div class="tax-type-modal">
    <div class="invoice-view-page-container" style="height: 50%">
      <iframe
        id="iframe"
        ref="iframeRef"
        :src="`${shareableLink}`"
        @load="iframeLoaded()"
        class="frame-style"
        width="100%"
        height="470px"
        style="border: none"
      />
    </div>
    <base-loader
      style="left: 50%; top: 50%; position: absolute"
      v-if="ProcessSendInvoice"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, requiredIf, minLength } = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});
export default {
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      isLoading: false,
      unique_hash: "",
      ProcessSendInvoice: false,
      subs: false,
      formData: {
        id: null,
        name: null,
        account_no: null,
        IBAN_no: null,
        swift_code: null,
        default: false,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
    ...mapGetters("currency", [
      "defaultCurrency",
      "defaultCountry",
      "currencies",
      "defaultCurrencyForInput",
    ]),
    shareableLink() {
      return `/system/oneTimeCharge?hash=${this.unique_hash}`;
    },
  },

  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      account_no: {
        required,
        minLength: minLength(3),
      },
      IBAN_no: {
        required,
        minLength: minLength(3),
      },
      swift_code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    modalDataID(val) {
      // console.log("my" + val);
      if (val) {
        this.isEdit = true;
        // this.formData.id = this.modalData.id;
        // this.setData();
      } else {
        this.isEdit = false;
      }
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData();
      }
    },
  },
  async mounted() {
    // console.log(this.modalDataID);
    // console.log(this.$refs.iframeRef.getAttribute("src"));
    // this.formData.id = this.modalData.id;
    if (this.modalDataID) {
      this.isEdit = true;
      // this.setData();
    }
  },

  created() {
    // console.log(this.$refs.iframeRef.getAttribute("src"));
    this.ProcessSendInvoice = true;

    this.setInitialData();
  },
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("bankInformation", ["addBankInformation", "updateBankInformation"]),
    ...mapActions("preferences", ["loadData", "loadDataLanding", "editPreferences"]),
    ...mapActions("invoice", ["markAsSentPrinting"]),
    resetFormData() {
      this.formData = {
        id: null,
        name: null,
        account_no: null,
        IBAN_no: null,
        swift_code: null,
        default: false,
      };
      this.$v.formData.$reset();
    },

    async iframeLoaded() {
      this.ProcessSendInvoice = false;
      if (
        this.$refs.iframeRef.contentWindow.location.href ==
        window.location.origin + "/system/dashboard"
      ) {
        // this.$emit("success");
        const data = {
          id: this.modalDataID,
        };
        let response = await this.markAsSentPrinting(data);

        // this.refreshTable();
        if (response.data) {
          // window.toastr["success"](this.$tc("invoices.mark_as_sent_successfully"));

          // $router.push("invoices");

          this.closeModal();
        }
        // this.closeModal();
      }

      // alert();
    },
    async setInitialData() {
      // console.log(this.modalDataID);
      this.formData.id = this.modalDataID;
      let response = await this.loadDataLanding();

      if (response.status == 200) {
        this.unique_hash = response.data.unique_hash;
      } else {
        window.toastr["error"]("There is some problem with internet connection!");
        this.closeModal();
      }
    },

    setDefault() {
      this.formData.default == false ? "YES" : "NO";
    },
    async submitBankInformationData() {
      this.$v.formData.$touch();

      if (this.$v.$invalid) {
        return true;
      }

      this.isLoading = true;
      let response;

      if (!this.isEdit) {
        try {
          response = await this.addBankInformation(this.formData);
          if (response.data.success) {
            window.toastr["success"](
              this.$t("settings.bankInformation.success_message_add")
            );

            this.closeBankInformationModal();
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        try {
          response = await this.updateBankInformation(this.formData);

          if (response.data.success) {
            window.toastr["success"](
              this.$t("settings.bankInformation.success_message_update")
            );

            this.closeBankInformationModal();
            this.$emit("close");
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      }
    },
    async setData() {
      this.formData = {
        id: this.modalData.id,
        name: this.modalData.name,
        account_no: this.modalData.account_no,
        IBAN_no: this.modalData.IBAN_no,
        swift_code: this.modalData.swift_code,
      };
      if (this.modalData.default === "NO") {
        this.formData.default = false;
      } else {
        this.formData.default = true;
      }
    },
    closeBankInformationModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>

<style>
.base-modal .modal-body {
  overflow: hidden !important;
}
</style>
