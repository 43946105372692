<template>
  <div class="payment-modes-modal">
    <form action="" @submit.prevent="submitInstallmentMonth">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.customization.installment.installment_month_number") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="month"
              :invalid="$v.formData.month.$error"
              v-model="formData.month"
              type="text"
              @input="$v.formData.month.$touch()"
            />
            <div v-if="$v.formData.month.$error">
              <span
                v-if="!$v.formData.month.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
            </div>
          </div>
          <!-- <label style="margin-top: 2%" class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.customization.installment.installment_month_status") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <div class="left">
              <base-switch v-model="formData.status" class="btn-switch" />
            </div>
          </div> -->
        </div>
      </div>
      <div class="card-footer">
        <base-button
          :outline="true"
          class="mr-3"
          color="theme"
          type="button"
          @click="closeInstallmentMonthModal"
        >
          {{ $t("general.cancel") }}
        </base-button>
        <base-button :loading="isLoading" color="theme" icon="save" type="submit">
          {{ !isEdit ? $t("general.save") : $t("general.update") }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      isLoading: false,
      formData: {
        id: null,
        month: null,
        // status: null,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
  },
  validations: {
    formData: {
      month: {
        required,
      },
    },
  },
  async mounted() {
    this.$refs.month.focus = true;
    if (this.modalDataID) {
      this.isEdit = true;
      this.setData();
    }
  },
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("installment", ["addInstallment", "updateInstallment"]),
    resetFormData() {
      this.formData = {
        id: null,
        month: null,
        status: null,
      };
      this.$v.formData.$reset();
    },
    async submitInstallmentMonth() {
      this.$v.formData.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      this.isLoading = true;
      // this.formData.status ? "true" : "false";
      let response;
      if (this.isEdit) {
        try {
          response = await this.updateInstallment(this.formData);
          if (response.data) {
            this.isLoading = false;
            window.toastr["success"](
              this.$t("settings.customization.installment.installment_month_updated")
            );
            this.closeInstallmentMonthModal();
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        try {
          response = await this.addInstallment(this.formData);
          console.log(response.data);
          if (response.data.success) {
            this.isLoading = false;
            window.toastr["success"](
              this.$t("settings.customization.installment.installment_month_added")
            );
            this.closeInstallmentMonthModal();
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      }
    },
    async setData() {
      this.formData = {
        id: this.modalData.id,
        month: this.modalData.month,
        status: this.modalData.status,
      };
    },
    closeInstallmentMonthModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>
