<template>
  <div class="login-page login-3">
    <div class="site-wrapper">
      <div style="margin: 0 auto; max-width: 100%" class="login-box">
        <div class="box-wrapper">
          <!-- <div class="logo-main">
            <a href="/admin">
              <img
                style="margin-top: 15%"
                src="/assets/img/crater-logo.png"
                alt="Crater Logo"
              />
            </a>
          </div> -->
          <router-view></router-view>
          <div class="page-copyright screens">
            <p class="screens1">{{ $t("layout_login.copyright_crater") }}</p>
          </div>
        </div>
      </div>
      <div style="display: none" class="content-box">
        <h1>
          {{ $t("layout_login.super_simple_invoicing") }}<br />
          {{ $t("layout_login.for_freelancer") }}<br />
          {{ $t("layout_login.small_businesses") }} <br />
        </h1>

        <p>
          {{ $t("layout_login.crater_help") }}<br />
          {{ $t("layout_login.invoices_and_estimates") }}<br />
        </p>

        <div class="content-bottom" />
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  watch: {
    $route: "onRouteChange",
  },
  mounted() {
    this.setLayoutClasses();
  },
  methods: {
    setLayoutClasses() {
      let body = $("body");
      body.removeClass();
      body.addClass("login-page login-1 skin-crater");
    },
  },
  onRouteChange() {
    $("body").removeClass("login-page");
  },
};
</script>

<style scoped>
@media only screen and (max-width: 3000px) {
  /*Tablets [601px -> 1200px]*/
  .screens {
    text-align: right !important;
    position: fixed;
    bottom: 0px;
    width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .screens {
    text-align: center !important;
    /* position: fixed; */
    bottom: 0px;
    width: 95%;
  }
  .screens1 {
    margin-right: 13%;
  }
}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .screens {
    text-align: center !important;
    /* position: fixed; */
    bottom: 0px;
    width: 95%;
  }
  .screens1 {
    margin-right: 13%;
  }
}
</style>
