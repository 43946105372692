<template>
  <div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->

      <!-- Icon -->
      <div class="fadeIn first">
        <a href="/">
          <img
            style="margin-top: 15%; margin-bottom: -17%; padding-bottom: 30%; width: 30%"
            src="/assets/img/faktura-logo.png"
            alt="Faktura Logo"
          />
        </a>

        <!-- <img src="/assets/img/crater-logo.png" id="icon" alt="User Icon" /> -->
      </div>

      <!-- Login Form -->
      <form id="loginForm" @submit.prevent="submitSignUpData">
        <div :class="{ 'form-group': true }" class="fadeIn second">
          <p class="input-label input-fields">
            {{ $t("subAdmins.display_name") }} <span class="text-danger"> * </span>
          </p>
          <base-input
            :invalid="$v.loginData.name.$error"
            v-model="loginData.name"
            focus
            type="text"
            name="name"
            tab-index="1"
            class="input-fields"
            @input="$v.loginData.name.$touch()"
          />
          <div v-if="$v.loginData.name.$error" style="text-align: left; margin-left: 5%">
            <span v-if="!$v.loginData.name.required" class="text-danger">
              {{ $tc("validation.required") }}
            </span>
            <span v-if="!$v.loginData.name.minLength" class="text-danger">
              {{
                $tc(
                  "validation.name_min_length",
                  $v.loginData.name.$params.minLength.min,
                  { count: $v.loginData.name.$params.minLength.min }
                )
              }}
            </span>
          </div>
        </div>
        <div :class="{ 'form-group': true }" class="fadeIn second">
          <p class="input-label input-fields">
            {{ $t("subAdmins.company_name") }} <span class="text-danger"> * </span>
          </p>
          <base-input
            :invalid="$v.loginData.companyName.$error"
            v-model="loginData.companyName"
            class="input-fields"
            focus
            type="text"
            name="name"
            tab-index="1"
            @input="$v.loginData.companyName.$touch()"
          />
          <div
            v-if="$v.loginData.companyName.$error"
            style="text-align: left; margin-left: 5%"
          >
            <span v-if="!$v.loginData.companyName.required" class="text-danger">
              {{ $tc("validation.required") }}
            </span>
            <span v-if="!$v.loginData.companyName.minLength" class="text-danger">
              {{
                $tc(
                  "validation.name_min_length",
                  $v.loginData.name.$params.minLength.min,
                  { count: $v.loginData.name.$params.minLength.min }
                )
              }}
            </span>
          </div>
        </div>
        <div :class="{ 'form-group': true }" class="fadeIn second">
          <p class="input-label input-fields">
            {{ $t("login.email") }} <span class="text-danger"> * </span>
          </p>
          <base-input
            :invalid="$v.loginData.email.$error"
            v-model="loginData.email"
            :placeholder="$t(login.login_placeholder)"
            focus
            id="login"
            class="input-fields"
            type="email"
            name="email"
            @input="$v.loginData.email.$touch()"
          />
          <div v-if="$v.loginData.email.$error" style="text-align: left; margin-left: 5%">
            <span v-if="!$v.loginData.email.required" class="text-danger">
              {{ $tc("validation.required") }}
            </span>
            <span v-if="!$v.loginData.email.email" class="text-danger">
              {{ $tc("validation.email_incorrect") }}
            </span>
          </div>
        </div>
        <!-- <div class="form-group fadeIn third">
          <p class="input-label input-fields">
            {{ $t("login.password") }} <span class="text-danger"> * </span>
          </p>
          <base-input
            v-model="loginData.password"
            :invalid="$v.loginData.password.$error"
            type="password"
            name="password"
            id="password"
            class="input-fields"
            @input="$v.loginData.password.$touch()"
          />
          <div
            v-if="$v.loginData.password.$error"
            style="text-align: left; margin-left: 5%"
          >
            <span v-if="!$v.loginData.password.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
            <span v-if="!$v.loginData.password.minLength" class="text-danger">
              {{
                $tc(
                  "validation.password_min_length",
                  $v.loginData.password.$params.minLength.min,
                  { count: $v.loginData.password.$params.minLength.min }
                )
              }}
            </span>
          </div>
        </div> -->

        <base-button
          :loading="isLoading"
          style="margin-left: 5%; margin-bottom: 4%"
          type="submit"
          color="theme"
          class="fadeIn fourth"
        >
          {{ $t("login.signUp") }}
        </base-button>

        <!-- <div class="social-links">

      <span class="link-text">{{ $t('login.or_signIn_with') }}</span>

      <div class="social-logo">
        <icon-facebook class="icon"/>
        <icon-twitter class="icon"/>
        <icon-google class="icon"/>
      </div>

    </div> -->
      </form>

      <!-- Remind Passowrd -->
      <div id="formFooter">
        <div class="col-sm-12 text-sm-center mb-4" style="margin-top: 5%">
          <router-link
            to="forgot-password"
            style="color: #0373ff"
            class="forgot-link fadeIn fifth"
          >
            {{ $t("login.forgot_password") }}
          </router-link>
          <!--          <a style="color: #0373ff" class="forgot-link fadeIn fifth" href="/">{{
            $t("login.home")
          }}</a>
          <span style="color: #0373ff">!</span>-->
          <router-link to="login" style="color: #0373ff" class="forgot-link fadeIn fifth">
            {{ $t("login.login") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions } from "vuex";

import IconFacebook from "../../components/icon/facebook";
import IconTwitter from "../../components/icon/twitter";
import IconGoogle from "../../components/icon/google";
import { validationMixin } from "vuelidate";
const { required, email, minLength } = require("vuelidate/lib/validators");

export default {
  components: {
    IconFacebook,
    IconTwitter,
    IconGoogle,
  },
  mixins: [validationMixin],
  data() {
    return {
      language: null,
      loginData: {
        name: "",
        companyName: "",
        email: "",
        password: "",
        remember: "",
        language: "",
      },
      submitted: false,
      isLoading: false,
    };
  },
  watch: {
    language: function (val, oldval) {
      if (val) {
        window.i18n.locale = val;
      }
    },
  },
  validations: {
    loginData: {
      email: {
        required,
        email,
      },
      //   password: {
      //     required,
      //     minLength: minLength(8),
      //   },
      name: {
        required,
        minLength: minLength(3),
      },
      companyName: {
        required,
        minLength: minLength(3),
      },
    },
  },
  created() {
    if (localStorage.getItem("language") == "da") {
      this.language = "da";
      this.loginData.language = "da";
    } else {
      this.language = "en";
      this.loginData.language = "en";
    }
  },
  methods: {
    ...mapActions("auth", ["login"]),
    ...mapActions("company", ["setSelectedCompany"]),
    ...mapActions("subAdmin", [
      "addSubAdmin",
      "fetchSubAdmin",
      "updateSubAdmin",
      "fetchTemplates",
      "signUp",
    ]),
    async validateBeforeSubmit() {
      this.$v.loginData.$touch();
      if (this.$v.$invalid) {
        return true;
      }

      this.isLoading = true;
      this.login(this.loginData)
        .then((res) => {
          // redirect user on the basis of role
          this.$router.push("/system/dashboard");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    async submitSignUpData() {
      this.$v.loginData.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      //   this.loginData.invoice_template_id = this.getTemplateId;

      //   if (this.hasBillingAdd && this.hasShippingAdd) {
      //     this.formData.addresses = [{ ...this.billing }, { ...this.shipping }];
      //   } else {
      //     if (this.hasBillingAdd) {
      //       this.formData.addresses = [{ ...this.billing }];
      //     }
      //     if (this.hasShippingAdd) {
      //       this.formData.addresses = [{ ...this.shipping }];
      //     }
      //   }

      this.isLoading = true;
      //   if (this.currency) {
      //     this.isLoading = true;
      //     this.formData.currency_id = this.currency.id;
      //   }
      //   console.log(this.loginData);
      //   return false;
      try {
        let response = await this.signUp(this.loginData);
        // console.log(response);
        if (response.data.success) {
          window.toastr["success"](this.$t("login.signup_message"));
          //   window.location.href = "/login";
          this.$router.push("/login");
          this.isLoading = false;
          return true;
        }
      } catch (err) {
        this.isLoading = false;
        /*if (err.response.data.errors.email) {
            this.isLoading = false;
            window.toastr["error"](this.$t("validation.email_already_taken"));
          }*/
      }
    },
  },
};
</script>

<style scoped>
/* BASIC */

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 0%;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding-bottom: 1%;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fadeIn.fifth {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}

.input-fields {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}
</style>
