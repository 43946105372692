import * as types from './mutation-types'

export const fetchIntegrations = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/integrations`, {params}).then((response) => {
      commit(types.BOOTSTRAP_INTEGRATIONS, response.data.integrations.data)
      commit(types.SET_TOTAL_INTEGRATIONS, response.data.integrations.total)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchIntegration = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/integrations/${id}/edit`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addIntegration = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/integrations', data).then((response) => {
      commit(types.ADD_INTEGRATION, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateIntegration = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/integrations/${data.id}`, data).then((response) => {
      if(response.data.success){
        commit(types.UPDATE_INTEGRATION, response.data)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteIntegration = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/integrations/${id}`).then((response) => {
      commit(types.DELETE_INTEGRATION, id)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteMultipleIntegrations = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/integrations/delete`, {'id': state.selectedIntegrations}).then((response) => {
      commit(types.DELETE_MULTIPLE_INTEGRATIONS, state.selectedIntegrations)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllIntegrations = ({ commit, dispatch, state }) => {
  if (state.selectedIntegrations.length === state.integrations.length) {
    commit(types.SET_SELECTED_INTEGRATIONS, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allIntegrationIds = state.integrations.map(cust => cust.id)
    commit(types.SET_SELECTED_INTEGRATIONS, allIntegrationIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const selectIntegration = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_INTEGRATIONS, data)
  if (state.selectedIntegrations.length === state.integrations.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

export const resetSelectedIntegration = ({ commit, dispatch, state }, data) => {
  commit(types.RESET_SELECTED_INTEGRATION)
}
