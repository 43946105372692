var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard" }, [
    _c("div", { staticClass: "step-indicator" }, [
      _c("div", { staticClass: "indicator-line" }, [
        _c("div", { staticClass: "center" }, [
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 4, completed: _vm.step > 4 },
            },
            [
              _vm.step > 4
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 5, completed: _vm.step > 5 },
            },
            [
              _vm.step > 5
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 6, completed: _vm.step > 6 },
            },
            [
              _vm.step > 6
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 7, completed: _vm.step > 7 },
            },
            [
              _vm.step > 7
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 8, completed: _vm.step > 8 },
            },
            [
              _vm.step > 8
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 9, completed: _vm.step > 9 },
            },
            [
              _vm.step > 9
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 10, completed: _vm.step > 10 },
            },
            [
              _vm.step > 10
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 11, completed: _vm.step > 11 },
            },
            [
              _vm.step > 11
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-content" }, [
      _c(
        "div",
        { staticClass: "card wizard-card" },
        [
          _c(_vm.tab, {
            tag: "component",
            on: { next: _vm.setTab, back: _vm.backTab },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }