<template>
  <div class="sidebar-left">
    <div class="sidebar-body scroll-pane">
      <div class="side-nav">
        <div v-for="(menuItems, index) in menuValues" :key="index" class="menu-group">
          <router-link
            v-for="(item, index1) in menuItems"
            v-if="$can($t(item.name))"
            :key="index1"
            :to="item.route"
            class="menu-item"
            @click.native="Toggle"
          >
            <font-awesome-icon :icon="item.icon" class="icon menu-icon" />
            <span class="ml-3 menu-text">{{ $t(item.title) }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapActions, mapGetters } from "vuex";
import * as subscription from "../../../store/modules/subscription/mutation-types";
import store from "../../../store/index.js";
export default {
  data() {
    return {
      menuValues: [],
      sidebar: "sidebar",
      status: false,
      // menu: [
      //   [
      //     {
      //       title: "navigation.dashboard",
      //       icon: "tachometer-alt",
      //       route: "/admin/dashboard",
      //       name: "Dashboard",
      //     },
      //     {
      //       title: "navigation.customers",
      //       icon: "user",
      //       route: "/admin/customers",
      //       name: "Customers",
      //     },
      //     {
      //       title: "navigation.items",
      //       icon: "shopping-basket",
      //       route: "/admin/products",
      //       name: "Products",
      //     },
      //   ],

      //   [
      //     {
      //       title: "navigation.estimates",
      //       icon: "file",
      //       route: "/admin/estimates",
      //       name: "Offers",
      //     },
      //     {
      //       title: "navigation.invoices",
      //       icon: "file-alt",
      //       route: "/admin/invoices",
      //       name: "Invoices",
      //     },
      //     {
      //       title: "navigation.editor",
      //       icon: "pencil-alt",
      //       route: "/admin/editor",
      //       name: "Editor",
      //     },
      //     {
      //       title: "navigation.printing",
      //       icon: "print",
      //       route: "/admin/printing",
      //       name: "Printing",
      //     },
      //     {
      //       title: "navigation.payments",
      //       icon: "credit-card",
      //       route: "/admin/payments",
      //       name: "Payments",
      //     },
      //     {
      //       title: "navigation.subscriptionDetails",
      //       icon: "user-plus",
      //       route: "/admin/subscriptionDetails",
      //       name: "Subscription Details",
      //     },
      //   ],

      //   [
      //     {
      //       title: "navigation.language",
      //       icon: "hashtag",
      //       route: "/admin/language",
      //       name: "Language",
      //     },
      //   ],

      //   [
      //     {
      //       title: "navigation.roles",
      //       icon: "marker",
      //       route: "/admin/roles",
      //       name: "Roles",
      //     },
      //     {
      //       title: "navigation.permissions",
      //       icon: "check",
      //       route: "/admin/permissions",
      //       name: "Permissions",
      //     },
      //   ],

      //   [
      //     {
      //       title: "navigation.subAdmins",
      //       icon: "user-plus",
      //       route: "/admin/subAdmins",
      //       name: "Sub Admins",
      //     },
      //   ],

      //   [
      //     {
      //       title: "navigation.integrations",
      //       icon: "cogs",
      //       route: "/admin/integrations",
      //       name: "All Integrations",
      //     },
      //     {
      //       title: "navigation.reports",
      //       icon: "industry",
      //       route: "/admin/reports",
      //       name: "Reports",
      //     },
      //     {
      //       title: "navigation.activities",
      //       icon: "link",
      //       route: "/admin/activities",
      //       name: "Activities",
      //     },
      //     {
      //       title: "navigation.settings",
      //       icon: "cog",
      //       route: "/admin/settings",
      //       name: "Settings",
      //     },
      //   ],
      // ],
    };
  },

  computed: {
    ...mapGetters("userProfile", ["user"]),
    ...mapGetters("subscription", ["defaultSubscription", "sub_check"]),
  },

  created() {
    this.permissionsData();
  },

  methods: {
    Toggle() {
      this.$utils.toggleSidebar();
    },
    async permissionsData() {
      let responseValue;
      var role = localStorage.getItem("role");
      if (!this.isEmpty(this.sub_check)) {
        responseValue = await axios.get(`/api/sidebar/getPlanId/${this.sub_check}`);
      } else {
        responseValue = await axios.get(`/api/sidebar/getPlanId/${this.sub_check}`);
      }
      let response = await axios.get(
        `/api/sidebar/overall/${responseValue.data.value}/${this.sidebar}`
      );
      if (response.data.permissions) {
        this.menuValues = response.data.permissions;
      }
    },

    isAdmin() {
      if (localStorage.getItem("role") === "admin") return true;
    },

    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
  },
};
</script>
