<template>
  <div class="main-content item-create">
    <div class="page-header">
      <h3 class="page-title">
        {{ isEdit ? $t("items.edit_item") : $t("items.new_item") }}
      </h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/system/dashboard">{{
            $t("general.home")
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/system/products">{{
            $tc("items.item", 2)
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="#"> {{ isEdit ? $t("items.edit_item") : $t("items.new_item") }}</a>
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <form action="" @submit.prevent="submitItem">
            <div class="card-body">
              <div class="form-group">
                <label class="control-label">{{ $t("items.name") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  v-model.trim="formData.name"
                  :invalid="$v.formData.name.$error"
                  focus
                  type="text"
                  name="name"
                  :placeholder="$t('items.name')"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger"
                    >{{ $t("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label">{{ $t("items.number") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  v-model.trim="formData.item_no"
                  :invalid="$v.formData.item_no.$error"
                  focus
                  type="text"
                  name="name"
                  :placeholder="$t('items.number')"
                  @input="$v.formData.item_no.$touch()"
                  disabled
                />
                <span v-if="uniqueItemNo" class="text-danger"
                  >{{ $t("validation.uniqueItemNo") }}
                </span>
                <div v-if="$v.formData.item_no.$error">
                  <span v-if="!$v.formData.item_no.required" class="text-danger"
                    >{{ $t("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.item_no.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.item_no.$params.minLength.min,
                        { count: $v.formData.item_no.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t("items.price") }}</label
                ><span class="text-danger"> *</span>
                <div class="base-input">
                  <money
                    :class="{ invalid: $v.formData.price.$error }"
                    v-model="price"
                    v-bind="defaultCurrencyForInput"
                    class="input-field"
                  />
                </div>
                <div v-if="$v.formData.price.$error">
                  <span v-if="!$v.formData.price.required" class="text-danger"
                    >{{ $t("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.price.maxLength" class="text-danger">{{
                    $t("validation.price_maxlength")
                  }}</span>
                  <span v-if="!$v.formData.price.minValue" class="text-danger">{{
                    $t("validation.price_minvalue")
                  }}</span>
                </div>
              </div>
              <div class="form-group">
                <label>{{ $t("items.unit") }}</label>
                <base-select
                  v-model="formData.unit"
                  :options="getitemUnits"
                  :invalid="$v.formData.unit.$error"
                  :searchable="true"
                  :show-labels="false"
                  :placeholder="$t('items.select_a_unit')"
                  label="name"
                >
                  <div slot="afterList">
                    <button type="button" class="list-add-button" @click="addItemUnit">
                      <font-awesome-icon class="icon" icon="cart-plus" />
                      <label>{{
                        $t("settings.customization.items.add_item_unit")
                      }}</label>
                    </button>
                  </div>
                </base-select>
                <div v-if="$v.formData.unit.$error">
                  <span v-if="!$v.formData.unit.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>
              <div v-if="isTaxPerItem" class="form-group">
                <label>{{ $t("items.taxes") }}</label>
                <base-select
                  v-model="formData.taxes"
                  :options="getTaxTypes"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  :multiple="true"
                  track-by="tax_type_id"
                  label="tax_name"
                />
              </div>
              <div class="form-group">
                <label for="description">{{ $t("items.description") }}</label>
                <base-text-area
                  v-model="formData.description"
                  rows="2"
                  name="description"
                  @input="$v.formData.description.$touch()"
                />
                <div v-if="$v.formData.description.$error">
                  <span v-if="!$v.formData.description.maxLength" class="text-danger">
                    {{ $t("validation.description_maxlength") }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label>{{ $t("items.subscription") }}</label
                ><span class="text-danger"> *</span>
                <base-select
                  v-model="selected"
                  :invalid="$v.selected.$error"
                  :options="formData.subscription"
                  :show-labels="false"
                  :placeholder="$t('items.subscription')"
                  @input="onChange()"
                  name="subscription"
                  label="id"
                >
                </base-select>
                <div v-if="$v.selected.$error">
                  <span v-if="!$v.selected.required" class="text-danger"
                    >{{ $t("validation.required") }}
                  </span>
                </div>
              </div>
              <div v-if="subs">
                <div class="form-group">
                  <label>{{ $t("items.billingPeriod") }}</label
                  ><span class="text-danger"> *</span>
                  <base-select
                    v-model="billingPeriod"
                    :invalid="$v.billingPeriod.$error"
                    :options="formData.billingPeriod"
                    :show-labels="false"
                    :placeholder="$t('items.billingPeriod')"
                    name="billingPeriod"
                    label="name"
                  >
                  </base-select>
                  <div v-if="$v.billingPeriod.$error">
                    <span v-if="!$v.billingPeriod.required" class="text-danger"
                      >{{ $t("validation.required") }}
                    </span>
                  </div>
                </div>

                <div class="form-group">
                  <label>{{ $t("items.periodStart") }}</label
                  ><span class="text-danger"> *</span>
                  <base-select
                    v-model="periodStart"
                    :invalid="$v.periodStart.$error"
                    :options="formData.periodStart"
                    :show-labels="false"
                    :placeholder="$t('items.periodStart')"
                    name="periodStart"
                    label="name"
                  >
                  </base-select>
                  <div v-if="$v.periodStart.$error">
                    <span v-if="!$v.periodStart.required" class="text-danger"
                      >{{ $t("validation.required") }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <base-button
                  :loading="isLoading"
                  :disabled="isLoading"
                  icon="save"
                  color="theme"
                  type="submit"
                  class="collapse-button"
                >
                  {{ isEdit ? $t("items.update_item") : $t("items.save_item") }}
                </base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapActions, mapGetters } from "vuex";
const {
  required,
  requiredIf,
  minLength,
  numeric,
  minValue,
  maxLength,
} = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});

export default {
  mixins: {
    validationMixin,
  },
  data() {
    return {
      addUnitFlag: false,
      isLoading: false,
      subs: false,
      uniqueItemNo: false,
      title: "Add Item",
      units: [],
      selected: "",
      billingPeriod: "",
      periodStart: "",

      taxes: [],
      taxPerItem: "",
      formData: {
        name: "",
        item_no: "",
        description: "",
        price: "",
        unit_id: null,
        unit: null,
        sid: null,
        bid: null,
        pid: null,
        subs: null,
        subs_bill: null,
        subs_start: null,
        taxes: [],
        tax_per_item: false,
        subscription: [{ id: "Yes" }, { id: "No" }],
        billingPeriod: [],
        periodStart: [],
      },
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters("currency", ["defaultCurrencyForInput"]),
    ...mapGetters("item", ["itemUnits"]),
    price: {
      get: function () {
        return this.formData.price / 100;
      },
      set: function (newValue) {
        this.formData.price = newValue * 100;
      },
    },

    ...mapGetters("taxType", ["taxTypes"]),
    isEdit() {
      if (this.$route.name === "products.edit") {
        return true;
      }
      return false;
    },
    isTaxPerItem() {
      return this.taxPerItem === "YES" ? 1 : 0;
    },
    getTaxTypes() {
      return this.taxTypes.map((tax) => {
        return {
          ...tax,
          tax_type_id: tax.id,
          tax_name: tax.name + " (" + tax.percent + "%)",
        };
      });
    },

    //created to get latest added unit automatically

    getitemUnits() {
      if (this.addUnitFlag == true) {
        this.formData.unit = this.itemUnits[0];
      }

      return this.itemUnits.map((unit) => {
        return {
          ...unit,
        };
      });
    },
  },

  mounted() {
    this.formData.unit = null;
  },
  created() {
    this.setTaxPerItem();
    if (this.isEdit) {
      this.loadEditData();
    } else {
      this.getItemNo();
    }
  },

  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      item_no: {
        required,
        minLength: minLength(3),
      },
      price: {
        required,
        numeric,
        maxLength: maxLength(20),
        minValue: minValue(0.1),
      },
      description: {
        maxLength: maxLength(255),
      },

      unit: {
        required,
      },
    },
    billingPeriod: {
      required: requiredIfRoot,
    },
    periodStart: {
      required: requiredIfRoot,
    },
    selected: {
      required,
    },
  },
  methods: {
    ...mapActions("item", ["addItem", "fetchItem", "updateItem"]),
    ...mapActions("modal", ["openModal"]),

    showbillingform() {
      this.subs = true;
    },
    async fetchBillingPeriod() {
      let res = await window.axios.get("/api/billingPeriodItem");
      if (res) {
        this.formData.billingPeriod = res.data.billing_period;
      }
    },

    async fetchPeriodStart() {
      let res = await window.axios.get("/api/periodStart");
      if (res) {
        this.formData.periodStart = res.data.period_start;
      }
    },

    onChange() {
      if (this.selected == null) {
      } else {
        if (this.selected.id == "Yes") {
          this.subs = true;
          this.fetchBillingPeriod();
          this.fetchPeriodStart();
        } else {
          this.subs = false;
        }
      }
    },

    getItemNo() {
      axios
        .get("/api/settings/get-item-no")
        .then((res) => {
          // console.log(res);

          // if (res.data.items.length == 0) {
          //   counter = 1;
          // } else {
          //   counter = res.data.items[0].id + 1;
          // }

          // var field = this.pad(counter, 6);
          this.formData.item_no = "PR-" + res.data.items;
        })
        .catch((res) => console.log(res.error));
    },
    //### Function to add leading zero(s)
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    //### END Function to add leading zero(s)

    async setTaxPerItem() {
      let res = await axios.get("/api/settings/get-setting?key=tax_per_item");
      if (res.data && res.data.tax_per_item === "YES") {
        this.taxPerItem = "YES";
      } else {
        this.taxPerItem = "FALSE";
      }
    },
    async loadEditData() {
      let response = await this.fetchItem(this.$route.params.id);

      // console.log(response.data.item.billing_period);
      this.formData = { ...response.data.item, unit: null };
      this.formData.subscription = [{ id: "Yes" }, { id: "No" }];
      this.formData.billingPeriod = response.data.billing_period;
      this.formData.periodStart = response.data.period_start;

      this.formData.taxes = response.data.item.taxes.map((tax) => {
        return { ...tax, tax_name: tax.name + " (" + tax.percent + "%)" };
      });

      this.formData.unit = this.itemUnits.find(
        (_unit) => response.data.item.unit_id === _unit.id
      );

      this.selected = this.formData.subscription.find(
        (_selected) => response.data.item.subscription_value === _selected.id
      );
      if (this.selected.id == "Yes") {
        this.showbillingform();
        this.billingPeriod = this.formData.billingPeriod.find(
          (_billingPeriod) => response.data.item.billing_period == _billingPeriod.id
        );
        this.periodStart = this.formData.periodStart.find(
          (_periodStart) => response.data.item.period_start == _periodStart.id
        );
      }
      this.formData.item_no = response.data.item.item_no;
      this.fractional_price = response.data.item.price;
    },
    async submitItem() {
      this.$v.formData.$touch();
      this.$v.selected.$touch();

      if (this.subs) {
        this.$v.billingPeriod.$touch();
        this.$v.periodStart.$touch();
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (this.formData.unit) {
        this.formData.unit_id = this.formData.unit.id;
      }

      let response;
      this.isLoading = true;
      if (this.isEdit) {
        this.formData.subs = this.selected.id;
        this.formData.subs_bill = this.billingPeriod.id;
        this.formData.subs_start = this.periodStart.id;
        try {
          response = await this.updateItem(this.formData);
          if (response.data) {
            window.toastr["success"](this.$tc("items.updated_message"));
            this.$router.push("/system/products");
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        let data = {
          ...this.formData,
          subs: this.selected.id,
          subs_bill: this.billingPeriod.id,
          subs_start: this.periodStart.id,
          taxes: this.formData.taxes.map((tax) => {
            return {
              tax_type_id: tax.id,
              amount: (this.formData.price * tax.percent) / 100,
              percent: tax.percent,
              name: tax.name,
              collective_tax: 0,
            };
          }),
        };
        try {
          response = await this.addItem(data);
          if (response.data) {
            window.toastr["success"](this.$tc("items.created_message"));
            this.$router.push("/system/products");
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      }
    },
    async addItemUnit() {
      this.addUnitFlag = true;
      this.openModal({
        title: this.$t("settings.customization.items.add_item_unit"),
        componentName: "ItemUnit",
      });
    },
  },
};
</script>
<style scoped></style>
