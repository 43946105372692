<template>
  <div class="tax-type-modal">
    <form action="" @submit.prevent="submitSubscriptionTypeData">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("tax_types.name") }} <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="name"
              :invalid="$v.formData.name.$error"
              v-model="formData.name"
              type="text"
              @input="$v.formData.name.$touch()"
            />
            <div v-if="$v.formData.name.$error">
              <span
                v-if="!$v.formData.name.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
              <span
                v-if="!$v.formData.name.minLength"
                class="form-group__message text-danger"
              >
                {{
                  $tc(
                    "validation.name_min_length",
                    $v.formData.name.$params.minLength.min,
                    { count: $v.formData.name.$params.minLength.min }
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.subscriptions.price") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7 base-input">
            <money
              :class="{ invalid: $v.formData.price.$error }"
              v-model="formData.price"
              v-bind="defaultCurrencyForInput"
              class="input-field"
              :disabled="this.isEdit"
            />
            <div v-if="$v.formData.price.$error">
              <span v-if="!$v.formData.price.required" class="text-danger"
                >{{ $t("validation.required") }}
              </span>
              <span v-if="!$v.formData.price.maxLength" class="text-danger">{{
                $t("validation.price_maxlength")
              }}</span>
              <span v-if="!$v.formData.price.minValue" class="text-danger">{{
                $t("validation.price_minvalue")
              }}</span>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label">{{
            $t("settings.subscriptions.currency")
          }}</label>
          <div class="col-sm-7">
            <base-select
              v-model="formData.currency"
              :options="currencies"
              :custom-label="currencyNameWithCode"
              :class="{ error: $v.formData.currency.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.currencies.select_currency')"
              label="name"
              track-by="id"
              :disabled="this.isEdit"
            />
            <div v-if="$v.formData.currency.$error">
              <span v-if="!$v.formData.currency.maxLength" class="text-danger">{{
                $t("validation.description_maxlength")
              }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.subscriptions.pricing_modal") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-select
              v-model="formData.pricing_modal"
              :options="pricing_modal"
              :class="{ error: $v.formData.pricing_modal.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.subscriptions.billing_type_select')"
              label="name"
              track-by="id"
              :disabled="this.isEdit"
            />

            <div v-if="$v.formData.pricing_modal.$error">
              <span
                v-if="!$v.formData.pricing_modal.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
            </div>
          </div>
        </div>-->

        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label">{{
            $t("settings.subscriptions.billing_type")
          }}</label>
          <div class="col-sm-7">
            <base-select
              v-model="formData.billing_type"
              :options="billing_type"
              :class="{ error: $v.formData.billing_type.$error }"
              :searchable="true"
              :show-labels="false"
              :allow-empty="false"
              :placeholder="$tc('settings.subscriptions.billing_type_select')"
              label="name"
              track-by="id"
              @input="onChange()"
              :disabled="this.isEdit"
            />
            <div v-if="$v.formData.billing_type.$error">
              <span
                v-if="!$v.formData.billing_type.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label">{{
            $t("tax_types.description")
          }}</label>
          <div class="col-sm-7">
            <base-text-area
              v-model="formData.description"
              rows="4"
              cols="50"
              @input="$v.formData.description.$touch()"
            />
            <div v-if="$v.formData.description.$error">
              <span v-if="!$v.formData.description.maxLength" class="text-danger">{{
                $t("validation.description_maxlength")
              }}</span>
            </div>
          </div>
        </div>
        <!--<div v-if="subs">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label input-label">{{
              $t("settings.subscriptions.billing_period")
            }}</label>
            <div class="col-sm-7">
              <base-select
                v-model="formData.billing_period"
                :options="billing_period"
                :class="{ error: $v.formData.billing_period.$error }"
                :searchable="true"
                :show-labels="false"
                :allow-empty="false"
                :placeholder="$tc('settings.subscriptions.billing_period_select')"
                label="name"
                track-by="id"
                :disabled="this.isEdit"
              />
              <div v-if="$v.formData.billing_period.$error">
                <span
                  v-if="!$v.formData.billing_period.required"
                  class="form-group__message text-danger"
                  >{{ $tc("validation.required") }}</span
                >
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label">{{
            $t("tax_types.compound_tax")
          }}</label>
          <div class="col-sm-7 mr-4">
            <base-switch
              v-model="formData.compound_tax"
              class="btn-switch compound-tax-toggle"
            />
          </div>
        </div> -->
      </div>
      <div class="card-footer">
        <base-button
          :outline="true"
          class="mr-3"
          color="theme"
          type="button"
          @click="closeSubscriptionModal"
        >
          {{ $t("general.cancel") }}
        </base-button>
        <base-button :loading="isLoading" color="theme" icon="save" type="submit">
          {{ !isEdit ? $t("general.save") : $t("general.update") }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const {
  required,
  requiredIf,
  minLength,
  minValue,
  between,
  numeric,
  maxLength,
} = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});
export default {
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      isLoading: false,
      billing_type: [
        { id: "1", name: "One Time" },
        { id: "2", name: "Recurring" },
      ],
      billing_period: [
        { id: "1", name: "Monthly" },
        { id: "2", name: "Yearly" },
      ],
      pricing_modal: [
        { id: "1", name: "Standard Pricing" },
        { id: "2", name: "Package Pricing" },
        { id: "3", name: "Graduated Pricing" },
        { id: "4", name: "Volume Pricing" },
      ],

      subs: false,
      formData: {
        name: null,
        price: "",
        billing_type: null,
        pricing_modal: null,
        billing_period: null,
        currency: null,
        description: null,
      },

      defaultInput: {
        decimal: ".",
        thousands: ",",
        prefix: "% ",
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
    ...mapGetters("currency", [
      "defaultCurrency",
      "defaultCountry",
      "currencies",
      "defaultCurrencyForInput",
    ]),
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      price: {
        required,
        // numeric,
        maxLength: maxLength(20),
        minValue: minValue(0.0),
      },
      billing_type: {
        required,
      },
      // billing_period: {
      //   required: requiredIfRoot,
      // },
      // pricing_modal: {
      //   required,
      //   // between: between(0, 100),
      // },

      currency: {
        required,
        // between: between(0, 100),
      },
      description: {
        maxLength: maxLength(255),
      },
    },
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true;
        this.setData();
      } else {
        this.isEdit = false;
      }
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData();
      }
    },
  },
  async mounted() {
    // this.$refs.name.focus = true;
    if (this.modalDataID) {
      this.isEdit = true;
      this.setData();
      // this.resetFormData()
    } else {
      this.formData.currency = this.defaultCurrency;
    }

    // this.fetchBillingType();
  },
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("plans", [
      "addSubscriptionPlan",
      "updateSubscriptionPlanType",
      "fetchSubscriptionPlanType",
    ]),
    resetFormData() {
      this.formData = {
        id: null,
        name: null,
        price: "",
        billing_type: null,
        pricing_modal: null,
        billing_period: null,
        currency: null,
        description: null,
      };
      this.$v.formData.$reset();
    },
    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`;
    },
    async fetchBillingType() {
      let res = await window.axios.get("/api/billingType");
      if (res) {
        this.billing_type = res.data.billing_type;
      }
    },
    async fetchBillingPeriod() {
      let res = await window.axios.get("/api/billingPeriod");
      if (res) {
        this.billing_period = res.data.billing_period;
      }
    },

    onChange() {
      //   console.log(this.formData.billing_type.id);
      if (this.formData.billing_type.id == "2") {
        this.subs = true;
        // this.fetchBillingPeriod();
        //   this.fetchPeriodStart();
      } else {
        this.subs = false;
      }
    },
    async submitSubscriptionTypeData() {
      this.$v.formData.$touch();

      if (this.$v.$invalid) {
        return true;
      }

      this.isLoading = true;
      let response;

      if (!this.isEdit) {
        try {
          response = await this.addSubscriptionPlan(this.formData);
          if (response.data.success) {
            window.toastr["success"](
              this.$t("settings.subscriptions.success_message_add")
            );
            // window.hub.$emit("newTax", response.data.taxType);
            this.closeSubscriptionModal();
            this.isLoading = false;
            return true;
          }
          // window.toastr["error"](response.data.error);
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        try {
          response = await this.updateSubscriptionPlanType(this.formData);
          // console.log(response);
          if (response.data.success) {
            window.toastr["success"](
              this.$t("settings.subscriptions.success_message_update")
            );
            // window.hub.$emit("newTax", response.data.taxType);
            this.closeSubscriptionModal();
            this.isLoading = false;
            return true;
          }
          // window.toastr["error"](response.data.error);
        } catch (err) {
          this.isLoading = false;
        }
      }

      // if (response.data) {
      //   if (!this.isEdit) {
      //     window.toastr["success"](this.$t("settings.tax_types.created_message"));
      //   } else {
      //     window.toastr["success"](this.$t("settings.tax_types.updated_message"));
      //   }
      //   window.hub.$emit("newTax", response.data.taxType);
      //   this.closeTaxModal();
      //   this.isLoading = false;
      //   return true;
      // }
      // window.toastr["error"](response.data.error);
    },
    async setData() {
      // console.log(this.modalData);
      this.formData.pricing_modal = this.pricing_modal.find(
        (_pricing_modal) => this.modalData.pricing_modal === _pricing_modal.name
      );

      this.formData.billing_type = this.billing_type.find(
        (_billing_type) => this.modalData.billing_type === _billing_type.name
      );

      this.formData.billing_period = this.billing_period.find(
        (_billing_period) => this.modalData.billing_period === _billing_period.name
      );

      this.formData.currency = this.currencies.find(
        (_currency) => this.modalData.currency_id == _currency.id
      );

      if (this.modalData.billing_type == "Recurring") {
        this.subs = true;
      }

      this.formData = {
        id: this.modalData.id,
        name: this.modalData.name,
        price: this.modalData.price / 100,
        billing_type: this.formData.billing_type,
        pricing_modal: this.formData.pricing_modal,
        billing_period: this.formData.billing_period,
        currency: this.formData.currency,
        description: this.modalData.description,
      };
    },
    closeSubscriptionModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>
