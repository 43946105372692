import * as types from './mutation-types'

export const setDefaultSubscription = ({ commit, dispatch, state }, data) => {
  commit(types.SET_DEFAULT_SUBSCRIPTION, { subscription: data })
}

export const cancelSubscription = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/subscription/cancel`).then((response) => {
      if (response.data.success) {
        commit(types.SET_DEFAULT_SUBSCRIPTION, response.data.subscription)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const resumeSubscription = ({ commit, dispatch, state }) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/subscription/resume`).then((response) => {
      if (response.data.success) {
        commit(types.SET_DEFAULT_SUBSCRIPTION, response.data.subscription)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const changePlan = ({ commit, dispatch, state },data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/subscription/changePlan`, data).then((response) => {
      
      if (response.data.success) {
        commit(types.SET_SUB_CHECK, response.data.subscription)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
