<template>
  <div class="wrapper fadeInDown">
    <div id="formContent">
      <!-- Tabs Titles -->

      <!-- Icon -->
      <div class="fadeIn first">
        <a href="/">
          <img
            style="margin-top: 15%; margin-bottom: -18%; padding-bottom: 30%; width: 30%"
            src="/assets/img/faktura-logo.png"
            alt="Faktura Logo"
          />
        </a>

        <!-- <img src="/assets/img/crater-logo.png" id="icon" alt="User Icon" /> -->
      </div>
      <form id="loginForm" @submit.prevent="validateBeforeSubmit">
        <div :class="{ 'form-group': true }" class="fadeIn second">
          <p class="input-label input-fields">
            {{ $t("login.email") }} <span class="text-danger"> * </span>
          </p>
          <base-input
            v-model.trim="formData.email"
            :invalid="$v.formData.email.$error"
            :placeholder="$t('login.enter_email')"
            type="email"
            name="email"
            class="input-fields"
            @input="$v.formData.email.$touch()"
          />
          <div v-if="$v.formData.email.$error">
            <span v-if="!$v.formData.email.required" class="help-block text-danger">
              {{ $t("validation.required") }}
            </span>
            <span v-if="!$v.formData.email.email" class="help-block text-danger">
              {{ $t("validation.email_incorrect") }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <base-input
            id="password"
            v-model.trim="formData.password"
            :invalid="$v.formData.password.$error"
            class="input-fields"
            :placeholder="$t('login.enter_password')"
            type="password"
            name="password"
            @input="$v.formData.password.$touch()"
          />
          <div v-if="$v.formData.password.$error">
            <span v-if="!$v.formData.password.required" class="help-block text-danger">
              {{ $t("validation.required") }}
            </span>
            <span v-if="!$v.formData.password.minLength" class="help-block text-danger">
              {{
                $tc("validation.password_length", $v.formData.password.minLength.min, {
                  count: $v.formData.password.$params.minLength.min,
                })
              }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <base-input
            v-model.trim="formData.password_confirmation"
            :invalid="$v.formData.password_confirmation.$error"
            class="input-fields"
            :placeholder="$t('login.retype_password')"
            type="password"
            name="password_confirmation"
            @input="$v.formData.password_confirmation.$touch()"
          />
          <div v-if="$v.formData.password_confirmation.$error">
            <span
              v-if="!$v.formData.password_confirmation.sameAsPassword"
              class="help-block text-danger"
            >
              {{ $t("validation.password_incorrect") }}
            </span>
          </div>
        </div>
        <base-button
          :loading="isLoading"
          style="margin-left: 5%; margin-bottom: 4%"
          class="fadeIn third"
          type="submit"
          color="theme"
        >
          {{ $t("login.reset_password") }}
        </base-button>
      </form>
    </div>
  </div>
</template>

<script type="text/babel">
import { validationMixin } from "vuelidate";
const { required, email, sameAs, minLength } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      formData: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      isLoading: false,
    };
  },
  validations: {
    formData: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        sameAsPassword: sameAs("password"),
      },
    },
  },
  methods: {
    async validateBeforeSubmit(e) {
      this.$v.formData.$touch();

      if (!this.$v.formData.$invalid) {
        try {
          let data = {
            email: this.formData.email,
            password: this.formData.password,
            password_confirmation: this.formData.password_confirmation,
            token: this.$route.params.token,
          };
          this.isLoading = true;
          let res = await axios.post("/api/auth/reset/password", data);
          this.isLoading = false;
          if (res.data) {
            toastr["success"](this.$t("login.password_reset_successfully"), "Success");
            this.$router.push("/login");
          }
        } catch (err) {
          if (err.response && err.response.status === 403) {
            toastr["error"](err.response.data, this.$t("validation.email_incorrect"));
            this.isLoading = false;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
/* BASIC */

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display: inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin: 40px 8px 10px 8px;
  color: #cccccc;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 0%;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding-bottom: 1%;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type="text"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type="text"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type="text"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.fadeIn.fifth {
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 60%;
}

.input-fields {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
}
</style>
