var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "site-header" }, [
    !_vm.isAdmin()
      ? _c("a", { staticClass: "brand-main", attrs: { href: "/" } }, [
          !_vm.flag
            ? _c("img", {
                staticClass: "d-none d-md-inline",
                staticStyle: { width: "36px", height: "36px" },
                attrs: {
                  id: "logo-white",
                  src: _vm.companyPicture,
                  alt: "Faktura Logo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.flag
            ? _c(
                "object",
                {
                  staticClass: "mylogo",
                  staticStyle: { "margin-left": "-35px" },
                  attrs: {
                    data: "/images/WHITE_LOGO_FAKTURA.svg",
                    type: "image/svg+xml",
                    width: "250",
                    height: "37",
                    href: "#home",
                  },
                },
                [
                  _c("img", {
                    staticClass: "d-none d-md-inline",
                    staticStyle: { width: "36px", height: "36px" },
                    attrs: {
                      id: "logo-white",
                      src: _vm.companyPicture,
                      alt: "Faktura Logo",
                    },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            staticClass: "d-md-none",
            staticStyle: { width: "36px", height: "36px" },
            attrs: {
              id: "logo-mobile",
              src: _vm.companyPicture,
              alt: "Laraspace Logo",
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isAdmin()
      ? _c(
          "a",
          { staticStyle: { "margin-left": "-35px" }, attrs: { href: "/" } },
          [
            !_vm.flag
              ? _c("img", {
                  staticClass: "d-none d-md-inline",
                  attrs: {
                    id: "logo-white",
                    width: "250px",
                    height: "40px",
                    src: "/images/WHITE_LOGO_FAKTURA.svg",
                    alt: "Faktura Logo",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.flag
              ? _c(
                  "object",
                  {
                    staticClass: "mylogo",
                    attrs: {
                      data: "/images/WHITE_LOGO_FAKTURA.svg",
                      type: "image/svg+xml",
                      width: "250",
                      height: "37",
                      href: "#home",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "d-none d-md-inline",
                      staticStyle: { width: "36px", height: "36px" },
                      attrs: {
                        id: "logo-white",
                        src: _vm.adminLogo,
                        alt: "Faktura Logo",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("img", {
              staticClass: "d-md-none",
              staticStyle: { width: "36px", height: "36px" },
              attrs: {
                id: "logo-mobile",
                src: _vm.adminLogo,
                alt: "Laraspace Logo",
              },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "nav-toggle",
        attrs: { href: "#" },
        on: { click: _vm.onNavToggle },
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "action-list" }, [
      _c(
        "li",
        [
          _c(
            "v-dropdown",
            { attrs: { "show-arrow": false } },
            [
              _c(
                "a",
                { attrs: { slot: "activator", href: "#" }, slot: "activator" },
                [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/system/invoices/create" },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "dropdown-item-icon",
                        attrs: { icon: "file-alt" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("invoices.new_invoice")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/system/estimates/create" },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "dropdown-item-icon",
                        attrs: { icon: "file" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("estimates.new_estimate")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/system/customers/create" },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "dropdown-item-icon",
                        attrs: { icon: "user" },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("customers.new_customer")) + " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _c(
            "v-dropdown",
            { attrs: { "show-arrow": false } },
            [
              _c(
                "a",
                {
                  staticClass: "avatar",
                  attrs: {
                    slot: "activator",
                    href: "#",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                  slot: "activator",
                },
                [
                  _c("img", {
                    attrs: { src: _vm.profilePicture, alt: "Avatar" },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.$can("Settings")
                ? _c(
                    "v-dropdown-item",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/system/settings" },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "dropdown-item-icon",
                            attrs: { icon: "cogs" },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("navigation.settings")) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-dropdown-item", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.logout.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "dropdown-item-icon",
                      attrs: { icon: "sign-out-alt" },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("navigation.logout")) + " "),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hamburger hamburger--arrowturn" }, [
      _c("div", { staticClass: "hamburger-box" }, [
        _c("div", { staticClass: "hamburger-inner" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }