<template>
  <div class="setting-main-container" style="padding: 30px">
    <div class="page-header">
      <h3 class="page-title">{{ $t("settings.notification.title") }}</h3>
      <p class="page-sub-title">
        {{ $t("settings.notification.description") }}
      </p>
    </div>
    <form action="" @submit.prevent="saveEmail()">
      <div class="form-group">
        <label class="form-label">{{ $t("settings.notification.email") }}</label
        ><span class="text-danger"> *</span>
        <base-input
          :invalid="$v.notification_email.$error"
          v-model.trim="notification_email"
          :placeholder="$tc('settings.notification.please_enter_email')"
          type="text"
          name="notification_email"
          icon="envelope"
          input-class="col-md-6"
          @input="$v.notification_email.$touch()"
        />
        <div v-if="$v.notification_email.$error">
          <span v-if="!$v.notification_email.required" class="text-danger">{{
            $tc("validation.required")
          }}</span>
          <span v-if="!$v.notification_email.email" class="text-danger">
            {{ $tc("validation.email_incorrect") }}
          </span>
        </div>
        <base-button
          :loading="isLoading"
          :disabled="isLoading"
          class="mt-4"
          icon="save"
          color="theme"
          type="submit"
        >
          {{ $tc("settings.notification.save") }}
        </base-button>
      </div>
    </form>
    <hr />
    <div class="flex-box mt-3 mb-4">
      <div class="left">
        <base-switch
          v-model="notify_invoice_viewed"
          class="btn-switch"
          @change="setInvoiceViewd"
        />
      </div>
      <div class="right" style="margin-top: 2%">
        <p class="box-title">
          <strong>{{ $t("settings.notification.invoice_viewed") }}</strong>
        </p>
        <p class="box-desc">{{ $t("settings.notification.invoice_viewed_desc") }}</p>
      </div>
    </div>
    <div class="flex-box mb-4">
      <div class="left">
        <base-switch
          v-model="notify_estimate_viewed"
          class="btn-switch"
          @change="setEstimateViewd"
        />
      </div>
      <div class="right" style="margin-top: 2%">
        <p class="box-title">
          <strong>{{ $t("settings.notification.estimate_viewed") }}</strong>
        </p>
        <p class="box-desc">{{ $t("settings.notification.estimate_viewed_desc") }}</p>
      </div>
    </div>
    <div class="flex-box mb-4">
      <div class="left">
        <base-switch
          v-model="notify_email_attachment"
          class="btn-switch"
          @change="setEmailAttachment"
        />
      </div>
      <div class="right" style="margin-top: 2%">
        <p class="box-title">
          <strong>{{ $t("settings.notification.email_attachment") }}</strong>
        </p>
        <p class="box-desc">{{ $t("settings.notification.email_attachment_desc") }}</p>
      </div>
    </div>

    <div class="row" style="float: right">
      <div class="col-md-2" style="margin-right: 29%">
        <base-button
          :loading="loadingBack"
          class="pull-right"
          icon="arrow-left"
          color="theme"
          @click="back"
        >
          {{ $t("wizard.back") }}
        </base-button>
      </div>
      <div class="col-md-3">
        <base-button
          :loading="loading"
          class="pull-right"
          icon="arrow-right"
          rightIcon
          @click="next"
          color="theme"
        >
          {{ $t("wizard.continue") }}
        </base-button>
      </div>
    </div>
  </div>

  <!--  else not authorize-->
  <!-- <div v-else>
    <not-authorize></not-authorize>
  </div> -->
</template>
<script>
import { validationMixin } from "vuelidate";
const { required, email } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      loadingBack: false,
      loading: false,
      isLoading: false,
      notification_email: null,
      notify_invoice_viewed: null,
      notify_estimate_viewed: null,
      notify_email_attachment: null,
    };
  },
  validations: {
    notification_email: {
      required,
      email,
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async back() {
      this.loadingBack = true;
      let response = await window.axios.post("/api/admin/onboarding/companyBack");

      if (response.data.success) {
        this.$emit("back");
        this.loadingBack = false;
      }
    },

    async next() {
      this.loading = true;
      let response = await window.axios.post("/api/admin/onboarding/notification");

      if (response.data) {
        localStorage.setItem("onboarding", 7);
        if (this.fileObject && this.previewLogo) {
          let logoData = new FormData();
          logoData.append(
            "company_logo",
            JSON.stringify({
              name: this.fileObject.name,
              data: this.previewLogo,
            })
          );

          await axios.post("/api/admin/onboarding/company/upload-logo", logoData, {
            headers: {
              "Content-Type": "multipart/form-data",
              company: response.data.user.company.id,
            },
          });
        }

        this.$emit("next");
        this.loading = false;
      }
    },
    async fetchData() {
      let response1 = await axios.get(
        "/api/settings/get-setting?key=notify_invoice_viewed"
      );
      if (response1.data) {
        let data = response1.data;
        data.notify_invoice_viewed === "YES"
          ? (this.notify_invoice_viewed = true)
          : (this.notify_invoice_viewed = null);
      }
      let response2 = await axios.get(
        "/api/settings/get-setting?key=notify_estimate_viewed"
      );
      if (response2.data) {
        let data = response2.data;
        data.notify_estimate_viewed === "YES"
          ? (this.notify_estimate_viewed = true)
          : (this.notify_estimate_viewed = null);
      }
      let response3 = await axios.get("/api/settings/get-setting?key=notification_email");
      if (response3.data) {
        this.notification_email = response3.data.notification_email;
      }
      let response4 = await axios.get(
        "/api/settings/get-setting?key=notify_email_attachment"
      );
      if (response4.data) {
        let data = response4.data;
        data.notify_email_attachment === "YES"
          ? (this.notify_email_attachment = true)
          : (this.notify_email_attachment = null);
      }
    },
    async saveEmail() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      this.isLoading = true;
      let data = {
        key: "notification_email",
        value: this.notification_email,
      };
      let response = await axios.put("/api/settings/update-setting", data);
      if (response.data.success) {
        this.isLoading = false;
        window.toastr["success"](this.$tc("settings.notification.email_save_message"));
      }
    },
    async setInvoiceViewd(val) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.notify_invoice_viewed = !this.notify_invoice_viewed;
        return true;
      }
      let data = {
        key: "notify_invoice_viewed",
        value: this.notify_invoice_viewed ? "YES" : "NO",
      };

      let response = await axios.put("/api/settings/update-setting", data);
      if (response.data.success) {
        window.toastr["success"](this.$tc("general.setting_updated"));
      }
    },
    async setEstimateViewd(val) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.notify_estimate_viewed = !this.notify_estimate_viewed;
        return true;
      }
      let data = {
        key: "notify_estimate_viewed",
        value: this.notify_estimate_viewed ? "YES" : "NO",
      };
      let response = await axios.put("/api/settings/update-setting", data);
      if (response.data) {
        window.toastr["success"](this.$tc("general.setting_updated"));
      }
    },
    async setEmailAttachment(val) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.notify_email_attachment = !this.notify_email_attachment;
        return true;
      }
      let data = {
        key: "notify_email_attachment",
        value: this.notify_email_attachment ? "YES" : "NO",
      };

      let response = await axios.put("/api/settings/update-setting", data);
      if (response.data.success) {
        window.toastr["success"](this.$tc("general.setting_updated"));
      }
    },
  },
};
</script>
