var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-modal", style: { height: _vm.boolVar2 } },
    [
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCustomerData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "tabs",
                {
                  staticClass: "tabs-simple",
                  attrs: { options: { defaultTabHash: "basic-home" } },
                },
                [
                  _c(
                    "tab",
                    {
                      attrs: {
                        id: "basic-home",
                        name: _vm.$t("customers.basic_info"),
                      },
                    },
                    [
                      _c("div", { staticClass: "basic-info" }, [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-4 col-form-label input-label",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("customers.customer_type")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-3" },
                            [
                              _c(
                                "base-button",
                                {
                                  staticClass: "btnStyles",
                                  class: { active: _vm.isP == true },
                                  style: {
                                    background: _vm.boolVar3,
                                    color: _vm.boolVar4,
                                  },
                                  attrs: {
                                    color: "theme",
                                    type: "button",
                                    onMouseOver: _vm.mouseIn1,
                                    onMouseOut: _vm.mouseOut2,
                                    id: "private_btn",
                                  },
                                  on: { click: _vm.showForm },
                                },
                                [
                                  _vm._v(
                                    "\n                  Private customer\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-3" },
                            [
                              _c(
                                "base-button",
                                {
                                  staticClass: "btnStyles",
                                  class: { active: _vm.isB == true },
                                  style: {
                                    background: _vm.boolVar5,
                                    color: _vm.boolVar6,
                                  },
                                  attrs: {
                                    color: "theme",
                                    type: "button",
                                    onMouseOver: _vm.mouseIn3,
                                    onMouseOut: _vm.mouseOut4,
                                    id: "business_btn",
                                  },
                                  on: { click: _vm.hideForm },
                                },
                                [
                                  _vm._v(
                                    "\n                  Business customer\n                "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v("\n             \n\n            "),
                        _vm.isBusiness
                          ? _c("div", [
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-labe",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t("customers.company_type")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-sm-6" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formData.removelines,
                                        expression: "formData.removelines",
                                      },
                                    ],
                                    attrs: {
                                      invalid:
                                        _vm.$v.formData.removelines.$error,
                                      checked: "",
                                      type: "radio",
                                      id: "f-option",
                                      name: "selector",
                                    },
                                    domProps: {
                                      value: 1,
                                      checked: _vm._q(
                                        _vm.formData.removelines,
                                        1
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.formData,
                                          "removelines",
                                          1
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: "f-option" } }, [
                                    _vm._v("Company With Personal Liabaility"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "check" }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formData.removelines,
                                        expression: "formData.removelines",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "s-option",
                                      name: "selector",
                                    },
                                    domProps: {
                                      value: 2,
                                      checked: _vm._q(
                                        _vm.formData.removelines,
                                        2
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.formData,
                                          "removelines",
                                          2
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: "s-option" } }, [
                                    _vm._v("Capital Company"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "check" }),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.formData.removelines,
                                        expression: "formData.removelines",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "t-option",
                                      name: "selector",
                                    },
                                    domProps: {
                                      value: 3,
                                      checked: _vm._q(
                                        _vm.formData.removelines,
                                        3
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.formData,
                                          "removelines",
                                          3
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", { attrs: { for: "t-option" } }, [
                                    _vm._v("Public Company / Authority"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "check" }),
                                  _vm._v(" "),
                                  _vm.$v.formData.removelines.$error
                                    ? _c("div", { staticClass: "col-sm-12" }, [
                                        !_vm.$v.formData.removelines.required
                                          ? _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "validation.required"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("hr"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isPrivate
                          ? _c("div", [
                              _vm.isBusiness
                                ? _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-sm-4 col-form-label input-label",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("customers.cvrNumber")
                                          ) + "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-sm-6" },
                                      [
                                        _c("base-input", {
                                          ref: "name",
                                          attrs: {
                                            type: "text",
                                            name: "cvr_no",
                                          },
                                          on: { blur: _vm.getCompanyInfo },
                                          model: {
                                            value: _vm.formData.cvr_no,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.formData,
                                                "cvr_no",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "formData.cvr_no",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customers.customer_number")
                                      ) + "\n                  "
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-prefix-input", {
                                      attrs: {
                                        prefix: _vm.customerPrefix,
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.customerNumAttribute,
                                        callback: function ($$v) {
                                          _vm.customerNumAttribute = $$v
                                        },
                                        expression: "customerNumAttribute",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("customers.eanNumber")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        focus: "",
                                        type: "text",
                                        name: "ean_no",
                                        "tab-index": "1",
                                      },
                                      model: {
                                        value: _vm.formData.ean_no,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "ean_no", $$v)
                                        },
                                        expression: "formData.ean_no",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("customers.display_name")) +
                                        "\n                  "
                                    ),
                                    _c("span", { staticClass: "required" }, [
                                      _vm._v("*"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-input", {
                                      ref: "name",
                                      attrs: {
                                        invalid: _vm.$v.formData.name.$error,
                                        type: "text",
                                        name: "name",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.name.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.formData.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.$v.formData.name.$error
                                      ? _c("div", [
                                          !_vm.$v.formData.name.required
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "validation.required"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.$v.formData.name.minLength
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "validation.name_min_length",
                                                          _vm.$v.formData.name
                                                            .$params.minLength
                                                            .min,
                                                          {
                                                            count:
                                                              _vm.$v.formData
                                                                .name.$params
                                                                .minLength.min,
                                                          }
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.$v.formData.name.alpha
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "validation.characters_only"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("customers.primary_display_name")
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.formData.contact_name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "contact_name",
                                            $$v
                                          )
                                        },
                                        expression: "formData.contact_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("login.email")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        invalid: _vm.$v.formData.email.$error,
                                        type: "text",
                                        name: "email",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.formData.email.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.formData.email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "email",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.email",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.$v.formData.email.$error
                                      ? _c("div", [
                                          !_vm.$v.formData.email.required
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "validation.required"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.$v.formData.email.email
                                            ? _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "validation.email_incorrect"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tc("settings.currencies.currency")
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-select", {
                                      attrs: {
                                        options: _vm.currencies,
                                        searchable: true,
                                        "allow-empty": false,
                                        "show-labels": false,
                                        placeholder: _vm.$t(
                                          "customers.select_currency"
                                        ),
                                        label: "name",
                                        "track-by": "id",
                                      },
                                      model: {
                                        value: _vm.currency,
                                        callback: function ($$v) {
                                          _vm.currency = $$v
                                        },
                                        expression: "currency",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("customers.phone")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-input", {
                                      attrs: { type: "text", name: "phone" },
                                      model: {
                                        value: _vm.formData.phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "phone",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "col-sm-4 col-form-label input-label",
                                  },
                                  [_vm._v(_vm._s(_vm.$t("customers.website")))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        type: "text",
                                        invalid:
                                          !_vm.formData.isValid &&
                                          _vm.formData.website != "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.checkWebsite($event)
                                        },
                                        change: function ($event) {
                                          return _vm.checkWebsite($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.formData.website,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.formData, "website", $$v)
                                        },
                                        expression: "formData.website",
                                      },
                                    }),
                                    _vm._v(" "),
                                    !_vm.formData.isValid &&
                                    _vm.formData.website != ""
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v(" Invalid Url ")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isPrivate
                    ? _c(
                        "div",
                        [
                          _c(
                            "tab",
                            {
                              attrs: {
                                id: "basic-profile",
                                name: _vm.$t("customers.billing_address"),
                              },
                            },
                            [
                              _c("div", { staticClass: "basic-info" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("customers.name")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.billing.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.billing, "name", $$v)
                                          },
                                          expression: "billing.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("customers.phone")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: { type: "text", name: "phone" },
                                        model: {
                                          value: _vm.billing.phone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billing,
                                              "phone",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "billing.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customers.address"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-text-area", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("general.street_1"),
                                          rows: "2",
                                          cols: "50",
                                          invalid:
                                            _vm.$v.billing.address_street_1
                                              .$error,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.billing.address_street_1.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.billing.address_street_1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billing,
                                              "address_street_1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "billing.address_street_1",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.billing.address_street_1.$error
                                        ? _c("div", [
                                            !_vm.$v.billing.address_street_1
                                              .required
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$tc(
                                                            "validation.required"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.$v.billing.address_street_1
                                              .maxLength
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "validation.address_maxlength"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("base-text-area", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("general.street_2"),
                                          rows: "2",
                                          cols: "50",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.billing.address_street_2.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.billing.address_street_2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.billing,
                                              "address_street_2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "billing.address_street_2",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.billing.address_street_2.$error
                                        ? _c("div", [
                                            !_vm.$v.billing.address_street_2
                                              .maxLength
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "validation.address_maxlength"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customers.country"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-select", {
                                        attrs: {
                                          invalid: _vm.$v.billingCountry.$error,
                                          options: _vm.countryList,
                                          searchable: true,
                                          "show-labels": false,
                                          placeholder: _vm.$t(
                                            "general.select_country"
                                          ),
                                          "allow-empty": false,
                                          "track-by": "id",
                                          label: "name",
                                        },
                                        model: {
                                          value: _vm.billingCountry,
                                          callback: function ($$v) {
                                            _vm.billingCountry = $$v
                                          },
                                          expression: "billingCountry",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.billingCountry.$error
                                        ? _c("div", [
                                            !_vm.$v.billingCountry.required
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$tc(
                                                            "validation.required"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                this.billingCountry.name != "Denmark"
                                  ? _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "col-sm-4 col-form-label input-label",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.state"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-7" },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                type: "text",
                                                name: "billingState",
                                              },
                                              model: {
                                                value: _vm.billing.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.billing,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression: "billing.state",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("customers.city")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: {
                                          type: "text",
                                          name: "billingCity",
                                          invalid: _vm.$v.billing.city.$error,
                                        },
                                        model: {
                                          value: _vm.billing.city,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.billing, "city", $$v)
                                          },
                                          expression: "billing.city",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.billing.city.$error
                                        ? _c("div", [
                                            !_vm.$v.billing.city.required
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$tc(
                                                            "validation.required"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customers.zip_code"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.billing.zip,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.billing, "zip", $$v)
                                          },
                                          expression: "billing.zip",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tab",
                            {
                              attrs: {
                                id: "basic-message",
                                name: _vm.$t("customers.shipping_address"),
                              },
                            },
                            [
                              _c("div", { staticClass: "basic-info" }, [
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-12 copy-address-button",
                                    },
                                    [
                                      _c(
                                        "base-button",
                                        {
                                          ref: "sameAddress",
                                          staticClass: "mr-2 btn-sm",
                                          attrs: {
                                            icon: "copy",
                                            color: "theme",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.copyAddress(true)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "customers.copy_billing_address"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("customers.name")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.shipping.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.shipping, "name", $$v)
                                          },
                                          expression: "shipping.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("customers.phone")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: { type: "text", name: "phone" },
                                        model: {
                                          value: _vm.shipping.phone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.shipping,
                                              "phone",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "shipping.phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customers.address"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-text-area", {
                                        staticClass: "mb-1",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("general.street_1"),
                                          rows: "2",
                                          cols: "50",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.shipping.address_street_1.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.shipping.address_street_1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.shipping,
                                              "address_street_1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "shipping.address_street_1",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.shipping.address_street_1.$error
                                        ? _c("div", [
                                            !_vm.$v.shipping.address_street_1
                                              .maxLength
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "validation.address_maxlength"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("base-text-area", {
                                        attrs: {
                                          placeholder:
                                            _vm.$t("general.street_2"),
                                          rows: "2",
                                          cols: "50",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.shipping.address_street_2.$touch()
                                          },
                                        },
                                        model: {
                                          value: _vm.shipping.address_street_2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.shipping,
                                              "address_street_2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "shipping.address_street_2",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.$v.shipping.address_street_2.$error
                                        ? _c("div", [
                                            !_vm.$v.shipping.address_street_2
                                              .maxLength
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "validation.address_maxlength"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customers.country"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.countryList,
                                          searchable: true,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          placeholder: _vm.$t(
                                            "general.select_country"
                                          ),
                                          "track-by": "id",
                                          label: "name",
                                        },
                                        model: {
                                          value: _vm.shippingCountry,
                                          callback: function ($$v) {
                                            _vm.shippingCountry = $$v
                                          },
                                          expression: "shippingCountry",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                this.billingCountry.name != "Denmark"
                                  ? _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "col-sm-4 col-form-label input-label",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("customers.state"))
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-sm-7" },
                                          [
                                            _c("base-input", {
                                              attrs: {
                                                type: "text",
                                                name: "shippingState",
                                              },
                                              model: {
                                                value: _vm.shipping.state,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.shipping,
                                                    "state",
                                                    $$v
                                                  )
                                                },
                                                expression: "shipping.state",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("customers.city")))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: {
                                          type: "text",
                                          name: "shippingCity",
                                        },
                                        model: {
                                          value: _vm.shipping.city,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.shipping, "city", $$v)
                                          },
                                          expression: "shipping.city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "col-sm-4 col-form-label input-label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("customers.zip_code"))
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-7" },
                                    [
                                      _c("base-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.shipping.zip,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.shipping, "zip", $$v)
                                          },
                                          expression: "shipping.zip",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isFront
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "card-footer" },
                      [
                        _c(
                          "base-button",
                          {
                            staticClass: "mr-3",
                            attrs: { outline: true, color: "theme" },
                            on: { click: _vm.cancelCustomer },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "base-button",
                          {
                            attrs: {
                              loading: _vm.isLoading,
                              icon: "save",
                              color: "theme",
                              type: "submit",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.save")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showmodal
        ? _c("transition", { attrs: { name: "modal" } }, [
            _c("div", { staticClass: "modal-mask" }, [
              _c("div", { staticClass: "modal-wrapper" }, [
                _c("div", { staticClass: "modal-container" }, [
                  _c(
                    "div",
                    { staticClass: "modal-header" },
                    [
                      _vm._t("header", function () {
                        return [
                          _c("h3", [_c("center", [_vm._v("Warning!!")])], 1),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-body" },
                    [
                      _vm._t("body", function () {
                        return [
                          _vm._v(
                            "\n              When you trade with a company, it is important to know\n              "
                          ),
                          _c("strong", [_vm._v("EXACTLY")]),
                          _vm._v(
                            " which company you are trading with. We therefore\n              recommend that you get a CVR number on the customer.\n            "
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "modal-footer",
                      staticStyle: { "justify-content": "center" },
                    },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "btnStyles",
                          attrs: { color: "theme", type: "button" },
                          on: { click: _vm.popback },
                        },
                        [_vm._v("\n              Go back\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "base-button",
                        {
                          staticClass: "btnStyles",
                          attrs: { color: "theme", type: "button" },
                          on: { click: _vm.popclose },
                        },
                        [
                          _vm._v(
                            "\n              Proceed without CVR\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }