var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Mail Configuration")
    ? _c("div", { staticClass: "setting-main-container" }, [
        _c("div", { staticClass: "card setting-card" }, [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("settings.mail.mail_config"))),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "page-sub-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.mail.mail_config_desc")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.mailConfigData
            ? _c(
                "div",
                [
                  _c(
                    _vm.mail_driver,
                    {
                      tag: "component",
                      attrs: {
                        "config-data": _vm.mailConfigData,
                        loading: _vm.loading,
                        "mail-drivers": _vm.mail_drivers,
                      },
                      on: {
                        "on-change-driver": function (val) {
                          return (_vm.mail_driver =
                            _vm.mailConfigData.mail_driver =
                              val)
                        },
                        "submit-data": _vm.saveEmailConfig,
                      },
                    },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "pull-right mt-4 ml-2",
                          attrs: {
                            loading: _vm.loading,
                            outline: "",
                            icon: "check",
                            color: "theme",
                            type: "button",
                          },
                          on: { click: _vm.openMailTestModal },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.test_mail_conf")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }