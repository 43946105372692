<template>
  <div class="template-modal" :style="{ height: boolvar }">
    <div class="card-body">
      <div v-if="this.templateArray.length > 0" class="template-container">
        <b-carousel
          ref="myCarousel"
          id="carousel-1"
          v-model="slide"
          :interval="0"
          controls
          indicators
          background="#FFFFFF"
          img-width="1024"
          img-height="480"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
        >
          <div v-for="(template, index) in this.templateArray" :key="index">
            <b-carousel-slide
              :id="template.id.toString()"
              v-bind:class="selectedTemplate == template.id ? 'active' : ''"
              contain
              class="image-fit"
              :img-src="template.path"
            ></b-carousel-slide>
          </div>
        </b-carousel>
      </div>
      <div v-else>
        <p><center>No Templates Available</center></p>
      </div>
    </div>
    <div class="card-footer">
      <base-button outline class="mr-3" color="theme" @click="closeInvoiceModal">
        {{ $t("general.cancel") }}
      </base-button>
      <base-button
        v-if="modalData.length > 0"
        :loading="isLoading"
        color="theme"
        @click="chooseTemplate()"
      >
        {{ $t("general.choose") }}
      </base-button>
      <base-button
        v-if="modalData.length == 0"
        :loading="isLoading"
        color="theme"
        @click="createTemplate()"
      >
        {{ $t("editor.add_new_template") }}
      </base-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      boolvar: 0,
      templateArray: [],
      tempData: null,
      selectTemplate: ".active",
      selectedTemplate: null,
      isLoading: false,
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    ...mapGetters("modal", ["modalData"]),
    ...mapGetters("subAdmin", ["getTemplateId", "getTemplateName"]),
  },

  mounted() {
    var index = -1;

    this.templateArray = [...this.modalData];
    // console.log(this.templateArray);
    this.selectedTemplate = this.getTemplateId;

    if (this.selectedTemplate) {
      this.tempData = this.templateArray.find((c) => c.id == this.selectedTemplate);
      if (this.tempData) {
        for (var i = 0; i < this.templateArray.length; i++) {
          if (this.templateArray[i].id == this.selectedTemplate) {
            index = i;
          }
        }

        if (index > -1) {
          this.templateArray.splice(index, 1);
        }

        this.templateArray.unshift(this.tempData);
      }
    }

    this.boolvar = "";
  },
  methods: {
    ...mapActions("subAdmin", ["setTemplate"]),
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    async chooseTemplate() {
      this.isLoading = true;
      const template_id = document.querySelector(".active").id;

      let data = {
        template_id,
      };
      this.selectedTemplate = template_id;
      let resp = await this.setTemplate(data);
      if (resp) {
        this.isLoading = false;
        this.resetModalData();
        this.templateArray = [];
        this.closeModal();
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    createTemplate() {
      this.closeInvoiceModal();
      this.$router.push("/system/editor/create");
      this.scrollToTop();
    },
    closeInvoiceModal() {
      this.selectedTemplate = this.getTemplateId;
      this.closeModal();
      this.resetModalData();
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },

    setSlide(index = 8) {
      this.$refs.myCarousel.setSlide(index);
    },
  },
};
</script>

<style>
.image-fit {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.template-modal .template-container {
  justify-content: center;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: #0373ff;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: #0373ff;
}

.carousel-indicators {
  background-color: #ffffff;
}

.carousel-indicators li {
  background-color: #0373ff;
}
</style>
