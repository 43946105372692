var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Faktura Fee")
    ? _c("div", { staticClass: "setting-main-container" }, [
        _c("div", { staticClass: "card setting-card" }, [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("settings.fakturafee.title"))),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "page-sub-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.facturafee.description")) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveFakturaFee()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.$t("settings.facturafee.fee"))),
                  ]),
                  _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "base-input" },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "input-field",
                            class: { invalid: _vm.$v.fakturaVal.$error },
                            model: {
                              value: _vm.price,
                              callback: function ($$v) {
                                _vm.price = $$v
                              },
                              expression: "price",
                            },
                          },
                          "money",
                          _vm.defaultCurrencyForInput,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$v.fakturaVal.$error
                    ? _c("div", [
                        !_vm.$v.fakturaVal.required
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(_vm.$tc("validation.required"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.fakturaVal.numeric
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tc("validation.numbers_only")) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                        icon: "save",
                        color: "theme",
                        type: "submit",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("settings.notification.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "flex-box mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("base-switch", {
                  staticClass: "btn-switch",
                  on: { change: _vm.setFacturaFee },
                  model: {
                    value: _vm.facturaFee,
                    callback: function ($$v) {
                      _vm.facturaFee = $$v
                    },
                    expression: "facturaFee",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "right ml-15" }, [
              _c("p", { staticClass: "box-title" }, [
                _vm._v(_vm._s(_vm.$t("settings.fakturafee.title"))),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "box-desc" }, [
                _vm._v(_vm._s(_vm.$t("settings.fakturafee.title"))),
              ]),
            ]),
          ]),
        ]),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }