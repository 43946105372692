<template>
  <div class="setting-main-container" v-if="$can('Printing Fee')">
    <div class="card setting-card">
      <div class="page-header">
        <h3 class="page-title">{{ $t("settings.printingfee.title") }}</h3>
        <p class="page-sub-title">
          {{ $t("settings.printingfee.description") }}
        </p>
      </div>
      <form action="" @submit.prevent="savePrintingFee()">
        <div class="form-group">
          <label class="form-label">{{ $t("settings.printingfee.title") }}</label
          ><span class="text-danger"> *</span>
          <div class="base-input">
            <money
              :class="{ invalid: $v.printingVal.$error }"
              v-model="price"
              v-bind="defaultCurrencyForInput"
              class="input-field"
            />
          </div>
          <!-- <base-input
            :invalid="$v.fakturaVal.$error"
            v-model.trim="fakturaVal"
            :placeholder="$tc('settings.facturafee.please_enter_amount')"
            type="text"
            name="fakturaVal"
            input-class="col-md-6"
            @input="$v.fakturaVal.$touch()"
          /> -->
          <div v-if="$v.printingVal.$error">
            <span v-if="!$v.printingVal.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
            <span v-if="!$v.printingVal.numeric" class="text-danger">
              {{ $tc("validation.numbers_only") }}
            </span>
          </div>
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            class="mt-4"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ $tc("settings.notification.save") }}
          </base-button>
        </div>
      </form>
      <hr />
      <div class="flex-box mt-3 mb-4">
        <div class="left">
          <base-switch
            v-model="printingFee"
            class="btn-switch"
            @change="setPrintingFee"
          />
        </div>
        <div class="right ml-15">
          <p class="box-title">{{ $t("settings.printingfee.title") }}</p>
          <p class="box-desc">{{ $t("settings.printingfee.title") }}</p>
        </div>
      </div>
      <!-- <div class="flex-box mb-2">
        <div class="left">
          <base-switch
            v-model="notify_estimate_viewed"
            class="btn-switch"
            @change="setEstimateViewd"
          />
        </div>
        <div class="right ml-15">
          <p class="box-title">{{ $t("settings.notification.estimate_viewed") }}</p>
          <p class="box-desc">{{ $t("settings.notification.estimate_viewed_desc") }}</p>
        </div>
      </div>  -->
    </div>
  </div>

  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { mapActions, mapGetters } from "vuex";
const {
  required,
  email,
  minLength,
  numeric,
  minValue,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      printingVal: "",
      printingFee: null,
    };
  },
  validations: {
    printingVal: {
      required,
      numeric,
      maxLength: maxLength(20),
      minValue: minValue(0.1),
    },
  },
  computed: {
    ...mapGetters("currency", ["defaultCurrencyForInput"]),
    price: {
      get: function () {
        return this.printingVal / 100;
      },
      set: function (newValue) {
        this.printingVal = newValue * 100;
      },
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let response1 = await axios.get("/api/settings/get-fee?key=printing_fee");

      if (response1.data) {
        this.printingVal = response1.data.printing_fee;
      }

      let response2 = await axios.get(
        "/api/settings/get-setting?key=printing_fee_charge"
      );
      if (response2.data) {
        let data = response2.data;
        data.printing_fee_charge === "YES"
          ? (this.printingFee = true)
          : (this.printingFee = null);
      }
    },
    async setPrintingFee(val) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.printingFee = !this.printingFee;
        return true;
      }
      let data = {
        key: "printing_fee_charge",
        value: this.printingFee ? "YES" : "NO",
      };

      let response = await axios.put("/api/settings/update-setting", data);
      if (response.data.success) {
        window.toastr["success"](this.$tc("general.setting_updated"));
      }
    },
    async savePrintingFee() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      this.isLoading = true;
      let data = {
        key: "printing_fee",
        value: this.printingVal,
      };
      let response = await axios.put("/api/settings/update-setting", data);
      if (response.data.success) {
        this.isLoading = false;
        window.toastr["success"](this.$tc("settings.printingfee.fee_save_message"));
      }
    },
  },
};
</script>
