var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-create main-content" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitActivityData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              _vm._s(
                _vm.isEdit
                  ? _vm.$t("activities.edit_activity")
                  : _vm.$t("activities.new_activity")
              )
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/system/dashboard" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/system/activities" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$tc("activities.title", 2)))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("activities.edit_activity")
                      : _vm.$t("activities.new_activity")
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions header-button-container" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    tabindex: 23,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("activities.update_activity")
                          : _vm.$t("activities.save_activity")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "customer-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(_vm._s(_vm.$t("activities.basic_info"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("activities.name"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(_vm._s(_vm.$t("activities.notes"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("activities.description"))),
                    ]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: { rows: "3", cols: "50" },
                      model: {
                        value: _vm.formData.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "description", $$v)
                        },
                        expression: "formData.description",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }