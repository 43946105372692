import * as types from './mutation-types'

export const addSubscriptionPlan = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/plans', data)
      .then((response) => {
        commit(types.ADD_PLANS, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchSubscriptionPlanType = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .get(`/api/plans/${id}/edit`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const updateSubscriptionPlanType = (
  { commit, dispatch, state },
  data
) => {
  return new Promise((resolve, reject) => {
    window.axios
      .put(`/api/plans/${data.id}`, data)
      .then((response) => {
        // console.log(response)
        commit(types.UPDATE_PLANS, response.data)
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const deleteSubscriptionPlanType = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {

    window.axios
      .delete(`/api/plans/${id}`)
      .then((response) => {
        if (response.data.success) {
          commit(types.DELETE_PLANS, id)
        }
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
