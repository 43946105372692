import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_INTEGRATIONS] (state, integrations) {
    state.integrations = integrations
  },

  [types.SET_TOTAL_INTEGRATIONS] (state, totalIntegrations) {
    state.totalIntegrations = totalIntegrations
  },

  [types.ADD_INTEGRATION] (state, data) {
    state.integrations.push(data.integration)
  },

  [types.UPDATE_INTEGRATION] (state, data) {
    let pos = state.integrations.findIndex(integration => integration.id === data.integration.id)

    state.integrations[pos] = data.integration
  },

  [types.DELETE_INTEGRATION] (state, id) {
    let index = state.integrations.findIndex(integration => integration.id === id)
    state.integrations.splice(index, 1)
  },

  [types.DELETE_MULTIPLE_INTEGRATIONS] (state, selectedRoles) {
    selectedIntegrations.forEach((integration) => {
      let index = state.integrations.findIndex(_cust => _cust.id === integration.id)
      state.integrations.splice(index, 1)
    })

    state.selectedIntegrations = []
  },

  [types.SET_SELECTED_INTEGRATIONS] (state, data) {
    state.selectedIntegrations = data
  },

  [types.RESET_SELECTED_INTEGRATION] (state, data) {
    state.selectedIntegration = null
  },

  [types.SET_SELECT_ALL_STATE] (state, data) {
    state.selectAllField = data
  }

}
