<template>
  <div class="item-unit-modal template-container" style="height: 500px">
    <div class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats" style="margin-left: 2%">
          {{ $t("general.showing") }}: <b>{{ installments.length }}</b>
          {{ $t("general.of") }} <b>{{ totalInstallments }}</b>
        </p>
      </div>
      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <!-- <table-column :label="$t('invoices.invoice_number')" show="id" /> -->
        <table-column
          :label="$t('installmentPlan.invoice_number')"
          show="invoices.invoice_number"
        />
        <table-column :label="$t('installmentPlan.amount')" show="amount" />
        <table-column :label="$t('installmentPlan.due_date')" show="formattedDueDate" />
        <table-column :label="$t('installmentPlan.status')" show="status" />
      </table-component>
      <!-- <base-loader v-if="ProcessSendInvoice" /> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");
export default {
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      isLoading: false,
      invoice_number: null,
      formData: {
        id: null,
        name: null,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
    ...mapGetters("invoice", [
      "selectedInvoices",
      "totalInstallments",
      "installments",
      "selectAllField",
    ]),
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  },
  async mounted() {},
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("item", ["addItemUnit", "updateItemUnit", "fatchItemUnit"]),
    ...mapActions("invoice", ["fetchInstallmentData"]),
    resetFormData() {
      this.formData = {
        id: null,
        name: null,
      };
      this.$v.formData.$reset();
    },
    async submitItemUnit() {
      this.$v.formData.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      this.isLoading = true;

      let response;

      if (this.isEdit) {
        try {
          response = await this.updateItemUnit(this.formData);

          if (response.data) {
            window.toastr["success"](
              this.$t("settings.customization.items.item_unit_updated")
            );
            this.closePaymentModeModal();
            return true;
          }
        } catch (err) {
          if (err.response.data.errors.name) {
            this.isLoading = false;
          }
        }
      } else {
        try {
          response = await this.addItemUnit(this.formData);
          if (response.data) {
            this.isLoading = false;
            window.toastr["success"](
              this.$t("settings.customization.items.item_unit_added")
            );
            this.closePaymentModeModal();
            return true;
          }
        } catch (err) {
          if (err.response.data.errors.name) {
            this.isLoading = false;
          }
        }
      }
    },
    async setData() {
      this.formData = {
        id: this.modalData.id,
        name: this.modalData.name,
      };
    },

    async fetchData({ page, filter, sort }) {
      let data = {
        page,
        invoice_number: this.modalData,
      };

      this.isRequestOngoing = true;

      let response = await this.fetchInstallmentData(data);
      this.isRequestOngoing = false;

      this.currency = response.data.currency;

      return {
        data: response.data.invoices.data,
        pagination: {
          totalPages: response.data.invoices.last_page,
          currentPage: page,
          count: response.data.invoices.count,
        },
      };
    },

    closePaymentModeModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.modal-body {
  max-width: 60%;
}

.template-modal .template-container {
  justify-content: center;
  max-width: 480px;
  margin: 0 auto;
  overflow: visible;
}
</style>
