var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper fadeInDown" }, [
    _c("div", { attrs: { id: "formContent" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "loginForm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.validateBeforeSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "fadeIn second", class: { "form-group": true } },
            [
              _c("p", { staticClass: "input-label input-fields" }, [
                _vm._v("\n          " + _vm._s(_vm.$t("login.email")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                staticClass: "input-fields",
                attrs: {
                  invalid: _vm.$v.formData.email.$error,
                  placeholder: _vm.$t("login.enter_email"),
                  focus: "",
                  id: "login",
                  type: "email",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.formData.email.$touch()
                  },
                },
                model: {
                  value: _vm.formData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "email", $$v)
                  },
                  expression: "formData.email",
                },
              }),
              _vm._v(" "),
              _vm.$v.formData.email.$error
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "margin-left": "5%",
                      },
                    },
                    [
                      !_vm.$v.formData.email.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tc("validation.required")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.email.email
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tc("validation.email_incorrect")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isSent
            ? _c(
                "base-button",
                {
                  staticClass: "fadeIn third",
                  staticStyle: { "margin-left": "5%", "margin-bottom": "4%" },
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    type: "submit",
                    color: "theme",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("validation.send_reset_link")) +
                      "\n      "
                  ),
                ]
              )
            : _c(
                "base-button",
                {
                  staticClass: "fadeIn third",
                  staticStyle: { "margin-left": "5%", "margin-bottom": "4%" },
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("validation.not_yet")) +
                      "\n      "
                  ),
                ]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "fadeIn fourth", attrs: { id: "formFooter" } }, [
        _c(
          "div",
          { staticClass: "other-actions mb-4" },
          [
            _c(
              "router-link",
              { staticStyle: { color: "#0373ff" }, attrs: { to: "/login" } },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("general.back_to_login")) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fadeIn first" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          staticStyle: {
            "margin-top": "15%",
            "margin-bottom": "-18%",
            "padding-bottom": "30%",
            width: "30%",
          },
          attrs: { src: "/assets/img/faktura-logo.png", alt: "Faktura Logo" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }