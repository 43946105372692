var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-create main-content" },
    [
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCustomerData.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("customers.edit_customer")
                      : _vm.$t("customers.new_customer")
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "/system/dashboard" },
                      slot: "item-title",
                    },
                    [_vm._v(_vm._s(_vm.$t("general.home")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "/system/customers" },
                      slot: "item-title",
                    },
                    [_vm._v(_vm._s(_vm.$tc("customers.customer", 2)))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("li", { staticClass: "breadcrumb-item" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.isEdit
                        ? _vm.$t("customers.edit_customer")
                        : _vm.$t("customers.new_customer")
                    ) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-actions header-button-container" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.saveBtnDisable,
                      tabindex: 23,
                      icon: "save",
                      color: "theme",
                      type: "submit",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("customers.update_customer")
                            : _vm.$t("customers.save_customer")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "customer-card card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "section-title col-sm-2" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("customers.customer_type")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-4" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "btnStyles",
                        class: { active: _vm.isP == true },
                        style: {
                          background: _vm.boolVar1,
                          color: _vm.boolVar2,
                        },
                        attrs: {
                          color: "theme",
                          type: "button",
                          onMouseOver: _vm.mouseIn1,
                          onMouseOut: _vm.mouseOut2,
                          id: "private_btn",
                        },
                        on: { click: _vm.checkdialog },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.pcustomer")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-4" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "btnStyles screens",
                        class: { active: _vm.isB == true },
                        style: {
                          background: _vm.boolVar3,
                          color: _vm.boolVar4,
                        },
                        attrs: {
                          color: "theme",
                          type: "button",
                          onMouseOver: _vm.mouseIn3,
                          onMouseOut: _vm.mouseOut4,
                          id: "business_btn",
                        },
                        on: { click: _vm.showCVR },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("customers.bcustomer")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.isBusiness
              ? _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-2 section-title" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("customers.company_type")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.removelines,
                            expression: "formData.removelines",
                          },
                        ],
                        attrs: {
                          invalid: _vm.$v.formData.removelines.$error,
                          type: "radio",
                          id: "f-option",
                          name: "selector",
                        },
                        domProps: {
                          value: 1,
                          checked: _vm._q(_vm.formData.removelines, 1),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, "removelines", 1)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "f-option" } }, [
                        _vm._v(_vm._s(_vm.$t("customers.type1"))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "check" }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.removelines,
                            expression: "formData.removelines",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "s-option",
                          name: "selector",
                        },
                        domProps: {
                          value: 2,
                          checked: _vm._q(_vm.formData.removelines, 2),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, "removelines", 2)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "s-option" } }, [
                        _vm._v(_vm._s(_vm.$t("customers.type2"))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "check" }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.removelines,
                            expression: "formData.removelines",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          id: "t-option",
                          name: "selector",
                        },
                        domProps: {
                          value: 3,
                          checked: _vm._q(_vm.formData.removelines, 3),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, "removelines", 3)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "t-option" } }, [
                        _vm._v(_vm._s(_vm.$t("customers.type3"))),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "check" }),
                      _vm._v(" "),
                      _vm.$v.formData.removelines.$error
                        ? _c("div", { staticClass: "col-sm-12" }, [
                            !_vm.$v.formData.removelines.required
                              ? _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tc("validation.required")) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.isPrivate
            ? _c("div", { staticClass: "customer-card card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "section-title col-sm-2" }, [
                      _vm._v(_vm._s(_vm.$t("customers.basic_info"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _vm.isBusiness
                        ? _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { staticClass: "form-label" }, [
                                _vm._v(_vm._s(_vm.$t("customers.cvrNumber"))),
                              ]),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(" *"),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                attrs: {
                                  focus: "",
                                  type: "text",
                                  name: "cvr_no",
                                  "tab-index": "1",
                                },
                                on: { blur: _vm.getCompanyInfo },
                                model: {
                                  value: _vm.formData.cvr_no,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "cvr_no", $$v)
                                  },
                                  expression: "formData.cvr_no",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.customer_number"))),
                          ]),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-prefix-input", {
                            attrs: { prefix: _vm.customerPrefix, disabled: "" },
                            model: {
                              value: _vm.customerNumAttribute,
                              callback: function ($$v) {
                                _vm.customerNumAttribute = $$v
                              },
                              expression: "customerNumAttribute",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.display_name"))),
                          ]),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              focus: "",
                              type: "text",
                              name: "name",
                              "tab-index": "1",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.name.$error
                            ? _c("div", [
                                !_vm.$v.formData.name.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.formData.name.minLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc(
                                              "validation.name_min_length",
                                              _vm.$v.formData.name.$params
                                                .minLength.min,
                                              {
                                                count:
                                                  _vm.$v.formData.name.$params
                                                    .minLength.min,
                                              }
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.email"))),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.formData.email.$error,
                              type: "text",
                              name: "email",
                              "tab-index": "3",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.email.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.email",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.email.$error
                            ? _c("div", [
                                !_vm.$v.formData.email.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.formData.email.email
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc(
                                              "validation.email_incorrect"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.eanNumber"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              focus: "",
                              type: "text",
                              name: "ean_no",
                              "tab-index": "1",
                            },
                            model: {
                              value: _vm.formData.ean_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "ean_no", $$v)
                              },
                              expression: "formData.ean_no",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(
                              _vm._s(_vm.$t("customers.primary_contact_name"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              label: _vm.$t("customers.contact_name"),
                              type: "text",
                              "tab-index": "2",
                            },
                            model: {
                              value: _vm.formData.contact_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "contact_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.contact_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.phone"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              type: "number",
                              name: "phone",
                              "tab-index": "4",
                            },
                            model: {
                              value: _vm.formData.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formData.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.website"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              "tab-index": "6",
                              invalid:
                                !_vm.formData.isValid &&
                                _vm.formData.website != "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkWebsite($event)
                              },
                              change: function ($event) {
                                return _vm.checkWebsite($event)
                              },
                            },
                            model: {
                              value: _vm.formData.website,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "website", $$v)
                              },
                              expression: "formData.website",
                            },
                          }),
                          _vm._v(" "),
                          !_vm.formData.isValid && _vm.formData.website != ""
                            ? _c("div", [
                                _c("span", { staticClass: "text-danger" }, [
                                  _vm._v(" Invalid Url "),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "section-title col-sm-2" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("customers.billing_address")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.name"))),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.billing.name.$error,
                              type: "text",
                              name: "address_name",
                              "tab-index": "7",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.billing.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.billing.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.billing,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "billing.name",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.billing.name.$error
                            ? _c("div", [
                                !_vm.$v.billing.name.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.billing.name.minLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc(
                                              "validation.name_min_length",
                                              _vm.$v.billing.name.$params
                                                .minLength.min,
                                              {
                                                count:
                                                  _vm.$v.billing.name.$params
                                                    .minLength.min,
                                              }
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.address"))),
                          ]),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-text-area", {
                            attrs: {
                              invalid: _vm.$v.billing.address_street_1.$error,
                              tabindex: 11,
                              placeholder: _vm.$t("general.street_1"),
                              type: "text",
                              name: "billing_street1",
                              rows: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.billing.address_street_1.$touch()
                              },
                            },
                            model: {
                              value: _vm.billing.address_street_1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.billing,
                                  "address_street_1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "billing.address_street_1",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.billing.address_street_1.$error
                            ? _c("div", [
                                !_vm.$v.billing.address_street_1.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.billing.address_street_1.maxLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "validation.address_maxlength"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("base-text-area", {
                            attrs: {
                              tabindex: 12,
                              placeholder: _vm.$t("general.street_2"),
                              type: "text",
                              name: "billing_street2",
                              rows: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.billing.address_street_2.$touch()
                              },
                            },
                            model: {
                              value: _vm.billing.address_street_2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.billing,
                                  "address_street_2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "billing.address_street_2",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.billing.address_street_2.$error
                            ? _c("div", [
                                !_vm.$v.billing.address_street_2.maxLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "validation.address_maxlength"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.country"))),
                          ]),
                          _vm._v(" "),
                          _c("base-select", {
                            attrs: {
                              invalid: _vm.$v.billing_country.$error,
                              options: _vm.billingCountries,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": false,
                              tabindex: 8,
                              placeholder: _vm.$t("general.select_country"),
                              label: "name",
                              "track-by": "id",
                            },
                            model: {
                              value: _vm.billing_country,
                              callback: function ($$v) {
                                _vm.billing_country = $$v
                              },
                              expression: "billing_country",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.billing_country.$error
                            ? _c("div", [
                                !_vm.$v.billing_country.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.city"))),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              name: "billing.city",
                              type: "text",
                              "tab-index": "10",
                              invalid: _vm.$v.billing.city.$error,
                            },
                            model: {
                              value: _vm.billing.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.billing, "city", $$v)
                              },
                              expression: "billing.city",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.billing.city.$error
                            ? _c("div", [
                                !_vm.$v.billing.city.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.phone"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              type: "number",
                              name: "phone",
                              "tab-index": "13",
                            },
                            model: {
                              value: _vm.billing.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.billing,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "billing.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.zip_code"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              name: "zip",
                              "tab-index": "14",
                            },
                            model: {
                              value: _vm.billing.zip,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.billing,
                                  "zip",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "billing.zip",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "same-address-checkbox-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "p-1" },
                        [
                          _c(
                            "base-button",
                            {
                              ref: "sameAddress",
                              staticClass: "btn-sm",
                              attrs: { icon: "copy", color: "theme" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyAddress(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("customers.copy_billing_address")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "section-title col-sm-2" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("customers.shipping_address")) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.name"))),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.shipping.name.$error,
                              type: "text",
                              name: "address_name",
                              "tab-index": "15",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.shipping.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.shipping.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.shipping,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "shipping.name",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.shipping.name.$error
                            ? _c("div", [
                                !_vm.$v.shipping.name.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.shipping.name.minLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc(
                                              "validation.name_min_length",
                                              _vm.$v.shipping.name.$params
                                                .minLength.min,
                                              {
                                                count:
                                                  _vm.$v.shipping.name.$params
                                                    .minLength.min,
                                              }
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.address"))),
                          ]),
                          _vm._v(" "),
                          _c("base-text-area", {
                            attrs: {
                              invalid: _vm.$v.shipping.address_street_1.$error,
                              tabindex: 19,
                              placeholder: _vm.$t("general.street_1"),
                              type: "text",
                              name: "street_1",
                              rows: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.shipping.address_street_1.$touch()
                              },
                            },
                            model: {
                              value: _vm.shipping.address_street_1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.shipping,
                                  "address_street_1",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "shipping.address_street_1",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.shipping.address_street_1.$error
                            ? _c("div", [
                                !_vm.$v.shipping.address_street_1.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.shipping.address_street_1.maxLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "validation.address_maxlength"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("base-text-area", {
                            attrs: {
                              tabindex: 20,
                              placeholder: _vm.$t("general.street_2"),
                              type: "text",
                              name: "street_2",
                              rows: "2",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.shipping.address_street_2.$touch()
                              },
                            },
                            model: {
                              value: _vm.shipping.address_street_2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.shipping,
                                  "address_street_2",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "shipping.address_street_2",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.shipping.address_street_2.$error
                            ? _c("div", [
                                !_vm.$v.shipping.address_street_2.maxLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("validation.address_maxlength")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-5" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.country"))),
                          ]),
                          _vm._v(" "),
                          _c("base-select", {
                            attrs: {
                              options: _vm.shippingCountries,
                              searchable: true,
                              "show-labels": false,
                              tabindex: 16,
                              "allow-empty": false,
                              placeholder: _vm.$t("general.select_country"),
                              label: "name",
                              "track-by": "id",
                            },
                            model: {
                              value: _vm.shipping_country,
                              callback: function ($$v) {
                                _vm.shipping_country = $$v
                              },
                              expression: "shipping_country",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.city"))),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(" *"),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              name: "shipping.city",
                              type: "text",
                              "tab-index": "18",
                              invalid: _vm.$v.shipping.city.$error,
                            },
                            model: {
                              value: _vm.shipping.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.shipping, "city", $$v)
                              },
                              expression: "shipping.city",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.shipping.city.$error
                            ? _c("div", [
                                !_vm.$v.shipping.city.required
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tc("validation.required")
                                          ) +
                                          "\n                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.phone"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              type: "number",
                              name: "phone",
                              "tab-index": "21",
                            },
                            model: {
                              value: _vm.shipping.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.shipping,
                                  "phone",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "shipping.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { staticClass: "form-label" }, [
                            _vm._v(_vm._s(_vm.$t("customers.zip_code"))),
                          ]),
                          _vm._v(" "),
                          _c("base-input", {
                            attrs: {
                              type: "text",
                              name: "zip",
                              "tab-index": "22",
                            },
                            model: {
                              value: _vm.shipping.zip,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.shipping,
                                  "zip",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "shipping.zip",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group collapse-button-container" },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "collapse-button",
                              attrs: {
                                tabindex: 23,
                                icon: "save",
                                color: "theme",
                                type: "submit",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$t("customers.save_customer")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      this.showmodal
        ? _c("pop-alert", {
            on: { "pop-back": _vm.popback, "pop-close": _vm.popclose },
          })
        : _vm._e(),
      _vm._v(" "),
      this.showModalCustomer
        ? _c("error-alert", {
            on: {
              "close-dialog": _vm.closedialog,
              "cancel-dialog": _vm.canceldialog,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.ProcessSendInvoice ? _c("base-loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }