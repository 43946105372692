import * as types from './mutation-types'

export default {
  [types.SET_INSTALLMENTS] (state, installments) {
    state.installments = installments
  },

  [types.SET_TOTAL_INSTALLMENTS] (state, totalInstallments) {
    state.totalInstallments = totalInstallments
  },

  [types.ADD_INSTALLMENT] (state, data) {
    // console.log(data);
    state.installments.push(data.installment_month)
  },

  [types.UPDATE_INSTALLMENT] (state, data) {
    let pos = state.installments.findIndex(installment => installment.id === data.installment_month.id)
    state.installments[pos] = data.installment_month
    state.installments = [...state.installments]
  },

  [types.DELETE_INSTALLMENT] (state, id) {
    let index = state.installments.findIndex(installment => installment.id === id)
    state.installments.splice(index, 1)
  },

  [types.DELETE_MULTIPLE_INSTALLMENTS] (state, selectedInstallments) {
    selectedInstallments.forEach((installment) => {
      let index = state.installments.findIndex(_inv => _inv.id === installment.id)
      state.installments.splice(index, 1)
    })

    state.selectedInstallments = []
  },

  [types.SET_SELECTED_INSTALLMENTS] (state, data) {
    state.selectedInstallments = data
  },

  [types.SET_SELECT_ALL_STATE] (state, data) {
    state.selectAllField = data
  },

  [types.SET_INSTALLMENT_MODES] (state, data) {
    state.installmentModes = data
  },

  [types.ADD_INSTALLMENT_MODE] (state, data) {
    state.installmentModes = [data.installmentMethod, ...state.installmentModes]
  },

  [types.DELETE_INSTALLMENT_MODE] (state, id) {
    let index = state.installmentModes.findIndex(installmentMethod => installmentMethod.id === id)
    state.installmentModes.splice(index, 1)
  },

  [types.UPDATE_INSTALLMENT_MODE] (state, data) {
    let pos = state.installmentModes.findIndex(installmentMethod => installmentMethod.id === data.installmentMethod.id)
    state.installmentModes.splice(pos, 1)
    state.installmentModes = [data.installmentMethod, ...state.installmentModes]
  }
}
