var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Offers")
    ? _c(
        "div",
        { staticClass: "estimate-index-page estimates main-content" },
        [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("estimates.title"))),
            ]),
            _vm._v(" "),
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "dashboard" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.home")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "#" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("estimates.estimate", 2)) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-actions row" },
              [
                _c(
                  "div",
                  { staticClass: "col-xs-2 mr-4" },
                  [
                    _c(
                      "base-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.totalEstimates || _vm.filtersApplied,
                            expression: "totalEstimates || filtersApplied",
                          },
                        ],
                        attrs: {
                          outline: true,
                          icon: _vm.filterIcon,
                          size: "large",
                          color: "theme",
                          "right-icon": "",
                        },
                        on: { click: _vm.toggleFilter },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.filter")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "col-xs-2",
                    attrs: { slot: "item-title", to: "estimates/create" },
                    slot: "item-title",
                  },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { size: "large", icon: "plus", color: "theme" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("estimates.new_estimate"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilters,
                    expression: "showFilters",
                  },
                ],
                staticClass: "filter-section",
              },
              [
                _c("div", { staticClass: "filter-container" }, [
                  _c(
                    "div",
                    { staticClass: "filter-customer" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$tc("customers.customer", 1)) + " "),
                      ]),
                      _vm._v(" "),
                      _c("base-customer-select", {
                        ref: "customerSelect",
                        on: {
                          select: _vm.onSelectCustomer,
                          deselect: _vm.clearCustomerSearch,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-status" },
                    [
                      _c("label", [_vm._v(_vm._s(_vm.$t("estimates.status")))]),
                      _vm._v(" "),
                      _c("base-select", {
                        attrs: {
                          options: _vm.status,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("general.select_a_status"),
                        },
                        on: {
                          remove: function ($event) {
                            return _vm.clearStatusSearch()
                          },
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "filter-date" }, [
                    _c(
                      "div",
                      { staticClass: "from pr-3" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("general.from")))]),
                        _vm._v(" "),
                        _c("base-date-picker", {
                          attrs: {
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          model: {
                            value: _vm.filters.from_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "from_date", $$v)
                            },
                            expression: "filters.from_date",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "dashed" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "to pl-3" },
                      [
                        _c("label", [_vm._v(_vm._s(_vm.$t("general.to")))]),
                        _vm._v(" "),
                        _c("base-date-picker", {
                          attrs: {
                            "calendar-button": true,
                            "calendar-button-icon": "calendar",
                          },
                          model: {
                            value: _vm.filters.to_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "to_date", $$v)
                            },
                            expression: "filters.to_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "filter-estimate" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("estimates.estimate_number"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: { icon: "hashtag" },
                        model: {
                          value: _vm.filters.estimate_number,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "estimate_number", $$v)
                          },
                          expression: "filters.estimate_number",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "clear-filter",
                    on: { click: _vm.clearFilter },
                  },
                  [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              staticClass: "col-xs-1 no-data-info",
              attrs: { align: "center" },
            },
            [
              _c("moon-walker-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "row", attrs: { align: "center" } }, [
                _c("label", { staticClass: "col title" }, [
                  _vm._v(_vm._s(_vm.$t("estimates.no_estimates"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "label",
                  {
                    staticClass: "description col mt-1",
                    attrs: { align: "center" },
                  },
                  [_vm._v(_vm._s(_vm.$t("estimates.list_of_estimates")))]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-container" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("estimates/create")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("estimates.add_new_estimate")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "table-container",
            },
            [
              _c(
                "div",
                { staticClass: "table-actions mt-5" },
                [
                  _c("p", { staticClass: "table-stats" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.estimates.length))]),
                    _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                    _c("b", [_vm._v(_vm._s(_vm.totalEstimates))]),
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "tabs" }, [
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              {
                                "a-active":
                                  _vm.filters.status === "" ||
                                  (_vm.filters.status !== "DRAFT" &&
                                    _vm.filters.status !== "SENT" &&
                                    _vm.filters.status !== "VIEWED" &&
                                    _vm.filters.status !== "ACCEPTED" &&
                                    _vm.filters.status !== "REJECTED" &&
                                    _vm.filters.status !== "EXPIRED"),
                              },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.all")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("DRAFT")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              { "a-active": _vm.filters.status === "DRAFT" },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.draft")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("SENT")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              { "a-active": _vm.filters.status === "SENT" },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.sent")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("VIEWED")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              { "a-active": _vm.filters.status === "VIEWED" },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.viewed")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("ACCEPTED")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              { "a-active": _vm.filters.status === "ACCEPTED" },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.accepted")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("REJECTED")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              { "a-active": _vm.filters.status === "REJECTED" },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.rejected")))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        staticClass: "tab",
                        on: {
                          click: function ($event) {
                            return _vm.getStatus("EXPIRED")
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: [
                              "tab-link",
                              { "a-active": _vm.filters.status === "EXPIRED" },
                            ],
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(_vm.$t("general.expired")))]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.selectedEstimates.length
                        ? _c(
                            "v-dropdown",
                            { attrs: { "show-arrow": false } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "table-actions-button dropdown-toggle",
                                  attrs: { slot: "activator", href: "#" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-item",
                                    on: { click: _vm.removeMultipleEstimates },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: ["fas", "trash"] },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("table-column", {
                    attrs: {
                      label: _vm.$tc("estimates.estimate_number", 1),
                      show: "estimate_number",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("estimates.date"),
                      "sort-as": "estimate_date",
                      show: "formattedEstimateDate",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("estimates.customer"),
                      "sort-as": "name",
                      show: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("estimates.status"),
                      show: "status",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("estimates.status"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  class:
                                    "est-status-" + row.status.toLowerCase(),
                                },
                                [_vm._v(_vm._s(row.status))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2745074645
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("invoices.total"),
                      "sort-as": "total",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("estimates.total"))),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.total,
                                      _vm.defaultCurrency
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3843608056
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("estimates.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-dropdown",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { slot: "activator", href: "#" },
                                      slot: "activator",
                                    },
                                    [_c("dot-icon")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  row.status !== "SENT" &&
                                  row.status !== "ACCEPTED"
                                    ? _c(
                                        "v-dropdown-item",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "dropdown-item",
                                              attrs: {
                                                to: {
                                                  path:
                                                    "estimates/" +
                                                    row.id +
                                                    "/edit",
                                                },
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass:
                                                  "dropdown-item-icon",
                                                attrs: {
                                                  icon: ["fas", "pencil-alt"],
                                                },
                                              }),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.edit")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeEstimate(row.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "dropdown-item-icon",
                                          attrs: { icon: ["fas", "trash"] },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("general.delete")) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path:
                                                "estimates/" + row.id + "/view",
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: { icon: "eye" },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("general.view")) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "dropdown-item",
                                        attrs: { href: "#/" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.convertInToinvoice(
                                              row.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "dropdown-item-icon",
                                          attrs: { icon: "file-alt" },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "estimates.convert_to_invoice"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  row.status !== "SENT"
                                    ? _c("v-dropdown-item", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#/" },
                                            on: {
                                              click: function ($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.onMarkAsSent(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: "check-circle" },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_sent"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status === "DRAFT"
                                    ? _c("v-dropdown-item", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#/" },
                                            on: {
                                              click: function ($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.sendEstimate(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: "paper-plane" },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.send_estimate"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status == "SENT" || row.status == "VIEWED"
                                    ? _c("v-dropdown-item", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#/" },
                                            on: {
                                              click: function ($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.sendEstimate(row.id)
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: "paper-plane" },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.resend_estimate"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status !== "ACCEPTED"
                                    ? _c("v-dropdown-item", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#/" },
                                            on: {
                                              click: function ($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.onMarkAsAccepted(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: "check-circle" },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_accepted"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.status !== "REJECTED"
                                    ? _c("v-dropdown-item", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#/" },
                                            on: {
                                              click: function ($event) {
                                                if (
                                                  $event.target !==
                                                  $event.currentTarget
                                                ) {
                                                  return null
                                                }
                                                return _vm.onMarkAsRejected(
                                                  row.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "dropdown-item-icon",
                                              attrs: { icon: "times-circle" },
                                            }),
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "estimates.mark_as_rejected"
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2883917860
                    ),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.ProcessSendEstimate ? _c("base-loader") : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }