var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-4 reports-tab-container" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-8" },
            [
              _c("label", { staticClass: "report-label" }, [
                _vm._v(_vm._s(_vm.$t("reports.sales.date_range"))),
              ]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  options: _vm.dateRange,
                  "allow-empty": false,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeDateRange },
                model: {
                  value: _vm.selectedRange,
                  callback: function ($$v) {
                    _vm.selectedRange = $$v
                  },
                  expression: "selectedRange",
                },
              }),
              _vm._v(" "),
              _vm.$v.range.$error && !_vm.$v.range.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row report-fields-container" }, [
          _c(
            "div",
            { staticClass: "col-md-6 report-field-container" },
            [
              _c("label", { staticClass: "report-label" }, [
                _vm._v(_vm._s(_vm.$t("reports.sales.from_date"))),
              ]),
              _vm._v(" "),
              _c("base-date-picker", {
                attrs: {
                  invalid: _vm.$v.formData.from_date.$error,
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                },
                on: {
                  change: function ($event) {
                    return _vm.$v.formData.from_date.$touch()
                  },
                  input: _vm.setRangeToCustom,
                },
                model: {
                  value: _vm.formData.from_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "from_date", $$v)
                  },
                  expression: "formData.from_date",
                },
              }),
              _vm._v(" "),
              _vm.$v.formData.from_date.$error &&
              !_vm.$v.formData.from_date.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6 report-field-container" },
            [
              _c("label", { staticClass: "report-label" }, [
                _vm._v(_vm._s(_vm.$t("reports.sales.to_date"))),
              ]),
              _vm._v(" "),
              _c("base-date-picker", {
                attrs: {
                  invalid: _vm.$v.formData.to_date.$error,
                  "calendar-button": true,
                  "calendar-button-icon": "calendar",
                },
                on: {
                  change: function ($event) {
                    return _vm.$v.formData.to_date.$touch()
                  },
                  input: _vm.setRangeToCustom,
                },
                model: {
                  value: _vm.formData.to_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "to_date", $$v)
                  },
                  expression: "formData.to_date",
                },
              }),
              _vm._v(" "),
              _vm.$v.formData.to_date.$error &&
              !_vm.$v.formData.to_date.required
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row report-fields-container" }, [
          _c(
            "div",
            { staticClass: "col-md-8 report-field-container" },
            [
              _c("label", { staticClass: "report-label" }, [
                _vm._v(_vm._s(_vm.$t("reports.sales.report_type"))),
              ]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  options: _vm.reportTypes,
                  "allow-empty": false,
                  "show-labels": false,
                  placeholder: _vm.$t("reports.sales.report_type"),
                },
                on: { input: _vm.getInitialReport },
                model: {
                  value: _vm.selectedType,
                  callback: function ($$v) {
                    _vm.selectedType = $$v
                  },
                  expression: "selectedType",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row report-submit-button-container" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c(
                "base-button",
                {
                  staticClass: "report-button",
                  attrs: { outline: "", color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.getReports()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("reports.update_report")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-8 reports-tab-container" }, [
        _c("iframe", {
          staticClass: "reports-frame-style",
          attrs: { src: _vm.getReportUrl },
          on: {
            load: function ($event) {
              return _vm.iframeLoaded()
            },
          },
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "base-button btn btn-primary btn-lg report-view-button",
            on: { click: _vm.viewReportsPDF },
          },
          [
            _c("font-awesome-icon", {
              staticClass:
                "vue-icon icon-left svg-inline--fa fa-download fa-w-16 mr-2",
              attrs: { icon: "file-pdf" },
            }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$t("reports.view_pdf")))]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.ProcessSendInvoice ? _c("base-loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }