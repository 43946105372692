var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Plans")
    ? _c("div", { staticClass: "setting-main-container" }, [
        _c(
          "div",
          { staticClass: "card setting-card" },
          [
            _c(
              "div",
              { staticClass: "page-header d-flex justify-content-between" },
              [
                _c("div", [
                  _c("h3", { staticClass: "page-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("settings.subscriptions.title")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "page-sub-title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("settings.subscriptions.description")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "base-button",
                  {
                    staticClass: "add-new-tax",
                    attrs: { outline: "", color: "theme" },
                    on: { click: _vm.openSubscriptionModal },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("settings.subscriptions.add_new_subscription")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "table-component",
              {
                ref: "table",
                staticClass: "mb-3",
                attrs: {
                  "show-filter": false,
                  data: _vm.plans,
                  "table-class": "table tax-table",
                },
              },
              [
                _c("table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("settings.subscriptions.name"),
                    show: "name",
                  },
                }),
                _vm._v(" "),
                _c("table-column", {
                  attrs: {
                    sortable: true,
                    label: _vm.$t("settings.subscriptions.billing_type"),
                    show: "billing_type",
                  },
                }),
                _vm._v(" "),
                _c("table-column", {
                  staticStyle: { "white-space": "nowrap" },
                  attrs: {
                    label: _vm.$t("settings.subscriptions.price"),
                    show: "price",
                    sortable: true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("settings.subscriptions.price")
                                  ) +
                                  " "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$utils.formatMoney(
                                    row.price,
                                    _vm.defaultCurrency
                                  )
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2021751533
                  ),
                }),
                _vm._v(" "),
                _c("table-column", {
                  attrs: {
                    sortable: false,
                    filterable: false,
                    "cell-class": "action-dropdown",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (row) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("settings.tax_types.action"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-dropdown",
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { slot: "activator", href: "#" },
                                    slot: "activator",
                                  },
                                  [_c("dot-icon")],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-dropdown-item", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dropdown-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.EditSubscription(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: ["fas", "pencil-alt"] },
                                      }),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("general.edit")) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    485799107
                  ),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
          ],
          1
        ),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }