<template>
  <div class="payment-modes-modal">
    <form action="" @submit.prevent="submitPaymentMode">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.customization.payments.mode_name") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="name"
              :invalid="$v.formData.name.$error"
              v-model="formData.name"
              type="text"
              @input="$v.formData.name.$touch()"
            />
            <div v-if="$v.formData.name.$error">
              <span
                v-if="!$v.formData.name.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
            </div>
          </div>
          <br />
          <label style="margin-top: 2%" class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.customization.installment.installment_month_status") }}
            <span class="required"> *</span></label
          >
          <div style="margin-left: 2%" class="right">
            <base-switch
              v-model="formData.status"
              class="btn-switch"
              @change="setPaymentStatus"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <base-button
          :outline="true"
          class="mr-3"
          color="theme"
          type="button"
          @click="closePaymentModeModal"
        >
          {{ $t("general.cancel") }}
        </base-button>
        <base-button :loading="isLoading" color="theme" icon="save" type="submit">
          {{ !isEdit ? $t("general.save") : $t("general.update") }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      isLoading: false,
      formData: {
        id: null,
        name: null,
        status: "NO",
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(2),
      },
    },
  },
  async mounted() {
    this.$refs.name.focus = true;
    if (this.modalDataID) {
      this.isEdit = true;
      this.setData();
    }
  },
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("payment", ["addPaymentMode", "updatePaymentMode"]),
    resetFormData() {
      this.formData = {
        id: null,
        name: null,
        status: "NO",
      };
      this.$v.formData.$reset();
    },
    async submitPaymentMode() {
      this.$v.formData.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      this.isLoading = true;
      if (this.formData.status == true) {
        this.formData.status = 1;
      } else {
        this.formData.status = 0;
      }
      let response;
      if (this.isEdit) {
        console.log(this.formData);
        try {
          response = await this.updatePaymentMode(this.formData);
          if (response.data) {
            window.toastr["success"](
              this.$t("settings.customization.payments.payment_mode_updated")
            );
            this.closePaymentModeModal();
            return true;
          }
        } catch (err) {
          if (err.response.data.errors.name) {
            this.isLoading = false;
          }
        }
      } else {
        try {
          response = await this.addPaymentMode(this.formData);

          if (response.data) {
            this.isLoading = false;
            window.toastr["success"](
              this.$t("settings.customization.payments.payment_mode_added")
            );
            this.closePaymentModeModal();
            return true;
          }
        } catch (err) {
          if (err.response.data.errors.name) {
            this.isLoading = false;
          }
        }
      }
    },
    async setData() {
      if (this.modalData.status === 1) {
        this.formData.status = true;
      } else {
        this.formData.status = false;
      }
      this.formData = {
        id: this.modalData.id,
        name: this.modalData.name,
        status: this.formData.status,
      };
    },
    closePaymentModeModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },

    async setPaymentStatus() {
      this.formData.status ? 1 : 0;
    },
  },
};
</script>
