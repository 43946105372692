import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index.js'

/*
 |--------------------------------------------------------------------------
 | Admin Views
 |--------------------------------------------------------------------------|
 */

 //installment plan
 import InstallmentPlan from './components/base/modal/InstallmentPlan.vue'

// Layouts
import LayoutBasic from './views/layouts/LayoutBasic.vue'
import LayoutLogin from './views/layouts/LayoutLogin.vue'
import LayoutWizard from './views/layouts/LayoutWizard.vue'
import LayoutBasicOnBoarding from './views/layouts/LayoutBasicOnBoarding.vue'

// Landing Page
import LandingPage from './views/frontend/LandingPage.vue'

// Auth
import Login from './views/auth/Login.vue'
import SignUp from './views/auth/SignUp.vue'
import ForgotPassword from './views/auth/ForgotPassword.vue'
import ResetPassword from './views/auth/ResetPassword.vue'
import Register from './views/auth/Register.vue'

import NotFoundPage from './views/errors/404.vue'

/*
 |--------------------------------------------------------------------------
 | Admin Views
 |--------------------------------------------------------------------------|
 */

 // Dashbord
import Dashboard from './views/dashboard/Dashboard.vue'

// Economics
import Economics from './views/economics/Economics.vue'

// selectPlan
import selectPlan from './views/selectPlan/selectPlan.vue'

// Customers
import CustomerIndex from './views/customers/Index.vue'
import CustomerCreate from './views/customers/Create.vue'

// Roles
import RoleIndex from './views/roles/Index.vue'
import RoleCreate from './views/roles/Create.vue'
import AssignPermission from './views/roles/Assign.vue'

// Permissions
import PermissionIndex from './views/permissions/Index.vue'
import PermissionCreate from './views/permissions/Create.vue'

// SubAdmins
import SubAdminIndex from './views/subadmins/Index.vue'
import SubAdminCreate from './views/subadmins/Create.vue'

// Items
import ItemsIndex from './views/items/Index.vue'
import ItemCreate from './views/items/Create.vue'

// Editor
import EditorIndex from './views/editor/Index.vue'
import EditorCreate from './views/editor/Create.vue'
import EditorView from './views/editor/View.vue'
import EditorTest from './views/editor/Test.vue'

// Printing
import PrintingIndex from './views/Printing/Index.vue'
import PrintingView from './views/Printing/View.vue'

// Invoices
import InvoiceIndex from './views/invoices/Index.vue'
import InvoiceCreate from './views/invoices/Create.vue'
import InvoiceView from './views/invoices/View.vue'

// Payments
import PaymentsIndex from './views/payments/Index.vue'
import PaymentCreate from './views/payments/Create.vue'
import PaymentView from './views/payments/View.vue'

// Subscription Details
import SubscriptionDetailsIndex from './views/subscriptionDetails/Index.vue'
import SubscriptionDetailsView from './views/subscriptionDetails/View.vue'

// Subscription Details
import PrintingDetailsIndex from './views/printingDetails/Index.vue'
import PrintingDetailsView from './views/printingDetails/View.vue'

// Estimates
import EstimateIndex from './views/estimates/Index.vue'
import EstimateCreate from './views/estimates/Create.vue'
import EstimateView from './views/estimates/View.vue'

// Expenses
import ExpensesIndex from './views/expenses/Index'
import ExpenseCreate from './views/expenses/Create.vue'

// Report
import SalesReports from './views/reports/SalesReports'
import ExpensesReport from './views/reports/ExpensesReport'
import ProfitLossReport from './views/reports/ProfitLossReport'
import TaxReport from './views/reports/TaxReport.vue'
import ReportLayout from './views/reports/layout/Index.vue'

// Settings
import SettingsLayout from './views/settings/layout/Index.vue'
import CompanyInfo from './views/settings/CompanyInfo.vue'
import Customization from './views/settings/Customization.vue'
import Notifications from './views/settings/Notifications.vue'
import Preferences from './views/settings/Preferences.vue'
import UserProfile from './views/settings/UserProfile.vue'
import TaxTypes from './views/settings/TaxTypes.vue'
import ExpenseCategory from './views/settings/ExpenseCategory.vue'
import MailConfig from './views/settings/MailConfig.vue'
import UpdateApp from './views/settings/UpdateApp.vue'
import FakturaFee from './views/settings/FakturaFee.vue'
import PrintingFee from './views/settings/PrintingFee.vue'
import Plans from './views/settings/Plans.vue'
import BankingInformation from './views/settings/BankInformation.vue'

// Language
import Language from './views/language/Index.vue'
import LanguageCreate from './views/language/Create.vue'


import Wizard from './views/wizard/Index.vue'
import ImportProducts from './views/import/Products.vue'
import importUsers from './views/import/Users.vue'

import Permission from "./views/wizard/Permission";
import Integration from "./views/settings/Integration";

// Integrations
import IntegrationIndex from './views/integrations/Index.vue'
import IntegrationCreate from './views/integrations/Create.vue'

// activities
import ActivityIndex from './views/activities/Index.vue'
import ActivityCreate from './views/activities/Create.vue'

Vue.use(VueRouter)

const routes = [
  /*
   |--------------------------------------------------------------------------
   | Frontend Routes
   |--------------------------------------------------------------------------|
   */

  /*
   |--------------------------------------------------------------------------
   | Auth & Registration Routes
   |--------------------------------------------------------------------------|
   */

  {
    path: 'login',
    component: LayoutLogin,
    meta: { redirectIfAuthenticated: true },
    children: [
      // {
      //   path: 'login',
      //   component: Login
      // },
      {
        path: '/login',
        component: Login,
        name: 'login'
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password'
      },
      {
        path: '/sign-up',
        component: SignUp,
        name: 'sign-up'
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword,
        name: 'reset-password'
      },
      {
        path: 'register',
        component: Register,
        name: 'register'
      }
    ],
  },

  // frontend
  {
    path: '/',
    component: LandingPage,
    name: '/',
    props:true

    // meta: { redirectIfAuthenticated: true },
  },


  /*
   |--------------------------------------------------------------------------
   | Onboarding Routes
   |--------------------------------------------------------------------------|
   */
  /*{
    path: '/on-boarding',
    component: LayoutWizard,
    children: [
      {
        path: '/',
        component: Wizard,
        name: 'wizard'
      }
    ]
  },*/

  {
    path: '/system',
    component: LayoutBasicOnBoarding, // Change the desired Layout here
    meta: { requiresAuth: true },
    children: [
      {
        path: 'on-boarding',
        component: LayoutWizard,
        children: [
          {
            path: '/',
            component: Wizard,
            name: 'wizard'
          }
        ]
      },
    ]
    },
  /*
   |--------------------------------------------------------------------------
   | Admin Backend Routes
   |--------------------------------------------------------------------------|
   */
  {
    path: '/system',
    component: LayoutBasic, // Change the desired Layout here
    meta: { requiresAuth: true },
    children: [
      // Dashbord
      {
        path: '/',
        component: Dashboard,
        name: 'dashboard'
      },
      {
        path: 'dashboard',
        component: Dashboard
      },
      {
        path: 'economics',
        component: Economics
      },
      

      //plans
      {
        path: 'selectPlan',
        component: selectPlan,
        name: 'selectPlan'
      },

      // Customer
      {
        path: 'customers',
        component: CustomerIndex
      },
      {
        path: 'customers/create',
        name: 'customers.create',
        component: CustomerCreate
      },
      {
        path: 'customers/:id/edit',
        name: 'customers.edit',
        component: CustomerCreate
      },

      // Items
      {
        path: 'products',
        component: ItemsIndex
      },
      {
        path: 'products/create',
        name: 'products.create',
        component: ItemCreate
      },
      {
        path: 'products/:id/edit',
        name: 'products.edit',
        component: ItemCreate
      },

       // Editor
       {
        path: 'editor',
        component: EditorIndex
      },
      {
        path: 'editor/create',
        name: 'editor.create',
        component: EditorCreate
      },
      {
        path: 'editor/:id/edit',
        name: 'editor.edit',
        component: EditorCreate
      },
      {
        path: 'editor/:id/view',
        name: 'editor.view',
        component: EditorView
      },
      {
        path: 'editor/test',
        name: 'editor.test',
        component: EditorTest
      },

       // Printing
       {
        path: 'printing',
        component: PrintingIndex
      },
      {
        path: 'printing/:id/view',
        name: 'printing.view',
        component: PrintingView,
        props:true,
      },

      // Estimate
      {
        path: 'estimates',
        name: 'estimates.index',
        component: EstimateIndex
      },
      {
        path: 'estimates/create',
        name: 'estimates.create',
        component: EstimateCreate
      },
      {
        path: 'estimates/:id/view',
        name: 'estimates.view',
        component: EstimateView
      },
      {
        path: 'estimates/:id/edit',
        name: 'estimates.edit',
        component: EstimateCreate
      },

      // Invoice
      {
        path: 'invoices',
        name: 'invoices.index',
        component: InvoiceIndex,
        props:true,
        children: [
          { path: 'installmentPlan/:id', component: InstallmentPlan, name: 'InstallmentPlan' }
        ]
      },
      {
        path: 'invoices/create',
        name: 'invoices.create',
        component: InvoiceCreate
      },
      {
        path: 'invoices/:id/view',
        name: 'invoices.view',
        component: InvoiceView
      },
      {
        path: 'invoices/:id/edit',
        name: 'invoices.edit',
        component: InvoiceCreate
      },

      // Payments
      {
        path: 'payments',
        name: 'payments.index',
        component: PaymentsIndex
      },
      {
        path: 'payments/create',
        name: 'payments.create',
        component: PaymentCreate
      },
      {
        path: 'payments/:id/create',
        name: 'invoice.payments.create',
        component: PaymentCreate
      },

      {
        path: 'payments/:id/edit',
        name: 'payments.edit',
        component: PaymentCreate
      },
      {
        path: 'payments/:id/view',
        name: 'payments.view',
        component: PaymentView
      },

       // Subscription Details
       {
        path: 'subscriptionDetails',
        name: 'subscriptionDetails.index',
        component: SubscriptionDetailsIndex
      },
      {
        path: 'subscriptionDetails/:id/view',
        name: 'subscriptionDetails.view',
        component: SubscriptionDetailsView
      },

        // Printing Details
        {
          path: 'printingDetails',
          name: 'printingDetails.index',
          component: PrintingDetailsIndex
        },
        {
          path: 'printingDetails/:id/view',
          name: 'printingDetails.view',
          component: PrintingDetailsView
        },


      // {
      //   path: 'subscriptionDetails/:id/view',
      //   name: 'subscriptionDetails.view',
      //   component: SubscriptionDetailsView
      // },

      // Expenses
      {
        path: 'expenses',
        component: ExpensesIndex
      },
      {
        path: 'expenses/create',
        name: 'expenses.create',
        component: ExpenseCreate
      },
      {
        path: 'expenses/:id/edit',
        name: 'expenses.edit',
        component: ExpenseCreate
      },

      // Reports
      {
        path: 'reports',
        component: ReportLayout,
        children: [
          {
            path: 'sales',
            component: SalesReports
          },
          {
            path: 'expenses',
            component: ExpensesReport
          },
          {
            path: 'profit-loss',
            component: ProfitLossReport
          },
          {
            path: 'taxes',
            component: TaxReport
          }
        ]
      },

      // Settings
      {
        path: 'settings',
        component: SettingsLayout,
        children: [
          {
            path: 'company-info',
            name: 'company.info',
            component: CompanyInfo
          },
          {
            path: 'customization',
            name: 'customization',
            component: Customization
          },
          {
            path: 'user-profile',
            name: 'user.profile',
            component: UserProfile
          },
          {
            path: 'preferences',
            name: 'preferences',
            component: Preferences
          },
          {
            path: 'tax-types',
            name: 'tax.types',
            component: TaxTypes
          },
          {
            path: 'expense-category',
            name: 'expense.category',
            component: ExpenseCategory
          },
          {
            path: 'mail-configuration',
            name: 'mailconfig',
            component: MailConfig
          },
          {
            path: 'notifications',
            name: 'notifications',
            component: Notifications
          },
          {
            path: 'integration',
            name: 'integration',
            component: Integration
          },
          {
            path: 'update-app',
            name: 'updateapp',
            component: UpdateApp
          },
          {
            path: 'faktura-fee',
            name: 'faktura-fee',
            component: FakturaFee
          },
          {
            path: 'printing-fee',
            name: 'printing-fee',
            component: PrintingFee
          },
          {
            path: 'plans',
            name: 'plans',
            component: Plans
          },

          {
            path: 'bankInformation',
            name: 'bankInformation',
            component: BankingInformation
          },
        ]
      },
      // Roles
      {
        path: 'roles',
        component: RoleIndex
      },
      {
        path: 'roles/create',
        name: 'roles.create',
        component: RoleCreate
      },
      {
        path: 'roles/:id/edit',
        name: 'roles.edit',
        component: RoleCreate
      },
      // Permissions
      {
        path: 'permissions',
        component: PermissionIndex
      },
      {
        path: 'permissions/create',
        name: 'permissions.create',
        component: PermissionCreate
      },
      {
        path: 'permissions/:id/edit',
        name: 'permissions.edit',
        component: PermissionCreate
      },
      {
        path: 'roles/permissions/:id',
        name: 'permissions.assign',
        component: AssignPermission
      },
      // Subadmins
      {
        path: 'subadmins',
        component: SubAdminIndex
      },
      {
        path: 'subadmins/create',
        name: 'subadmins.create',
        component: SubAdminCreate
      },
      {
        path: 'subadmins/:id/edit',
        name: 'subadmins.edit',
        component: SubAdminCreate
      },

      // Langauge
      {
        path: 'language',
        name:'language.index',
        component: Language,
        props:true
      },
      {
        path: 'language/create',
        name: 'language.create',
        component: LanguageCreate
      },
      {
        path: 'language/:id/edit',
        name: 'language.edit',
        component: LanguageCreate,
        props:true
      },

      // Import
      {
        path: 'importProducts',
        component: ImportProducts
      },
      {
        path: 'importUsers',
        component: importUsers
      },

      // Integrations
      {
        path: 'integrations',
        component: IntegrationIndex
      },
      {
        path: 'integrations/create',
        name: 'integrations.create',
        component: IntegrationCreate
      },
      {
        path: 'integrations/:id/edit',
        name: 'integrations.edit',
        component: IntegrationCreate
      },

      // Activities
      {
        path: 'activities',
        component: ActivityIndex
      },
      {
        path: 'activities/create',
        name: 'activities.create',
        component: ActivityCreate
      },
      {
        path: 'activities/:id/edit',
        name: 'activities.edit',
        component: ActivityCreate
      },
    ]
  },

  //  DEFAULT ROUTE
  { path: '*', component: NotFoundPage }
]

const router = new VueRouter({
  routes,
  history: true,
  mode: 'history',
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  //  Redirect if not authenticated on secured routes
  if (to.matched.some(m => m.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      return next('/login')
    }
    // setTimeout(function(){
    //   if (store.getters["userProfile/user"].onboard_settings != null) {
    //     if(store.getters['userProfile/user'].onboard_settings.value != 'COMPLETED'){
    //       return next('/admin/on-boarding')
    //     }
    //   }
    // },2000)
  }

  if (to.matched.some(m => m.meta.redirectIfAuthenticated) && store.getters['auth/isAuthenticated']) {
    return next('/system/dashboard')
  }

  return next()
})

export default router
