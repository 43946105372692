<template>
  <div class="graph-container">
    <canvas id="graph" ref="graph" />
  </div>
</template>

<script>
import Chart from "chart.js";
import Utils from "../../helpers/utilities";
import { mapGetters } from "vuex";

export default {
  props: {
    labels: {
      type: Array,
      require: true,
      default: Array,
    },
    values: {
      type: Array,
      require: true,
      default: Array,
    },
    invoices: {
      type: Array,
      require: true,
      default: Array,
    },
    offers: {
      type: Array,
      require: true,
      default: Array,
    },
    expenses: {
      type: Array,
      require: true,
      default: Array,
    },
    receipts: {
      //1/.
      type: Array,
      require: true,
      default: Array,
    },
    overdue: {
      type: Array,
      require: true,
      default: Array,
    },
    income: {
      type: Array,
      require: true,
      default: Array,
    },
    formatMoney: {
      type: Function,
      require: false,
      default: Function,
    },
    FormatGraphMoney: {
      type: Function,
      require: false,
      default: Function,
    },
  },

  computed: {
    ...mapGetters("currency", ["defaultCurrency"]),
  },

  watch: {
    labels(val) {
      this.update();
    },
  },

  mounted() {
    let self = this;
    let context = this.$refs.graph.getContext("2d");
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem, data) {
            return Utils.formatMoney(tooltipItem.value * 100, self.defaultCurrency);
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (label, index, labels) {
                return Utils.formatMoney(label * 100, self.defaultCurrency);
              },
            },
          },
        ],
      },
    };
    let data = {
      labels: this.labels,
      datasets: [
        {
          label: "Offers",
          fill: false,
          lineTension: 0.3,
          backgroundColor: "rgb(251, 113, 120)",
          borderColor: "rgb(251, 113, 120)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(251, 113, 120)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(251, 113, 120)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: this.offers.map((offer) => offer / 100),
        },
        {
          label: "Sales",
          fill: false,
          lineTension: 0.3,
          backgroundColor: "rgb(3, 115, 255)",
          borderColor: "rgb(3, 115, 255)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(3, 115, 255)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(3, 115, 255)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: this.invoices.map((invoice) => invoice / 100),
        },
        {
          label: "Overdue",
          fill: false,
          lineTension: 0.3,
          backgroundColor: "rgb(242, 89, 41)",
          borderColor: "rgb(242, 89, 41)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgb(242, 89, 41)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(242, 89, 41)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 4,
          pointHitRadius: 10,
          data: this.overdue.map((over) => over / 100),
        },
        // {
        //   label: "Net Income",
        //   fill: false,
        //   lineTension: 0.3,
        //   backgroundColor: "rgba(236, 235, 249)",
        //   borderColor: "rgba(88, 81, 216, 1)",
        //   borderCapStyle: "butt",
        //   borderDash: [],
        //   borderDashOffset: 0.0,
        //   borderJoinStyle: "miter",
        //   pointBorderColor: "rgba(88, 81, 216, 1)",
        //   pointBackgroundColor: "#fff",
        //   pointBorderWidth: 1,
        //   pointHoverRadius: 5,
        //   pointHoverBackgroundColor: "rgba(88, 81, 216, 1)",
        //   pointHoverBorderColor: "rgba(220,220,220,1)",
        //   pointHoverBorderWidth: 2,
        //   pointRadius: 4,
        //   pointHitRadius: 10,
        //   data: this.income.map((_i) => _i / 100),
        // },
      ],
    };

    this.myLineChart = new Chart(context, {
      type: "line",
      data: data,
      options: options,
    });

    // console.log(this.myLineChart.config.data.datasets);
  },

  methods: {
    update() {
      this.myLineChart.data.labels = this.labels;
      this.myLineChart.data.datasets[0].data = this.offers.map((offer) => offer / 100);
      this.myLineChart.data.datasets[1].data = this.invoices.map(
        (invoice) => invoice / 100
      );
      this.myLineChart.data.datasets[2].data = this.overdue.map((over) => over / 100);
      // this.myLineChart.data.datasets[3].data = this.income.map((_i) => _i / 100);
      this.myLineChart.update({
        lazy: true,
      });
    },

    beforeDestroy() {
      this.myLineChart.destroy();
    },
  },
};
</script>

<style scoped>
.graph-container {
  height: 300px;
}
</style>
