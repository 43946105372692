import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_ACTIVITIES] (state, activities) {
    state.activities = activities
  },

  [types.SET_TOTAL_ACTIVITIES] (state, totalActivities) {
    state.totalActivities = totalActivities
  },

  [types.ADD_ACTIVITY] (state, data) {
    state.activities.push(data.activity)
  },

  [types.UPDATE_ACTIVITY] (state, data) {
    let pos = state.activities.findIndex(activity => activity.id === data.activity.id)

    state.activities[pos] = data.activity
  },

  [types.DELETE_ACTIVITY] (state, id) {
    let index = state.activities.findIndex(activity => activity.id === id)
    state.activities.splice(index, 1)
  },

  [types.DELETE_MULTIPLE_ACTIVITIES] (state, selectedRoles) {
    selectedactivities.forEach((activity) => {
      let index = state.activities.findIndex(_cust => _cust.id === activity.id)
      state.activities.splice(index, 1)
    })

    state.selectedActivities = []
  },

  [types.SET_SELECTED_ACTIVITIES] (state, data) {
    state.selectedActivities = data
  },

  [types.RESET_SELECTED_ACTIVITY] (state, data) {
    state.selectedActivity = null
  },

  [types.SET_SELECT_ALL_STATE] (state, data) {
    state.selectAllField = data
  }

}
