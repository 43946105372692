<template>
  <div id="example">
    <div class="container">
      <div id="bar">
        <h1>Vue Email Editor (Demo)</h1>

        <button type="submit" v-on:click="saveDesign">Save Design</button>
        <button type="button" v-on:click="getDesign">Load Design</button>
        <!-- <button v-on:click="exportHtml">Export HTML</button> -->
      </div>

      <EmailEditor style="width: 93%" ref="emailEditor" v-on:load="editorLoaded" />
    </div>
  </div>
</template>
<script>
import { EmailEditor } from "vue-email-editor";
import sample from "../../../../data/sample.json";
export default {
  components: {
    EmailEditor,
  },
  data() {
    return {
      formData: {
        template: null,
        html: null,
      },
    };
  },
  methods: {
    editorLoaded() {
      this.$refs.emailEditor.editor.loadDesign(sample);
    },
    getDesign() {
      window.axios
        .get("/api/editor/load")
        .then((response) => {
          this.formData.template = response.data.template[0];
          console.log(this.formData.template.template);
          this.$refs.emailEditor.editor.loadDesign(
            JSON.parse(this.formData.template.template)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveDesign() {
      this.$refs.emailEditor.editor.exportHtml((design) => {
        console.log(design);
        this.formData.template = JSON.stringify({ body: design.design.body });
        this.formData.html = design.html;
        window.axios
          .post("/api/editor/save", {
            template: this.formData.template,
            html: this.formData.html,
          })
          .then((response) => {
            window.toastr["success"](this.$t("navigation.template_created"));
          })
          .catch((err) => {
            ndow.toastr["error"](err);
          });
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        var json = data.design; // design json
        var html = data.html; // final html
        console.log(data.design);
        console.log(data.html);
      });
    },
  },
};
</script>
<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; */
}

#app,
#example {
  height: 100%;
}

#example .container {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
}

#bar {
  flex: 1;
  background-color: #40b883;
  color: #fff;
  padding: 10px;
  display: flex;
  max-height: 40px;
  max-width: 94%;
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  flex: 1;
  padding: initial;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #000;
  color: #fff;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}
</style>
