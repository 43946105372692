<template>
  <div class="card-body">
    <form action="" @submit.prevent="next()">
      <p class="form-title">{{ $t("wizard.company_info") }}</p>
      <p class="form-desc">{{ $t("wizard.company_info_desc") }}</p>
      <div class="row mb-4" v-if="isAdmin()">
        <div class="col-md-6">
          <label class="input-label">{{
            $tc("settings.company_info.company_logo")
          }}</label>
          <div id="pick-avatar" class="image-upload-box">
            <div class="overlay">
              <font-awesome-icon class="white-icon" icon="camera" />
            </div>
            <img v-if="previewLogo" :src="previewLogo" class="preview-logo" />
            <div v-else class="upload-content">
              <font-awesome-icon class="upload-icon" icon="cloud-upload-alt" />
              <p class="upload-text">{{ $t("general.choose_file") }}</p>
            </div>
          </div>
        </div>
        <avatar-cropper
          :labels="{ submit: 'Submit', cancel: 'Cancel' }"
          :cropper-options="cropperOptions"
          :output-options="cropperOutputOptions"
          :output-quality="0.8"
          :upload-handler="cropperHandler"
          trigger="#pick-avatar"
          @changed="setFileObject"
          @error="handleUploadError"
        />
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="input-group">
            <!-- <label for="cvr">Enter CVR Number</label> -->
            <base-input
              v-model="companyData.cvr"
              focus
              type="text"
              name="cvr"
              :placeholder="$t('settings.cvrPlaceholder')"
              tab-index="1"
            />
          </div>
        </div>

        <!-- <button  style="height:50px; width=200px;" @click="getCompanyInfo"  :class="{ 'active':  isP  == true }" class="btn btn-primary btn-outline" type="button" aria-pressed="false" id="private_btn">{{this.cvrText}}</button> -->
        <div class="col-sm-2">
          <base-button
            :loading="isLoading"
            type="button"
            color="theme"
            @click="getCompanyInfo"
            class="btnStyles screens"
            aria-pressed="false"
            id="private_btn"
          >
            {{ $t("customers.cvrtext") }}
          </base-button>
        </div>

        <!-- <button :loading="isLoading" style="height:50px; width=200px;" @click="hideForm"  :class="{ 'active':  isP  == true }" class="btn btn-primary btn-outline" type="button" aria-pressed="false" id="private_btn">{{this.cvrText}}</button> -->
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.company_name") }}</label
          ><span class="text-danger"> *</span>
          <base-input
            :invalid="$v.companyData.name.$error"
            v-model.trim="companyData.name"
            type="text"
            name="name"
            @input="$v.companyData.name.$touch()"
          />
          <div v-if="$v.companyData.name.$error">
            <span v-if="!$v.companyData.name.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.phone") }}</label>
          <base-input v-model.trim="companyData.phone" type="text" name="phone" />
        </div>
      </div>
      <!--      <div v-if="this.companyData.country_id != '58'" class="row">
        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.state") }}</label>
          <base-input v-model="companyData.state" name="state" type="text" />
        </div>
        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.city") }}</label>
          <base-input v-model="companyData.city" name="city" type="text" />
        </div>
      </div>-->
      <div class="row">
        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.country") }}</label
          ><span class="text-danger"> *</span>
          <base-select
            v-model="country"
            :class="{ error: $v.companyData.country_id.$error }"
            :options="countries"
            :searchable="true"
            :allow-empty="false"
            :show-labels="false"
            :placeholder="$t('general.select_country')"
            track-by="id"
            label="name"
          />
          <div v-if="$v.companyData.country_id.$error">
            <span v-if="!$v.companyData.country_id.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
          </div>
        </div>

        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.city") }}</label>
          <base-input v-model="companyData.city" name="city" type="text" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.zip_code") }}</label>
          <base-input v-model.trim="companyData.zip" type="text" name="zip" />
        </div>

        <div class="col-md-6">
          <label class="form-label">{{ $t("wizard.address") }}</label>
          <base-text-area
            :invalid="$v.companyData.address_street_1.$error"
            v-model.trim="companyData.address_street_1"
            :placeholder="$t('general.street_1')"
            name="billing_street1"
            rows="2"
            @input="$v.companyData.address_street_1.$touch()"
          />
          <div v-if="$v.companyData.address_street_1.$error">
            <span v-if="!$v.companyData.address_street_1.maxLength" class="text-danger">{{
              $t("validation.description_maxlength")
            }}</span>
          </div>
          <base-text-area
            :invalid="$v.companyData.address_street_2.$error"
            v-model="companyData.address_street_2"
            :placeholder="$t('general.street_2')"
            name="billing_street2"
            rows="2"
            @input="$v.companyData.address_street_2.$touch()"
          />
          <div v-if="$v.companyData.address_street_2.$error">
            <span v-if="!$v.companyData.address_street_2.maxLength" class="text-danger">{{
              $t("validation.description_maxlength")
            }}</span>
          </div>
        </div>
      </div>

      <div class="row" style="float: right">
        <div class="col-md-2" style="margin-right: 29%">
          <base-button
            :loading="loadingBack"
            class="pull-right"
            icon="arrow-left"
            color="theme"
            @click="back"
          >
            {{ $t("wizard.back") }}
          </base-button>
        </div>
        <div class="col-md-3">
          <base-button
            :loading="loading"
            class="pull-right"
            icon="arrow-right"
            rightIcon
            color="theme"
            type="submit"
          >
            {{ $t("wizard.continue") }}
          </base-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import MultiSelect from "vue-multiselect";
import AvatarCropper from "vue-avatar-cropper";
import { validationMixin } from "vuelidate";
import { mapActions } from "vuex";
const { required, maxLength } = require("vuelidate/lib/validators");

export default {
  components: {
    MultiSelect,
    AvatarCropper,
  },
  mixins: [validationMixin],
  data() {
    return {
      cropperOutputOptions: {
        width: 150,
        height: 150,
      },
      cropperOptions: {
        autoCropArea: 1,
        viewMode: 0,
        movable: true,
        zoomable: true,
      },
      companyData: {
        cvr: null,
        logo: "",
        name: null,
        address_street_1: "",
        address_street_2: "",
        city: "",
        state: "",
        country_id: "",
        zip: "",
        phone: "",
      },
      isLoading: false,
      loading: false,
      loadingBack: false,
      step: 1,
      countries: [],
      country: null,
      previewLogo: null,
    };
  },
  validations: {
    companyData: {
      name: {
        required,
      },
      country_id: {
        required,
      },
      address_street_1: {
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
    },
  },
  watch: {
    country({ id }) {
      this.companyData.country_id = id;
      return true;
    },
  },
  mounted() {
    this.fetchCountry(), this.setInitialData();
  },
  methods: {
    ...mapActions("companyInfo", ["loadData", "uploadLogo", "editCompany", "getFile"]),
    ...mapActions("company", ["uploadLogoCompany"]),

    isAdmin() {
      if (localStorage.getItem("role") === "admin") return true;
    },

    getCompanyInfo() {
      this.isLoading = true;
      if (this.companyData.cvr == undefined || this.companyData.cvr == "") {
        window.toastr["error"]("Please enter any valid cvr number");
        this.isLoading = false;
        // this.showmodalpop();
      } else {
        window.axios
          .post(`/api/customers/company_cvr`, {
            cvr: this.companyData.cvr,
          })
          .then((response) => {
            window.toastr["success"](this.$t("customers.datafetched"));

            this.companyData.name = response.data.data.name;
            this.companyData.phone = response.data.data.phone;
            this.companyData.cvr = response.data.data.vat;
            this.companyData.city = response.data.data.city;
            this.companyData.address_street_1 = response.data.data.address;
            this.companyData.address_street_2 = "";
            this.companyData.zip = response.data.data.zipcode;
            this.companyData.country_id = this.country.id;

            // this.buttonClick++;
            // this.isDisable = true;

            // this.hideForm();
            // this.saveBtnDisable = false;
          })
          .catch((err) => {
            window.toastr["error"]("Data has not been fetched successfully..");
          });
      }

      this.isLoading = false;
    },

    async setInitialData() {
      let response = await this.loadData();

      this.isFetchingData = true;
      this.companyData.name = response.data.user.company.name;
      this.companyData.address_street_1 =
        response.data.user.addresses[0].address_street_1;
      this.companyData.address_street_2 =
        response.data.user.addresses[0].address_street_2;
      this.companyData.zip = response.data.user.addresses[0].zip;
      this.companyData.phone = response.data.user.addresses[0].phone;
      this.companyData.state = response.data.user.addresses[0].state;
      this.companyData.city = response.data.user.addresses[0].city;
      this.country = response.data.user.addresses[0].country;
      if (response.data.user.company.logo) {
        this.previewLogo = response.data.user.company.logo;
        this.url = this.previewLogo;
        this.getExtension();
      } else {
        this.previewLogo = "assets/images/WHITE_LOGO_FAKTURA.svg";
      }

      // console.log(this.previewLogo);
    },
    cropperHandler(cropper) {
      this.previewLogo = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
    },
    setFileObject(file) {
      this.fileObject = file;
    },
    handleUploadError(message, type, xhr) {
      window.toastr["error"]("Oops! Something went wrong...");
    },
    async back() {
      this.loadingBack = true;
      let response = await window.axios.post("/api/admin/onboarding/customizationBack");

      if (response.data.success) {
        this.$emit("back");
        this.loadingBack = false;
      }
    },
    async next() {
      this.$v.companyData.$touch();
      if (this.$v.companyData.$invalid) {
        return true;
      }
      this.loading = true;
      let response = await window.axios.post(
        "/api/admin/onboarding/company",
        this.companyData
      );

      if (response.data) {
        localStorage.setItem("onboarding", 6);
        if (this.fileObject && this.previewLogo) {
          let logoData = new FormData();
          logoData.append(
            "company_logo",
            JSON.stringify({
              name: this.fileObject.name,
              data: this.previewLogo,
            })
          );

          await axios.post("/api/admin/onboarding/company/upload-logo", logoData, {
            headers: {
              "Content-Type": "multipart/form-data",
              company: response.data.user.company.id,
            },
          });
        }

        this.$emit("next");
        this.loading = false;
      }
    },
    onFileChange(e) {
      var input = event.target;
      this.companyData.logo = input.files[0];
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.previewLogo = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    async fetchCountry() {
      let res = await window.axios.get("/api/countries");
      if (res) {
        this.countries = res.data.countries;
      }
    },
  },
};
</script>
