import * as types from './mutation-types'

export const fetchInstallments = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/installments`, {params}).then((response) => {
      commit(types.SET_INSTALLMENTS, response.data.installments.data)
      commit(types.SET_TOTAL_INSTALLMENTS, response.data.installments.total)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchInstallment = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/installments/${id}`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addInstallment = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/installments', data).then((response) => {
      commit(types.ADD_INSTALLMENT, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateInstallment = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/installments/${data.id}`, data).then((response) => {
      commit(types.UPDATE_INSTALLMENT, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteInstallment = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/installments/${id}`).then((response) => {
      commit(types.DELETE_INSTALLMENT, id)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteMultipleInstallments = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/installments/delete`, {'id': state.selectedInstallments}).then((response) => {
      commit(types.DELETE_MULTIPLE_INSTALLMENTS, state.selectedInstallments)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
