<template>
  <div class="main-content item-create">
    <div class="page-header">
      <h3 class="page-title">
        <span v-if="this.$route.name === 'editor.edit'">
          {{ $t("editor.edit_item") }}
        </span>
        <span v-else>
          {{ $t("editor.new_item") }}
        </span>
      </h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/system/dashboard">{{
            $t("general.home")
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/system/editor">{{
            $tc("editor.templates", 2)
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="#">
            <span v-if="this.$route.name === 'editor.edit'">
              {{ $t("editor.edit_item") }}
            </span>
            <span v-else>
              {{ $t("editor.new_item") }}
            </span>
          </a>
        </li>
      </ol>
    </div>
    <div>
      <!-- <div class="panel panel-info">
        <div class="panel-heading">
          Invoice data will be displayed after the first component in template i.e (Logo /
          Company Name).
        </div>
      </div> -->
      <br />
      <form v-if="!initLoading" action="" @submit.prevent="saveDesign">
        <div class="container">
          <div style="display: flex">
            <div class="col-xs-4">
              <base-button
                :outline="true"
                color="theme"
                size="large"
                type="submit"
                right-icon
              >
                <span v-if="this.$route.name === 'editor.edit'">
                  {{ $t("editor.update") }}
                </span>
                <span v-else>
                  {{ $t("editor.save") }}
                </span>
              </base-button>
            </div>
            <div class="col-xs-6" style="width: 50%; margin-left: 2%">
              <base-input
                v-model="formData.name"
                :invalid="$v.formData.name.$error"
                @input="$v.formData.name.$touch()"
                :placeholder="$t('editor.enter_template_name')"
              />
              <div v-if="$v.formData.name.$error" class="text-danger">
                <span v-show="!$v.formData.name.required" class="text-danger mt-1">
                  {{ $tc("validation.required") }}
                </span>
              </div>
            </div>
            <label style="margin-left: 2%" for="colorpicker">Invoice Text Color:</label>
            <div class="col-xs-6" style="width: 50%; margin-left: 2%">
              <base-input
                v-model="formData.color"
                type="color"
                :placeholder="$t('editor.enter_template_name')"
              />
              <!-- <div v-if="$v.formData.color.$error" class="text-danger">
                <span v-show="!$v.formData.color.required" class="text-danger mt-1">
                  {{ $tc("validation.required") }}
                </span>
              </div> -->
            </div>

            <!-- <input type="color" id="body" name="body" value="#f6b73c" /> -->

            <!-- <colour-picker
              v-model="colour"
              :value="colour"
              label="Pick Colour"
              picker="chrome"
            /> -->

            <!-- <base-button
              :outline="true"
              color="theme"
              size="large"
              type="button"
              right-icon
              style="margin-left: 2%"
              @click="exportHtml"
            >
              {{ "Get PDF" }}
            </base-button> -->
          </div>
          <hr />

          <!-- <button type="submit" v-on:click="saveDesign">Save Design</button>
          <button type="button" v-on:click="getDesign">Load Design</button> -->
          <!-- <button v-on:click="exportHtml">Export HTML</button> -->

          <!-- <EmailEditor
            style="width: 93%; height: 1px"
            ref="emailEditor"
            minHeight="650px"
            v-on:load="editorLoaded"
          /> -->

          <!-- <ckeditor
            :editor="editor"
            v-model="editorData"
            :config="editorConfig"
          ></ckeditor> -->

          <vue-editor v-model="content" />
          <div>{{ content }}</div>
        </div>
      </form>
      <base-loader v-else />
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { EmailEditor } from "vue-email-editor";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { VueEditor } from "vue2-editor";
import sample from "../../../../data/sample.json";
import { mapActions, mapGetters } from "vuex";
// import { ColourPicker } from "vue-colour-picker";

const {
  required,
  requiredIf,
  minLength,
  numeric,
  minValue,
  maxLength,
} = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});

export default {
  mixins: {
    validationMixin,
  },
  components: {
    EmailEditor,
    VueEditor,
    // "colour-picker": ColourPicker,
  },
  data() {
    return {
      // colour: "#000000",
      content: "My First Content",
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
        // toolbar: {
        //   items: [
        //     "heading",
        //     "|",
        //     "fontfamily",
        //     "fontsize",
        //     "|",
        //     "alignment",
        //     "|",
        //     "fontColor",
        //     "fontBackgroundColor",
        //     "|",
        //     "bold",
        //     "italic",
        //     "strikethrough",
        //     "underline",
        //     "subscript",
        //     "superscript",
        //     "|",
        //     "link",
        //     "|",
        //     "outdent",
        //     "indent",
        //     "|",
        //     "bulletedList",
        //     "numberedList",
        //     "todoList",
        //     "|",
        //     "code",
        //     "codeBlock",
        //     "|",
        //     "insertTable",
        //     "|",
        //     "uploadImage",
        //     "blockQuote",
        //     "|",
        //     "undo",
        //     "redo",
        //   ],
        //   shouldNotGroupWhenFull: true,
        // },
      },
      initLoading: false,
      formData: {
        template: null,
        html: null,
        name: null,
        color: null,
      },
    };
  },
  computed: {
    isEdit() {
      if (this.$route.name === "editor.edit") {
        return true;
      }
      return false;
    },
  },
  created() {
    if (this.isEdit) {
      this.getDesign();
    }
  },
  mounted() {
    this.getItemNo();
  },
  validations: {
    counter: 0,
    formData: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("editor", ["addItem", "fetchItem", "updateItem"]),
    editorLoaded() {
      this.$refs.emailEditor.editor.loadDesign(sample);
    },

    getItemNo() {
      axios
        .get("/api/settings/get-template-no")
        .then((res) => {
          var counter;
          // console.log(res.data.items.length)
          if (res.data.editor.length == 0) {
            counter = 1;
          } else {
            counter = res.data.editor[0].id + 1;
          }

          var field = this.pad(counter, 1);
          if (!this.isEdit) {
            this.formData.name = "Template-" + field;
          }
        })
        .catch((res) => console.log(res.error));
    },
    //### Function to add leading zero(s)
    pad(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    //### END Function to add leading zero(s)

    async getDesign() {
      try {
        let response = await this.fetchItem(this.$route.params.id);
        // console.log(response);
        if (response.data.success) {
          this.formData.template = response.data.editor.json_data;
          this.formData.name = response.data.editor.name;
          this.formData.color = response.data.editor.color;
          // console.log(this.formData.template);
          this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.formData.template));
        }
      } catch (err) {
        console.log(err);
      }
    },

    async enterData() {
      let data = {
        id: this.$route.params.id,
        name: this.formData.name,
        template: this.formData.template,
        html: this.formData.html,
        color: this.formData.color,
      };

      try {
        if (this.isEdit) {
          this.initLoading = true;
          let response = await this.updateItem(data);
          // console.log(response);
          if (response.data.success) {
            this.initLoading = false;
            window.toastr["success"](this.$t("editor.template_updated"));
            this.$router.push("/system/editor");
          }
          // this.initLoading = false
        } else {
          this.initLoading = true;
          let response = await this.addItem(data);
          // console.log(response);
          if (response.data.success) {
            this.initLoading = false;
            window.toastr["success"](this.$t("navigation.template_created"));
            this.$router.push("/system/editor");
            return true;
          }
        }
        this.initLoading = false;
      } catch (err) {
        // alert();
        this.initLoading = false;
        console.log(err);
      }
    },
    async saveDesign() {
      let res = await window.axios.get("/webTest");
      // let idArray = [];
      // let data = [
      //   {
      //     title: "foo",
      //     body: "bar",
      //     userId: 1,
      //   },
      //   {
      //     title: "zoo",
      //     body: "zar",
      //     userId: 2,
      //   },
      // ];

      // for (var i = 0; i < data.length; i++) {
      //   await fetch("https://jsonplaceholder.typicode.com/posts", {
      //     method: "POST",
      //     body: JSON.stringify(data[i]),
      //     headers: {
      //       "Content-type": "application/json; charset=UTF-8",
      //     },
      //   })
      //     .then((response) => response.json())
      //     .then((json) => {
      //       console.log(json);
      //       idArray[i] = json.id;
      //     });
      // }

      // console.log(idArray);
      // return false;
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return true;
      // }
      // await this.$refs.emailEditor.editor.exportHtml((design) => {
      //   // console.log(design);
      //   this.formData.template = JSON.stringify({ body: design.design.body });
      //   this.formData.html = design.html;
      //   this.enterData();
      // });
    },
    async exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.formData.html = data.html;
        this.printPDF(this.formData.html);
      });
    },

    printPDF(html) {
      console.log(html);
      var endpoint = "https://v2018.api2pdf.com/chrome/html";
      var apiKey = "23678351-f3f4-42d1-b04c-4a366b7fc4fe"; //Replace this API key from portal.api2pdf.com
      var config = {
        headers: {
          Authorization: apiKey,
        },
      };

      var payload = {
        html: html, //Use your own HTML
        inlinePdf: true,
        fileName: "test.pdf",
        options: {
          landscape: "true",
          printBackground: true,
        },
      };
      axios
        .post(endpoint, payload, config)
        .then(function (response) {
          console.log(response.data.pdf); //this is your PDF! Do something with it
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "poppins", sans-serif;
}

#app,
#example {
  height: 100%;
}

#example .container {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
}

#bar {
  flex: 1;
  background-color: #40b883;
  color: #fff;
  padding: 10px;
  display: flex;
  max-height: 40px;
}
.v-enter-to {
  opacity: 1;
  transform: translateY(0);
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #000;
  color: #fff;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}

.panel {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  padding: 12px;
}

.ck-editor__editable {
  min-height: 500px;
}
</style>
