var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Account Settings")
    ? _c("div", { staticClass: "setting-main-container" }, [
        _c(
          "form",
          {
            attrs: { action: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.updateUserData.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "card setting-card" }, [
              _c("div", { staticClass: "page-header" }, [
                _c("h3", { staticClass: "page-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("settings.account_settings.account_settings")
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "page-sub-title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("settings.account_settings.section_description")
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row mb-4" },
                [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tc("settings.account_settings.profile_picture")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "image-upload-box avatar-upload",
                        attrs: { id: "pick-avatar" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "overlay" },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "white-icon",
                              attrs: { icon: "camera" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.previewAvatar
                          ? _c("img", {
                              staticClass: "preview-logo",
                              attrs: { src: _vm.previewAvatar },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.previewAvatar
                          ? _c(
                              "div",
                              { staticClass: "upload-content" },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "upload-icon",
                                  attrs: { icon: "cloud-upload-alt" },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "upload-text" }, [
                                  _vm._v(
                                    _vm._s(_vm.$tc("general.choose_file"))
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("avatar-cropper", {
                    attrs: {
                      labels: { submit: "Submit", cancel: "Cancel" },
                      "cropper-options": _vm.cropperOptions,
                      "output-options": _vm.cropperOutputOptions,
                      "output-quality": 0.8,
                      "upload-handler": _vm.cropperHandler,
                      trigger: "#pick-avatar",
                    },
                    on: {
                      changed: _vm.setFileObject,
                      error: _vm.handleUploadError,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4 form-group" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$tc("settings.account_settings.name"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        placeholder: _vm.$t("settings.user_profile.name"),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4 form-group" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.account_settings.email"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.email.$error,
                        disabled: "",
                        placeholder: _vm.$t("settings.user_profile.email"),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.email.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "email", $$v)
                        },
                        expression: "formData.email",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.email.$error
                      ? _c("div", [
                          !_vm.$v.formData.email.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.email.email
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$tc("validation.email_incorrect"))
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4 form-group" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.account_settings.oldpassword"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.oldPassword.$error,
                        placeholder: _vm.$t("settings.user_profile.password"),
                        type: "password",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.oldPassword.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.oldPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "oldPassword", $$v)
                        },
                        expression: "formData.oldPassword",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.oldPassword.$error
                      ? _c("div", [
                          !_vm.$v.formData.oldPassword.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.password_min_length",
                                        _vm.$v.formData.oldPassword.$params
                                          .minLength.min,
                                        {
                                          count:
                                            _vm.$v.formData.oldPassword.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4 form-group" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        _vm._s(_vm.$tc("settings.account_settings.password"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.password.$error,
                        placeholder: _vm.$t("settings.user_profile.password"),
                        type: "password",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.password.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "password", $$v)
                        },
                        expression: "formData.password",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.password.$error
                      ? _c("div", [
                          !_vm.$v.formData.password.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.password_min_length",
                                        _vm.$v.formData.password.$params
                                          .minLength.min,
                                        {
                                          count:
                                            _vm.$v.formData.password.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4 form-group" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tc("settings.account_settings.confirm_password")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.confirm_password.$error,
                        placeholder: _vm.$t(
                          "settings.user_profile.confirm_password"
                        ),
                        type: "password",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.confirm_password.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.confirm_password,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "confirm_password", $$v)
                        },
                        expression: "formData.confirm_password",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.confirm_password.$error
                      ? _c("div", [
                          !_vm.$v.formData.confirm_password.sameAsPassword
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tc("validation.password_incorrect")
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 input-group" },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          icon: "save",
                          color: "theme",
                          type: "submit",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tc("settings.account_settings.save")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }