import * as types from './mutation-types'

export const addBankInformation = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/bankInformation', data).then((response) => {
      commit(types.ADD_BANK_INFO, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchBankInformation = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/bankInformation/${id}/edit`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateBankInformation = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/bankInformation/${data.id}`, data).then((response) => {
      commit(types.UPDATE_BANK_INFO, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteBankInformation = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/bankInformation/${id}`).then((response) => {
      if (response.data.success) {
        commit(types.DELETE_BANK_INFO, id)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
