var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoice
    ? _c(
        "div",
        { staticClass: "main-content invoice-view-page" },
        [
          _c("div", { staticClass: "page-header" }, [
            _c(
              "div",
              { staticClass: "page-actions row" },
              [
                _c(
                  "base-button",
                  {
                    attrs: {
                      loading: _vm.isSendingEmail,
                      disabled: _vm.isSendingEmail,
                      color: "theme",
                    },
                    on: { click: _vm.onSendInvoicePrint },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("invoices.print")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "invoice-view-page-container" }, [
            _c("iframe", {
              staticClass: "frame-style",
              attrs: { id: "iframe", src: "" + _vm.shareableLink },
              on: {
                load: function ($event) {
                  return _vm.iframeLoaded()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.ProcessSendInvoice ? _c("base-loader") : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }