<template>
  <div class="wizard">
    <div class="step-indicator">
      <!--      <img
        id="logo-crater"
        src="/assets/img/crater-logo.png"
        alt="Crater Logo"
        class="logo-main"
      >-->
      <div class="indicator-line">
        <div class="center">
          <!--<div class="steps" :class="{'active': step === 1, 'completed': step > 1}">
            <font-awesome-icon v-if="step > 1" icon="check" class="icon-check"/>
          </div>
          <div class="steps" :class="{'active': step === 2, 'completed': step > 2}">
            <font-awesome-icon v-if="step > 2" icon="check" class="icon-check"/>
          </div>
          <div class="steps" :class="{'active': step === 3, 'completed': step > 3}">
            <font-awesome-icon v-if="step > 3" icon="check" class="icon-check"/>
          </div>-->
          <div class="steps" :class="{ active: step === 4, completed: step > 4 }">
            <font-awesome-icon v-if="step > 4" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 5, completed: step > 5 }">
            <font-awesome-icon v-if="step > 5" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 6, completed: step > 6 }">
            <font-awesome-icon v-if="step > 6" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 7, completed: step > 7 }">
            <font-awesome-icon v-if="step > 7" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 8, completed: step > 8 }">
            <font-awesome-icon v-if="step > 8" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 9, completed: step > 9 }">
            <font-awesome-icon v-if="step > 9" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 10, completed: step > 10 }">
            <font-awesome-icon v-if="step > 10" icon="check" class="icon-check" />
          </div>
          <div class="steps" :class="{ active: step === 11, completed: step > 11 }">
            <font-awesome-icon v-if="step > 11" icon="check" class="icon-check" />
          </div>
        </div>
      </div>
    </div>
    <div class="form-content">
      <div class="card wizard-card">
        <component :is="tab" @next="setTab" @back="backTab" />
      </div>
    </div>
  </div>
</template>
<script>
import SystemRequirement from "./SystemRequirement";
import Permission from "./Permission";
import Database from "./Database";
import EmailConfiguration from "./EmailConfiguration";
import Customization from "./Customization";
import UserProfile from "./UserProfile";
import CompanyInfo from "./CompanyInfo";
import Notifications from "./Notifications";
import Integration from "./Integration";
import Template from "./Template";
import BankInformation from "./BankInformation";
import Settings from "./Settings";

export default {
  components: {
    /*step_1: UserProfile,
    step_2: CompanyInfo,
    step_3: Settings,*/
    step_4: UserProfile,
    step_5: Customization,
    step_6: CompanyInfo,
    step_7: Notifications,
    step_8: Integration,
    step_9: Template,
    step_10: BankInformation,
    step_11: Settings,
  },
  data() {
    return {
      loading: false,
      tab: "step_4",
      step: 4,
    };
  },
  created() {
    this.getOnboardingData();
  },
  methods: {
    async getOnboardingData() {
      let response = await window.axios.get("/api/admin/onboarding");
      if (response.data) {
        if (response.data.profile_complete === "COMPLETED") {
          this.$router.push("/system/dashboard");

          return;
        }

        let dbStep = parseInt(response.data.profile_complete);

        if (dbStep) {
          this.step = dbStep + 1;
          this.tab = `step_${dbStep + 1}`;
        }

        this.languages = response.data.languages;
        this.currencies = response.data.currencies;
        this.dateFormats = response.data.date_formats;
        this.timeZones = response.data.time_zones;

        // this.settingData.currency = this.currencies.find(currency => currency.id === 1)
        // this.settingData.language = this.languages.find(language => language.code === 'en')
        // this.settingData.dateFormat = this.dateFormats.find(dateFormat => dateFormat.value === 'd M Y')
      }
    },
    setTab(data) {
      this.step++;

      if (this.step <= 11) {
        this.tab = "step_" + this.step;
      } else {
        // window.location.reload()
      }
    },
    backTab(data) {
      this.step--;

      if (this.step <= 11) {
        this.tab = "step_" + this.step;
      } else {
        // window.location.reload()
      }
    },
  },
};
</script>
