<template>
  <div class="tax-type-modal">
    <form action="" @submit.prevent="submitBankInformationData">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("tax_types.name") }} <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="name"
              :invalid="$v.formData.name.$error"
              v-model="formData.name"
              type="text"
              @input="$v.formData.name.$touch()"
            />
            <div v-if="$v.formData.name.$error">
              <span
                v-if="!$v.formData.name.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
              <span
                v-if="!$v.formData.name.minLength"
                class="form-group__message text-danger"
              >
                {{
                  $tc(
                    "validation.name_min_length",
                    $v.formData.name.$params.minLength.min,
                    { count: $v.formData.name.$params.minLength.min }
                  )
                }}
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.bankInformation.account_no") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="name"
              :invalid="$v.formData.account_no.$error"
              v-model="formData.account_no"
              type="text"
              @input="$v.formData.account_no.$touch()"
            />
            <div v-if="$v.formData.account_no.$error">
              <span
                v-if="!$v.formData.account_no.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
              <span
                v-if="!$v.formData.account_no.numeric"
                class="form-group__message text-danger"
                >{{ $tc("validation.numbers_only") }}</span
              >
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.bankInformation.IBAN_no") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="name"
              :invalid="$v.formData.IBAN_no.$error"
              v-model="formData.IBAN_no"
              type="text"
              @input="$v.formData.IBAN_no.$touch()"
            />
            <div v-if="$v.formData.IBAN_no.$error">
              <span
                v-if="!$v.formData.IBAN_no.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
              <span
                v-if="!$v.formData.IBAN_no.numeric"
                class="form-group__message text-danger"
                >{{ $tc("validation.numbers_only") }}</span
              >
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label"
            >{{ $t("settings.bankInformation.swift_code") }}
            <span class="required"> *</span></label
          >
          <div class="col-sm-7">
            <base-input
              ref="name"
              :invalid="$v.formData.swift_code.$error"
              v-model="formData.swift_code"
              type="text"
              @input="$v.formData.swift_code.$touch()"
            />
            <div v-if="$v.formData.swift_code.$error">
              <span
                v-if="!$v.formData.swift_code.required"
                class="form-group__message text-danger"
                >{{ $tc("validation.required") }}</span
              >
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-4 col-form-label input-label">{{
            $t("settings.bankInformation.default")
          }}</label>
          <div class="col-sm-7" style="margin-top: 1%">
            <base-switch
              v-model="formData.default"
              class="btn-switch"
              @change="setDefault"
              style="display: flex"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <base-button
          :outline="true"
          class="mr-3"
          color="theme"
          type="button"
          @click="closeBankInformationModal"
        >
          {{ $t("general.cancel") }}
        </base-button>
        <base-button :loading="isLoading" color="theme" icon="save" type="submit">
          {{ !isEdit ? $t("general.save") : $t("general.update") }}
        </base-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, requiredIf, minLength, numeric } = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});
export default {
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      isLoading: false,

      subs: false,
      formData: {
        id: null,
        name: null,
        account_no: null,
        IBAN_no: null,
        swift_code: null,
        default: false,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
    ...mapGetters("currency", [
      "defaultCurrency",
      "defaultCountry",
      "currencies",
      "defaultCurrencyForInput",
    ]),
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      account_no: {
        required,
        numeric,
        minLength: minLength(3),
      },
      IBAN_no: {
        required,
        numeric,
        minLength: minLength(3),
      },
      swift_code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true;
        this.setData();
      } else {
        this.isEdit = false;
      }
    },
    modalActive(val) {
      if (!this.modalActive) {
        this.resetFormData();
      }
    },
  },
  async mounted() {
    if (this.modalDataID) {
      this.isEdit = true;
      this.setData();
    }
  },
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("bankInformation", ["addBankInformation", "updateBankInformation"]),
    resetFormData() {
      this.formData = {
        id: null,
        name: null,
        account_no: null,
        IBAN_no: null,
        swift_code: null,
        default: false,
      };
      this.$v.formData.$reset();
    },

    setDefault() {
      this.formData.default == false ? "YES" : "NO";
    },
    async submitBankInformationData() {
      this.$v.formData.$touch();

      if (this.$v.$invalid) {
        return true;
      }

      this.isLoading = true;
      let response;

      if (!this.isEdit) {
        try {
          response = await this.addBankInformation(this.formData);
          if (response.data.success) {
            window.toastr["success"](
              this.$t("settings.bankInformation.success_message_add")
            );

            this.closeBankInformationModal();
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        try {
          response = await this.updateBankInformation(this.formData);

          if (response.data.success) {
            window.toastr["success"](
              this.$t("settings.bankInformation.success_message_update")
            );

            this.closeBankInformationModal();
            this.$emit("close");
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      }
    },
    async setData() {
      this.formData = {
        id: this.modalData.id,
        name: this.modalData.name,
        account_no: this.modalData.account_no,
        IBAN_no: this.modalData.IBAN_no,
        swift_code: this.modalData.swift_code,
      };
      if (this.modalData.default === "NO") {
        this.formData.default = false;
      } else {
        this.formData.default = true;
      }
    },
    closeBankInformationModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>
