<template>
  <div class="h2 mb-0">
    <b-icon-arrow-down></b-icon-arrow-down>
  </div>
</template>

<script>
export default {
name: "ArrowDown"
}
</script>

<style scoped>

</style>
