var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-input" },
    [
      _vm.icon && _vm.isAlignLeftIcon
        ? _c("font-awesome-icon", {
            staticClass: "left-icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.toggleType === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "baseInput",
            staticClass: "input-field",
            class: [
              {
                "input-field-left-icon": _vm.icon && _vm.isAlignLeftIcon,
                "input-field-right-icon":
                  (_vm.icon && !_vm.isAlignLeftIcon) || _vm.isInputGroup,
                invalid: _vm.isFieldValid,
                disabled: _vm.disabled,
                "small-input": _vm.small,
              },
              _vm.inputClass,
            ],
            attrs: {
              disabled: _vm.disabled,
              readonly: _vm.readOnly,
              name: _vm.name,
              tabindex: _vm.tabIndex,
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.inputValue)
                ? _vm._i(_vm.inputValue, null) > -1
                : _vm.inputValue,
            },
            on: {
              input: _vm.handleInput,
              change: [
                function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
                _vm.handleChange,
              ],
              keyup: _vm.handleKeyupEnter,
              keydown: _vm.handleKeyDownEnter,
              blur: _vm.handleFocusOut,
            },
          })
        : _vm.toggleType === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "baseInput",
            staticClass: "input-field",
            class: [
              {
                "input-field-left-icon": _vm.icon && _vm.isAlignLeftIcon,
                "input-field-right-icon":
                  (_vm.icon && !_vm.isAlignLeftIcon) || _vm.isInputGroup,
                invalid: _vm.isFieldValid,
                disabled: _vm.disabled,
                "small-input": _vm.small,
              },
              _vm.inputClass,
            ],
            attrs: {
              disabled: _vm.disabled,
              readonly: _vm.readOnly,
              name: _vm.name,
              tabindex: _vm.tabIndex,
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.inputValue, null) },
            on: {
              input: _vm.handleInput,
              change: [
                function ($event) {
                  _vm.inputValue = null
                },
                _vm.handleChange,
              ],
              keyup: _vm.handleKeyupEnter,
              keydown: _vm.handleKeyDownEnter,
              blur: _vm.handleFocusOut,
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "baseInput",
            staticClass: "input-field",
            class: [
              {
                "input-field-left-icon": _vm.icon && _vm.isAlignLeftIcon,
                "input-field-right-icon":
                  (_vm.icon && !_vm.isAlignLeftIcon) || _vm.isInputGroup,
                invalid: _vm.isFieldValid,
                disabled: _vm.disabled,
                "small-input": _vm.small,
              },
              _vm.inputClass,
            ],
            attrs: {
              disabled: _vm.disabled,
              readonly: _vm.readOnly,
              name: _vm.name,
              tabindex: _vm.tabIndex,
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              type: _vm.toggleType,
            },
            domProps: { value: _vm.inputValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                },
                _vm.handleInput,
              ],
              change: _vm.handleChange,
              keyup: _vm.handleKeyupEnter,
              keydown: _vm.handleKeyDownEnter,
              blur: _vm.handleFocusOut,
            },
          }),
      _vm._v(" "),
      _vm.showPassword && _vm.isAlignLeftIcon
        ? _c(
            "div",
            {
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  _vm.showPass = !_vm.showPass
                },
              },
            },
            [
              _c("font-awesome-icon", {
                staticClass: "right-icon",
                attrs: { icon: !_vm.showPass ? "eye" : "eye-slash" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.icon && !_vm.isAlignLeftIcon
        ? _c("font-awesome-icon", {
            staticClass: "right-icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isInputGroup
        ? _c("span", { staticClass: "right-input-group-text" }, [
            _vm._v("\n    " + _vm._s(_vm.inputGroupText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }