<template>
  <div class="customer-create main-content" v-if="$can('Import Users')">
    <div class="page-header">
      <h3 class="page-title">Import</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#"> Import Users </router-link>
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="row" style="margin-left: 5%;margin-bottom:3%">
          <div class="section-title" style="padding-top: 10px">
            {{ $t("customers.customer_type") }}
          </div>
          <div class="col-sm-4">
            <base-button
              @click="setCustomerType('private')"
              color="theme"
              type="button"
              :onMouseOver="mouseIn1"
              :onMouseOut="mouseOut2"
              :class="{ active: isP == true }"
              id="private_btn"
              style="width: 100%; display: inline-block"
              :style="{ background: boolVar1, color: boolVar2 }"
            >
              {{ $t("customers.private") }}
            </base-button>
          </div>
          <div class="col-sm-4">
            <!-- &lt;!&ndash; -->
            <div class="divider" />
            <!-- &ndash;&gt; -->
            <base-button
              @click="setCustomerType('business')"
              color="theme"
              type="button"
              :onMouseOver="mouseIn3"
              :onMouseOut="mouseOut4"
              :class="{ active: isB == true }"
              id="business_btn"
              style="width: 100%; display: inline-block"
              :style="{ background: boolVar3, color: boolVar4 }"
            >
              {{ $t("customers.business") }}
            </base-button>
          </div>
        </div>
        <div class="card">
          <div>
            <!--getting change input file event-->
            <div
              class="form-control"
              @click="trigInput"
              style="border: 2px dashed; position: relative"
              :style="setHeight"
            >
              <p
                style="
                  position: absolute;
                  cursor: pointer;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
              >
                 <center><b>Choose file</b></center>
              </p>
            </div>
          </div>
        </div>

            <!--vue csv import main component-->
            <vue-csv-import
              :key="customerType"
              style="margin-top: -18%"
              v-model="csv"
              :map-fields="dataArray"
              ref="csv"
              headers="false"
              inputClass="form-control log"
            >
              <!--error message handling-->
              <template v-slot:error>
                <b class="text text-danger">{{ error }}</b>
              </template>

              <template slot="thead">
                <tr>
                  <th>My Fields</th>
                  <th>Column</th>
                </tr>
              </template>

              <template slot="next" slot-scope="{ load }">
                <base-button
                  @click="loadCsv"
                  color="theme"
                  type="submit"
                  class="collapse-button custom"
                  :disabled="disabled"
                  style="margin-top: 12%"
                >
                  Load Csv
                </base-button>
              </template>
            </vue-csv-import>

            <base-button
              @click="saveCsv"
              :loading="isLoading"
              :disabled="importDisabled"
              icon="save"
              color="theme"
              type="submit"
              class="collapse-button"
              v-if="showImportBtn"
            >
              Import Csv
            </base-button>
            <span style="color: #0e7ea7">{{ text }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="section-title" style="padding-top: 10px">
                {{ $t("customers.customer_type") }}
              </div>
              <div class="col-sm-4">
                <base-button
                  @click="setCustomerType('private')"
                  color="theme"
                  type="button"
                  :onMouseOver="mouseIn1"
                  :onMouseOut="mouseOut2"
                  :class="{ active: isP == true }"
                  id="private_btn"
                  style="width: 100%; display: inline-block"
                  :style="{ background: boolVar1, color: boolVar2 }"
                >
                  {{ $t("customers.private") }}
                </base-button>
              </div>
              <div class="col-sm-4">

                <div class="divider" />

                <base-button
                  @click="setCustomerType('business')"
                  color="theme"
                  type="button"
                  :onMouseOver="mouseIn3"
                  :onMouseOut="mouseOut4"
                  :class="{ active: isB == true }"
                  id="business_btn"
                  style="width: 100%; display: inline-block"
                  :style="{ background: boolVar3, color: boolVar4 }"
                >
                  {{ $t("customers.business") }}
                </base-button>
              </div>
            </div>
            &nbsp;
            <div class="row">
              <div class="form-group" v-if="customerType">
                <label class="control-label"><h3>Import Rules</h3></label
                ><span class="text-danger"> *</span>
                <div class="page-actions row">
                  <div class="container">
                    <div class="large-12 medium-12 small-12 cell">
                      <ul v-if="customerType == 'private'">
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">Data Columns:</strong>Name,
                          Email, Address, City, Country, ZipCode.
                        </li>
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">Country:</strong>The country
                          must be defined according to a specific country code.
                        </li>
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">Zip code:</strong>Zip code
                          can contain both numbers and letters. Unless the company is
                          located in DK, as Danish rules for postcodes will enter into
                          force.
                        </li>
                      </ul>

                      <ul v-else-if="customerType == 'business'">
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">Data Columns:</strong>Name,
                          Email, Address, City, Country, ZipCode.
                        </li>
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">CVR no.:</strong>CVR no. can
                          contain both numbers and letters. Unless the company is located
                          in DK, then the Danish rules for CVR number will enter into
                          force.
                        </li>
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">Country:</strong>The country
                          must be defined according to a specific country code.
                        </li>
                        <li style="padding: 5px">
                          <strong style="padding-right: 10px">Zip code:</strong>Zip code
                          can contain both numbers and letters. Unless the company is
                          located in DK, as Danish rules for postcodes will enter into
                          force.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mouseIn1: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseOut2: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseIn3: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseOut4: "this.style.backgroundColor='white',this.style.color='black'",
      boolVar1: "#0373ff",
      boolVar2: "white",
      boolVar3: "transparent",
      boolVar4: "black",
      isPrivate: false,
      isBusiness: false,
      buttonValue: null,
      isP: false,
      isB: false,
      isY: true,

      csv: null,
      isLoading: false,
      total: 0,
      limit: 500,
      offset: 0,
      timeOut: 0,
      text: "",
      customerType: "private",
      disabled: true,
      showImportBtn: false,
      importDisabled: true,
      error: "",
      height: 120,
    };
  },

  computed: {
    /*set height of background upload image*/
    setHeight() {
      return {
        // height: `${this.height}%`,
        height: `${this.height}px`,
      };
    },
    dataArray() {
      if (this.customerType == "private") {
        return [
          "Name",
          "Email",
          "Address",
          "City",
          /*"Country",*/
          "Zip",
          "Phone",
          /*"Currency",*/
        ];
      } else if (this.customerType == "business") {
        return [
          "Name",
          "Email",
          "Address",
          "City",
          /*"Country",*/
          "Zip",
          "Phone",
          "Cvr",
          "CustomerGroup",
          /*"CustomerNumber",*/
          "HomePage",
          "Access",
          "Zone",
          /*"Currency",*/
          "EANLocationNumber",
          "PaymentTerms",
          "ResponsibleEmployee",
          "Attention",
          "Reference",
          "AccountingNo",
          "Credits",
          "Layout",
          "PNR",
          "DoNotSendInvoices",
        ];
      }
    },
  },

  watch: {
    csv() {
      this.importDisabled = false;
    },
  },

  methods: {
    setCustomerType(type) {
      // this.height = 74;
      this.showImportBtn = false;
      if (type == "private") {
        this.customerType = "private";
        this.mouseIn1 = "";
        this.mouseOut2 = "";
        this.mouseIn3 = "this.style.backgroundColor='#0373ff',this.style.color='white'";
        this.mouseOut4 = "this.style.backgroundColor='white',this.style.color='black'";
        this.boolVar5 = "transparent";
        this.boolVar1 = "#0373ff";
        this.boolVar2 = "white";

        this.boolVar3 = "transparent";
        this.boolVar4 = "black";
      } else if (type == "business") {
        this.customerType = "business";
        this.mouseIn1 = "this.style.backgroundColor='#0373ff',this.style.color='white'";
        this.mouseOut2 = "this.style.backgroundColor='white',this.style.color='black'";
        this.mouseIn3 = "";
        this.mouseOut4 = "";
        this.boolVar1 = "transparent";
        this.boolVar2 = "black";
        this.boolVar3 = "#0373ff";
        this.boolVar4 = "white";
      }
    },

    trigInput() {
      $("input[type=file]").click();
      const input = document.getElementsByClassName("form-control log")[0];
      input.addEventListener("input", this.inputHandler);
    },
    inputHandler() {
      if (this.$refs.csv.$refs.csv.files[0].name.split(".")[1] != "csv") {
        window.toastr["error"]("Please select a valid csv file");
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    loadCsv() {
      if (
        typeof this.$refs.csv.$refs.csv.files[0] !== "undefined" &&
        this.$refs.csv.$refs.csv.files[0].name.split(".")[1] == "csv"
      ) {
        // this.height = (this.customerType == 'private') ? 32 : 30;
        this.error = "";
        this.showImportBtn = true;
        this.$refs.csv.load();
        this.disabled = true;
      } else {
        this.error = "Please select a valid csv file";
      }
    },

    async saveCsv() {
      let fileData = this.csv;
      let formData = {
        data: fileData,
        type: "users",
        customerType: this.customerType,
      };

      try {
        this.isLoading = true;
        const res = await window.axios.post("/api/data/saveCsv", formData);
        /*import csv data function*/
        this.importCsv(res.data.data.file_name);
      } catch (error) {
        window.toastr["error"](error.message);
        this.isLoading = false;
      }
    },

    async importCsv(filename) {
      const limit = this.limit;
      const offset = this.offset;
      try {
        const res = await window.axios.post("/api/data/import", {
          limit: limit,
          offset: offset,
          filename: filename,
          type: "users",
          customerType: this.customerType,
        });

        this.total = res.data.total;
        this.offset = offset + limit;
        if (res.data.status) {
          this.text = "Importing " + this.offset + " of " + this.total;
          // this.timeOut = setTimeout(this.importCsv(filename), 1000); //set the timeout again
          setTimeout(() => {
            this.importCsv(filename);
          }, 1000);
        } else {
          this.importFinished();
        }
        this.isLoading = false;
      } catch (error) {
        window.toastr["error"](error.message);
        this.isLoading = false;
      }
    },

    importFinished() {
      window.toastr["success"]("Data Imported Successfully.");
      clearTimeout(this.timeOut);
      this.limit = 500;
      this.offset = 0;
      this.total = 0;
      this.timeOut = 0;
      this.text = "Import Finished";
      //redirect to customers page after successful import
      this.$router.push("/system/customers");
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>
<style scoped>
.image-uploader {
  position: relative;
  top: 44px;
  left: 0;
  background-image: linear-gradient(to bottom, #009a48c7, rgba(156, 253, 0, 0)),
    url("../../../../../public/assets/img/upload.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.vue-csv-uploader {
  /* margin-top: 42%; */
}

.custom {
  margin-top: 0%;
  position: relative;
}

.text-center {
  text-align: center;
}
</style>
