<template>
  <div class="customer-create main-content">
    <form action="" @submit.prevent="submitSubAdminData">
      <div class="page-header">
        <h3 class="page-title">
          {{ isEdit ? $t("subAdmins.edit_subAdmin") : $t("subAdmins.new_subAdmin") }}
        </h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/system/dashboard">{{
              $t("general.home")
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/system/subAdmins">{{
              $tc("subAdmins.subAdmin", 2)
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            {{ isEdit ? $t("subAdmins.edit_subAdmin") : $t("subAdmins.new_subAdmin") }}
          </li>
        </ol>
        <div class="page-actions header-button-container">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            :tabindex="23"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ isEdit ? $t("subAdmins.update_subAdmin") : $t("subAdmins.save_subAdmin") }}
          </base-button>
        </div>
      </div>

      <div class="customer-card card">
        <div class="card-body">
          <div class="row">
            <div class="section-title col-sm-2">{{ $t("subAdmins.basic_info") }}</div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.display_name") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.email") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.email.$error"
                  v-model.trim="formData.email"
                  type="text"
                  name="email"
                  tab-index="3"
                  @input="$v.formData.email.$touch()"
                />
                <div v-if="$v.formData.email.$error">
                  <span v-if="!$v.formData.email.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.email.email" class="text-danger">
                    {{ $tc("validation.email_incorrect") }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">{{ $tc("subAdmins.password") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  v-model="formData.password"
                  :invalid="$v.formData.password.$error"
                  type="password"
                  @input="$v.formData.password.$touch()"
                />
                <div v-if="$v.formData.password.$error">
                  <span v-if="!$v.formData.password.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.password.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.password_min_length",
                        $v.formData.password.$params.minLength.min,
                        { count: $v.formData.password.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">{{
                  $tc("settings.account_settings.confirm_password")
                }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  v-model="formData.password_confirmation"
                  :invalid="$v.formData.password_confirmation.$error"
                  type="password"
                  @input="$v.formData.password_confirmation.$touch()"
                />
                <div v-if="$v.formData.password_confirmation.$error">
                  <span
                    v-if="!$v.formData.password_confirmation.sameAsPassword"
                    class="text-danger"
                    >{{ $tc("validation.password_incorrect") }}</span
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.primary_color") }}</label>
                <base-input
                  v-model="formData.color"
                  type="color"
                  :placeholder="$t('subAdmins.primary_color')"
                />
              </div>
            </div>
            <div class="col-sm-5" v-if="$can('Sub Admins')">
              <div class="form-group">
                <label class="form-label">{{
                  $t("settings.company_info.company_name")
                }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  v-model.trim="formData.company_name"
                  :label="$t('subAdmins.company_name')"
                  :invalid="$v.formData.company_name.$error"
                  @input="$v.formData.company_name.$touch()"
                  type="text"
                  tab-index="2"
                />
                <div v-if="$v.formData.company_name.$error">
                  <span v-if="!$v.formData.company_name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.company_name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.company_name_min_length",
                        $v.formData.company_name.$params.minLength.min,
                        { count: $v.formData.company_name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.phone") }}</label>
                <base-input
                  v-model.trim="formData.phone"
                  type="text"
                  name="phone"
                  tab-index="4"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.website") }}</label>
                <base-input
                  v-model="formData.website"
                  type="text"
                  tab-index="6"
                  @input="checkWebsite($event)"
                  @change="checkWebsite($event)"
                  :invalid="!formData.isValid && formData.website != ''"
                />
                <div v-if="!formData.isValid && formData.website != ''">
                  <span class="text-danger"> Invalid Url </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.primary_currency") }}</label>
                <base-select
                  v-model="currency"
                  :options="currencies"
                  :custom-label="currencyNameWithCode"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  :tabindex="5"
                  :placeholder="$t('subAdmins.select_currency')"
                  label="name"
                  track-by="id"
                />
                <div>
                  <label class="mt-3 mb-1 d-block"
                    >{{ $t("default.invoice_template") }}
                    <span class="text-danger"> * </span></label
                  >
                  <base-button
                    type="button"
                    class="btn-template"
                    icon="pencil-alt"
                    right-icon
                    @click="openTemplateModal"
                    style="
                      width: 100%;
                      background-color: white;
                      color: black;
                      border: 1px solid #ebf1fa;
                    "
                  >
                    <span class="mr-4">
                      <!-- {{ $t("invoices.template") }}  -->
                      <!-- {{ getTemplateName ? getTemplateName : $t("invoices.template") }} -->
                      {{ getTemplateName ? getTemplateName : "Template 1" }}
                    </span>
                  </base-button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <!-- first row complete  -->
          <div class="row" v-if="$can('Sub Admins')">
            <div class="section-title col-sm-2">
              {{ $t("subAdmins.billing_address") }}
            </div>
            <div class="col-sm-5">
              <!-- <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.name") }}</label>
                <base-input
                  v-model.trim="billing.name"
                  type="text"
                  name="address_name"
                  tab-index="7"
                />
              </div> -->
<!--              <div v-if="this.billing.country_id != '58'" class="form-group">
                <label class="form-label">{{ $t("subAdmins.state") }}</label>
                <base-input
                  v-model="billing.state"
                  name="billing.state"
                  type="text"
                  tab-index="9"
                />
              </div>-->
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.address") }}</label>
                <span class="text-danger"> *</span>
                <base-text-area
                  v-model.trim="billing.address_street_1"
                  :invalid="$v.billing.address_street_1.$error"
                  :tabindex="11"
                  :placeholder="$t('general.street_1')"
                  type="text"
                  name="billing_street1"
                  rows="2"
                  @input="$v.billing.address_street_1.$touch()"
                />
                <!-- <div v-if="$v.billing.address_street_1.$error">
                  <span v-if="!$v.billing.address_street_1.maxLength" class="text-danger">
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div> -->
                <div v-if="$v.billing.address_street_1.$error">
                  <span v-if="!$v.billing.address_street_1.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.billing.address_street_1.maxLength" class="text-danger">
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div>
                <base-text-area
                  :tabindex="12"
                  v-model.trim="billing.address_street_2"
                  :placeholder="$t('general.street_2')"
                  type="text"
                  name="billing_street2"
                  rows="2"
                  @input="$v.billing.address_street_2.$touch()"
                />
                <div v-if="$v.billing.address_street_2.$error">
                  <span v-if="!$v.billing.address_street_2.maxLength" class="text-danger">
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div>

                <div class="form-group" style="margin-top: 1%">
                  <label class="form-label">{{ $t("subAdmins.zip_code") }}</label>
                  <base-input
                    v-model.trim="billing.zip"
                    type="text"
                    name="zip"
                    tab-index="14"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.country") }}</label>
                <base-select
                  v-model="billing_country"
                  :invalid="$v.billing_country.$error"
                  :options="billingCountries"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="false"
                  :tabindex="8"
                  :placeholder="$t('general.select_country')"
                  label="name"
                  track-by="id"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.city") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  v-model="billing.city"
                  name="billing.city"
                  type="text"
                  tab-index="10"
                  :invalid="$v.billing.city.$error"
                />
                <div v-if="$v.billing.city.$error">
                  <span v-if="!$v.billing.city.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("subAdmins.phone") }}</label>
                <base-input
                  v-model.trim="billing.phone"
                  type="text"
                  name="phone"
                  tab-index="13"
                />
              </div>
            </div>
          </div>
          <hr />
          <!-- second row complete  -->
          <div class="same-address-checkbox-container">
            <div class="p-1">
              <base-button
                ref="sameAddress"
                icon="copy"
                color="theme"
                class="btn-sm"
                @click="copyAddress(true)"
              >
                {{ $t("customers.copy_billing_address") }}
              </base-button>
            </div>
          </div>

          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t("customers.shipping_address") }}
            </div>
            <div class="col-sm-5">
              <!-- <div class="form-group">
                <label class="form-label">{{ $t("customers.name") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.shipping.name.$error"
                  v-model.trim="shipping.name"
                  type="text"
                  name="address_name"
                  tab-index="15"
                  @input="$v.shipping.name.$touch()"
                />
                <div v-if="$v.shipping.name.$error">
                  <span v-if="!$v.shipping.name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.shipping.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.shipping.name.$params.minLength.min,
                        { count: $v.shipping.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div> -->
<!--              <div v-if="this.billing.country_id != '58'" class="form-group">
                <label class="form-label">{{ $t("customers.state") }}</label>
                <base-input
                  v-model="shipping.state"
                  name="shipping.state"
                  type="text"
                  tab-index="17"
                />
              </div>-->
              <div class="form-group">
                <label class="form-label">{{ $t("customers.address") }}</label>
                <span class="text-danger"> *</span>
                <base-text-area
                  v-model.trim="shipping.address_street_1"
                  :invalid="$v.shipping.address_street_1.$error"
                  :tabindex="19"
                  :placeholder="$t('general.street_1')"
                  type="text"
                  name="street_1"
                  rows="2"
                  @input="$v.shipping.address_street_1.$touch()"
                />
                <div v-if="$v.shipping.address_street_1.$error">
                  <span v-if="!$v.shipping.address_street_1.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span
                    v-if="!$v.shipping.address_street_1.maxLength"
                    class="text-danger"
                  >
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div>
                <base-text-area
                  v-model.trim="shipping.address_street_2"
                  :tabindex="20"
                  :placeholder="$t('general.street_2')"
                  type="text"
                  name="street_2"
                  rows="2"
                  @input="$v.shipping.address_street_2.$touch()"
                />
                <div v-if="$v.shipping.address_street_2.$error">
                  <span
                    v-if="!$v.shipping.address_street_2.maxLength"
                    class="text-danger"
                    >{{ $t("validation.address_maxlength") }}</span
                  >
                </div>
                <div class="form-group" style="margin-top: 1%">
                  <label class="form-label">{{ $t("customers.zip_code") }}</label>
                  <base-input
                    v-model.trim="shipping.zip"
                    type="text"
                    name="zip"
                    tab-index="22"
                  />
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("customers.country") }}</label>
                <base-select
                  v-model="shipping_country"
                  :options="shippingCountries"
                  :searchable="true"
                  :show-labels="false"
                  :tabindex="16"
                  :allow-empty="false"
                  :placeholder="$t('general.select_country')"
                  label="name"
                  track-by="id"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.city") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  v-model="shipping.city"
                  name="shipping.city"
                  type="text"
                  tab-index="18"
                  :invalid="$v.shipping.city.$error"
                />
                <div v-if="$v.shipping.city.$error">
                  <span v-if="!$v.shipping.city.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.phone") }}</label>
                <base-input
                  v-model.trim="shipping.phone"
                  type="number"
                  name="phone"
                  tab-index="21"
                />
              </div>

              <div class="form-group collapse-button-container">
                <base-button
                  :tabindex="23"
                  icon="save"
                  color="theme"
                  class="collapse-button"
                  type="submit"
                >
                  {{ $t("customers.save_customer") }}
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import AddressStub from "../../stub/address";
const {
  required,
  requiredIf,
  sameAs,
  minLength,
  email,
  url,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  components: { MultiSelect },
  mixins: [validationMixin],
  data() {
    return {
      /*isCopyFromBilling: false,*/
      isLoading: false,
      invoiceTemplates: [],
      formData: {
        name: null,
        company_name: localStorage.getItem("role") == "admin" ? null : "test",
        email: null,
        phone: null,
        color: null,
        password: null,
        password_confirmation: null,
        website: "",
        currency_id: null,
        addresses: [],
        isValid: false,
        invoice_template_id: 1,
      },

      currency: null,
      billing: {
        name: null,
        country_id: null,
        state: null,
        city: null,
        phone: null,
        zip: null,
        address_street_1: null,
        address_street_2: null,
        type: "billing",
      },
      shipping: {
        name: null,
        country_id: null,
        state: null,
        city: null,
        phone: null,
        zip: null,
        address_street_1: null,
        address_street_2: null,
        type: "shipping",
      },
      currencyList: [],

      billing_country: null,
      shipping_country: null,

      billingCountries: [],
      shippingCountries: [],
    };
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      company_name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      /*website: {
        url,
      },*/
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required: requiredIf("isRequired"),
        sameAsPassword: sameAs("password"),
      },
    },
    billing: {
      address_street_1: {
        required,
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },

      city: {
        required,
      },
    },
    shipping: {
      address_street_1: {
        required,
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
      city: {
        required,
      },
    },
    billing_country: {
      required,
    },
  },
  computed: {
    ...mapGetters("currency", ["defaultCurrency", "currencies"]),
    ...mapGetters("invoice", ["getTemplateId", "selectedCustomer", "getTemplateName"]),
    ...mapGetters("subAdmin", ["getTemplateId", "getTemplateName"]),

    isRequired() {
      if (
        this.formData.password === null ||
        this.formData.password === undefined ||
        this.formData.password === ""
      ) {
        return false;
      }
      return true;
    },
    isEdit() {
      if (this.$route.name === "subadmins.edit") {
        return true;
      }
      return false;
    },
    hasBillingAdd() {
      let billing = this.billing;
      if (
        billing.name ||
        billing.country_id ||
        billing.state ||
        billing.city ||
        billing.phone ||
        billing.zip ||
        billing.address_street_1 ||
        billing.address_street_2
      ) {
        return true;
      }
      return false;
    },
    hasShippingAdd() {
      let shipping = this.shipping;
      if (
        shipping.name ||
        shipping.country_id ||
        shipping.state ||
        shipping.city ||
        shipping.phone ||
        shipping.zip ||
        shipping.address_street_1 ||
        shipping.address_street_2
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    billing_country(newCountry) {
      if (newCountry) {
        this.billing.country_id = newCountry.id;
        this.isDisabledBillingState = false;
      } else {
        this.billing.country_id = null;
      }
    },
    shipping_country(newCountry) {
      if (newCountry) {
        this.shipping.country_id = newCountry.id;
        return true;
      } else {
        this.shipping.country_id = null;
      }
    },
  },
  mounted() {
    this.fetchCountry();
    this.fetchDefaultCountry();
    this.docTemplates();
    if (this.isEdit) {
      /*for website validation in edit*/
      this.formData.isValid = true;

      this.loadSubAdmin();
    } else {
      this.currency = this.defaultCurrency;
    }
  },
  methods: {
    ...mapActions("modal", ["openModal"]),
    checkWebsite(str) {
      var regex = new RegExp(
        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
      );
      if (regex.test(str)) {
        this.formData.isValid = true;
      } else {
        this.formData.isValid = false;
      }
    },
    openTemplateModal() {
      this.openModal({
        title: this.$t("general.choose_template"),
        componentName: "SubAdminTemplate",
        data: this.invoiceTemplates,
      });
    },
    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`;
    },
    ...mapActions("subAdmin", [
      "addSubAdmin",
      "fetchSubAdmin",
      "updateSubAdmin",
      "fetchTemplates",
    ]),

    async docTemplates() {
      let {
        data: { invoiceTemplates },
      } = await this.fetchTemplates();
      // console.log(color);
      this.invoiceTemplates = invoiceTemplates;
    },

    async loadSubAdmin() {
      let {
        data: { subAdmin, currencies, currency, color },
      } = await this.fetchSubAdmin(this.$route.params.id);
      // console.log(color);
      this.formData.id = subAdmin.id;
      this.formData.name = subAdmin.name;
      this.formData.company_name = subAdmin.company_name;
      this.formData.email = subAdmin.email;
      this.formData.phone = subAdmin.phone;
      this.formData.website = subAdmin.website;
      this.formData.currency_id = subAdmin.currency_id;
      this.formData.color = color;

      if (subAdmin.billing_address) {
        this.billing = subAdmin.billing_address;

        if (subAdmin.billing_address.country_id) {
          this.billing_country = this.billingCountries.find(
            (c) => c.id === subAdmin.billing_address.country_id
          );
        }
      }

      if (subAdmin.shipping_address) {
        this.shipping = subAdmin.shipping_address;

        if (subAdmin.shipping_address.country_id) {
          this.shipping_country = this.shippingCountries.find(
            (c) => c.id === subAdmin.shipping_address.country_id
          );
        }
      }

      this.currencyList = currencies;
      this.formData.currency_id = subAdmin.currency_id;
      this.currency = currency;
    },
    async fetchCountry() {
      let res = await window.axios.get("/api/countries");
      if (res) {
        this.billingCountries = res.data.countries;
        this.shippingCountries = res.data.countries;
      }
    },
    async fetchDefaultCountry() {
      let res = await window.axios.get("/api/default_countries");
      if (res) {
        this.billing_country = res.data.defaultCountry[0];
      }
      // console.log(this.billing_country.name);
    },
    copyAddress(val) {
      if (val === true) {
        this.isCopyFromBilling = true;
        this.shipping = { ...this.billing, type: "shipping" };
        this.shipping_country = this.billing_country;
        this.shipping_state = this.billing_state;
        this.shipping_city = this.billing_city;
      } else {
        this.shipping = { ...AddressStub, type: "shipping" };
        this.shipping_country = null;
        this.shipping_state = null;
        this.shipping_city = null;
      }
    },
    async submitSubAdminData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return true;
      }
      this.formData.invoice_template_id = this.getTemplateId;

      if (this.hasBillingAdd && this.hasShippingAdd) {
        this.formData.addresses = [{ ...this.billing }, { ...this.shipping }];
      } else {
        if (this.hasBillingAdd) {
          this.formData.addresses = [{ ...this.billing }];
        }
        if (this.hasShippingAdd) {
          this.formData.addresses = [{ ...this.shipping }];
        }
      }

      if (this.isEdit) {
        if (this.currency) {
          this.formData.currency_id = this.currency.id;
        }
        this.isLoading = true;
        // console.log(this.formData);
        try {
          let response = await this.updateSubAdmin(this.formData);
          if (response.data.success) {
            window.toastr["success"](this.$t("subAdmins.updated_message"));
            this.$router.push("/system/subAdmins");
            this.isLoading = false;
            return true;
          } else {
            this.isLoading = false;
            if (response.data.error) {
              window.toastr["error"](this.$t("validation.email_already_taken"));
            }
          }
        } catch (err) {
          if (err.response.data.errors.email) {
            this.isLoading = false;
            window.toastr["error"](this.$t("validation.email_already_taken"));
          }
        }
      } else {
        this.isLoading = true;
        if (this.currency) {
          this.isLoading = true;
          this.formData.currency_id = this.currency.id;
        }
        // console.log(this.formData);
        // return false;
        try {
          let response = await this.addSubAdmin(this.formData);
          console.log(response);
          if (response.data.success) {
            window.toastr["success"](this.$t("subAdmins.created_message"));
            this.$router.push("/system/subAdmins");
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
          /*if (err.response.data.errors.email) {
            this.isLoading = false;
            window.toastr["error"](this.$t("validation.email_already_taken"));
          }*/
        }
      }
    },
  },
};
</script>
