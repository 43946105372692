<template>
  <div class="main-content dashboard" v-if="this.sub_check == 'Free'">
    <!--====== PRICING PART START ======-->

    <section id="pricing" class="pricing-area pt-110 pb-110">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="section-title text-center pb-30">
              <h3 class="title">{{ $tc("frontend.cost") }}</h3>
              <p class="text">
                {{ $tc("frontend.cost_description") }}
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div
            style="min-height: 10%; position: relative"
            class="col-lg-4 col-md-7 col-sm-9"
          >
            <div
              class="single-pricing text-center mt-30 wow fadeIn"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
              style="height: 100%"
            >
              <div class="pricing-title">
                <h4 class="title">{{ $tc("frontend.free") }}</h4>
              </div>
              <div class="pricing-price">
                <span class="price">0 kr</span>
                <p class="text">{{ $tc("frontend.free_month") }}</p>
              </div>
              <div class="pricing-list">
                <ul class="list">
                  <li v-for="(perm, index) in this.freePermissions" :key="index">
                    {{ $tc(perm.landing_page_text) }}
                  </li>
                  <!-- <li>{{ $tc("frontend.free_li1") }}</li>
                  <li>{{ $tc("frontend.free_li2") }}</li>
                  <li>{{ $tc("frontend.free_li3") }}</li>
                  <li>{{ $tc("frontend.free_li4") }}</li>
                  <li>{{ $tc("frontend.free_li5") }}</li> -->
                </ul>
              </div>
              <div
                v-if="this.subscription !== 'Plus' && this.subscription !== 'Pro'"
                class="pricing-btn"
              >
                <base-button
                  :style="linkC"
                  :loading="isLoadingFree"
                  :disabled="isLoadingFree"
                  :class="boolVarFree"
                  id="free"
                  type="button"
                >
                  <span>
                    <center v-if="this.subscription === 'Free'">
                      {{ $tc("frontend.currentPlan") }}
                    </center>
                    <center v-else>
                      {{ $tc("frontend.free_choose") }}
                    </center>
                  </span>
                </base-button>
              </div>
            </div>
            <!-- single pricing -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-9">
            <div
              class="single-pricing pricing-active text-center mt-30 wow fadeIn"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
              style="height: 100%"
            >
              <div class="pricing-shape">
                <img src="/assets/images/price-shape.png" alt="" />
              </div>
              <div class="pricing-title">
                <h4 class="title">{{ $tc("frontend.plus") }}</h4>
              </div>
              <div class="pricing-price">
                <span class="price">19 kr</span>
                <p class="text">{{ $tc("frontend.plus_month") }}</p>
              </div>
              <div class="pricing-list">
                <ul class="list">
                  <li v-for="(perm, index) in this.plusPermissions" :key="index">
                    {{ $tc(perm.landing_page_text) }}
                  </li>
                  <!-- <li>{{ $tc("frontend.plus_li1") }}</li>
                  <li>{{ $tc("frontend.plus_li2") }}</li>
                  <li>{{ $tc("frontend.plus_li3") }}</li>
                  <li>{{ $tc("frontend.plus_li4") }}</li>
                  <li>{{ $tc("frontend.plus_li5") }}</li>
                  <li>{{ $tc("frontend.plus_li6") }}</li>
                  <li>{{ $tc("frontend.plus_li7") }}</li> -->
                </ul>
              </div>
              <div class="pricing-btn">
                <base-button
                  :style="linkA"
                  :loading="isLoadingPlus"
                  :disabled="isLoadingPlus"
                  :class="boolVarPlus"
                  v-on:click="callChangePlan(1)"
                  id="plus"
                  type="button"
                >
                  <span>
                    <center v-if="this.subscription === 'Plus'">
                      {{ $tc("frontend.currentPlan") }}
                    </center>
                    <center v-else>
                      {{ $tc("frontend.free_choose") }}
                    </center>
                  </span>
                </base-button>
              </div>
            </div>
            <!-- single pricing -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-9">
            <div
              class="single-pricing text-center mt-30 wow fadeIn"
              data-wow-duration="1.3s"
              data-wow-delay="0.9s"
              style="height: 100%"
            >
              <div class="pricing-title">
                <h4 class="title">{{ $tc("frontend.pro") }}</h4>
              </div>
              <div class="pricing-price">
                <span class="price">99 kr</span>
                <p class="text">{{ $tc("frontend.pro_month") }}</p>
              </div>
              <div class="pricing-list">
                <ul class="list">
                  <li v-for="(perm, index) in this.proPermissions" :key="index">
                    {{ $tc(perm.landing_page_text) }}
                  </li>
                  <!-- <li>{{ $tc("frontend.pro_li1") }}</li>
                  <li>{{ $tc("frontend.pro_li2") }}</li>
                  <li>{{ $tc("frontend.pro_li3") }}</li>
                  <li>{{ $tc("frontend.pro_li4") }}</li>
                  <li>{{ $tc("frontend.pro_li5") }}</li>
                  <li>{{ $tc("frontend.pro_li6") }}</li>
                  <li>{{ $tc("frontend.pro_li7") }}</li> -->
                  <li>{{ $tc("frontend.pro_li8") }}</li>
                  <li>{{ $tc("frontend.pro_li9") }}</li>
                </ul>
              </div>
              <div class="pricing-btn">
                <base-button
                  :style="linkB"
                  :loading="isLoadingPro"
                  :disabled="isLoadingPro"
                  :class="boolVarPro"
                  v-on:click="callChangePlan(2)"
                  id="pro"
                  type="button"
                >
                  <span>
                    <center v-if="this.subscription === 'Pro'">
                      {{ $tc("frontend.currentPlan") }}
                    </center>
                    <center v-else>
                      {{ $tc("frontend.free_choose") }}
                    </center>
                  </span>
                </base-button>
              </div>
            </div>
            <!-- single pricing -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== PRICING PART ENDS ======-->
  </div>
  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LangFlag from "vue-lang-code-flags";

export default {
  components: {
    LangFlag,
  },
  props: ["val"],
  data() {
    return {
      planId: null,
      isLoadingFree: false,
      isLoadingPlus: false,
      isLoadingPro: false,
      flag: true,
      unique_hash: null,
      loggedIn: false,
      languages: [],
      freePermissions: [],
      plusPermissions: [],
      proPermissions: [],
      subscription: null,
      boolVarPlus: "main-btn-2",
      boolVarPro: "main-btn-2",
      boolVarFree: "main-btn-2",
      url: null,
      freeHref: "/",
      paidHref: "/",
      plusHref: "/",
      proHref: "/",
      linkA: "",
      linkB: "",
      linkC: "",
      formData: {
        language: null,
      },
    };
  },
  watch: {
    "formData.language": function (val, oldval) {
      if (val) {
        window.i18n.locale = val.code;
        localStorage.setItem("language", val.code);
      }
    },
  },

  computed: {
    ...mapGetters("subscription", ["defaultSubscription", "sub_check"]),
  },

  created() {
    this.setInitialData();

    if (this.val == "home") {
      this.flag = false;
    }

    if (localStorage.getItem("selectedCompany")) {
      this.loggedIn = true;
      this.freeHref = "/system/dashboard";
    } else {
      this.freeHref = "/login";

      this.plusHref = "/login";
      this.proHref = "/login";
    }
  },
  methods: {
    ...mapActions("preferences", ["loadData", "loadDataLanding", "editPreferences"]),
    ...mapActions("subscription", ["changePlan"]),

    async callChangePlan(id) {
      var selCompanay = localStorage.getItem("selectedCompany");

      //if user is logged in as free
      if (
        (typeof selCompanay !== "undefined" || selCompanay !== null) &&
        this.subscription === "Free"
      ) {
        if (id == 1) {
          this.isLoadingPlus = true;
          this.isLoadingPro = false;
        } else {
          this.isLoadingPlus = false;
          this.isLoadingPro = true;
        }

        window.location.href = "/system/subscription?hash=" + this.unique_hash;
        return true;
      }
    },

    async setInitialData() {
      try {
        let response = await this.loadDataLanding();

        this.unique_hash = response.data.unique_hash;

        if (response.data.subscription != null) {
          this.subscription = response.data.subscription;
        }

        this.freePermissions = response.data.permissions[0];
        this.plusPermissions = response.data.permissions[1];
        this.proPermissions = response.data.permissions[2];

        if (this.subscription == "Free") {
          this.boolVarFree = "main-btn";
          this.linkC = `"display: inline-block;pointer-events: none;"`;
        } else {
        }
      } catch (err) {
        window.toastr["error"](err);
      }
    },
  },
};
</script>

<style scoped>
.navbar-nav {
  align-items: center;
}
.base-button {
  align-items: center;
  margin: 0 auto;
}
.pricing-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 23px;
  z-index: 1;
}

.single-pricing {
  position: relative;
}

.main-btn-2 {
  color: black !important;
}

.main-btn {
  background: #007bff !important;
}

button:hover {
  background: #007bff;
  color: white !important;
}

.base-button {
  background: transparent;
}

.btn-dark:not(:disabled):not(.disabled):active {
  background: #007bff;
  color: white;
}
.layout-default .main-content {
  padding: 0px 30px 10px 270px;
}
</style>
