import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_PLANS] (state, plans) {
    state.plans = plans
  },

  [types.ADD_PLANS] (state, data) {
    state.plans.push(data.plans)
  },

  [types.UPDATE_PLANS] (state, data) {
    let pos = state.plans.findIndex(plans => plans.id === data.plans.id)
    Object.assign(state.plans[pos], {...data.plans})
  },

  [types.DELETE_PLANS] (state, id) {
    let pos = state.plans.findIndex(plans => plans.stripe_product_id === id)
    state.plans.splice(pos, 1)
  }
}
