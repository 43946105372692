var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.next()
          },
        },
      },
      [
        _c("p", { staticClass: "form-title" }, [
          _vm._v(_vm._s(_vm.$t("wizard.company_info"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-desc" }, [
          _vm._v(_vm._s(_vm.$t("wizard.company_info_desc"))),
        ]),
        _vm._v(" "),
        _vm.isAdmin()
          ? _c(
              "div",
              { staticClass: "row mb-4" },
              [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("label", { staticClass: "input-label" }, [
                    _vm._v(
                      _vm._s(_vm.$tc("settings.company_info.company_logo"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "image-upload-box",
                      attrs: { id: "pick-avatar" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "overlay" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "white-icon",
                            attrs: { icon: "camera" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.previewLogo
                        ? _c("img", {
                            staticClass: "preview-logo",
                            attrs: { src: _vm.previewLogo },
                          })
                        : _c(
                            "div",
                            { staticClass: "upload-content" },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "upload-icon",
                                attrs: { icon: "cloud-upload-alt" },
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "upload-text" }, [
                                _vm._v(_vm._s(_vm.$t("general.choose_file"))),
                              ]),
                            ],
                            1
                          ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("avatar-cropper", {
                  attrs: {
                    labels: { submit: "Submit", cancel: "Cancel" },
                    "cropper-options": _vm.cropperOptions,
                    "output-options": _vm.cropperOutputOptions,
                    "output-quality": 0.8,
                    "upload-handler": _vm.cropperHandler,
                    trigger: "#pick-avatar",
                  },
                  on: {
                    changed: _vm.setFileObject,
                    error: _vm.handleUploadError,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "input-group" },
              [
                _c("base-input", {
                  attrs: {
                    focus: "",
                    type: "text",
                    name: "cvr",
                    placeholder: _vm.$t("settings.cvrPlaceholder"),
                    "tab-index": "1",
                  },
                  model: {
                    value: _vm.companyData.cvr,
                    callback: function ($$v) {
                      _vm.$set(_vm.companyData, "cvr", $$v)
                    },
                    expression: "companyData.cvr",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-2" },
            [
              _c(
                "base-button",
                {
                  staticClass: "btnStyles screens",
                  attrs: {
                    loading: _vm.isLoading,
                    type: "button",
                    color: "theme",
                    "aria-pressed": "false",
                    id: "private_btn",
                  },
                  on: { click: _vm.getCompanyInfo },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.cvrtext")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.company_name"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.companyData.name.$error,
                  type: "text",
                  name: "name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.companyData.name.$touch()
                  },
                },
                model: {
                  value: _vm.companyData.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.companyData,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "companyData.name",
                },
              }),
              _vm._v(" "),
              _vm.$v.companyData.name.$error
                ? _c("div", [
                    !_vm.$v.companyData.name.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.phone"))),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "text", name: "phone" },
                model: {
                  value: _vm.companyData.phone,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.companyData,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "companyData.phone",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.country"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                class: { error: _vm.$v.companyData.country_id.$error },
                attrs: {
                  options: _vm.countries,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false,
                  placeholder: _vm.$t("general.select_country"),
                  "track-by": "id",
                  label: "name",
                },
                model: {
                  value: _vm.country,
                  callback: function ($$v) {
                    _vm.country = $$v
                  },
                  expression: "country",
                },
              }),
              _vm._v(" "),
              _vm.$v.companyData.country_id.$error
                ? _c("div", [
                    !_vm.$v.companyData.country_id.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.city"))),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { name: "city", type: "text" },
                model: {
                  value: _vm.companyData.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.companyData, "city", $$v)
                  },
                  expression: "companyData.city",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.zip_code"))),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "text", name: "zip" },
                model: {
                  value: _vm.companyData.zip,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.companyData,
                      "zip",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "companyData.zip",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.address"))),
              ]),
              _vm._v(" "),
              _c("base-text-area", {
                attrs: {
                  invalid: _vm.$v.companyData.address_street_1.$error,
                  placeholder: _vm.$t("general.street_1"),
                  name: "billing_street1",
                  rows: "2",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.companyData.address_street_1.$touch()
                  },
                },
                model: {
                  value: _vm.companyData.address_street_1,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.companyData,
                      "address_street_1",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "companyData.address_street_1",
                },
              }),
              _vm._v(" "),
              _vm.$v.companyData.address_street_1.$error
                ? _c("div", [
                    !_vm.$v.companyData.address_street_1.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(_vm.$t("validation.description_maxlength"))
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("base-text-area", {
                attrs: {
                  invalid: _vm.$v.companyData.address_street_2.$error,
                  placeholder: _vm.$t("general.street_2"),
                  name: "billing_street2",
                  rows: "2",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.companyData.address_street_2.$touch()
                  },
                },
                model: {
                  value: _vm.companyData.address_street_2,
                  callback: function ($$v) {
                    _vm.$set(_vm.companyData, "address_street_2", $$v)
                  },
                  expression: "companyData.address_street_2",
                },
              }),
              _vm._v(" "),
              _vm.$v.companyData.address_street_2.$error
                ? _c("div", [
                    !_vm.$v.companyData.address_street_2.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(_vm.$t("validation.description_maxlength"))
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row", staticStyle: { float: "right" } }, [
          _c(
            "div",
            { staticClass: "col-md-2", staticStyle: { "margin-right": "29%" } },
            [
              _c(
                "base-button",
                {
                  staticClass: "pull-right",
                  attrs: {
                    loading: _vm.loadingBack,
                    icon: "arrow-left",
                    color: "theme",
                  },
                  on: { click: _vm.back },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("wizard.back")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c(
                "base-button",
                {
                  staticClass: "pull-right",
                  attrs: {
                    loading: _vm.loading,
                    icon: "arrow-right",
                    rightIcon: "",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("wizard.continue")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }