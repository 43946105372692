var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-create main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$t("permissions.title"))),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "dashboard" },
                slot: "item-title",
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("general.home")) + "\n        "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$tc("permissions.assign_permission", 2)) +
                    " TO " +
                    _vm._s(_vm._f("uppercase")(_vm.role)) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-actions row" }),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.save_permissions()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "col-sm-12" },
          _vm._l(_vm.permissions, function (permission) {
            return _c(
              "div",
              {
                staticClass: "col-sm-3",
                staticStyle: { display: "inline-block" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.assigned_permissions,
                      expression: "assigned_permissions",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "checkbox", id: permission.id },
                  domProps: {
                    value: permission.id,
                    checked: permission.assigned,
                    checked: Array.isArray(_vm.assigned_permissions)
                      ? _vm._i(_vm.assigned_permissions, permission.id) > -1
                      : _vm.assigned_permissions,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.assigned_permissions,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = permission.id,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.assigned_permissions = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.assigned_permissions = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.assigned_permissions = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: permission.id } }, [
                  _vm._v(_vm._s(_vm._f("uppercase")(permission.name))),
                ]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-4" }, [
          _c(
            "div",
            { staticClass: "col-md-12 input-group" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("settings.account_settings.save")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }