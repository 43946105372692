<template>
  <div class="h2 mb-0">
    <b-icon-arrow-up></b-icon-arrow-up>
  </div>
</template>

<script>
export default {
name: "ArrowUp"
}
</script>

<style scoped>

</style>
