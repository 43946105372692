export const BOOTSTRAP_SUBADMINS = 'BOOTSTRAP_SUBADMINS'
export const ADD_SUBADMIN = 'ADD_SUBADMIN'
export const UPDATE_SUBADMIN = 'UPDATE_SUBADMIN'
export const DELETE_SUBADMIN = 'DELETE_SUBADMIN'
export const DELETE_MULTIPLE_SUBADMINS = 'DELETE_MULTIPLE_SUBADMINS'
export const SET_SELECTED_SUBADMINS = 'SET_SELECTED_SUBADMINS'
export const SET_TOTAL_SUBADMINS = 'SET_TOTAL_SUBADMINS'
export const RESET_SELECTED_SUBADMIN = 'RESET_SELECTED_SUBADMIN'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID'
export const SET_TEMPLATE_NAME = 'SET_TEMPLATE_NAME'
