var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("dialog", { attrs: { open: "" } }, [
      _c("h2", [_c("center", [_vm._v("Warning!!")])], 1),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c(
            "base-button",
            {
              staticClass: "btn-work",
              attrs: { tabindex: 23, color: "theme", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("pop-back")
                },
              },
            },
            [_vm._v("\n        Go back\n      ")]
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "btn-work",
              attrs: { tabindex: 23, color: "theme", type: "button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("pop-close")
                },
              },
            },
            [_vm._v("\n        Proceed without CVR\n      ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      When you trade with a company, it is important to know\n      "
      ),
      _c("strong", [_vm._v("EXACTLY")]),
      _vm._v(
        " which company you are trading with. We therefore\n      recommend that you get a CVR number on the customer.\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }