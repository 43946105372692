var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAppLoaded
    ? _c(
        "div",
        { staticClass: "template-container" },
        [
          _c("base-modal"),
          _vm._v(" "),
          _c("site-header"),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
          _vm._v(" "),
          _c("site-footer"),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "template-container" },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-spin",
            attrs: { icon: "spinner" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }