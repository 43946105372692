import mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

const initialState = {
  subAdmins: [],
  totalSubAdmins: 0,
  selectAllField: false,
  selectedSubAdmins: [],
  subAdminTemplateId: null,
  subAdminTemplateName:null,
  invoiceTemplateId: 1,
  invoiceTemplateName:'Template 1',
}

export default {
  namespaced: true,

  state: initialState,

  getters: getters,

  actions: actions,

  mutations: mutations
}
