<template>
  <div class="payments main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $t("subscriptionDetails.title") }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("subscriptionDetails.title", 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row"></div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
          <div class="col-md-3">
            <label class="form-label">{{ $t("payments.customer") }}</label>
            <base-customer-select
              ref="customerSelect"
              @select="onSelectCustomer"
              @deselect="clearCustomerSearch"
            />
          </div>
          <div class="col-sm-3">
            <label for="">{{ $t("payments.payment_number") }}</label>
            <base-input
              v-model="filters.payment_number"
              :placeholder="$t(payments.payment_number)"
              name="payment_number"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label">{{ $t("payments.payment_mode") }}</label>
            <base-select
              v-model="filters.payment_mode"
              :options="paymentModes"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('payments.payment_mode')"
              label="name"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label"></label>
            <base-button
              @click="refreshFilterTable"
              style="margin-top: 3%; height: 40px"
              size="large"
              color="theme"
            >
              {{ $t("navigation.applyFilters") }}
            </base-button>
          </div>
        </div>
        <label class="clear-filter" @click="clearFilter">{{
          $t("general.clear_all")
        }}</label>
      </div>
    </transition>

    <div v-cloak v-show="showEmptyScreen" class="col-xs-1 no-data-info" align="center">
      <capsule-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{
          $t("subscriptionDetails.no_subscriptionDetails")
        }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t("subscriptionDetails.list_of_subscriptions")
        }}</label>
      </div>
      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('selectPlan')"
        >
          {{ $t("subscriptionDetails.click_to_subscribe") }}
        </base-button>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t("general.showing") }}: <b>{{ payments.length }}</b>
          {{ $t("general.of") }} <b>{{ totalSubscriptions }}</b>
        </p>

        <transition name="fade">
          <v-dropdown v-if="selectedPayments.length" :show-arrow="false">
            <span slot="activator" href="#" class="table-actions-button dropdown-toggle">
              {{ $t("general.actions") }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultiplePayments">
                <font-awesome-icon :icon="['fas', 'trash']" class="dropdown-item-icon" />
                {{ $t("general.delete") }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

<!--      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllPayments"
        />
        <label
          v-show="!isRequestOngoing"
          for="select-all"
          class="custom-control-label selectall"
        >
          <span class="select-all-label">{{ $t("general.select_all") }} </span>
        </label>
      </div>-->

      <table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
<!--        <table-column :sortable="false" :filterable="false" cell-class="no-click">
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column>-->
        <table-column
          :label="$t('payments.date')"
          sort-as="created_at"
          show="formattedCreatedAt"
        />
        <table-column :label="$t('subscriptionDetails.name')" show="name" />
        <!-- <table-column
          :label="$t('subscriptionDetails.active')"
          :sortable="false"
          :formatter="currentlyActive"
          show="currentlyActive"
        /> -->
        <table-column
          :label="$t('subscriptionDetails.status')"
          :formatter="currentlyActive"
          :sortable="false"
        >
          <template slot-scope="row">
            <span> {{ $t("subscriptionDetails.active") }}</span>
            <span :class="'history-status-' + row.currentlyActive">{{
              row.currentlyActive === true ? "Active" : "In Active"
            }}</span>
          </template>
        </table-column>
        <table-column
          :label="$t('subscriptionDetails.renewal')"
          :sortable="false"
          sort-as="renewal_at"
          show="formattedRenewalAt"
        />

        <table-column :label="$t('invoices.paid')" sort-as="due_amount">
          <template slot-scope="row">
            <span>{{ $t("invoices.paid") }}</span>
            <!-- {{ row.due_amount_nice }} -->
            <div v-html="$utils.formatMoney(row.total, defaultCurrency)" />
          </template>
        </table-column>

        <table-column :label="$t('expenses.receipt')" :sortable="false" sort-as="status">
          <template slot-scope="row">
            <span> {{ $t("expenses.receipt") }}</span>
            <span class="inv-status-draft"><a :href="row.receipt_url" target="_blank">{{ $t("general.view") }}</a></span>
          </template>
        </table-column>

        <table-column
          :sortable="false"
          :filterable="false"
          cell-class="action-dropdown no-click"
        >
          <template slot-scope="row">
            <span>{{ $t("payments.action") }}</span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>

              <v-dropdown-item
                v-if="
                  defaultSubscription &&
                  row.currentlyActive &&
                  !row.user_subscription.ends_at
                "
              >
                <a
                  class="dropdown-item"
                  href=""
                  v-on:click.prevent="cancleSubscription()"
                >
                  <font-awesome-icon icon="pencil-alt" class="dropdown-item-icon" />
                  {{ $t("general.cancel") }}
                </a>
              </v-dropdown-item>
              <!-- <v-dropdown-item>
                <router-link
                  :to="{ path: `payments/${row.id}/edit` }"
                  class="dropdown-item"
                >
                  <font-awesome-icon
                    :icon="['fas', 'pencil-alt']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.edit") }}
                </router-link>
              </v-dropdown-item> -->
              <v-dropdown-item>
                <router-link
                  :to="{ path: `subscriptionDetails/${row.payment_id}/view` }"
                  class="dropdown-item"
                >
                  <font-awesome-icon icon="eye" class="dropdown-item-icon" />
                  {{ $t("general.view") }}
                </router-link>
              </v-dropdown-item>
              <!-- <v-dropdown-item>
                <div class="dropdown-item" @click="removePayment(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.delete") }}
                </div>
              </v-dropdown-item> -->
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
      <base-loader v-if="CancelSubscription" />
    </div>
  </div>

  <!--  else not authorize-->
<!--  <div v-else>
    <not-authorize></not-authorize>
  </div>-->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import CapsuleIcon from "../../components/icon/CapsuleIcon";
import BaseButton from "../../../js/components/base/BaseButton";

export default {
  components: {
    "capsule-icon": CapsuleIcon,
    SweetModal: SweetModal,
    SweetModalTab: SweetModalTab,
    BaseButton,
  },
  data() {
    return {
      showFilters: false,
      sortedBy: "created_at",
      filtersApplied: false,
      isRequestOngoing: true,
      CancelSubscription: false,
      filters: {
        customer: null,
        payment_mode: "",
        payment_number: "",
      },
    };
  },
  computed: {
    checkSubscription() {
      if (
        this.$store.getters["subscription/defaultSubscription"] &&
        this.$store.getters["subscription/defaultSubscription"] != "Free"
      ) {
        return true;
      } else {
        return false;
      }
    },
    showEmptyScreen() {
      return !this.totalSubscriptions && !this.isRequestOngoing && !this.filtersApplied;
    },
    filterIcon() {
      return this.showFilters ? "times" : "filter";
    },
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("currency", ["defaultCurrency"]),
    ...mapGetters("subscriptionDetails", [
      "selectedPayments",
      "totalSubscriptions",
      "payments",
      "selectAllField",
      "paymentModes",
    ]),
    ...mapGetters("subscription", ["defaultSubscription", "sub_check"]),
    selectField: {
      get: function () {
        return this.selectedPayments;
      },
      set: function (val) {
        this.selectPayment(val);
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField;
      },
      set: function (val) {
        this.setSelectAllState(val);
      },
    },
  },
  watch: {
    filters: {
      handler: "setFilters",
      deep: true,
    },
  },
  mounted() {
    // this.fetchCustomers();
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllPayments();
    }
  },
  methods: {
    ...mapActions("subscriptionDetails", [
      "fetchSubscriptionHistory",
      "selectAllPayments",
      "selectPayment",
      "deletePayment",
      "deleteMultiplePayments",
      "setSelectAllState",
    ]),
    ...mapActions("subscription", {
      cancelSub: "cancelSubscription",
      resumeSub: "resumeSubscription",
    }),
    currentlyActive(active) {
      return active === false ? "In Active" : "Active";
    },
    ...mapActions("customer", ["fetchCustomers"]),
    async fetchData({ page, filter, sort }) {
      let data = {
        // customer_id: this.filters.customer !== null ? this.filters.customer.id : "",
        // payment_number: this.filters.payment_number,
        // payment_method_id: this.filters.payment_mode ? this.filters.payment_mode.id : "",
        orderByField: sort.fieldName || "created_at",
        orderBy: sort.order || "desc",
        page,
      };

      this.isRequestOngoing = true;

      let response = await this.fetchSubscriptionHistory(data);

      this.isRequestOngoing = false;

      return {
        data: response.data.subscriptionHistory.data,
        pagination: {
          totalPages: response.data.subscriptionHistory.last_page,
          currentPage: page,
          count: response.data.subscriptionHistory.scount,
        },
      };
    },

    async cancleSubscription() {
      window
        .swal({
          title: this.$t("general.are_you_sure"),
          text: "Your subscription will be cancelled",
          icon: "/assets/icon/paper-plane-solid.svg",
          buttons: true,
          dangerMode: true,
        })
        .then(async (cancel) => {
          if (cancel) {
            this.CancelSubscription = true;
            let response = await this.cancelSub();
            if (response.data.success) {
              window.toastr["success"]("Your subscription cancelled successfully");
              /*window.toastr["success"](this.$tc("estimates.send_estimate_successfully"));*/
              this.CancelSubscription = false;
              this.refreshTable();
              return true;
            }
            if (response.data.error) {
              window.toastr["error"](this.$tc("estimates.something_went_wrong"));
              this.CancelSubscription = false;
              return true;
            }
            window.toastr["error"](this.$tc("estimates.something_went_wrong"));
            this.CancelSubscription = false;
          }
        });
    },

    refreshTable() {
      this.$refs.table.refresh();
    },
    setFilters() {
      this.filtersApplied = true;
    },
    refreshFilterTable() {
      this.refreshTable();
    },
    clearFilter() {
      if (this.filters.customer) {
        this.$refs.customerSelect.$refs.baseSelect.removeElement(this.filters.customer);
      }

      this.filters = {
        customer: null,
        payment_mode: "",
        payment_number: "",
      };

      this.$nextTick(() => {
        this.filtersApplied = false;
      });
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter();
        this.refreshTable();
      }

      this.showFilters = !this.showFilters;
    },
    onSelectCustomer(customer) {
      this.filters.customer = customer;
    },
    async removePayment(id) {
      this.id = id;
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("payments.confirm_delete"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deletePayment(this.id);
          if (res.data.success) {
            window.toastr["success"](this.$tc("payments.deleted_message", 1));
            this.$refs.table.refresh();
            return true;
          } else if (res.data.error) {
            window.toastr["error"](res.data.message);
          }
        }
      });
    },
    async removeMultiplePayments() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("payments.confirm_delete", 2),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let request = await this.deleteMultiplePayments();
          if (request.data.success) {
            window.toastr["success"](this.$tc("payments.deleted_message", 2));
            this.$refs.table.refresh();
          } else if (request.data.error) {
            window.toastr["error"](request.data.message);
          }
        }
      });
    },
    async clearCustomerSearch(removedOption, id) {
      this.filters.customer = "";
      this.$refs.table.refresh();
    },
    showModel(selectedRow) {
      this.selectedRow = selectedRow;
      this.$refs.Delete_modal.open();
    },
    async removeSelectedItems() {
      this.$refs.Delete_modal.close();
      await this.selectedRow.forEach((row) => {
        this.deletePayment(this.id);
      });
      this.$refs.table.refresh();
    },
  },
};
</script>
