var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-main-container", staticStyle: { padding: "30px" } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("settings.notification.title"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "page-sub-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("settings.notification.description")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveEmail()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("settings.notification.email"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.notification_email.$error,
                  placeholder: _vm.$tc(
                    "settings.notification.please_enter_email"
                  ),
                  type: "text",
                  name: "notification_email",
                  icon: "envelope",
                  "input-class": "col-md-6",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.notification_email.$touch()
                  },
                },
                model: {
                  value: _vm.notification_email,
                  callback: function ($$v) {
                    _vm.notification_email =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "notification_email",
                },
              }),
              _vm._v(" "),
              _vm.$v.notification_email.$error
                ? _c("div", [
                    !_vm.$v.notification_email.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.notification_email.email
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tc("validation.email_incorrect")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "base-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tc("settings.notification.save")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "flex-box mt-3 mb-4" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("base-switch", {
              staticClass: "btn-switch",
              on: { change: _vm.setInvoiceViewd },
              model: {
                value: _vm.notify_invoice_viewed,
                callback: function ($$v) {
                  _vm.notify_invoice_viewed = $$v
                },
                expression: "notify_invoice_viewed",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right", staticStyle: { "margin-top": "2%" } },
          [
            _c("p", { staticClass: "box-title" }, [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("settings.notification.invoice_viewed"))),
              ]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "box-desc" }, [
              _vm._v(
                _vm._s(_vm.$t("settings.notification.invoice_viewed_desc"))
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-box mb-4" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("base-switch", {
              staticClass: "btn-switch",
              on: { change: _vm.setEstimateViewd },
              model: {
                value: _vm.notify_estimate_viewed,
                callback: function ($$v) {
                  _vm.notify_estimate_viewed = $$v
                },
                expression: "notify_estimate_viewed",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right", staticStyle: { "margin-top": "2%" } },
          [
            _c("p", { staticClass: "box-title" }, [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("settings.notification.estimate_viewed"))),
              ]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "box-desc" }, [
              _vm._v(
                _vm._s(_vm.$t("settings.notification.estimate_viewed_desc"))
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-box mb-4" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("base-switch", {
              staticClass: "btn-switch",
              on: { change: _vm.setEmailAttachment },
              model: {
                value: _vm.notify_email_attachment,
                callback: function ($$v) {
                  _vm.notify_email_attachment = $$v
                },
                expression: "notify_email_attachment",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right", staticStyle: { "margin-top": "2%" } },
          [
            _c("p", { staticClass: "box-title" }, [
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.$t("settings.notification.email_attachment"))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "box-desc" }, [
              _vm._v(
                _vm._s(_vm.$t("settings.notification.email_attachment_desc"))
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { float: "right" } }, [
        _c(
          "div",
          { staticClass: "col-md-2", staticStyle: { "margin-right": "29%" } },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loadingBack,
                  icon: "arrow-left",
                  color: "theme",
                },
                on: { click: _vm.back },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.back")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loading,
                  icon: "arrow-right",
                  rightIcon: "",
                  color: "theme",
                },
                on: { click: _vm.next },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }