var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Economics")
    ? _c(
        "div",
        { staticClass: "customer-create main-content" },
        [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("economics.sync"))),
            ]),
            _vm._v(" "),
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "dashboard" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.home")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "#" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("navigation.economics", 2)) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "col-xs-1 no-data-info",
              attrs: { align: "center" },
            },
            [
              _c(
                "div",
                { staticClass: "btn-container pb-20" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: { click: _vm.getEconomicProducts },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("economics.sync_items")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-container pb-20" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: { click: _vm.getEconomicCustomers },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("economics.sync_customers")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-container pb-20" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: { click: _vm.getEconomicInvoices },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("economics.sync_invoices")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              staticClass: "col-xs-1 no-data-info",
              attrs: { align: "center" },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "row", attrs: { align: "center" } }, [
                _c("label", { staticClass: "col title" }, [
                  _vm._v(_vm._s(_vm.$t("economics.add_economic_details"))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-container" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("settings/integration")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("economics.sync_invoices")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isLoading ? _c("base-loader") : _vm._e(),
        ],
        1
      )
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }