<template>
  <div class="invoice-create-page main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $tc("settings.setting", 1) }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/system/dashboard">{{
            $t("general.home")
          }}</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="/system/settings/user-profile">{{
            $tc("settings.setting", 2)
          }}</router-link>
        </li>
      </ol>
    </div>
    <div class="row settings-container">
      <div class="col-lg-3 settings-sidebar-container">
        <ol class="settings-sidebar">
          <li
            v-for="(menuItems, index) in menuValues"
            :key="index"
            class="settings-menu-item"
          >
            <router-link
              v-for="(item, index1) in menuItems"
              v-if="$can($t(item.name))"
              :key="index1"
              style="margin-bottom: 7%"
              :to="item.route"
              @click.native="Toggle"
              :class="['link-color', { 'active-setting': hasActiveUrl(item.route) }]"
            >
              <font-awesome-icon :icon="item.icon" class="setting-icon" />
              <span class="ml-3 menu-text">{{ $t(item.title) }}</span>
            </router-link>
          </li>
          <!-- <li class="settings-menu-item">
            <router-link
              v-if="sub_check"
              style="margin-top: -8%"
              to="/admin/subscriptionDetails"
              @click.native="Toggle"
              :class="[
                'link-color',
                { 'active-setting': hasActiveUrl('/admin/subscriptionDetails') },
              ]"
            >
              <font-awesome-icon icon="universal-access" class="setting-icon" />
              <span class="menu-title ml-3">{{
                $t("navigation.subscriptionDetails")
              }}</span>
            </router-link>
          </li> -->
        </ol>
      </div>
      <div class="col-lg-9">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      menuValues: [],
      setting: "setting",
      // menuItems: [
      //   {
      //     link: "/admin/settings/user-profile",
      //     title: "settings.menu_title.account_settings",
      //     icon: "user",
      //     iconType: "far",
      //     name: "Account Settings",
      //   },
      //   {
      //     link: "/admin/settings/company-info",
      //     title: "settings.menu_title.company_information",
      //     icon: "money-bill",
      //     iconType: "far",
      //     name: "Company Information",
      //   },
      //   {
      //     link: "/admin/settings/customization",
      //     title: "settings.menu_title.customization",
      //     icon: "edit",
      //     iconType: "fa",
      //     name: "Customization",
      //   },
      //   {
      //     link: "/admin/settings/preferences",
      //     title: "settings.menu_title.preferences",
      //     icon: "cog",
      //     iconType: "fas",
      //     name: "Preferences",
      //   },
      //   {
      //     link: "/admin/settings/tax-types",
      //     title: "settings.menu_title.tax_types",
      //     icon: "check-circle",
      //     iconType: "far",
      //     name: "Tax Types",
      //   },
      //   {
      //     link: "/admin/settings/expense-category",
      //     title: "settings.menu_title.expense_category",
      //     icon: "list-alt",
      //     iconType: "far",
      //     name: "Expense Categories",
      //   },
      //   {
      //     link: "/admin/settings/mail-configuration",
      //     title: "settings.mail.mail_config",
      //     icon: "envelope",
      //     iconType: "fa",
      //     name: "Mail Configuration",
      //   },
      //   {
      //     link: "/admin/settings/notifications",
      //     title: "settings.menu_title.notifications",
      //     icon: "bell",
      //     iconType: "far",
      //     name: "Notifications",
      //   },
      //   {
      //     link: "/admin/settings/integration",
      //     title: "settings.integration.integration_settings",
      //     icon: "cogs",
      //     iconType: "fas",
      //     name: "Integration Settings",
      //   },
      //   {
      //     link: "/admin/settings/update-app",
      //     title: "settings.menu_title.update_app",
      //     icon: "sync-alt",
      //     iconType: "fas",
      //     name: "Update App",
      //   },
      //   {
      //     link: "/admin/settings/faktura-fee",
      //     title: "settings.menu_title.fakturaFee",
      //     icon: "credit-card",
      //     iconType: "far",
      //     name: "Faktura Fee",
      //   },
      //   {
      //     link: "/admin/settings/plans",
      //     title: "settings.menu_title.subscriptions",
      //     icon: "shopping-basket",
      //     iconType: "fas",
      //     name: "Plans",
      //   },
      //   {
      //     link: "/admin/settings/bankInformation",
      //     title: "settings.menu_title.bankInformation",
      //     icon: "money-bill",
      //     iconType: "fas",
      //     name: "BankInformation",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters("subscription", ["defaultSubscription", "sub_check"]),
  },
  watch: {
    "$route.path"(newValue) {
      if (newValue === "/system/settings") {
        this.$router.push("/system/settings/user-profile");
      }
    },
  },
  created() {
    if (this.$route.path === "/system/settings") {
      this.$router.push("/system/settings/user-profile");
    }

    this.permissionsData();
  },
  methods: {
    hasActiveUrl(url) {
      return this.$route.path.indexOf(url) > -1;
    },
    Toggle() {
      this.$utils.toggleSidebar();
    },
    isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }
      return true;
    },
    async permissionsData() {
      let responseValue;
      var role = localStorage.getItem("role");
      if (!this.isEmpty(this.sub_check)) {
        responseValue = await axios.get(`/api/sidebar/getPlanId/${this.sub_check}`);
      } else {
        responseValue = await axios.get(`/api/sidebar/getPlanId/${this.sub_check}`);
      }
      let response = await axios.get(
        `/api/sidebar/overall/${responseValue.data.value}/${this.setting}`
      );
      if (response.data.permissions) {
        this.menuValues = response.data.permissions;
      }
    },
  },
  isAdmin() {
    if (localStorage.getItem("role") === "admin") return true;
  },
};
</script>
