<template>
  <div class="customer-create main-content">
    <form action="" @submit.prevent="submitCustomerData">
      <div class="page-header">
        <h3 class="page-title">
          {{ isEdit ? $t("customers.edit_customer") : $t("customers.new_customer") }}
        </h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/system/dashboard">{{
              $t("general.home")
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/system/customers">{{
              $tc("customers.customer", 2)
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            {{ isEdit ? $t("customers.edit_customer") : $t("customers.new_customer") }}
          </li>
        </ol>
        <div class="page-actions header-button-container">
          <base-button
            :loading="isLoading"
            :disabled="saveBtnDisable"
            :tabindex="23"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ isEdit ? $t("customers.update_customer") : $t("customers.save_customer") }}
          </base-button>
        </div>
      </div>
      <!-- /////// -->
      <div class="customer-card card">
        <div class="card-body">
          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t("customers.customer_type") }}
            </div>

            <div class="col-sm-4">
              <base-button
                color="theme"
                type="button"
                @click="checkdialog"
                :onMouseOver="mouseIn1"
                :onMouseOut="mouseOut2"
                :class="{ active: isP == true }"
                id="private_btn"
                class="btnStyles"
                :style="{ background: boolVar1, color: boolVar2 }"
              >
                {{ $t("customers.pcustomer") }}
              </base-button>
            </div>
            <div class="col-sm-4">
              <!-- <div class="divider"/> -->
              <base-button
                color="theme"
                type="button"
                @click="showCVR"
                :onMouseOver="mouseIn3"
                :onMouseOut="mouseOut4"
                :class="{ active: isB == true }"
                id="business_btn"
                class="btnStyles screens"
                :style="{ background: boolVar3, color: boolVar4 }"
              >
                {{ $t("customers.bcustomer") }}
              </base-button>
            </div>
          </div>
          <!-- <hr> second row complete  -->
        </div>

        <div v-if="isBusiness" class="card-body">
          <div class="row">
            <div class="col-sm-2 section-title">
              {{ $t("customers.company_type") }}
            </div>

            <div class="col-sm-5">
              <input
                :invalid="$v.formData.removelines.$error"
                v-model="formData.removelines"
                :value="1"
                type="radio"
                id="f-option"
                name="selector"
              />
              <label for="f-option">{{ $t("customers.type1") }}</label>

              <div class="check"></div>

              <input
                v-model="formData.removelines"
                :value="2"
                type="radio"
                id="s-option"
                name="selector"
              />
              <label for="s-option">{{ $t("customers.type2") }}</label>

              <div class="check"></div>

              <input
                v-model="formData.removelines"
                :value="3"
                type="radio"
                id="t-option"
                name="selector"
              />
              <label for="t-option">{{ $t("customers.type3") }}</label>

              <div class="check"></div>

              <div class="col-sm-12" v-if="$v.formData.removelines.$error">
                <span v-if="!$v.formData.removelines.required" class="text-danger">
                  {{ $tc("validation.required") }}
                </span>
              </div>
            </div>
          </div>
          <hr />
          <!-- second row complete  -->
        </div>
      </div>
      <!-- /////////////// -->
      <div v-if="isPrivate" class="customer-card card">
        <div class="card-body">
          <div class="row">
            <div class="section-title col-sm-2">{{ $t("customers.basic_info") }}</div>
            <div class="col-sm-5">
              <div v-if="isBusiness" class="form-group">
                <label class="form-label">{{ $t("customers.cvrNumber") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  v-model="formData.cvr_no"
                  focus
                  type="text"
                  name="cvr_no"
                  tab-index="1"
                  @blur="getCompanyInfo"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.customer_number") }}</label
                ><span class="text-danger"> *</span>

                <base-prefix-input
                  v-model="customerNumAttribute"
                  :prefix="customerPrefix"
                  disabled
                />
              </div>

              <div class="form-group">
                <label class="form-label">{{ $t("customers.display_name") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.email") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.email.$error"
                  v-model.trim="formData.email"
                  type="text"
                  name="email"
                  tab-index="3"
                  @input="$v.formData.email.$touch()"
                />
                <div v-if="$v.formData.email.$error">
                  <span v-if="!$v.formData.email.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.email.email" class="text-danger">
                    {{ $tc("validation.email_incorrect") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("customers.eanNumber") }}</label>
                <base-input
                  v-model="formData.ean_no"
                  focus
                  type="text"
                  name="ean_no"
                  tab-index="1"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{
                  $t("customers.primary_contact_name")
                }}</label>
                <base-input
                  v-model.trim="formData.contact_name"
                  :label="$t('customers.contact_name')"
                  type="text"
                  tab-index="2"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.phone") }}</label>
                <base-input
                  v-model.trim="formData.phone"
                  type="number"
                  name="phone"
                  tab-index="4"
                />
              </div>

              <div class="form-group">
                <label class="form-label">{{ $t("customers.website") }}</label>
                <base-input
                  v-model="formData.website"
                  type="text"
                  tab-index="6"
                  @input="checkWebsite($event)"
                  @change="checkWebsite($event)"
                  :invalid="!formData.isValid && formData.website != ''"
                />
                <div v-if="!formData.isValid && formData.website != ''">
                  <span class="text-danger"> Invalid Url </span>
                </div>
              </div>
              <!-- <div class="form-group">
                <label class="form-label">{{ $t("customers.primary_currency") }}</label>
                <base-select
                  v-model="currency"
                  :options="currencies"
                  :custom-label="currencyNameWithCode"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  :tabindex="5"
                  :placeholder="$t('customers.select_currency')"
                  label="name"
                  track-by="id"
                />
              </div> -->
            </div>
          </div>
          <hr />
          <!-- first row complete  -->
          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t("customers.billing_address") }}
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("customers.name") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.billing.name.$error"
                  v-model.trim="billing.name"
                  type="text"
                  name="address_name"
                  tab-index="7"
                  @input="$v.billing.name.$touch()"
                />
                <div v-if="$v.billing.name.$error">
                  <span v-if="!$v.billing.name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.billing.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.billing.name.$params.minLength.min,
                        { count: $v.billing.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <!--              <div v-if="this.billing.country_id != '58'" class="form-group">
                <label class="form-label">{{ $t("customers.state") }}</label>
                <base-input
                  v-model="billing.state"
                  name="billing.state"
                  type="text"
                  tab-index="9"
                />
              </div>-->
              <div class="form-group">
                <label class="form-label">{{ $t("customers.address") }}</label
                ><span class="text-danger"> *</span>
                <base-text-area
                  v-model.trim="billing.address_street_1"
                  :invalid="$v.billing.address_street_1.$error"
                  :tabindex="11"
                  :placeholder="$t('general.street_1')"
                  type="text"
                  name="billing_street1"
                  rows="2"
                  @input="$v.billing.address_street_1.$touch()"
                />
                <div v-if="$v.billing.address_street_1.$error">
                  <span v-if="!$v.billing.address_street_1.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.billing.address_street_1.maxLength" class="text-danger">
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div>
                <base-text-area
                  :tabindex="12"
                  v-model.trim="billing.address_street_2"
                  :placeholder="$t('general.street_2')"
                  type="text"
                  name="billing_street2"
                  rows="2"
                  @input="$v.billing.address_street_2.$touch()"
                />
                <div v-if="$v.billing.address_street_2.$error">
                  <span v-if="!$v.billing.address_street_2.maxLength" class="text-danger">
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("customers.country") }}</label>
                <base-select
                  v-model="billing_country"
                  :invalid="$v.billing_country.$error"
                  :options="billingCountries"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="false"
                  :tabindex="8"
                  :placeholder="$t('general.select_country')"
                  label="name"
                  track-by="id"
                />

                <div v-if="$v.billing_country.$error">
                  <span v-if="!$v.billing_country.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.city") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  v-model="billing.city"
                  name="billing.city"
                  type="text"
                  tab-index="10"
                  :invalid="$v.billing.city.$error"
                />

                <div v-if="$v.billing.city.$error">
                  <span v-if="!$v.billing.city.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.phone") }}</label>
                <base-input
                  v-model.trim="billing.phone"
                  type="number"
                  name="phone"
                  tab-index="13"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.zip_code") }}</label>
                <base-input
                  v-model.trim="billing.zip"
                  type="text"
                  name="zip"
                  tab-index="14"
                />
              </div>
            </div>
          </div>
          <hr />
          <!-- second row complete  -->
          <div class="same-address-checkbox-container">
            <div class="p-1">
              <base-button
                ref="sameAddress"
                icon="copy"
                color="theme"
                class="btn-sm"
                @click="copyAddress(true)"
              >
                {{ $t("customers.copy_billing_address") }}
              </base-button>
            </div>
          </div>

          <div class="row">
            <div class="section-title col-sm-2">
              {{ $t("customers.shipping_address") }}
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("customers.name") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.shipping.name.$error"
                  v-model.trim="shipping.name"
                  type="text"
                  name="address_name"
                  tab-index="15"
                  @input="$v.shipping.name.$touch()"
                />
                <div v-if="$v.shipping.name.$error">
                  <span v-if="!$v.shipping.name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.shipping.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.shipping.name.$params.minLength.min,
                        { count: $v.shipping.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <!--              <div v-if="this.billing.country_id != '58'" class="form-group">
                <label class="form-label">{{ $t("customers.state") }}</label>
                <base-input
                  v-model="shipping.state"
                  name="shipping.state"
                  type="text"
                  tab-index="17"
                />
              </div>-->
              <div class="form-group">
                <label class="form-label">{{ $t("customers.address") }}</label>
                <base-text-area
                  v-model.trim="shipping.address_street_1"
                  :invalid="$v.shipping.address_street_1.$error"
                  :tabindex="19"
                  :placeholder="$t('general.street_1')"
                  type="text"
                  name="street_1"
                  rows="2"
                  @input="$v.shipping.address_street_1.$touch()"
                />
                <div v-if="$v.shipping.address_street_1.$error">
                  <span v-if="!$v.shipping.address_street_1.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span
                    v-if="!$v.shipping.address_street_1.maxLength"
                    class="text-danger"
                  >
                    {{ $t("validation.address_maxlength") }}
                  </span>
                </div>
                <base-text-area
                  v-model.trim="shipping.address_street_2"
                  :tabindex="20"
                  :placeholder="$t('general.street_2')"
                  type="text"
                  name="street_2"
                  rows="2"
                  @input="$v.shipping.address_street_2.$touch()"
                />
                <div v-if="$v.shipping.address_street_2.$error">
                  <span
                    v-if="!$v.shipping.address_street_2.maxLength"
                    class="text-danger"
                    >{{ $t("validation.address_maxlength") }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("customers.country") }}</label>
                <base-select
                  v-model="shipping_country"
                  :options="shippingCountries"
                  :searchable="true"
                  :show-labels="false"
                  :tabindex="16"
                  :allow-empty="false"
                  :placeholder="$t('general.select_country')"
                  label="name"
                  track-by="id"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.city") }}</label>
                <span class="text-danger"> *</span>
                <base-input
                  v-model="shipping.city"
                  name="shipping.city"
                  type="text"
                  tab-index="18"
                  :invalid="$v.shipping.city.$error"
                />
                <div v-if="$v.shipping.city.$error">
                  <span v-if="!$v.shipping.city.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.phone") }}</label>
                <base-input
                  v-model.trim="shipping.phone"
                  type="number"
                  name="phone"
                  tab-index="21"
                />
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("customers.zip_code") }}</label>
                <base-input
                  v-model.trim="shipping.zip"
                  type="text"
                  name="zip"
                  tab-index="22"
                />
              </div>
              <div class="form-group collapse-button-container">
                <base-button
                  :tabindex="23"
                  icon="save"
                  color="theme"
                  class="collapse-button"
                  type="submit"
                >
                  {{ $t("customers.save_customer") }}
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <pop-alert v-if="this.showmodal" @pop-back="popback" @pop-close="popclose">
    </pop-alert>

    <error-alert
      v-if="this.showModalCustomer"
      @close-dialog="closedialog"
      @cancel-dialog="canceldialog"
    >
    </error-alert>
    <base-loader v-if="ProcessSendInvoice" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import AddressStub from "../../stub/address";
import ErrorALert from "./ErrorAlert.vue";
import PopALert from "./PopAlert.vue";
const {
  required,
  minLength,
  email,
  url,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  components: { MultiSelect, "error-alert": ErrorALert, "pop-alert": PopALert },
  mixins: [validationMixin],
  data() {
    return {
      ProcessSendInvoice: false,
      customerPrefix: "null",
      customerNumAttribute: null,
      cvrText: "Get Info",
      buttonClick: 0,
      isCVR: false,
      mouseIn1: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseOut2: "this.style.backgroundColor='white',this.style.color='black'",
      mouseIn3: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseOut4: "this.style.backgroundColor='white',this.style.color='black'",
      boolVar1: "transparent",
      boolVar2: "black",
      boolVar3: "transparent",
      boolVar4: "black",
      popCloseBtn: 0,
      businessBtn: 0,
      isRequired: false,
      showmodal: false,
      showModalCustomer: false,
      isDisable: false,
      isPrivate: false,
      isBusiness: false,
      buttonValue: null,
      isP: false,
      isB: false,
      isY: true,
      isCopyFromBilling: false,
      isLoading: false,
      formData: {
        c_number: null,
        ean_no: null,
        name: null,
        contact_name: null,
        email: null,
        cvr_no: null,
        phone: null,
        currency_id: null,
        website: "",
        removelines: 1,
        addresses: [],
        isValid: false,
      },

      currency: null,
      billing: {
        name: null,
        country_id: null,
        state: null,
        city: null,
        phone: null,
        zip: null,
        address_street_1: null,
        address_street_2: null,
        type: "billing",
      },
      shipping: {
        name: null,
        country_id: null,
        state: null,
        city: null,
        phone: null,
        zip: null,
        address_street_1: null,
        address_street_2: null,
        type: "shipping",
      },
      currencyList: [],

      billing_country: null,
      shipping_country: null,

      billingCountries: [],
      shippingCountries: [],
      saveBtnDisable: true,
    };
  },
  validations: {
    formData: {
      // c_number: {
      //   required,
      //   minLength: minLength(3),
      // },
      name: {
        required,
        minLength: minLength(3),
      },
      cvr: {},
      removelines: {
        required,
      },
      email: {
        required,
        email,
      },
    },
    billing: {
      name: {
        required,
        minLength: minLength(3),
      },
      address_street_1: {
        required,
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },

      city: {
        required,
      },
    },
    shipping: {
      name: {
        required,
        minLength: minLength(3),
      },
      address_street_1: {
        required,
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
      city: {
        required,
      },
    },
    billing_country: {
      required,
    },
  },
  computed: {
    ...mapGetters("currency", ["defaultCurrency", "defaultCountry", "currencies"]),
    isEdit() {
      if (this.$route.name === "customers.edit") {
        this.saveBtnDisable = false;
        return true;
      }
      return false;
    },
    hasBillingAdd() {
      let billing = this.billing;
      if (
        billing.name ||
        billing.country_id ||
        billing.state ||
        billing.city ||
        billing.phone ||
        billing.zip ||
        billing.address_street_1 ||
        billing.address_street_2
      ) {
        return true;
      }
      return false;
    },
    hasShippingAdd() {
      let shipping = this.shipping;
      if (
        shipping.name ||
        shipping.country_id ||
        shipping.state ||
        shipping.city ||
        shipping.phone ||
        shipping.zip ||
        shipping.address_street_1 ||
        shipping.address_street_2
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    billing_country(newCountry) {
      if (newCountry) {
        this.billing.country_id = newCountry.id;
        this.isDisabledBillingState = false;
      } else {
        this.billing.country_id = null;
      }
    },
    shipping_country(newCountry) {
      if (newCountry) {
        this.shipping.country_id = newCountry.id;
        return true;
      } else {
        this.shipping.country_id = null;
      }
    },
  },
  mounted() {
    this.fetchCountry();
    this.checkdialog();
    this.fetchDefaultCountry();

    if (this.isEdit) {
      /*for website validation in edit*/
      this.formData.isValid = true;

      this.loadCustomer();
      this.showCVR();
    } else {
      this.currency = this.defaultCurrency;
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions("customer", ["fetchCreateCustomer"]),
    async loadData() {
      this.initLoading = true;
      let response = await this.fetchCreateCustomer();
      if (response.data) {
        this.customerPrefix = response.data.customer_prefix;
        this.customerNumAttribute = response.data.nextCustomerNumberAttribute;
      }
      this.initLoading = false;
    },

    checkWebsite(str) {
      var regex = new RegExp(
        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
      );
      if (regex.test(str)) {
        this.formData.isValid = true;
      } else {
        this.formData.isValid = false;
      }
    },
    showmodalpop() {
      swal({
        title: this.$t("general.warning"),
        text: this.$t("customers.popup"),
        icon: "https://svg-clipart.com/svg/symbol/FcKU4jE-warning-sign-vector.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          this.popclose();
          this.loadCompanyInfoData();
        }
      });
    },
    popclose() {
      this.saveBtnDisable = false;
      this.showmodal = false;
      this.isCVR = true;
      this.popCloseBtn++;
      this.hideForm();
    },
    popback() {
      this.showmodal = false;
    },

    closedialog() {
      this.showModalCustomer = false;
      this.buttonClick = 0;
      this.showForm();
    },
    canceldialog() {
      this.showModalCustomer = false;
      this.hideForm();
    },
    checkdialog() {
      this.saveBtnDisable = false;
      if (this.buttonClick > 0) {
        swal({
          title: this.$t("general.warning"),
          text: this.$t("customers.error"),
          icon: "https://svg-clipart.com/svg/symbol/FcKU4jE-warning-sign-vector.svg",
          buttons: true,
          dangerMode: true,
        }).then(async (value) => {
          if (value) {
            this.closedialog();
          }
        });
      } else {
        this.showForm();
      }
    },
    showForm() {
      this.isPrivate = true;
      this.isBusiness = false;
      this.isCVR = false;
      this.buttonValue = 1;
      this.isRequired = false;
      this.isP = true;
      this.isB = false;
      this.isY = false;
      this.mouseIn1 = "";
      this.mouseOut2 = "";
      (this.mouseIn3 = "this.style.backgroundColor='#0373ff',this.style.color='white'"),
        (this.mouseOut4 = "this.style.backgroundColor='white',this.style.color='black'"),
        (this.boolVar5 = "transparent");
      this.boolVar1 = "#0373ff";
      this.boolVar2 = "white";

      this.boolVar3 = "transparent";
      this.boolVar4 = "black";
    },
    hideForm() {
      this.isBusiness = true;
      this.isPrivate = true;
      this.buttonValue = 2;
      this.isRequired = true;
      this.boolVar1 = "transparent";
      this.boolVar2 = "black";
      this.boolVar3 = "#0373ff";
      this.boolVar4 = "white";
      this.isP = false;
      this.isB = true;
      this.isY = true;
    },
    showCVR() {
      (this.mouseIn1 = "this.style.backgroundColor='#0373ff',this.style.color='white'"),
        (this.mouseOut2 = "this.style.backgroundColor='white',this.style.color='black'"),
        (this.mouseIn3 = ""),
        (this.mouseOut4 = ""),
        (this.boolVar1 = "transparent");
      this.boolVar2 = "black";
      this.boolVar3 = "#0373ff";
      this.boolVar4 = "white";
      this.businessBtn++;
      if (this.$route.name === "customers.edit") {
        this.isBusiness = true;
        this.isPrivate = true;

        this.isCVR = true;
        this.buttonValue = 2;
        this.isP = false;
        this.isB = true;
        this.isY = false;
      } else {
        this.formData.cvr = "";

        this.isCVR = true;
        this.isDisable = false;
        this.isBusiness = false;
        this.isPrivate = false;

        this.buttonValue = 2;
        this.isP = false;
        this.isB = true;
        this.isY = false;

        if (this.businessBtn == 0) {
          this.formData.name = "";
          this.formData.contact_name = "";
          this.formData.email = "";
          this.formData.phone = "";

          this.billing.name = "";
          this.billing.city = "";
          this.billing.address_street_1 = "";
          this.billing.phone = "";
          this.billing.zip = "";
        }
      }
      this.popclose();
    },

    loadCompanyInfoData() {
      this.ProcessSendInvoice = true;
      window.axios
        .post(`/api/customers/company_cvr`, {
          cvr: this.formData.cvr_no,
        })
        .then((response) => {
          window.toastr["success"](this.$t("customers.datafetched"));

          this.formData.name = response.data.data.name;
          this.formData.contact_name = response.data.data.name;
          this.formData.email = response.data.data.email;
          this.formData.phone = response.data.data.phone;
          // this.formData.cvr_no = response.data.data.vat;

          this.billing.name = response.data.data.name;
          this.billing.city = response.data.data.city;
          this.billing.address_street_1 = response.data.data.address;
          this.billing.phone = response.data.data.phone;
          this.billing.zip = response.data.data.zipcode;

          this.shipping.name = "";
          this.shipping.city = "";
          this.shipping.address_street_1 = "";
          this.shipping.address_street_2 = "";
          this.shipping.phone = "";
          this.shipping.zip = "";

          this.buttonClick++;
          this.isDisable = true;

          this.hideForm();
          this.saveBtnDisable = false;
          this.ProcessSendInvoice = false;
        })
        .catch((err) => {
          this.ProcessSendInvoice = false;
          window.toastr["error"]("Data has not been fetched successfully..");
        });
    },

    getCompanyInfo() {
      this.isLoading = true;
      if (this.formData.cvr_no == undefined || this.formData.cvr_no == "") {
        window.toastr["error"](this.$t("language.cvr_error"));
        // this.showmodalpop();
      } else {
        window.axios
          .post(`/api/customers/company_cvr`, {
            cvr: this.formData.cvr_no,
          })
          .then((response) => {
            // console.log(response);
            if (response.data.success) {
              window.toastr["success"](this.$t("customers.datafetched"));

              this.formData.name = response.data.data.name;
              this.formData.contact_name = response.data.data.name;
              this.formData.email = response.data.data.email;
              this.formData.phone = response.data.data.phone;
              // this.formData.cvr_no = response.data.data.vat;

              this.billing.name = response.data.data.name;
              this.billing.city = response.data.data.city;
              this.billing.address_street_1 = response.data.data.address;
              this.billing.phone = response.data.data.phone;
              this.billing.zip = response.data.data.zipcode;

              this.shipping.name = "";
              this.shipping.city = "";
              this.shipping.address_street_1 = "";
              this.shipping.address_street_2 = "";
              this.shipping.phone = "";
              this.shipping.zip = "";

              this.buttonClick++;
              this.isDisable = true;

              this.hideForm();
              this.saveBtnDisable = false;
            } else {
              window.toastr["error"](res.data.message);
            }
          })
          .catch((err) => {
            window.toastr["error"](this.$t("language.cvr_error_data_fetched"));
            this.resetForm();
          });
      }

      this.isLoading = false;
    },
    currencyNameWithCode({ name, code }) {
      return `${code} - ${name}`;
    },
    ...mapActions("customer", ["addCustomer", "fetchCustomer", "updateCustomer"]),
    async loadCustomer() {
      let {
        data: { customer, currencies, currency },
      } = await this.fetchCustomer(this.$route.params.id);
      // console.log(customer);
      this.formData.id = customer.id;
      this.formData.cvr_no = customer.cvr_no;
      this.formData.ean_no = customer.ean_no;
      this.customerNumAttribute = customer.customer_number;
      this.customerPrefix = customer.customer_prefix;
      this.formData.name = customer.name;
      this.formData.contact_name = customer.contact_name;
      this.formData.email = customer.email;
      this.formData.phone = customer.phone;
      this.formData.currency_id = customer.currency_id;

      this.formData.website = customer.website;
      if (customer.company_type_id != null) {
        this.formData.removelines = customer.company_type_id;
      } else if (customer.company_type_id == null) {
        this.formData.removelines = 1;
      }

      this.buttonValue = customer.customer_type_id;

      if (customer.billing_address) {
        this.billing = customer.billing_address;

        if (customer.billing_address.country_id) {
          this.billing_country = this.billingCountries.find(
            (c) => c.id === customer.billing_address.country_id
          );
        }
      }

      if (customer.shipping_address) {
        this.shipping = customer.shipping_address;

        if (customer.shipping_address.country_id) {
          this.shipping_country = this.shippingCountries.find(
            (c) => c.id === customer.shipping_address.country_id
          );
        }
      }

      this.currencyList = currencies;

      this.formData.currency_id = customer.currency_id;
      this.currency = currency;

      if (this.buttonValue == 1) {
        this.showForm();
      } else if (this.buttonValue == 2) {
        this.hideForm();
      }
    },
    async fetchCountry() {
      let res = await window.axios.get("/api/countries");
      if (res) {
        this.billingCountries = res.data.countries;
        this.shippingCountries = res.data.countries;
      }
    },

    async fetchDefaultCountry() {
      let res = await window.axios.get("/api/default_countries");
      if (res) {
        this.billing_country = res.data.defaultCountry[0];
        this.shipping_country = res.data.defaultCountry[0];
      }
    },
    copyAddress(val) {
      if (val === true) {
        this.isCopyFromBilling = true;
        this.shipping = { ...this.billing, type: "shipping" };
        this.shipping_country = this.billing_country;
        this.shipping_state = this.billing_state;
        this.shipping_city = this.billing_city;
      } else {
        this.shipping = { ...AddressStub, type: "shipping" };
        this.shipping_country = null;
        this.shipping_state = null;
        this.shipping_city = null;
      }
    },
    async submitCustomerData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return true;
      }
      this.formData.c_number = this.customerPrefix + "-" + this.customerNumAttribute;
      if (this.buttonValue) {
        this.formData.customer_type_id = this.buttonValue;
      }
      if (this.hasBillingAdd && this.hasShippingAdd) {
        this.formData.addresses = [{ ...this.billing }, { ...this.shipping }];
      } else {
        if (this.hasBillingAdd) {
          this.formData.addresses = [{ ...this.billing }];
        }
        if (this.hasShippingAdd) {
          this.formData.addresses = [{ ...this.shipping }];
        }
      }

      if (this.isEdit) {
        if (this.currency) {
          this.formData.currency_id = this.currency.id;
        }

        this.isLoading = true;
        try {
          let response = await this.updateCustomer(this.formData);

          if (response.data.success) {
            window.toastr["success"](this.$t("customers.updated_message"));
            this.$router.push("/system/customers");
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
          if (err.response.data.status) {
            this.isLoading = false;

            return true;
          }
        }
      } else {
        this.isLoading = true;
        if (this.currency) {
          this.isLoading = true;
          this.formData.currency_id = this.currency.id;
        }
        // console.log(this.formData);
        try {
          let response = await this.addCustomer(this.formData);
          if (response.data.success) {
            window.toastr["success"](this.$t("customers.created_message"));
            this.$router.push("/system/customers");
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          if (err.response.data.status) {
            this.isLoading = false;

            return true;
          }
        }
      }
    },

    resetForm() {
      this.formData.name = "";
      this.formData.contact_name = "";
      this.formData.email = "";
      this.formData.phone = "";
      // this.formData.cvr_no = "";

      this.billing.name = "";
      this.billing.city = "";
      this.billing.address_street_1 = "";
      this.billing.phone = "";
      this.billing.zip = "";

      this.shipping.name = "";
      this.shipping.city = "";
      this.shipping.address_street_1 = "";
      this.shipping.address_street_2 = "";
      this.shipping.phone = "";
      this.shipping.zip = "";
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Lato");

.center {
  margin: auto;
}

.titleVal {
  display: contents;
}

.center-all {
  margin: auto;
  padding: 3%;
  padding-left: 20%;
}

.btn-work {
  display: inline-block;
  margin-right: 13%;
  width: 36%;
}

.sbtn {
  padding-top: 3%;
}

#getinfo {
  width: 15%;
  height: 20%;
  padding-top: 0.7%;
}
.loginBtn {
  text-align: center;
}

.leftbtn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px 30px;
  border-style: solid;
  border-width: thick;
  background-color: #fff;
  border-radius: 20px;
  border-color: #389262;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.btnStyles {
  width: 100%;
  display: inline-block;
  white-space: revert;
}

@media (max-width: 576px) {
  .screens {
    margin-top: 2%;
  }
}
</style>
