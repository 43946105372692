import * as types from './mutation-types'

export default {
  [types.BOOTSTRAP_BANK_INFO] (state, bankInformation) {
    state.bankInformation = bankInformation
  },

  [types.ADD_BANK_INFO] (state, data) {

    if(data.Information.default == 'YES'){
      var arrayLength = state.bankInformation.length;
      for (var i = 0; i < arrayLength; i++) {
        state.bankInformation[i].default = 'NO';
      }
    }
    
    state.bankInformation.push(data.Information)
  },

  [types.UPDATE_BANK_INFO] (state, data) {

    if(data.Information.default == 'YES'){
      var arrayLength = state.bankInformation.length;
      for (var i = 0; i < arrayLength; i++) {
        state.bankInformation[i].default = 'NO';
      }
    }

    let pos = state.bankInformation.findIndex(info => info.id === data.Information.id)
    Object.assign(state.bankInformation[pos], {...data.Information})
  },

  [types.DELETE_BANK_INFO] (state, id) {
    let pos = state.bankInformation.findIndex(info => info.id === id)
    state.bankInformation.splice(pos, 1)
  }
}
