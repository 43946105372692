var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invoice-create-page main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v(_vm._s(_vm.$tc("settings.setting", 1))),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/system/dashboard" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: {
                  slot: "item-title",
                  to: "/system/settings/user-profile",
                },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("settings.setting", 2)))]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row settings-container" }, [
      _c("div", { staticClass: "col-lg-3 settings-sidebar-container" }, [
        _c(
          "ol",
          { staticClass: "settings-sidebar" },
          _vm._l(_vm.menuValues, function (menuItems, index) {
            return _c(
              "li",
              { key: index, staticClass: "settings-menu-item" },
              _vm._l(menuItems, function (item, index1) {
                return _vm.$can(_vm.$t(item.name))
                  ? _c(
                      "router-link",
                      {
                        key: index1,
                        class: [
                          "link-color",
                          { "active-setting": _vm.hasActiveUrl(item.route) },
                        ],
                        staticStyle: { "margin-bottom": "7%" },
                        attrs: { to: item.route },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.Toggle.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "setting-icon",
                          attrs: { icon: item.icon },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "ml-3 menu-text" }, [
                          _vm._v(_vm._s(_vm.$t(item.title))),
                        ]),
                      ],
                      1
                    )
                  : _vm._e()
              }),
              1
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-9" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }