<template>
  <div class="customer-create main-content">
    <form action="" @submit.prevent="submitPermissionData">
      <div class="page-header">
        <h3 class="page-title">
          {{
            isEdit ? $t("permissions.edit_permission") : $t("permissions.new_permission")
          }}
        </h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/system/dashboard">{{
              $t("general.home")
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link slot="item-title" to="/system/permissions">{{
              $tc("permissions.permission", 2)
            }}</router-link>
          </li>
          <li class="breadcrumb-item">
            {{
              isEdit
                ? $t("permissions.edit_permission")
                : $t("permissions.new_permission")
            }}
          </li>
        </ol>
        <div class="page-actions header-button-container">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            :tabindex="23"
            icon="save"
            color="theme"
            type="submit"
          >
            {{
              isEdit
                ? $t("permissions.update_permission")
                : $t("permissions.save_permission")
            }}
          </base-button>
        </div>
      </div>
      <div class="customer-card card">
        <div class="card-body">
          <div class="row">
            <div class="section-title col-sm-2">{{ $t("permissions.basic_info") }}</div>
            <div class="col-sm-5">
              <div class="form-group">
                <label class="form-label">{{ $t("permissions.display_name") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.name.$params.minLength.min,
                        { count: $v.formData.name.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("permissions.titles") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.title.$error"
                  v-model="formData.title"
                  focus
                  type="text"
                  name="title"
                  tab-index="1"
                  @input="$v.formData.title.$touch()"
                />
                <div v-if="$v.formData.title.$error">
                  <span v-if="!$v.formData.title.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.title.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.title.$params.minLength.min,
                        { count: $v.formData.title.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">{{ $t("permissions.landingPage") }}</label>
                <base-input
                  v-model="formData.landingPageText"
                  focus
                  type="text"
                  name="landingPageText"
                  tab-index="1"
                  @input="$v.formData.landingPageText.$touch()"
                />
                <!-- <div v-if="$v.formData.title.$error">
                  <span v-if="!$v.formData.title.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.title.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.title.$params.minLength.min,
                        { count: $v.formData.title.$params.minLength.min }
                      )
                    }}
                  </span>
                </div> -->
              </div>

              <div class="form-group">
                <label class="form-label">{{ $t("permissions.icon") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.icon.$error"
                  v-model="formData.icon"
                  focus
                  type="text"
                  name="icon"
                  tab-index="1"
                  @input="$v.formData.icon.$touch()"
                />
                <div v-if="$v.formData.icon.$error">
                  <span v-if="!$v.formData.icon.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.icon.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.icon.$params.minLength.min,
                        { count: $v.formData.icon.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">{{ $t("permissions.route") }}</label
                ><span class="text-danger"> *</span>
                <base-input
                  :invalid="$v.formData.route.$error"
                  v-model="formData.route"
                  focus
                  type="text"
                  name="route"
                  tab-index="1"
                  @input="$v.formData.route.$touch()"
                />
                <div v-if="$v.formData.route.$error">
                  <span v-if="!$v.formData.route.required" class="text-danger">
                    {{ $tc("validation.required") }}
                  </span>
                  <span v-if="!$v.formData.route.minLength" class="text-danger">
                    {{
                      $tc(
                        "validation.name_min_length",
                        $v.formData.route.$params.minLength.min,
                        { count: $v.formData.route.$params.minLength.min }
                      )
                    }}
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label class="form-label">{{
                  $t("permissions.subscription_plans")
                }}</label
                ><span class="text-danger"> *</span>
                <base-select
                  v-model="formData.subscription_plan"
                  :options="subscription_plans"
                  :invalid="$v.formData.subscription_plan.$error"
                  :show-labels="false"
                  :multiple="true"
                  :placeholder="$t('customers.select_payment')"
                  label="name"
                  track-by="id"
                />
                <div v-if="$v.formData.subscription_plan.$error">
                  <span
                    v-if="!$v.formData.subscription_plan.required"
                    class="text-danger"
                  >
                    {{ $tc("validation.required") }}
                  </span>
                </div>
              </div>

              <div style="display: flex; flex-direction: row; align-items: flex-end">
                <div class="left">
                  <base-switch
                    v-model="formData.sidebar"
                    class="btn-switch"
                    @change="setSidebar"
                  />
                </div>
                <!-- <input type="checkbox" id="checkbox" v-model="formData.sidebar" /> -->
                <div class="right" style="margin: -1%; margin-left: 7%">
                  <label class="form-label">{{ $t("permissions.sidebar") }}</label>
                </div>
              </div>
              <div style="display: flex; flex-direction: row; align-items: flex-end">
                <div class="left">
                  <base-switch
                    v-model="formData.show_on_landing"
                    class="btn-switch"
                    @change="setSidebar"
                  />
                </div>
                <!-- <input type="checkbox" id="checkbox" v-model="formData.sidebar" /> -->
                <div class="right" style="margin: -1%; margin-left: 7%">
                  <label class="form-label">{{
                    $t("permissions.show_on_landing_page")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import AddressStub from "../../stub/address";
const {
  required,
  minLength,
  email,
  url,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  components: { MultiSelect },
  mixins: [validationMixin],
  data() {
    return {
      isLoading: false,
      subscription_plans: [],
      formData: {
        name: null,
        title: null,
        icon: null,
        route: null,
        subscription_plan: [],
        sidebar: null,
        show_on_landing: null,
        landingPageText: null,
      },
    };
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
      },
      title: {
        required,
        minLength: minLength(3),
      },
      icon: {
        required,
        minLength: minLength(3),
      },
      route: {
        required,
        minLength: minLength(3),
      },
      subscription_plan: {},
    },
  },
  computed: {
    isEdit() {
      if (this.$route.name === "permissions.edit") {
        return true;
      }
      return false;
    },
  },
  created() {
    this.fetchPlans();
  },
  mounted() {
    if (this.isEdit) {
      this.loadPermission();
    }
  },
  methods: {
    ...mapActions("permission", ["addPermission", "fetchPermission", "updatePermission"]),
    async fetchPlans() {
      let res = await window.axios.get("/api/subscriptionPlans");

      if (res.data.length > 0) {
        this.subscription_plans = res.data;
        // this.formData.subscription_plan = [
        //   { id: res.data[0].id, name: res.data[0].name },
        // ];
      }
    },
    async setSidebar() {
      this.formData.sidebar ? "YES" : "NO";
    },
    async loadPermission() {
      let {
        data: { permission, plans, plans_all },
      } = await this.fetchPermission(this.$route.params.id);

      this.formData.id = permission.id;
      this.formData.landingPageText = permission.landing_page_text;
      this.formData.name = permission.name;
      this.formData.title = permission.title;
      this.formData.icon = permission.icon;
      this.formData.route = permission.route;
      permission.is_main_sidebar === "YES"
        ? (this.formData.sidebar = true)
        : (this.formData.sidebar = null);

      permission.show_on_landing === "YES"
        ? (this.formData.show_on_landing = true)
        : (this.formData.show_on_landing = null);
      // this.formData.sidebar = permission.is_main_sidebar;

      // console.log(plans);
      this.subscription_plans = plans_all.map((pay) => {
        return { ...pay, name: pay.name, id: pay.id };
      });

      this.formData.subscription_plan = plans.map((pay) => {
        return { ...pay, name: pay.name, id: pay.id };
      });
    },
    async submitPermissionData() {
      this.$v.formData.$touch();

      if (this.$v.$invalid) {
        return true;
      }

      if (this.isEdit) {
        this.isLoading = true;
        try {
          let response = await this.updatePermission(this.formData);
          if (response.data.success) {
            window.toastr["success"](this.$t("permissions.updated_message"));
            this.$router.push("/system/permissions");
            this.isLoading = false;
            return true;
          } else {
            this.isLoading = false;
            if (response.data.error) {
              window.toastr["error"](this.$t("validation.email_already_taken"));
            }
          }
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        this.isLoading = true;

        try {
          let response = await this.addPermission(this.formData);
          if (response.data.success) {
            window.toastr["success"](this.$t("permissions.created_message"));
            this.$router.push("/system/permissions");
            this.isLoading = false;
            return true;
          }
        } catch (err) {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
