var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.sub_check == "Free"
    ? _c("div", { staticClass: "main-content dashboard" }, [
        _c(
          "section",
          {
            staticClass: "pricing-area pt-110 pb-110",
            attrs: { id: "pricing" },
          },
          [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row justify-content-center" }, [
                _c("div", { staticClass: "col-lg-7" }, [
                  _c(
                    "div",
                    { staticClass: "section-title text-center pb-30" },
                    [
                      _c("h3", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.cost"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$tc("frontend.cost_description")) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row justify-content-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-lg-4 col-md-7 col-sm-9",
                    staticStyle: { "min-height": "10%", position: "relative" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "single-pricing text-center mt-30 wow fadeIn",
                        staticStyle: { height: "100%" },
                        attrs: {
                          "data-wow-duration": "1.3s",
                          "data-wow-delay": "0.2s",
                        },
                      },
                      [
                        _c("div", { staticClass: "pricing-title" }, [
                          _c("h4", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.$tc("frontend.free"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pricing-price" }, [
                          _c("span", { staticClass: "price" }, [
                            _vm._v("0 kr"),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$tc("frontend.free_month"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "pricing-list" }, [
                          _c(
                            "ul",
                            { staticClass: "list" },
                            _vm._l(
                              this.freePermissions,
                              function (perm, index) {
                                return _c("li", { key: index }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$tc(perm.landing_page_text)) +
                                      "\n                "
                                  ),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        this.subscription !== "Plus" &&
                        this.subscription !== "Pro"
                          ? _c(
                              "div",
                              { staticClass: "pricing-btn" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    class: _vm.boolVarFree,
                                    style: _vm.linkC,
                                    attrs: {
                                      loading: _vm.isLoadingFree,
                                      disabled: _vm.isLoadingFree,
                                      id: "free",
                                      type: "button",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      [
                                        this.subscription === "Free"
                                          ? _c("center", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "frontend.currentPlan"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ])
                                          : _c("center", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "frontend.free_choose"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 col-md-7 col-sm-9" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "single-pricing pricing-active text-center mt-30 wow fadeIn",
                      staticStyle: { height: "100%" },
                      attrs: {
                        "data-wow-duration": "1.3s",
                        "data-wow-delay": "0.5s",
                      },
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "pricing-title" }, [
                        _c("h4", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.plus"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pricing-price" }, [
                        _c("span", { staticClass: "price" }, [_vm._v("19 kr")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.plus_month"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pricing-list" }, [
                        _c(
                          "ul",
                          { staticClass: "list" },
                          _vm._l(this.plusPermissions, function (perm, index) {
                            return _c("li", { key: index }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$tc(perm.landing_page_text)) +
                                  "\n                "
                              ),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pricing-btn" },
                        [
                          _c(
                            "base-button",
                            {
                              class: _vm.boolVarPlus,
                              style: _vm.linkA,
                              attrs: {
                                loading: _vm.isLoadingPlus,
                                disabled: _vm.isLoadingPlus,
                                id: "plus",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.callChangePlan(1)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  this.subscription === "Plus"
                                    ? _c("center", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$tc("frontend.currentPlan")
                                            ) +
                                            "\n                  "
                                        ),
                                      ])
                                    : _c("center", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$tc("frontend.free_choose")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-4 col-md-7 col-sm-9" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "single-pricing text-center mt-30 wow fadeIn",
                      staticStyle: { height: "100%" },
                      attrs: {
                        "data-wow-duration": "1.3s",
                        "data-wow-delay": "0.9s",
                      },
                    },
                    [
                      _c("div", { staticClass: "pricing-title" }, [
                        _c("h4", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.pro"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pricing-price" }, [
                        _c("span", { staticClass: "price" }, [_vm._v("99 kr")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.pro_month"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pricing-list" }, [
                        _c(
                          "ul",
                          { staticClass: "list" },
                          [
                            _vm._l(this.proPermissions, function (perm, index) {
                              return _c("li", { key: index }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc(perm.landing_page_text)) +
                                    "\n                "
                                ),
                              ])
                            }),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(_vm._s(_vm.$tc("frontend.pro_li8"))),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(_vm._s(_vm.$tc("frontend.pro_li9"))),
                            ]),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pricing-btn" },
                        [
                          _c(
                            "base-button",
                            {
                              class: _vm.boolVarPro,
                              style: _vm.linkB,
                              attrs: {
                                loading: _vm.isLoadingPro,
                                disabled: _vm.isLoadingPro,
                                id: "pro",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.callChangePlan(2)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  this.subscription === "Pro"
                                    ? _c("center", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$tc("frontend.currentPlan")
                                            ) +
                                            "\n                  "
                                        ),
                                      ])
                                    : _c("center", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$tc("frontend.free_choose")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pricing-shape" }, [
      _c("img", { attrs: { src: "/assets/images/price-shape.png", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }