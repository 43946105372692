<template>
  <div v-if="invoice" class="main-content invoice-view-page">
    <div class="page-header">
      <div class="page-actions row">
        <base-button
          :loading="isSendingEmail"
          :disabled="isSendingEmail"
          color="theme"
          @click="onSendInvoicePrint"
        >
          {{ $t("invoices.print") }}
        </base-button>
      </div>
    </div>

    <div class="invoice-view-page-container">
      <iframe
        id="iframe"
        :src="`${shareableLink}`"
        @load="iframeLoaded()"
        class="frame-style"
      />
    </div>
    <base-loader v-if="ProcessSendInvoice" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const _ = require("lodash");
export default {
  props: ["id", "val", "company"],
  data() {
    return {
      // id: null,
      ProcessSendInvoice: false,
      count: null,
      invoices: [],
      invoice: null,
      currency: null,
      searchData: {
        orderBy: null,
        orderByField: null,
        searchText: null,
      },
      isRequestOnGoing: false,
      isSearching: false,
      isSendingEmail: false,
      isMarkingAsSent: false,
    };
  },
  computed: {
    ...mapGetters("currency", ["defaultCurrency"]),
    getOrderBy() {
      if (this.searchData.orderBy === "asc" || this.searchData.orderBy == null) {
        return true;
      }
      return false;
    },
    getOrderName() {
      if (this.getOrderBy) {
        return this.$t("general.ascending");
      }
      return this.$t("general.descending");
    },
    shareableLink() {
      return `/invoices/print/${this.val}/${this.company}`;
    },
  },
  watch: {
    $route(to, from) {
      this.loadInvoice();
    },
  },
  created() {
    this.ProcessSendInvoice = true;
    // this.loadInvoices();
    this.loadInvoice();
    this.onSearch = _.debounce(this.onSearch, 500);
    // console.log(this.company);
  },
  methods: {
    ...mapActions("printing", ["fetchViewInvoice"]),

    iframeLoaded() {
      this.ProcessSendInvoice = false;
    },
    async loadInvoice() {
      let response = await this.fetchViewInvoice(this.$route.params.id);

      if (response.data) {
        this.invoice = response.data.invoice;
      }
    },
    async onSearch() {
      let data = "";
      if (
        this.searchData.searchText !== "" &&
        this.searchData.searchText !== null &&
        this.searchData.searchText !== undefined
      ) {
        data += `search=${this.searchData.searchText}&`;
      }

      if (this.searchData.orderBy !== null && this.searchData.orderBy !== undefined) {
        data += `orderBy=${this.searchData.orderBy}&`;
      }

      if (
        this.searchData.orderByField !== null &&
        this.searchData.orderByField !== undefined
      ) {
        data += `orderByField=${this.searchData.orderByField}`;
      }
      this.isSearching = true;
      let response = await this.searchInvoice(data);
      this.isSearching = false;
      if (response.data) {
        this.invoices = response.data.invoices.data;
      }
    },
    sortData() {
      if (this.searchData.orderBy === "asc") {
        this.searchData.orderBy = "desc";
        this.onSearch();
        return true;
      }
      this.searchData.orderBy = "asc";
      this.onSearch();
      return true;
    },
    async onMarkAsSent() {
      window
        .swal({
          title: this.$t("general.are_you_sure"),
          text: this.$t("invoices.invoice_mark_as_sent"),
          icon: "/assets/icon/check-circle-solid.svg",
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.isMarkingAsSent = true;
            let response = await this.markAsSent({ id: this.invoice.id });
            this.isMarkingAsSent = false;
            if (response.data) {
              window.toastr["success"](this.$tc("invoices.marked_as_sent_message"));
            }
          }
        });
    },
    async onSendInvoicePrint() {
      window
        .swal({
          title: this.$tc("general.are_you_sure"),
          text: this.$tc("invoices.confirm_print"),
          icon: "/assets/icon/paper-plane-solid.svg",
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.isSendingEmail = true;
            document.getElementById("iframe").contentWindow.print();
            this.isSendingEmail = false;
          }
        });
    },
    copyPdfUrl() {
      let pdfUrl = `${window.location.origin}/invoices/pdf/${this.invoice.unique_hash}`;

      let response = this.$utils.copyTextToClipboard(pdfUrl);

      window.toastr["success"](this.$tc("Copied PDF url to clipboard!"));
    },
    // async removeInvoice(id) {
    //   this.selectInvoice([parseInt(id)]);
    //   this.id = id;
    //   window
    //     .swal({
    //       title: "Deleted",
    //       text: "you will not be able to recover this invoice!",
    //       icon: "/assets/icon/trash-solid.svg",
    //       buttons: true,
    //       dangerMode: true,
    //     })
    //     .then(async (value) => {
    //       if (value) {
    //         let request = await this.deleteInvoice(this.id);
    //         if (request.data.success) {
    //           window.toastr["success"](this.$tc("invoices.deleted_message", 1));
    //           this.$router.push("/admin/invoices");
    //         } else if (request.data.error) {
    //           window.toastr["error"](request.data.message);
    //         }
    //       }
    //     });
    // },
  },
};
</script>

<style scoped>
.invoice-view-page {
  padding-left: 280px !important;
}
</style>
