var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("header", { staticClass: "header-area" }, [
      _c("div", { staticClass: "navbar-area" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("nav", { staticClass: "navbar navbar-expand-lg" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse sub-menu-bar",
                    attrs: { id: "navbarSupportedContent" },
                  },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "navbar-nav ml-auto",
                        attrs: { id: "nav" },
                      },
                      [
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "page-scroll",
                              attrs: { href: "#why" },
                            },
                            [_vm._v(_vm._s(_vm.$t("frontend.why")))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "page-scroll",
                              attrs: { href: "#features" },
                            },
                            [_vm._v(_vm._s(_vm.$t("frontend.features")))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "page-scroll",
                              attrs: { href: "#screenshots" },
                            },
                            [_vm._v(_vm._s(_vm.$t("frontend.screenshot")))]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "page-scroll",
                              attrs: { href: "#pricing" },
                            },
                            [_vm._v(_vm._s(_vm.$t("frontend.pricing")))]
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.loggedIn
                          ? _c(
                              "li",
                              {
                                staticClass: "nav-item btn btn-primary",
                                staticStyle: {
                                  width: "53%",
                                  "background-color": "#0898e7",
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticStyle: { color: "white" },
                                    attrs: { to: "/login" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("frontend.login")))]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "li",
                              {
                                staticClass: "nav-item btn btn-primary",
                                staticStyle: { "background-color": "#0898e7" },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticStyle: { color: "white" },
                                    attrs: { to: "/login" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("navigation.dashboard"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        !_vm.loggedIn
                          ? _c(
                              "li",
                              {
                                staticClass: "nav-item btn btn-primary",
                                staticStyle: { "background-color": "#0898e7" },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticStyle: {
                                      color: "white",
                                      "white-space": "nowrap",
                                    },
                                    attrs: { to: "/sign-up" },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("login.signUp")))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li"),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "header-hero bg_cover d-lg-flex align-items-center",
          attrs: { id: "home" },
        },
        [
          _c("div", { staticClass: "shape shape-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "shape shape-2" }),
          _vm._v(" "),
          _c("div", { staticClass: "shape shape-3" }),
          _vm._v(" "),
          _c("div", { staticClass: "shape shape-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "shape shape-5" }),
          _vm._v(" "),
          _c("div", { staticClass: "shape shape-6" }),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center justify-content-center justify-content-lg-between",
              },
              [
                _c("div", { staticClass: "col-lg-6 col-md-10" }, [
                  _c("div", { staticClass: "header-hero-content" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "header-title wow fadeInLeftBig",
                        attrs: {
                          "data-wow-duration": "1.3s",
                          "data-wow-delay": "0.2s",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tc("frontend.launch"))),
                        ]),
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$tc("frontend.confidence")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "text wow fadeInLeftBig",
                        attrs: {
                          "data-wow-duration": "1.3s",
                          "data-wow-delay": "0.6s",
                        },
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.$tc("frontend.description")) +
                            "\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("ul", { staticClass: "d-flex" }, [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "main-btn wow fadeInLeftBig",
                            attrs: {
                              href: "#",
                              "data-wow-duration": "1.3s",
                              "data-wow-delay": "0.8s",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$tc("frontend.button")))]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(2),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(3),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "header-shape-1 d-none d-md-block" }),
          _vm._v(" "),
          _vm._m(4),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "services-area pt-110 pb-120", attrs: { id: "why" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c("div", { staticClass: "section-title text-center pb-25" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$tc("frontend.choose_faktura"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tc("frontend.choose_description")) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-3 col-sm-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "single-services services-color-1 text-center mt-30 wow fadeInUpBig",
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.2s",
                  },
                },
                [
                  _vm._m(5),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-content" }, [
                    _c("h4", { staticClass: "services-title" }, [
                      _c("a", { attrs: { href: "/system/estimates" } }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.offer"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.$tc("frontend.offer_description")) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-sm-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "single-services services-color-2 text-center mt-30 wow fadeInUpBig",
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.4s",
                  },
                },
                [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-content" }, [
                    _c("h4", { staticClass: "services-title" }, [
                      _c("a", { attrs: { href: "/system/invoices" } }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.invoices"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.$tc("frontend.invoices_description")) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-sm-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "single-services services-color-3 text-center mt-30 wow fadeInUpBig",
                  staticStyle: { height: "93%" },
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.6s",
                  },
                },
                [
                  _vm._m(7),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-content" }, [
                    _c("h4", { staticClass: "services-title" }, [
                      _c("a", { attrs: { href: "/system/payments" } }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.payments"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.$tc("frontend.payments_description")) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-sm-6" }, [
              _c(
                "div",
                {
                  staticClass:
                    "single-services services-color-4 text-center mt-30 wow fadeInUpBig",
                  staticStyle: { height: "93%" },
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.8s",
                  },
                },
                [
                  _vm._m(8),
                  _vm._v(" "),
                  _c("div", { staticClass: "services-content" }, [
                    _c("h4", { staticClass: "services-title" }, [
                      _c("a", { attrs: { href: "/system/invoices" } }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.reminders"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.$tc("frontend.reminders_description")) +
                          "\n                "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "about-area pt-70 pb-120", attrs: { id: "about" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _vm._m(9),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6" }, [
              _c(
                "div",
                {
                  staticClass: "about-content mt-50 wow fadeInRightBig",
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.5s",
                  },
                },
                [
                  _c("div", { staticClass: "section-title" }, [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.autopilot"))),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$tc("frontend.autopilot_li1"))),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$tc("frontend.autopilot_li2"))),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$tc("frontend.autopilot_li3"))),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$tc("frontend.autopilot_li4"))),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(_vm._s(_vm.$tc("frontend.autopilot_li5"))),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$tc("frontend.autopilot_li_simple")) +
                        "\n              "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("a", { staticClass: "main-btn", attrs: { href: "#" } }, [
                    _vm._v(_vm._s(_vm.$tc("frontend.autopilot_download"))),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "features-area pt-110", attrs: { id: "features" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c("div", { staticClass: "section-title text-center pb-25" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$tc("frontend.key_features"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tc("frontend.key_features_description")) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "features-items" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "single-features features-color-1 d-sm-flex mt-30 wow fadeInUpBig",
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.2s",
                    },
                  },
                  [
                    _vm._m(10),
                    _vm._v(" "),
                    _c("div", { staticClass: "features-content media-body" }, [
                      _c("h4", { staticClass: "features-title" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.responsive"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tc("frontend.responsive_description")) +
                            "\n                  "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "single-features features-color-2 d-sm-flex mt-30 wow fadeInUpBig",
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.4s",
                    },
                  },
                  [
                    _vm._m(11),
                    _vm._v(" "),
                    _c("div", { staticClass: "features-content media-body" }, [
                      _c("h4", { staticClass: "features-title" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.seller"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.$tc("frontend.seller_description")) +
                            "\n                  "
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "single-features features-color-3 d-sm-flex mt-30 wow fadeInUpBig",
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.6s",
                    },
                  },
                  [
                    _vm._m(12),
                    _vm._v(" "),
                    _c("div", { staticClass: "features-content media-body" }, [
                      _c("h4", { staticClass: "features-title" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.integrations"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.$tc("frontend.integrations_description")
                            ) +
                            "\n                  "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(13),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "screenshots-area pt-110 pb-120",
        attrs: { id: "screenshots" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c("div", { staticClass: "section-title text-center pb-45" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$tc("frontend.screenshots"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tc("frontend.screenshots_description")) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(14),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        staticClass: "testimonial-area pt-110 pb-120",
        attrs: { id: "testimonial" },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c("div", { staticClass: "section-title text-center pb-45" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$tc("frontend.reviews"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tc("frontend.reviews_description")) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(15),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "pricing-area pt-110", attrs: { id: "pricing" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-lg-7" }, [
              _c("div", { staticClass: "section-title text-center pb-30" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$tc("frontend.cost"))),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$tc("frontend.cost_description")) +
                      "\n              "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "col-lg-4 col-md-7 col-sm-9",
                staticStyle: { "min-height": "10%", position: "relative" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "single-pricing text-center mt-30 wow fadeIn",
                    staticStyle: { height: "100%" },
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.2s",
                    },
                  },
                  [
                    _c("div", { staticClass: "pricing-title" }, [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.free"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pricing-price" }, [
                      _c("span", { staticClass: "price" }, [_vm._v("0 kr")]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.free_month"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pricing-list" }, [
                      _c(
                        "ul",
                        { staticClass: "list" },
                        _vm._l(this.freePermissions, function (perm, index) {
                          return _c("li", { key: index }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$tc(perm.landing_page_text)) +
                                "\n                  "
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                    _vm._v(" "),
                    this.subscription !== "Plus" && this.subscription !== "Pro"
                      ? _c(
                          "div",
                          { staticClass: "pricing-btn" },
                          [
                            _c(
                              "base-button",
                              {
                                class: _vm.boolVarFree,
                                style: _vm.linkC,
                                attrs: {
                                  loading: _vm.isLoadingFree,
                                  disabled: _vm.isLoadingFree,
                                  id: "free",
                                  type: "button",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    this.subscription === "Free"
                                      ? _c("center", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$tc("frontend.currentPlan")
                                              ) +
                                              "\n                    "
                                          ),
                                        ])
                                      : _c("center", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$tc("frontend.free_choose")
                                              ) +
                                              "\n                    "
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-4 col-md-7 col-sm-9" }, [
              _c(
                "div",
                {
                  staticClass:
                    "single-pricing pricing-active text-center mt-30 wow fadeIn",
                  staticStyle: { height: "100%" },
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.5s",
                  },
                },
                [
                  _vm._m(16),
                  _vm._v(" "),
                  _c("div", { staticClass: "pricing-title" }, [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.plus"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pricing-price" }, [
                    _c("span", { staticClass: "price" }, [_vm._v("19 kr")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.plus_month"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pricing-list" }, [
                    _c(
                      "ul",
                      { staticClass: "list" },
                      _vm._l(this.plusPermissions, function (perm, index) {
                        return _c("li", { key: index }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$tc(perm.landing_page_text)) +
                              "\n                  "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pricing-btn" },
                    [
                      _c(
                        "base-button",
                        {
                          class: _vm.boolVarPlus,
                          style: _vm.linkA,
                          attrs: {
                            loading: _vm.isLoadingPlus,
                            disabled: _vm.isLoadingPlus,
                            id: "plus",
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.callChangePlan(2)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              this.subscription === "Plus"
                                ? _c("center", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$tc("frontend.currentPlan")
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _c("center", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$tc("frontend.free_choose")
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-4 col-md-7 col-sm-9" }, [
              _c(
                "div",
                {
                  staticClass: "single-pricing text-center mt-30 wow fadeIn",
                  staticStyle: { height: "100%" },
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.9s",
                  },
                },
                [
                  _c("div", { staticClass: "pricing-title" }, [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.pro"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pricing-price" }, [
                    _c("span", { staticClass: "price" }, [_vm._v("99 kr")]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.pro_month"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pricing-list" }, [
                    _c(
                      "ul",
                      { staticClass: "list" },
                      [
                        _vm._l(this.proPermissions, function (perm, index) {
                          return _c("li", { key: index }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$tc(perm.landing_page_text)) +
                                "\n                  "
                            ),
                          ])
                        }),
                        _vm._v(" "),
                        _c("li", [_vm._v(_vm._s(_vm.$tc("frontend.pro_li8")))]),
                        _vm._v(" "),
                        _c("li", [_vm._v(_vm._s(_vm.$tc("frontend.pro_li9")))]),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pricing-btn" },
                    [
                      _c(
                        "base-button",
                        {
                          class: _vm.boolVarPro,
                          style: _vm.linkB,
                          attrs: {
                            loading: _vm.isLoadingPro,
                            disabled: _vm.isLoadingPro,
                            id: "pro",
                            type: "button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.callChangePlan(3)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            [
                              this.subscription === "Pro"
                                ? _c("center", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$tc("frontend.currentPlan")
                                        ) +
                                        "\n                    "
                                    ),
                                  ])
                                : _c("center", [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.$tc("frontend.free_choose")
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "download-area pt-70 pb-40", attrs: { id: "download" } },
      [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "row justify-content-center align-items-center" },
            [
              _vm._m(17),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "download-content mt-45 wow fadeInLeftBig",
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.5s",
                    },
                  },
                  [
                    _c("h3", { staticClass: "download-title" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.start"))),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$tc("frontend.start_description")) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(18),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("footer", { staticClass: "footer-area", attrs: { id: "footer" } }, [
      _c("div", { staticClass: "footer-shape shape-1" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-shape shape-2" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-shape shape-3" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-shape shape-4" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-shape shape-5" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-shape shape-6" }),
      _vm._v(" "),
      _c("div", { staticClass: "footer-shape shape-7" }),
      _vm._v(" "),
      _vm._m(19),
      _vm._v(" "),
      _c("div", { staticClass: "footer-widget pt-30 pb-80" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-4 col-md-6" }, [
              _c(
                "div",
                {
                  staticClass: "footer-about mt-50 wow fadeIn",
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.2s",
                  },
                },
                [
                  _vm._m(20),
                  _vm._v(" "),
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                  " +
                        _vm._s(_vm.$tc("frontend.footer_description")) +
                        "\n                "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    staticClass: "nav-item",
                    attrs: {
                      options: _vm.languages,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc(
                        "settings.preferences.select_language"
                      ),
                      label: "name",
                      "track-by": "code",
                      showCountryFlag: true,
                    },
                    model: {
                      value: _vm.formData.language,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "language", $$v)
                      },
                      expression: "formData.language",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-5 col-md-6" }, [
              _c("div", { staticClass: "footer-link d-flex flex-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "footer-link-wrapper mt-45 wow fadeIn",
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.4s",
                    },
                  },
                  [
                    _c("div", { staticClass: "footer-title" }, [
                      _c("h4", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.$tc("frontend.links"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "link" }, [
                      _c("li", [
                        _c("a", { attrs: { href: "#home" } }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.top"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("a", { attrs: { href: "#features" } }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.features"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("a", { attrs: { href: "/system/customers" } }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.customers"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("a", { attrs: { href: "#pricing" } }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.prices"))),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "footer-link-wrapper mt-45 wow fadeIn",
                    attrs: {
                      "data-wow-duration": "1.3s",
                      "data-wow-delay": "0.6s",
                    },
                  },
                  [
                    _vm._m(21),
                    _vm._v(" "),
                    _c("ul", { staticClass: "link" }, [
                      _vm._m(22),
                      _vm._v(" "),
                      _c("li", [
                        _c("a", { attrs: { href: "/privacy-policy" } }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.privacy_policy"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("a", { attrs: { href: "#" } }, [
                          _vm._v(_vm._s(_vm.$tc("frontend.terms_of_use"))),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-3 col-md-6" }, [
              _c(
                "div",
                {
                  staticClass: "footer-contact mt-45 wow fadeIn",
                  attrs: {
                    "data-wow-duration": "1.3s",
                    "data-wow-delay": "0.8s",
                  },
                },
                [
                  _c("div", { staticClass: "footer-title" }, [
                    _c("h4", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$tc("frontend.contact"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(23),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(24),
    ]),
    _vm._v(" "),
    _vm._m(25),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "navbar-brand", attrs: { href: "#home" } }, [
      _c("img", {
        attrs: {
          src: "/assets/images/BLUE_LOGO_FAKTURA.svg",
          type: "image/svg+xml",
          alt: "Logo",
          width: "250",
          height: "100",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
        },
      },
      [
        _c("span", { staticClass: "toggler-icon" }),
        _vm._v(" "),
        _c("span", { staticClass: "toggler-icon" }),
        _vm._v(" "),
        _c("span", { staticClass: "toggler-icon" }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: "header-video video-popup wow fadeInLeftBig",
          attrs: {
            href: "https://www.youtube.com/watch?v=r44RKWyfcFw",
            "data-wow-duration": "1.3s",
            "data-wow-delay": "1.2s",
          },
        },
        [_c("i", { staticClass: "lni lni-play" })]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-4 col-md-6 col-sm-6 col-10" }, [
      _c("div", { staticClass: "header-image" }, [
        _c("img", {
          staticClass: "image wow fadeInRightBig",
          attrs: {
            src: "/assets/images/faktura/Group 15.png",
            alt: "app",
            "data-wow-duration": "1.3s",
            "data-wow-delay": "0.5s",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "image-shape wow fadeInRightBig",
            attrs: { "data-wow-duration": "1.3s", "data-wow-delay": "0.8s" },
          },
          [
            _c("img", {
              attrs: { src: "/assets/images/image-shape.svg", alt: "shape" },
            }),
          ]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-shape-2" }, [
      _c("img", {
        attrs: { src: "/assets/images/header-shape-2.svg", alt: "shape" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "services-icon d-flex align-items-center justify-content-center",
      },
      [_c("i", { staticClass: "lni lni-layers" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "services-icon d-flex align-items-center justify-content-center",
      },
      [_c("i", { staticClass: "lni lni-layout" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "services-icon d-flex align-items-center justify-content-center",
      },
      [_c("i", { staticClass: "lni lni-bolt" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "services-icon d-flex align-items-center justify-content-center",
      },
      [_c("i", { staticClass: "lni lni-protection" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6" }, [
      _c(
        "div",
        {
          staticClass: "about-image mt-50 wow fadeInLeftBig",
          attrs: { "data-wow-duration": "1.3s", "data-wow-delay": "0.5s" },
        },
        [
          _c("div", { staticClass: "about-shape" }),
          _vm._v(" "),
          _c("img", {
            staticClass: "app",
            attrs: { src: "/assets/images/faktura/Group 16.png", alt: "app" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "features-icon d-flex justify-content-center align-items-center",
      },
      [_c("i", { staticClass: "lni lni-laptop-phone" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "features-icon d-flex justify-content-center align-items-center",
      },
      [_c("i", { staticClass: "lni lni-leaf" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "features-icon d-flex justify-content-center align-items-center",
      },
      [_c("i", { staticClass: "lni lni-bootstrap" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6 col-md-6" }, [
      _c(
        "div",
        {
          staticClass: "features-image wow fadeInRightBig",
          attrs: { "data-wow-duration": "1.3s", "data-wow-delay": "0.5s" },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: { src: "/assets/images/faktura/Group 17.png", alt: "App" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "features-shape-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "features-shape-2" }, [
            _c("img", {
              staticClass: "svg",
              attrs: { src: "/assets/images/features-shape-1.svg", alt: "" },
            }),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "screenshot-slider" }, [
          _c("div", { staticClass: "swiper-container" }, [
            _c("div", { staticClass: "swiper-wrapper" }, [
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/Group 37.png",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/Group 46.png",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/screenshot-1.jpeg",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/screenshot-2.jpeg",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/screenshot-3.jpeg",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/screenshot-4.jpeg",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swiper-slide" }, [
                _c("div", { staticClass: "slider-image" }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/faktura/Group 33.png",
                      alt: "screenshot",
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "swiper-pagination" }),
            _vm._v(" "),
            _c("div", { staticClass: "screenshot-frame d-none d-sm-block" }, [
              _c("img", {
                staticStyle: { height: "90%" },
                attrs: { src: "/assets/images/app-frame.png", alt: "frame" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-lg-9" }, [
        _c("div", { staticClass: "testimonial-wrapper" }, [
          _c("div", { staticClass: "testimonial-content" }, [
            _c(
              "div",
              { staticClass: "single-testimonial-content text-center" },
              [
                _c("div", { staticClass: "testimonial-quote" }, [
                  _c("img", {
                    staticClass: "svg",
                    attrs: {
                      src: "/assets/images/icon-quote.svg",
                      alt: "quote",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "testimonial-text" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam\n                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,\n                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea\n                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem\n                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "holder-name" }, [
                    _vm._v("Linda Sophia"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("Designer, SpaceX"),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "single-testimonial-content text-center" },
              [
                _c("div", { staticClass: "testimonial-quote" }, [
                  _c("img", {
                    staticClass: "svg",
                    attrs: {
                      src: "/assets/images/icon-quote.svg",
                      alt: "quote",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "testimonial-text" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam\n                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,\n                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea\n                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem\n                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "holder-name" }, [
                    _vm._v("Michel Jhonsmith"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("CEO, OnePlus"),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "single-testimonial-content text-center" },
              [
                _c("div", { staticClass: "testimonial-quote" }, [
                  _c("img", {
                    staticClass: "svg",
                    attrs: {
                      src: "/assets/images/icon-quote.svg",
                      alt: "quote",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "testimonial-text" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam\n                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,\n                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea\n                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem\n                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "holder-name" }, [
                    _vm._v("John Doe"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("CEO, UIdeck"),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "single-testimonial-content text-center" },
              [
                _c("div", { staticClass: "testimonial-quote" }, [
                  _c("img", {
                    staticClass: "svg",
                    attrs: {
                      src: "/assets/images/icon-quote.svg",
                      alt: "quote",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "testimonial-text" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam\n                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,\n                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea\n                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem\n                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "holder-name" }, [
                    _vm._v("Windy Lisa"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("Founder, PageBulb"),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "single-testimonial-content text-center" },
              [
                _c("div", { staticClass: "testimonial-quote" }, [
                  _c("img", {
                    staticClass: "svg",
                    attrs: {
                      src: "/assets/images/icon-quote.svg",
                      alt: "quote",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "testimonial-text" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam\n                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,\n                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea\n                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem\n                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "holder-name" }, [
                    _vm._v("Musharof Chowdhury"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("CEO, GrayGrids"),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "single-testimonial-content text-center" },
              [
                _c("div", { staticClass: "testimonial-quote" }, [
                  _c("img", {
                    staticClass: "svg",
                    attrs: {
                      src: "/assets/images/icon-quote.svg",
                      alt: "quote",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "testimonial-text" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam\n                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,\n                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea\n                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem\n                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "holder-name" }, [
                    _vm._v("Smith Martin"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "sub-title" }, [
                    _vm._v("Designer, LineIcons"),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "testimonial-author" }, [
            _c("div", { staticClass: "single-testimonial-author" }, [
              _c("img", {
                attrs: { src: "/assets/images/author5.png", alt: "author" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single-testimonial-author" }, [
              _c("img", {
                attrs: { src: "/assets/images/author2.png", alt: "author" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single-testimonial-author" }, [
              _c("img", {
                attrs: { src: "/assets/images/author4.png", alt: "author" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single-testimonial-author" }, [
              _c("img", {
                attrs: { src: "/assets/images/author3.png", alt: "author" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single-testimonial-author" }, [
              _c("img", {
                attrs: { src: "/assets/images/author1.png", alt: "author" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single-testimonial-author" }, [
              _c("img", {
                attrs: { src: "/assets/images/author6.png", alt: "author" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pricing-shape" }, [
      _c("img", { attrs: { src: "/assets/images/price-shape.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6 col-md-9" }, [
      _c(
        "div",
        {
          staticClass: "download-image mt-50 wow fadeInRightBig",
          attrs: { "data-wow-duration": "1.3s", "data-wow-delay": "0.2s" },
        },
        [
          _c("img", {
            staticClass: "image",
            attrs: {
              src: "/assets/images/faktura/Group 15.png",
              alt: "download",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "download-shape-1" }),
          _vm._v(" "),
          _c("div", { staticClass: "download-shape-2" }, [
            _c("img", {
              staticClass: "svg",
              attrs: { src: "/assets/images/download-shape.svg", alt: "shape" },
            }),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("a", { staticClass: "app-store", attrs: { href: "#" } }, [
          _c("img", {
            attrs: { src: "/assets/images/app-store.png", alt: "store" },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { staticClass: "play-store", attrs: { href: "#" } }, [
          _c("img", {
            attrs: { src: "/assets/images/play-store.png", alt: "store" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-shape shape-8" }, [
      _c("img", {
        staticClass: "svg",
        attrs: { src: "/assets/images/footer-shape.svg", alt: "Shape" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "logo", attrs: { href: "#" } }, [
      _c("object", {
        staticClass: "mylogo",
        attrs: {
          data: "/assets/images/BLUE_LOGO_FAKTURA.svg",
          type: "image/svg+xml",
          width: "250",
          height: "100",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-title" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Support")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("FAQ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "contact-list" }, [
      _c("li"),
      _vm._v(" "),
      _c("li", [
        _c("div", { staticClass: "contact-info d-flex" }, [
          _c("div", { staticClass: "info-content media-body" }, [
            _c("p", { staticClass: "text" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("i", { staticClass: "lni lni-envelope" }),
                _vm._v(" support@faktura.dk\n                          "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("div", { staticClass: "contact-info d-flex" }, [
          _c("div", { staticClass: "info-content media-body" }, [
            _c("p", { staticClass: "text" }, [
              _c("a", { attrs: { href: "#" } }, [
                _c("i", { staticClass: "lni lni-world" }),
                _vm._v(" www.faktura.dk "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("div", { staticClass: "contact-info d-flex" }, [
          _c("div", { staticClass: "info-content media-body" }, [
            _c("p", { staticClass: "text" }, [
              _c("i", { staticClass: "lni lni-map" }),
              _vm._v(" Sandtoften 10 2820 Gentofte\n                        "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-copyright" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-lg-12" }, [
            _c(
              "div",
              { staticClass: "copyright d-sm-flex justify-content-between" },
              [
                _c("div", { staticClass: "copyright-text text-center" }, [
                  _c("p", { staticClass: "text" }, [
                    _vm._v(
                      "\n                    All rights reserved\n\n                    "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          rel: "nofollow",
                          target: "_blank",
                          href: "https://www.weiswise.com",
                        },
                      },
                      [_vm._v("faktura.dk")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "copyright-privacy text-center" }, [
                  _c("a", { attrs: { href: "#" } }, [_vm._v("Report Issues")]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "back-to-top", attrs: { href: "#" } }, [
      _c("i", { staticClass: "lni lni-chevron-up" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }