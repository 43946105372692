<template>
  <div class="items main-content">
    <div class="page-header">
      <div class="d-flex flex-row">
        <div>
          <h3 class="page-title">{{ $tc("navigation.printing", 2) }}</h3>
        </div>
      </div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("navigation.printing", 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalItems || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            color="theme"
            size="large"
            right-icon
            @click="toggleFilter"
          >
            {{ $t("general.filter") }}
          </base-button>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
          <div class="col-sm-5">
            <label class="form-label"> {{ $tc("invoices.invoice_number") }} </label>
            <base-input
              v-model="filters.invoice_number"
              type="text"
              name="name"
              autocomplete="off"
              icon="hashtag"
            />
          </div>
          <div class="filter-status col-sm-5">
            <label>{{ $t("invoices.status") }}</label>
            <base-select
              v-model="filters.status"
              :options="status"
              :group-select="false"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('general.select_a_status')"
              group-values="options"
              group-label="label"
              track-by="name"
              label="name"
              @remove="clearStatusSearch()"
            />
          </div>
          <div class="col-sm-2">
            <label class="form-label"></label>
            <base-button
              @click="refreshFilterTable"
              style="margin-top: 3%; height: 40px"
              size="large"
              color="theme"
            >
              {{ $t("navigation.applyFilters") }}
            </base-button>
          </div>
          <label class="clear-filter" @click="clearFilter">
            {{ $t("general.clear_all") }}</label
          >
        </div>
      </div>
    </transition>

    <div v-cloak v-show="showEmptyScreen" class="col-xs-1 no-data-info" align="center">
      <satellite-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t("printing.no_printing") }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t("printing.list_of_printing")
        }}</label>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t("general.showing") }}: <b>{{ items.length }}</b> {{ $t("general.of") }}
          <b>{{ totalItems }}</b>
        </p>
        <transition name="fade">
          <v-dropdown v-if="selectedItems.length" :show-arrow="false">
            <span slot="activator" href="#" class="table-actions-button dropdown-toggle">
              {{ $t("general.actions") }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="printingMultipleItems">
                <font-awesome-icon :icon="['fas', 'print']" class="dropdown-item-icon" />
                {{ $t("general.print") }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllItems"
        />
        <label
          v-show="!isRequestOngoing"
          for="select-all"
          class="custom-control-label selectall"
        >
          <span class="select-all-label">{{ $t("general.select_all") }} </span>
        </label>
      </div>

      <table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <table-column :sortable="false" :filterable="false" cell-class="no-click">
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column>
        <table-column :label="$t('printing.file_name')" show="file_name" />
        <table-column :label="$t('printing.merge_status')" show="merge_status" />
        <table-column :label="$t('printing.user')" show="user.name" />

        <table-column :label="$t('printing.printing_status')" sort-as="status">
          <template slot-scope="row">
            <span>{{ $t("invoices.status") }}</span>
            <span :class="'inv-status-' + row.status.toLowerCase()">{{
              row.status
            }}</span>
          </template>
        </table-column>

        <table-column
          :label="$t('items.added_on')"
          sort-as="created_at"
          show="formattedCreatedAt"
        />
        <table-column :sortable="false" :filterable="false" cell-class="action-dropdown">
          <template slot-scope="row">
            <span> {{ $t("items.action") }} </span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>
              <!-- <v-dropdown-item>
                <a
                  class="dropdown-item"
                  href="#/"
                  @click="viewInvoice(row.invoice_number)"
                >
                  <font-awesome-icon icon="check-circle" class="dropdown-item-icon" />
                  {{ $t("invoices.view") }}
                </a>
              </v-dropdown-item> -->
              <v-dropdown-item>
                <router-link
                  :to="{
                    path: `printing/${row.invoice_id}/view`,
                    name: 'printing.view',
                    params: { id: row.id, val: row.file_name, company: row.company_id },
                  }"
                  class="dropdown-item"
                >
                  <font-awesome-icon icon="eye" class="dropdown-item-icon" />
                  {{ $t("invoices.view") }}
                </router-link>
              </v-dropdown-item>
              <v-dropdown-item v-if="row.status == 'PENDING'">
                <a class="dropdown-item" href="#/" @click="markAsPrinted(row.id)">
                  <font-awesome-icon icon="check-circle" class="dropdown-item-icon" />
                  {{ $t("printing.mark_as_printed") }}
                </a>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DotIcon from "../../components/icon/DotIcon";
import SatelliteIcon from "../../components/icon/SatelliteIcon";
import BaseButton from "../../../js/components/base/BaseButton";

export default {
  components: {
    DotIcon,
    SatelliteIcon,
    BaseButton,
  },
  data() {
    return {
      id: null,
      showFilters: false,
      sortedBy: "created_at",
      isRequestOngoing: true,
      filtersApplied: false,
      status: [
        {
          label: "Print Status",
          options: [
            { name: "PENDING", value: "PENDING" },
            { name: "PRINTED", value: "PRINTED" },
          ],
        },
      ],
      filters: {
        status: "",
        invoice_number: "",
      },
    };
  },
  computed: {
    ...mapGetters("printing", [
      "items",
      "selectedItems",
      "totalItems",
      "selectAllField",
      "itemUnits",
    ]),
    shareableLink() {
      return `/invoices/print/${this.invoice.unique_hash}`;
    },
    ...mapGetters("currency", ["defaultCurrency"]),
    showEmptyScreen() {
      return !this.totalItems && !this.isRequestOngoing;
    },
    filterIcon() {
      return this.showFilters ? "times" : "filter";
    },
    selectField: {
      get: function () {
        return this.selectedItems;
      },
      set: function (val) {
        this.selectItem(val);
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField;
      },
      set: function (val) {
        this.setSelectAllState(val);
      },
    },
  },
  watch: {
    filters: {
      handler: "setFilters",
      deep: true,
    },
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllItems();
    }
  },
  methods: {
    ...mapActions("printing", [
      "fetchItems",
      "selectAllItems",
      "selectItem",
      "deleteItem",
      "markPrinted",
      "deleteMultipleItems",
      "printMultipleItems",
      "setSelectAllState",
    ]),
    refreshTable() {
      this.$refs.table.refresh();
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        status: this.filters.status.value !== null ? this.filters.status.value : "",
        invoice_number:
          this.filters.invoice_number !== null ? this.filters.invoice_number : "",

        orderByField: sort.fieldName || "created_at",
        orderBy: sort.order || "desc",
        limit: 10,
        page,
      };

      this.isRequestOngoing = true;
      let response = await this.fetchItems(data);
      this.isRequestOngoing = false;

      return {
        data: response.data.items.data,
        pagination: {
          totalPages: response.data.items.last_page,
          currentPage: page,
        },
      };
    },
    setFilters() {
      this.filtersApplied = true;
    },
    refreshFilterTable() {
      this.refreshTable();
    },
    clearFilter() {
      this.filters = {
        status: "",
        invoice_number: "",
      };

      this.$nextTick(() => {
        this.filtersApplied = false;
      });
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter();
        this.refreshTable();
      }

      this.showFilters = !this.showFilters;
    },

    async markAsPrinted(id) {
      let data = {
        id: id,
      };

      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("printing.are_you_sure_printed"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.markPrinted(data);

          if (res.data.success) {
            window.toastr["success"](this.$tc("printing.success_message", 1));
            this.$refs.table.refresh();
            return true;
          }

          if (res.data.data_exist) {
            window.toastr["error"](this.$tc("editor.deleted_message_exist", 1));
            this.$refs.table.refresh();
            return true;
          }

          if (res.data.error === "item_attached") {
            window.toastr["error"](
              this.$tc("items.item_attached_message"),
              this.$t("general.action_failed")
            );
            return true;
          }

          window.toastr["error"](res.data.message);
          return true;
        }
      });
    },

    async removeItems(id) {
      this.id = id;
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("items.confirm_delete"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deleteItem(this.id);

          if (res.data.success) {
            window.toastr["success"](this.$tc("editor.deleted_message", 1));
            this.$refs.table.refresh();
            return true;
          }

          if (res.data.data_exist) {
            window.toastr["error"](this.$tc("editor.deleted_message_exist", 1));
            this.$refs.table.refresh();
            return true;
          }

          if (res.data.error === "item_attached") {
            window.toastr["error"](
              this.$tc("items.item_attached_message"),
              this.$t("general.action_failed")
            );
            return true;
          }

          window.toastr["error"](res.data.message);
          return true;
        }
      });
    },
    async printingMultipleItems() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("invoices.confirm_print_merge", 2),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: false,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.printMultipleItems();
          if (res.data.success) {
            window.toastr["success"]("Merged file generated");
            this.$refs.table.refresh();
          } else if (res.data.data_exist || res.data.items) {
            window.toastr["error"](this.$tc("editor.deleted_message_exist"));
            this.$refs.table.refresh();
          } else if (res.data.error) {
            window.toastr["error"]("Same user files cannot be merged only");
          }
        }
      });
    },

    viewInvoice(id) {
      // window.open(
      //   `/assets/invoices/PDF/invoice-${id}.pdf`,
      //   "_blank"
      //   // <- This is what makes it open in a new window.
      // );
      // window.open(`/assets/invoices/PDF/invoice-${id}.pdf`, "", "postwindow", "replace");
      // Make a request for a user with a given ID
      window.axios
        .get(`/invoices/print/${id}`)
        .then((response) => {
          // console.log(response.data.path);
          window.open(response.data.path);
        })
        .catch((err) => {});
    },
  },
};
</script>
