<template>
  <div class="setting-main-container" v-if="$can('BankInformation')">
    <div class="card setting-card">
      <div class="page-header d-flex justify-content-between">
        <div>
          <h3 class="page-title">
            {{ $t("settings.menu_title.bankInformation") }}
          </h3>
          <p class="page-sub-title">
            {{ $t("settings.bankInformation.description") }}
          </p>
        </div>
        <base-button
          outline
          class="add-new-tax"
          color="theme"
          @click="openBankInformationModal"
        >
          {{ $t("settings.bankInformation.add_bank_information") }}
        </base-button>
      </div>

      <table-component
        ref="table"
        :show-filter="false"
        :data="bankInformation"
        table-class="table tax-table"
        class="mb-3"
      >
        <table-column
          :sortable="true"
          :label="$t('settings.subscriptions.name')"
          show="name"
        />
        <table-column
          :sortable="true"
          :label="$t('settings.bankInformation.account_no')"
          show="account_no"
        />
        <table-column
          :sortable="true"
          :label="$t('settings.bankInformation.IBAN_no')"
          show="IBAN_no"
        />
        <table-column
          :sortable="true"
          :label="$t('settings.bankInformation.default')"
          show="default"
        />

        <table-column :sortable="false" :filterable="false" cell-class="action-dropdown">
          <template slot-scope="row">
            <span>{{ $t("settings.tax_types.action") }}</span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>
              <v-dropdown-item>
                <div class="dropdown-item" @click="EditBankInformation(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'pencil-alt']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.edit") }}
                </div>
              </v-dropdown-item>
              <v-dropdown-item>
                <div class="dropdown-item" @click="removeBankInformation(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.delete") }}
                </div>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>

      <hr />
    </div>
  </div>

  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      id: null,
      formData: {
        tax_per_item: false,
      },
    };
  },
  computed: {
    ...mapGetters("bankInformation", ["bankInformation"]),
    ...mapGetters("currency", ["defaultCurrency"]),
  },
  mounted() {},
  methods: {
    ...mapActions("modal", ["openModal"]),

    ...mapActions("bankInformation", [
      "addBankInformation",
      "fetchBankInformation",
      "deleteBankInformation",
    ]),

    async fetchData() {
      let response = await this.fetchBankInformation();

      return {
        data: response.data.Information,
      };
    },

    refreshTable() {
      alert();
      this.$refs.table.refresh();
    },

    async removeBankInformation(id, index) {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$t("settings.bankInformation.confirm_delete"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          let response = await this.deleteBankInformation(id);
          if (response.data.success) {
            window.toastr["success"](this.$t("settings.bankInformation.deleted_message"));
            this.id = null;
            this.$refs.table.refresh();
            return true;
          }
          // window.toastr["error"](this.$t("settings.tax_types.already_in_use"));
        }
      });
    },
    openBankInformationModal() {
      this.openModal({
        title: this.$t("settings.menu_title.bankInformation"),
        componentName: "BankInformationModal",
      });
      // this.$refs.table.refresh();
    },
    async EditBankInformation(id) {
      let response = await this.fetchBankInformation(id);
      this.openModal({
        title: this.$t("settings.menu_title.bankInformation"),
        componentName: "BankInformationModal",
        id: id,
        data: response.data.Information,
      });
      // this.refreshTable();
      // this.$refs.table.refresh();
    },
  },
};
</script>
