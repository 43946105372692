export const invoices = (state) => state.invoices
export const installments = (state) => state.installments
export const selectAllField = (state) => state.selectAllField
export const getTemplateId = (state) => state.invoiceTemplateId
export const getTemplateName = (state) => state.invoiceTemplateName
export const selectedInvoices = (state) => state.selectedInvoices
export const totalInvoices = (state) => state.totalInvoices
export const totalInstallments = (state) => state.totalInstallments
export const selectedCustomer = (state) => state.selectedCustomer
export const getInvoice = (state) => (id) => {
  let invId = parseInt(id)
  return state.invoices.find(invoice => invoice.id === invId)
}
