var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-main-container", staticStyle: { padding: "30px" } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("wizard.bankInformation")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "page-sub-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("settings.bank_description")) + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-box mb-4" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("base-switch", {
              staticClass: "btn-switch",
              on: { change: _vm.setBankInformation },
              model: {
                value: _vm.notify_bank,
                callback: function ($$v) {
                  _vm.notify_bank = $$v
                },
                expression: "notify_bank",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("p", { staticClass: "box-desc" }, [
            _vm._v(_vm._s(_vm.$t("settings.bankInfo.switch_desc"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tax-type-modal", staticStyle: { position: "none" } },
        [
          _c("div", { staticClass: "card-body" }, [
            this.details
              ? _c(
                  "form",
                  {
                    attrs: { action: "" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitBankInformationData.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-2 col-form-label input-label" },
                        [_vm._v(_vm._s(_vm.$t("bank.name")) + "\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-7" },
                        [
                          _c("base-input", {
                            ref: "name",
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.name.$error
                            ? _c("div", [
                                !_vm.$v.formData.name.required
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "form-group__message text-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$tc("validation.required"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.formData.name.minLength
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "form-group__message text-danger",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$tc(
                                                "validation.name_min_length",
                                                _vm.$v.formData.name.$params
                                                  .minLength.min,
                                                {
                                                  count:
                                                    _vm.$v.formData.name.$params
                                                      .minLength.min,
                                                }
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-2 col-form-label input-label" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.bankInformation.account_no")
                            ) + "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-7" },
                        [
                          _c("base-input", {
                            ref: "name",
                            attrs: {
                              invalid: _vm.$v.formData.account_no.$error,
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.account_no.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.account_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "account_no", $$v)
                              },
                              expression: "formData.account_no",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.account_no.$error
                            ? _c("div", [
                                !_vm.$v.formData.account_no.required
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "form-group__message text-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$tc("validation.required"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-2 col-form-label input-label" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("settings.bankInformation.IBAN_no")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-7" },
                        [
                          _c("base-input", {
                            ref: "name",
                            attrs: {
                              invalid: _vm.$v.formData.IBAN_no.$error,
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.IBAN_no.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.IBAN_no,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "IBAN_no", $$v)
                              },
                              expression: "formData.IBAN_no",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.IBAN_no.$error
                            ? _c("div", [
                                !_vm.$v.formData.IBAN_no.required
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "form-group__message text-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$tc("validation.required"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-sm-2 col-form-label input-label" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.bankInformation.swift_code")
                            ) + "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-7" },
                        [
                          _c("base-input", {
                            ref: "name",
                            attrs: {
                              invalid: _vm.$v.formData.swift_code.$error,
                              type: "text",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.swift_code.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.swift_code,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "swift_code", $$v)
                              },
                              expression: "formData.swift_code",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.swift_code.$error
                            ? _c("div", [
                                !_vm.$v.formData.swift_code.required
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "form-group__message text-danger",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$tc("validation.required"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { float: "right" } }, [
        _c(
          "div",
          { staticClass: "col-md-2", staticStyle: { "margin-right": "29%" } },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loadingBack,
                  icon: "arrow-left",
                  color: "theme",
                },
                on: { click: _vm.back },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.back")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loading,
                  icon: "arrow-right",
                  rightIcon: "",
                  color: "theme",
                },
                on: { click: _vm.next },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }