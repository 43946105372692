var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Import Products")
    ? _c("div", { staticClass: "customer-create main-content" }, [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [_vm._v("Import")]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "dashboard" },
                    slot: "item-title",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("general.home")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "#" },
                    slot: "item-title",
                  },
                  [_vm._v(" Import Products ")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("div", { staticClass: "card" }, [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "form-control",
                      staticStyle: {
                        border: "2px dashed",
                        position: "relative",
                      },
                      style: _vm.setHeight,
                      on: { click: _vm.trigInput },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            position: "absolute",
                            cursor: "pointer",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                          },
                        },
                        [_c("center", [_c("b", [_vm._v("Choose file")])])],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "vue-csv-import",
                {
                  ref: "csv",
                  staticStyle: { "margin-top": "-18%" },
                  attrs: {
                    "map-fields": [
                      "Name",
                      /*'Number',*/
                      "Description",
                      "Unit",
                      "Price",
                      "CostPrice",
                      "GuidePrice",
                      "Category",
                      "BarCode",
                      "Access",
                    ],
                    headers: "false",
                    inputClass: "form-control log",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "error",
                        fn: function () {
                          return [
                            _c("b", { staticClass: "text text-danger" }, [
                              _vm._v(_vm._s(_vm.error)),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "next",
                        fn: function (ref) {
                          var load = ref.load
                          return [
                            _c(
                              "base-button",
                              {
                                staticClass: "collapse-button custom",
                                staticStyle: { "margin-top": "12%" },
                                attrs: {
                                  color: "theme",
                                  type: "submit",
                                  disabled: _vm.disabled,
                                },
                                on: { click: _vm.loadCsv },
                              },
                              [_vm._v("\n            Load Csv\n          ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    562936674
                  ),
                  model: {
                    value: _vm.csv,
                    callback: function ($$v) {
                      _vm.csv = $$v
                    },
                    expression: "csv",
                  },
                },
                [
                  _vm._v(" "),
                  _c("template", { slot: "thead" }, [
                    _c("tr", [
                      _c("th", [_vm._v("My Fields")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Column")]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _vm.showImportBtn
                ? _c(
                    "base-button",
                    {
                      staticClass: "collapse-button",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.importDisabled,
                        icon: "save",
                        color: "theme",
                        type: "submit",
                      },
                      on: { click: _vm.saveCsv },
                    },
                    [_vm._v("\n        Import Csv\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticStyle: { color: "#0e7ea7" } }, [
                _vm._v(_vm._s(_vm.text)),
              ]),
            ],
            1
          ),
        ]),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }