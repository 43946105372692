var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-main-container", staticStyle: { padding: "30px" } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("settings.integration.integration_settings"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "page-sub-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("settings.integration.description")) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-box mb-4" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("base-switch", {
              staticClass: "btn-switch",
              on: { change: _vm.setIntegrationSettings },
              model: {
                value: _vm.notify_integration,
                callback: function ($$v) {
                  _vm.notify_integration = $$v
                },
                expression: "notify_integration",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("p", { staticClass: "box-title" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.$t("settings.integration.switch_base_line"))),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "box-desc" }, [
            _vm._v(_vm._s(_vm.$t("settings.integration.switch_desc"))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      this.details
        ? _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.saveIntegration()
                },
              },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12 mb-4" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$t("integrations.basic_info"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                    _vm._v(" "),
                    _c("base-select", {
                      class: { error: _vm.$v.formData.integration.$error },
                      attrs: {
                        options: _vm.integrations,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": false,
                        placeholder: _vm.$t("general.select_integration"),
                        label: "name",
                        "track-by": "id",
                      },
                      on: { select: _vm.onSelectIntegration },
                      model: {
                        value: _vm.formData.integration,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "integration", $$v)
                        },
                        expression: "formData.integration",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.integration.$error
                      ? _c("div", [
                          !_vm.$v.formData.integration.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$t("settings.integration.api_key"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.api_key.$error,
                        placeholder: "API Key",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.api_key.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.api_key,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "api_key", $$v)
                        },
                        expression: "formData.api_key",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.api_key.$error
                      ? _c("div", [
                          !_vm.$v.formData.api_key.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 mb-4" },
                  [
                    _c("label", { staticClass: "input-label" }, [
                      _vm._v(_vm._s(_vm.$t("settings.integration.api_secret"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.api_secret.$error,
                        placeholder: "API Secret",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.api_secret.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.api_secret,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "api_secret", $$v)
                        },
                        expression: "formData.api_secret",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.api_secret.$error
                      ? _c("div", [
                          !_vm.$v.formData.api_secret.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { float: "right" } }, [
        _c(
          "div",
          { staticClass: "col-md-2", staticStyle: { "margin-right": "29%" } },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loadingBack,
                  icon: "arrow-left",
                  color: "theme",
                },
                on: { click: _vm.back },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.back")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loading,
                  icon: "arrow-right",
                  rightIcon: "",
                  color: "theme",
                },
                on: { click: _vm.next },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }