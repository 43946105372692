var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "example" } }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { attrs: { id: "bar" } }, [
          _c("h1", [_vm._v("Vue Email Editor (Demo)")]),
          _vm._v(" "),
          _c(
            "button",
            { attrs: { type: "submit" }, on: { click: _vm.saveDesign } },
            [_vm._v("Save Design")]
          ),
          _vm._v(" "),
          _c(
            "button",
            { attrs: { type: "button" }, on: { click: _vm.getDesign } },
            [_vm._v("Load Design")]
          ),
        ]),
        _vm._v(" "),
        _c("EmailEditor", {
          ref: "emailEditor",
          staticStyle: { width: "93%" },
          on: { load: _vm.editorLoaded },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }