import * as types from './mutation-types'

export default {

  [types.SET_DEFAULT_SUBSCRIPTION] (state, subscription) {
    state.defaultSubscription = subscription
  },

  [types.SET_SUB_CHECK] (state, subscription) {
    state.sub_check = subscription
  }
}