<template>
  <!--====== PRELOADER PART START ======-->
  <div>
    <!-- <div v-if="flag" class="preloader">
      <div class="loader">
        <div class="ytp-spinner">
          <div class="ytp-spinner-container">
            <div class="ytp-spinner-rotator">
              <div class="ytp-spinner-left">
                <div class="ytp-spinner-circle"></div>
              </div>
              <div class="ytp-spinner-right">
                <div class="ytp-spinner-circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--====== PRELOADER PART ENDS ======-->

    <!--====== HEADER PART START ======-->

    <header class="header-area">
      <div class="navbar-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="#home">
                  <img
                    src="/assets/images/BLUE_LOGO_FAKTURA.svg"
                    type="image/svg+xml"
                    alt="Logo"
                    width="250"
                    height="100"
                  />
                  <!-- <object
                    data="/assets/images/LOGO_FAKTURA.svg"
                    type="image/svg+xml"
                    width="250"
                    height="100"
                    class="mylogo"
                    href="#home"
                  ></object> -->
                </a>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="toggler-icon"></span>
                  <span class="toggler-icon"></span>
                  <span class="toggler-icon"></span>
                </button>

                <div
                  class="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" class="navbar-nav ml-auto">
                    <!-- <li class="nav-item active">
                      <a class="page-scroll" href="#home">{{ $t("frontend.home") }}</a>
                    </li> -->
                    <li class="nav-item">
                      <a class="page-scroll" href="#why">{{ $t("frontend.why") }}</a>
                      <!--test-->
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#features">{{
                        $t("frontend.features")
                      }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#screenshots">{{
                        $t("frontend.screenshot")
                      }}</a>
                    </li>
                    <li class="nav-item">
                      <a class="page-scroll" href="#pricing">{{
                        $t("frontend.pricing")
                      }}</a>
                    </li>
                    <!-- <li class="nav-item">
                      <a class="page-scroll" href="#download">{{
                        $t("frontend.download")
                      }}</a>
                    </li> -->
                    <li
                      v-if="!loggedIn"
                      style="width: 53%; background-color: #0898e7"
                      class="nav-item btn btn-primary"
                    >
                      <router-link style="color: white" to="/login">{{
                        $t("frontend.login")
                      }}</router-link>
                      <!-- <a class="page-scroll" href='router.push("login")'>Login</a> -->
                    </li>
                    <li
                      v-else
                      style="background-color: #0898e7"
                      class="nav-item btn btn-primary"
                    >
                      <router-link style="color: white" to="/login">{{
                        $t("navigation.dashboard")
                      }}</router-link>
                      <!-- <a class="page-scroll" href='router.push("login")'>Login</a> -->
                    </li>
                    <li
                      v-if="!loggedIn"
                      style="background-color: #0898e7"
                      class="nav-item btn btn-primary"
                    >
                      <router-link
                        style="color: white; white-space: nowrap"
                        to="/sign-up"
                        >{{ $t("login.signUp") }}</router-link
                      >
                      <!-- <a class="page-scroll" href='router.push("login")'>Login</a> -->
                    </li>

                    <li></li>
                  </ul>
                </div>

                <!-- <base-select
                  v-model="formData.language"
                  :options="languages"
                  :show-labels="false"
                  :allow-empty="false"
                  :placeholder="$tc('settings.preferences.select_language')"
                  label="name"
                  track-by="code"
                  class="nav-item"
                  :showCountryFlag="true"
                /> -->
                <!-- navbar collapse -->
              </nav>
              <!-- navbar -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- navbar area -->

      <div id="home" class="header-hero bg_cover d-lg-flex align-items-center">
        <div class="shape shape-1"></div>
        <div class="shape shape-2"></div>
        <div class="shape shape-3"></div>
        <div class="shape shape-4"></div>
        <div class="shape shape-5"></div>
        <div class="shape shape-6"></div>

        <div class="container">
          <div
            class="row align-items-center justify-content-center justify-content-lg-between"
          >
            <div class="col-lg-6 col-md-10">
              <div class="header-hero-content">
                <h3
                  class="header-title wow fadeInLeftBig"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.2s"
                >
                  <span>{{ $tc("frontend.launch") }}</span>
                  {{ $tc("frontend.confidence") }}
                </h3>
                <p
                  class="text wow fadeInLeftBig"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.6s"
                >
                  {{ $tc("frontend.description") }}
                </p>
                <ul class="d-flex">
                  <li>
                    <a
                      href="#"
                      class="main-btn wow fadeInLeftBig"
                      data-wow-duration="1.3s"
                      data-wow-delay="0.8s"
                      >{{ $tc("frontend.button") }}</a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/watch?v=r44RKWyfcFw"
                      class="header-video video-popup wow fadeInLeftBig"
                      data-wow-duration="1.3s"
                      data-wow-delay="1.2s"
                      ><i class="lni lni-play"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <!-- header hero content -->
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-10">
              <div class="header-image">
                <img
                  src="/assets/images/faktura/Group 15.png"
                  alt="app"
                  class="image wow fadeInRightBig"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.5s"
                />
                <div
                  class="image-shape wow fadeInRightBig"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.8s"
                >
                  <img src="/assets/images/image-shape.svg" alt="shape" />
                </div>
              </div>
              <!-- header image -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
        <div class="header-shape-1 d-none d-md-block"></div>
        <!-- header shape -->
        <div class="header-shape-2">
          <img src="/assets/images/header-shape-2.svg" alt="shape" />
        </div>
        <!-- header shape -->
      </div>
      <!-- header hero -->
    </header>

    <!--====== HEADER PART ENDS ======-->

    <!--====== SERVICES PART START ======-->

    <section id="why" class="services-area pt-110 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="section-title text-center pb-25">
              <h3 class="title">{{ $tc("frontend.choose_faktura") }}</h3>
              <p class="text">
                {{ $tc("frontend.choose_description") }}
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-3 col-sm-6">
            <div
              class="single-services services-color-1 text-center mt-30 wow fadeInUpBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
            >
              <div class="services-icon d-flex align-items-center justify-content-center">
                <i class="lni lni-layers"></i>
              </div>
              <div class="services-content">
                <h4 class="services-title">
                  <a href="/system/estimates">{{ $tc("frontend.offer") }}</a>
                </h4>
                <p class="text">
                  {{ $tc("frontend.offer_description") }}
                </p>
              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-3 col-sm-6">
            <div
              class="single-services services-color-2 text-center mt-30 wow fadeInUpBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.4s"
            >
              <div class="services-icon d-flex align-items-center justify-content-center">
                <i class="lni lni-layout"></i>
              </div>
              <div class="services-content">
                <h4 class="services-title">
                  <a href="/system/invoices">{{ $tc("frontend.invoices") }}</a>
                </h4>
                <p class="text">
                  {{ $tc("frontend.invoices_description") }}
                </p>
              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-3 col-sm-6">
            <div
              class="single-services services-color-3 text-center mt-30 wow fadeInUpBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.6s"
              style="height: 93%"
            >
              <div class="services-icon d-flex align-items-center justify-content-center">
                <i class="lni lni-bolt"></i>
              </div>
              <div class="services-content">
                <h4 class="services-title">
                  <a href="/system/payments">{{ $tc("frontend.payments") }}</a>
                </h4>
                <p class="text">
                  {{ $tc("frontend.payments_description") }}
                </p>
              </div>
            </div>
            <!-- single services -->
          </div>
          <div class="col-lg-3 col-sm-6">
            <div
              class="single-services services-color-4 text-center mt-30 wow fadeInUpBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.8s"
              style="height: 93%"
            >
              <div class="services-icon d-flex align-items-center justify-content-center">
                <i class="lni lni-protection"></i>
              </div>
              <div class="services-content">
                <h4 class="services-title">
                  <a href="/system/invoices">{{ $tc("frontend.reminders") }}</a>
                </h4>
                <p class="text">
                  {{ $tc("frontend.reminders_description") }}
                </p>
              </div>
            </div>
            <!-- single services -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== SERVICES PART ENDS ======-->

    <!--====== ABOUT PART START ======-->

    <section id="about" class="about-area pt-70 pb-120">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div
              class="about-image mt-50 wow fadeInLeftBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
            >
              <div class="about-shape"></div>
              <img class="app" src="/assets/images/faktura/Group 16.png" alt="app" />
            </div>
            <!-- about image -->
          </div>
          <div class="col-lg-6">
            <div
              class="about-content mt-50 wow fadeInRightBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
            >
              <div class="section-title">
                <h3 class="title">{{ $tc("frontend.autopilot") }}</h3>

                <li>{{ $tc("frontend.autopilot_li1") }}</li>
                <li>{{ $tc("frontend.autopilot_li2") }}</li>
                <li>{{ $tc("frontend.autopilot_li3") }}</li>
                <li>{{ $tc("frontend.autopilot_li4") }}</li>
                <li>{{ $tc("frontend.autopilot_li5") }}</li>
                <br />
                {{ $tc("frontend.autopilot_li_simple") }}
              </div>
              <!-- section title -->
              <a href="#" class="main-btn">{{ $tc("frontend.autopilot_download") }}</a>
            </div>
            <!-- about content -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== ABOUT PART ENDS ======-->

    <!--====== FUETURES PART START ======-->

    <section id="features" class="features-area pt-110">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="section-title text-center pb-25">
              <h3 class="title">{{ $tc("frontend.key_features") }}</h3>
              <p class="text">
                {{ $tc("frontend.key_features_description") }}
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="features-items">
              <div
                class="single-features features-color-1 d-sm-flex mt-30 wow fadeInUpBig"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
              >
                <div
                  class="features-icon d-flex justify-content-center align-items-center"
                >
                  <i class="lni lni-laptop-phone"></i>
                </div>
                <div class="features-content media-body">
                  <h4 class="features-title">{{ $tc("frontend.responsive") }}</h4>
                  <p class="text">
                    {{ $tc("frontend.responsive_description") }}
                  </p>
                </div>
              </div>
              <!-- single features -->

              <div
                class="single-features features-color-2 d-sm-flex mt-30 wow fadeInUpBig"
                data-wow-duration="1.3s"
                data-wow-delay="0.4s"
              >
                <div
                  class="features-icon d-flex justify-content-center align-items-center"
                >
                  <i class="lni lni-leaf"></i>
                </div>
                <div class="features-content media-body">
                  <h4 class="features-title">{{ $tc("frontend.seller") }}</h4>
                  <p class="text">
                    {{ $tc("frontend.seller_description") }}
                  </p>
                </div>
              </div>
              <!-- single features -->

              <div
                class="single-features features-color-3 d-sm-flex mt-30 wow fadeInUpBig"
                data-wow-duration="1.3s"
                data-wow-delay="0.6s"
              >
                <div
                  class="features-icon d-flex justify-content-center align-items-center"
                >
                  <i class="lni lni-bootstrap"></i>
                </div>
                <div class="features-content media-body">
                  <h4 class="features-title">{{ $tc("frontend.integrations") }}</h4>
                  <p class="text">
                    {{ $tc("frontend.integrations_description") }}
                  </p>
                </div>
              </div>
              <!-- single features -->
            </div>
            <!-- features items -->
          </div>
          <div class="col-lg-6 col-md-6">
            <div
              class="features-image wow fadeInRightBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
            >
              <img class="image" src="/assets/images/faktura/Group 17.png" alt="App" />

              <div class="features-shape-1"></div>
              <div class="features-shape-2">
                <img class="svg" src="/assets/images/features-shape-1.svg" alt="" />
              </div>
            </div>
            <!-- features image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== FUETURES PART ENDS ======-->

    <!--====== SCREENSHOTS PART START ======-->

    <section id="screenshots" class="screenshots-area pt-110 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="section-title text-center pb-45">
              <h3 class="title">{{ $tc("frontend.screenshots") }}</h3>
              <p class="text">
                {{ $tc("frontend.screenshots_description") }}
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-12">
            <div class="screenshot-slider">
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img src="/assets/images/faktura/Group 37.png" alt="screenshot" />
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img src="/assets/images/faktura/Group 46.png" alt="screenshot" />
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img
                        src="/assets/images/faktura/screenshot-1.jpeg"
                        alt="screenshot"
                      />
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img
                        src="/assets/images/faktura/screenshot-2.jpeg"
                        alt="screenshot"
                      />
                    </div>
                  </div>
                  <!-- swiper slide -->

                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img
                        src="/assets/images/faktura/screenshot-3.jpeg"
                        alt="screenshot"
                      />
                    </div>
                  </div>
                  <!-- swiper slide -->

                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img
                        src="/assets/images/faktura/screenshot-4.jpeg"
                        alt="screenshot"
                      />
                    </div>
                  </div>

                  <div class="swiper-slide">
                    <div class="slider-image">
                      <img src="/assets/images/faktura/Group 33.png" alt="screenshot" />
                    </div>
                  </div>
                  <!-- swiper slide -->
                </div>
                <!-- swiper wrapper -->
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <div class="screenshot-frame d-none d-sm-block">
                  <img
                    style="height: 90%"
                    src="/assets/images/app-frame.png"
                    alt="frame"
                  />
                </div>
                <!-- screenshot frame -->
              </div>
              <!-- swiper container -->
            </div>
            <!-- screenshot slider -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== SCREENSHOTS PART ENDS ======-->

    <!--====== TESTIMONIAL PART START ======-->

    <section id="testimonial" class="testimonial-area pt-110 pb-120">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="section-title text-center pb-45">
              <h3 class="title">{{ $tc("frontend.reviews") }}</h3>
              <p class="text">
                {{ $tc("frontend.reviews_description") }}
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="testimonial-wrapper">
              <div class="testimonial-content">
                <div class="single-testimonial-content text-center">
                  <div class="testimonial-quote">
                    <img class="svg" src="/assets/images/icon-quote.svg" alt="quote" />
                  </div>
                  <div class="testimonial-text">
                    <p class="text">
                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam
                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,
                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea
                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.
                    </p>
                    <h4 class="holder-name">Linda Sophia</h4>
                    <span class="sub-title">Designer, SpaceX</span>
                  </div>
                </div>
                <!-- single testimonial content -->

                <div class="single-testimonial-content text-center">
                  <div class="testimonial-quote">
                    <img class="svg" src="/assets/images/icon-quote.svg" alt="quote" />
                  </div>
                  <div class="testimonial-text">
                    <p class="text">
                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam
                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,
                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea
                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.
                    </p>
                    <h4 class="holder-name">Michel Jhonsmith</h4>
                    <span class="sub-title">CEO, OnePlus</span>
                  </div>
                </div>
                <!-- single testimonial content -->

                <div class="single-testimonial-content text-center">
                  <div class="testimonial-quote">
                    <img class="svg" src="/assets/images/icon-quote.svg" alt="quote" />
                  </div>
                  <div class="testimonial-text">
                    <p class="text">
                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam
                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,
                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea
                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.
                    </p>
                    <h4 class="holder-name">John Doe</h4>
                    <span class="sub-title">CEO, UIdeck</span>
                  </div>
                </div>
                <!-- single testimonial content -->

                <div class="single-testimonial-content text-center">
                  <div class="testimonial-quote">
                    <img class="svg" src="/assets/images/icon-quote.svg" alt="quote" />
                  </div>
                  <div class="testimonial-text">
                    <p class="text">
                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam
                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,
                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea
                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.
                    </p>
                    <h4 class="holder-name">Windy Lisa</h4>
                    <span class="sub-title">Founder, PageBulb</span>
                  </div>
                </div>
                <!-- single testimonial content -->

                <div class="single-testimonial-content text-center">
                  <div class="testimonial-quote">
                    <img class="svg" src="/assets/images/icon-quote.svg" alt="quote" />
                  </div>
                  <div class="testimonial-text">
                    <p class="text">
                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam
                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,
                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea
                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.
                    </p>
                    <h4 class="holder-name">Musharof Chowdhury</h4>
                    <span class="sub-title">CEO, GrayGrids</span>
                  </div>
                </div>
                <!-- single testimonial content -->

                <div class="single-testimonial-content text-center">
                  <div class="testimonial-quote">
                    <img class="svg" src="/assets/images/icon-quote.svg" alt="quote" />
                  </div>
                  <div class="testimonial-text">
                    <p class="text">
                      Lorem ipsum dolor situt amet, consetetur sadipscing elitr, sed diam
                      nonumy eirmod tempo invidunt labore et dolore magna aliquyam erat,
                      sed diam voluptua. At vero eoset accusam et justo duo dolores et ea
                      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                      ipsum dolor sit amet. Lorem ipsum dolor amet sadipscing elitr.
                    </p>
                    <h4 class="holder-name">Smith Martin</h4>
                    <span class="sub-title">Designer, LineIcons</span>
                  </div>
                </div>
                <!-- single testimonial content -->
              </div>
              <!-- testimonial content -->

              <div class="testimonial-author">
                <div class="single-testimonial-author">
                  <img src="/assets/images/author5.png" alt="author" />
                </div>
                <!-- single testimonial author -->
                <div class="single-testimonial-author">
                  <img src="/assets/images/author2.png" alt="author" />
                </div>
                <!-- single testimonial author -->
                <div class="single-testimonial-author">
                  <img src="/assets/images/author4.png" alt="author" />
                </div>
                <!-- single testimonial author -->
                <div class="single-testimonial-author">
                  <img src="/assets/images/author3.png" alt="author" />
                </div>
                <!-- single testimonial author -->
                <div class="single-testimonial-author">
                  <img src="/assets/images/author1.png" alt="author" />
                </div>
                <!-- single testimonial author -->
                <div class="single-testimonial-author">
                  <img src="/assets/images/author6.png" alt="author" />
                </div>
                <!-- single testimonial author -->
              </div>
              <!-- testimonial author -->
            </div>
            <!-- testimonial wrapper -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== TESTIMONIAL PART ENDS ======-->

    <!--====== PRICING PART START ======-->

    <section id="pricing" class="pricing-area pt-110">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-7">
            <div class="section-title text-center pb-30">
              <h3 class="title">{{ $tc("frontend.cost") }}</h3>
              <p class="text">
                {{ $tc("frontend.cost_description") }}
              </p>
            </div>
            <!-- section title -->
          </div>
        </div>
        <!-- row -->
        <div class="row justify-content-center">
          <div
            style="min-height: 10%; position: relative"
            class="col-lg-4 col-md-7 col-sm-9"
          >
            <div
              class="single-pricing text-center mt-30 wow fadeIn"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
              style="height: 100%"
            >
              <div class="pricing-title">
                <h4 class="title">{{ $tc("frontend.free") }}</h4>
              </div>
              <div class="pricing-price">
                <span class="price">0 kr</span>
                <p class="text">{{ $tc("frontend.free_month") }}</p>
              </div>
              <div class="pricing-list">
                <ul class="list">
                  <li v-for="(perm, index) in this.freePermissions" :key="index">
                    {{ $tc(perm.landing_page_text) }}
                  </li>
                  <!-- <li>{{ $tc("frontend.free_li2") }}</li>
                  <li>{{ $tc("frontend.free_li3") }}</li>
                  <li>{{ $tc("frontend.free_li4") }}</li>
                  <li>{{ $tc("frontend.free_li5") }}</li> -->
                </ul>
              </div>
              <div
                v-if="this.subscription !== 'Plus' && this.subscription !== 'Pro'"
                class="pricing-btn"
              >
                <base-button
                  :style="linkC"
                  :loading="isLoadingFree"
                  :disabled="isLoadingFree"
                  :class="boolVarFree"
                  id="free"
                  type="button"
                >
                  <span>
                    <center v-if="this.subscription === 'Free'">
                      {{ $tc("frontend.currentPlan") }}
                    </center>
                    <center v-else>
                      {{ $tc("frontend.free_choose") }}
                    </center>
                  </span>
                </base-button>
                <!-- <a
                  style="margin-top: 54%"
                  class="main-btn"
                  :style="linkC"
                  :href="freeHref"
                  :class="boolVarFree"
                  >{{ $tc("frontend.free_choose") }}</a
                > -->
              </div>
            </div>
            <!-- single pricing -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-9">
            <div
              class="single-pricing pricing-active text-center mt-30 wow fadeIn"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
              style="height: 100%"
            >
              <div class="pricing-shape">
                <img src="/assets/images/price-shape.png" alt="" />
              </div>
              <div class="pricing-title">
                <h4 class="title">{{ $tc("frontend.plus") }}</h4>
              </div>
              <div class="pricing-price">
                <span class="price">19 kr</span>
                <p class="text">{{ $tc("frontend.plus_month") }}</p>
              </div>
              <div class="pricing-list">
                <ul class="list">
                  <li v-for="(perm, index) in this.plusPermissions" :key="index">
                    {{ $tc(perm.landing_page_text) }}
                  </li>
                  <!-- <li>{{ $tc("frontend.plus_li1") }}</li>
                  <li>{{ $tc("frontend.plus_li2") }}</li>
                  <li>{{ $tc("frontend.plus_li3") }}</li>
                  <li>{{ $tc("frontend.plus_li4") }}</li>
                  <li>{{ $tc("frontend.plus_li5") }}</li>
                  <li>{{ $tc("frontend.plus_li6") }}</li>
                  <li>{{ $tc("frontend.plus_li7") }}</li> -->
                </ul>
              </div>
              <div class="pricing-btn">
                <base-button
                  :style="linkA"
                  :loading="isLoadingPlus"
                  :disabled="isLoadingPlus"
                  :class="boolVarPlus"
                  v-on:click="callChangePlan(2)"
                  id="plus"
                  type="button"
                >
                  <span>
                    <center v-if="this.subscription === 'Plus'">
                      {{ $tc("frontend.currentPlan") }}
                    </center>
                    <center v-else>
                      {{ $tc("frontend.free_choose") }}
                    </center>
                  </span>
                </base-button>
                <!-- <a
                  style="margin-top: 28%"
                  :style="linkA"
                  :loading="isLoading"
                  :disabled="isLoading"
                  class="main-btn"
                  :class="boolVarPlus"
                  href=""
                  v-on:click.prevent="callChangePlan(1)"
                  id="plus"
                  >{{ $tc("frontend.plus_choose") }}</a
                > -->
              </div>
            </div>
            <!-- single pricing -->
          </div>
          <div class="col-lg-4 col-md-7 col-sm-9">
            <div
              class="single-pricing text-center mt-30 wow fadeIn"
              data-wow-duration="1.3s"
              data-wow-delay="0.9s"
              style="height: 100%"
            >
              <div class="pricing-title">
                <h4 class="title">{{ $tc("frontend.pro") }}</h4>
              </div>
              <div class="pricing-price">
                <span class="price">99 kr</span>
                <p class="text">{{ $tc("frontend.pro_month") }}</p>
              </div>
              <div class="pricing-list">
                <ul class="list">
                  <li v-for="(perm, index) in this.proPermissions" :key="index">
                    {{ $tc(perm.landing_page_text) }}
                  </li>
                  <!-- <li>{{ $tc("frontend.pro_li1") }}</li>
                  <li>{{ $tc("frontend.pro_li2") }}</li>
                  <li>{{ $tc("frontend.pro_li3") }}</li>
                  <li>{{ $tc("frontend.pro_li4") }}</li>
                  <li>{{ $tc("frontend.pro_li5") }}</li>
                  <li>{{ $tc("frontend.pro_li6") }}</li>
                  <li>{{ $tc("frontend.pro_li7") }}</li>-->
                  <li>{{ $tc("frontend.pro_li8") }}</li>
                  <li>{{ $tc("frontend.pro_li9") }}</li>
                </ul>
              </div>
              <div class="pricing-btn">
                <base-button
                  :style="linkB"
                  :loading="isLoadingPro"
                  :disabled="isLoadingPro"
                  :class="boolVarPro"
                  v-on:click="callChangePlan(3)"
                  id="pro"
                  type="button"
                >
                  <span>
                    <center v-if="this.subscription === 'Pro'">
                      {{ $tc("frontend.currentPlan") }}
                    </center>
                    <center v-else>
                      {{ $tc("frontend.free_choose") }}
                    </center>
                  </span>
                </base-button>
                <!-- <a
                  class="main-btn"
                  id="pro"
                  :loading="isLoading"
                  :disabled="isLoading"
                  :style="linkB"
                  :class="boolVarPro"
                  href=""
                  v-on:click.prevent="callChangePlan(2)"
                  >{{ $tc("frontend.pro_choose") }}</a
                > -->
              </div>
            </div>
            <!-- single pricing -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== PRICING PART ENDS ======-->

    <!--====== DOWNLOAD PART START ======-->

    <section id="download" class="download-area pt-70 pb-40">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6 col-md-9">
            <div
              class="download-image mt-50 wow fadeInRightBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.2s"
            >
              <img
                class="image"
                src="/assets/images/faktura/Group 15.png"
                alt="download"
              />

              <div class="download-shape-1"></div>
              <div class="download-shape-2">
                <img class="svg" src="/assets/images/download-shape.svg" alt="shape" />
              </div>
            </div>
            <!-- download image -->
          </div>
          <div class="col-lg-6">
            <div
              class="download-content mt-45 wow fadeInLeftBig"
              data-wow-duration="1.3s"
              data-wow-delay="0.5s"
            >
              <h3 class="download-title">{{ $tc("frontend.start") }}</h3>
              <p class="text">
                {{ $tc("frontend.start_description") }}
              </p>
              <ul>
                <li>
                  <a class="app-store" href="#"
                    ><img src="/assets/images/app-store.png" alt="store"
                  /></a>
                </li>
                <li>
                  <a class="play-store" href="#"
                    ><img src="/assets/images/play-store.png" alt="store"
                  /></a>
                </li>
              </ul>
            </div>
            <!-- download image -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </section>

    <!--====== DOWNLOAD PART ENDS ======-->

    <!--====== PART START ======-->

    <footer id="footer" class="footer-area">
      <div class="footer-shape shape-1"></div>
      <div class="footer-shape shape-2"></div>
      <div class="footer-shape shape-3"></div>
      <div class="footer-shape shape-4"></div>
      <div class="footer-shape shape-5"></div>
      <div class="footer-shape shape-6"></div>
      <div class="footer-shape shape-7"></div>
      <div class="footer-shape shape-8">
        <img class="svg" src="/assets/images/footer-shape.svg" alt="Shape" />
      </div>

      <div class="footer-widget pt-30 pb-80">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div
                class="footer-about mt-50 wow fadeIn"
                data-wow-duration="1.3s"
                data-wow-delay="0.2s"
              >
                <a class="logo" href="#">
                  <!-- <img src="/assets/images/logo.png" alt="Logo" /> -->
                  <object
                    data="/assets/images/BLUE_LOGO_FAKTURA.svg"
                    type="image/svg+xml"
                    width="250"
                    height="100"
                    class="mylogo"
                  ></object>
                </a>
                <p class="text">
                  {{ $tc("frontend.footer_description") }}
                </p>

                <base-select
                  v-model="formData.language"
                  :options="languages"
                  :show-labels="false"
                  :allow-empty="false"
                  :placeholder="$tc('settings.preferences.select_language')"
                  label="name"
                  track-by="code"
                  class="nav-item"
                  :showCountryFlag="true"
                />
                <!-- <li>
                    <a href="#"><i class="lni lni-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni lni-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni lni-instagram"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="lni lni-linkedin"></i></a>
                  </li> -->
              </div>
              <!-- footer about -->
            </div>
            <div class="col-lg-5 col-md-6">
              <div class="footer-link d-flex flex-wrap">
                <div
                  class="footer-link-wrapper mt-45 wow fadeIn"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.4s"
                >
                  <div class="footer-title">
                    <h4 class="title">{{ $tc("frontend.links") }}</h4>
                  </div>
                  <ul class="link">
                    <li>
                      <a class="" href="#home">{{ $tc("frontend.top") }}</a>
                    </li>
                    <li>
                      <a class="" href="#features">{{ $tc("frontend.features") }}</a>
                    </li>
                    <li>
                      <a class="" href="/system/customers">{{
                        $tc("frontend.customers")
                      }}</a>
                    </li>
                    <li>
                      <a class="" href="#pricing">{{ $tc("frontend.prices") }}</a>
                    </li>
                  </ul>
                </div>
                <!-- footer link wrapper -->

                <div
                  class="footer-link-wrapper mt-45 wow fadeIn"
                  data-wow-duration="1.3s"
                  data-wow-delay="0.6s"
                >
                  <div class="footer-title">
                    <h4 class="title">Support</h4>
                  </div>
                  <ul class="link">
                    <li><a class="" href="#">FAQ</a></li>
                    <li>
                      <a class="" href="/privacy-policy">{{
                        $tc("frontend.privacy_policy")
                      }}</a>
                    </li>
                    <li>
                      <a class="" href="#">{{ $tc("frontend.terms_of_use") }}</a>
                    </li>
                    <!-- <li><a class="" href="#">Legal</a></li>
                    <li><a class="" href="#">Site Map</a></li> -->
                  </ul>
                </div>
                <!-- footer link wrapper -->
              </div>
              <!-- footer link -->
            </div>
            <div class="col-lg-3 col-md-6">
              <div
                class="footer-contact mt-45 wow fadeIn"
                data-wow-duration="1.3s"
                data-wow-delay="0.8s"
              >
                <div class="footer-title">
                  <h4 class="title">{{ $tc("frontend.contact") }}</h4>
                </div>
                <ul class="contact-list">
                  <li>
                    <!-- <div class="contact-info d-flex">
                      <div class="info-content media-body">
                        <p class="text"><i class="lni lni-phone"></i> +809272561823</p>
                      </div>
                    </div> -->
                    <!-- contact info -->
                  </li>
                  <li>
                    <div class="contact-info d-flex">
                      <div class="info-content media-body">
                        <p class="text">
                          <a href="#"
                            ><i class="lni lni-envelope"></i> support@faktura.dk
                          </a>
                        </p>
                      </div>
                    </div>
                    <!-- contact info -->
                  </li>
                  <li>
                    <div class="contact-info d-flex">
                      <div class="info-content media-body">
                        <p class="text">
                          <a href="#"><i class="lni lni-world"></i> www.faktura.dk </a>
                        </p>
                      </div>
                    </div>
                    <!-- contact info -->
                  </li>
                  <li>
                    <div class="contact-info d-flex">
                      <div class="info-content media-body">
                        <p class="text">
                          <i class="lni lni-map"></i> Sandtoften 10 2820 Gentofte
                        </p>
                      </div>
                    </div>
                    <!-- contact info -->
                  </li>
                </ul>
                <!-- contact list -->
              </div>
              <!-- footer contact -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- footer widget -->

      <div class="footer-copyright">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="copyright d-sm-flex justify-content-between">
                <div class="copyright-text text-center">
                  <p class="text">
                    All rights reserved

                    <a rel="nofollow" target="_blank" href="https://www.weiswise.com"
                      >faktura.dk</a
                    >
                  </p>
                </div>
                <!-- copyright text -->

                <div class="copyright-privacy text-center">
                  <a href="#">Report Issues</a>
                </div>
                <!-- copyright privacy -->
              </div>
              <!-- copyright -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- footer copyright -->
    </footer>

    <!--====== PART ENDS ======-->

    <!--====== BACK TOP TOP PART START ======-->

    <a href="#" class="back-to-top"><i class="lni lni-chevron-up"></i></a>

    <!--====== BACK TOP TOP PART ENDS ======-->

    <!--====== PART START ======-->

    <!--
    <section class="">
        <div class="container">
            <div class="row">
                <div class="col-lg-"></div>
            </div>
        </div>
    </section>
-->

    <!--====== PART ENDS ======-->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LangFlag from "vue-lang-code-flags";

export default {
  components: {
    LangFlag,
  },
  props: ["val"],
  data() {
    return {
      planId: null,
      isLoadingFree: false,
      isLoadingPlus: false,
      isLoadingPro: false,
      flag: true,
      unique_hash: null,
      loggedIn: false,
      languages: [],
      freePermissions: [],
      plusPermissions: [],
      proPermissions: [],
      subscription: null,
      boolVarPlus: "main-btn-2",
      boolVarPro: "main-btn-2",
      boolVarFree: "main-btn-2",
      url: null,
      freeHref: "/",
      paidHref: "/",
      plusHref: "/",
      proHref: "/",
      linkA: "",
      linkB: "",
      linkC: "",
      formData: {
        language: null,
      },
    };
  },
  watch: {
    "formData.language": function (val, oldval) {
      if (val) {
        window.i18n.locale = val.code;
        localStorage.setItem("language", val.code);
      }
    },
  },

  created() {
    this.setInitialData();

    if (this.val == "home") {
      this.flag = false;
    }

    if (localStorage.getItem("selectedCompany")) {
      this.loggedIn = true;
      this.freeHref = "/system/dashboard";
      // this.paidHref = "/admin/subscription?hash=" + this.url;
    } else {
      this.freeHref = "/login";

      this.plusHref = "/login";
      this.proHref = "/login";
    }

    var selLanguage = localStorage.getItem("language");

    //if language is empty
    if (typeof selLanguage === "undefined" || selLanguage === null) {
      this.formData.language = this.languages[1];
      localStorage.setItem("language", "da");
    } else {
      window.i18n.locale = localStorage.getItem("language");
    }
  },
  methods: {
    ...mapActions("preferences", ["loadData", "loadDataLanding", "editPreferences"]),
    ...mapActions("subscription", ["changePlan"]),

    async callChangePlan(id) {
      var selCompanay = localStorage.getItem("selectedCompany");

      //if user is not logged in
      if (typeof selCompanay === "undefined" || selCompanay === null) {
        this.$router.push("/login");
        return true;
      }

      //if user is logged in as free
      if (
        (typeof selCompanay !== "undefined" || selCompanay !== null) &&
        this.subscription === "Free"
      ) {
        window.location.href = "/system/subscription?hash=" + this.unique_hash;
        return true;
      }

      //if user is admin
      if (this.subscription === "Admin") {
        this.linkA = `"display: inline-block;pointer-events: none;"`;
        this.linkC = `"display: inline-block;pointer-events: none;"`;
        this.linkB = `"display: inline-block;pointer-events: none;"`;
        return true;
      }

      //if user is subscribed to any package and is logged in
      if (this.subscription === "Plus" || this.subscription === "Pro") {
        let response;
        let data = {
          id: id,
          name: this.subscription,
        };

        ////////////////////////
        swal({
          title: this.$t("general.are_you_sure"),
          text: this.$t("landing.swap_pop_up"),
          icon: "/assets/icon/paper-plane-solid.svg",
          buttons: true,
          dangerMode: true,
        }).then(async (value) => {
          if (value) {
            try {
              if (data.id == 2) {
                this.isLoadingFree = false;
                this.isLoadingPlus = true;
                this.isLoadingPro = false;
              } else if (data.id == 3) {
                this.isLoadingFree = false;
                this.isLoadingPlus = false;
                this.isLoadingPro = true;
              }
              response = await this.changePlan(data);
              if (response.data.success) {
                this.isLoadingFree = false;
                this.isLoadingPlus = false;
                this.isLoadingPro = false;
                window.toastr["success"](
                  this.$tc("settings.subscriptions.success_message_swap")
                );
                this.$router.push("/system/dashboard");
                return true;
              } else if (response.data.error) {
                this.isLoadingFree = false;
                this.isLoadingPlus = false;
                this.isLoadingPro = false;
                window.toastr["error"](response.data.error);
              }
            } catch (err) {
              // window.toastr["error"](response.data.error);
            }
          }
        });

        ///////////////////////
        // try {
        //   response = await this.changePlan(data);
        //   if (response.data.success) {
        //     window.toastr["success"](
        //       this.$tc("settings.subscriptions.success_message_swap")
        //     );
        //     this.$router.push("/admin/dashboard");
        //     return true;
        //   }
        // } catch (err) {
        //   // this.isLoading = false;
        // }
      }
    },

    async setInitialData() {
      try {
        let response = await this.loadDataLanding();
        if (response.data.status == 200) {
          this.flag = false;
        }

        this.languages = [...response.data.languages];
        this.unique_hash = response.data.unique_hash;

        this.freePermissions = response.data.permissions[0];
        this.plusPermissions = response.data.permissions[1];
        this.proPermissions = response.data.permissions[2];

        // if (response.data.unique_hash != null) {
        //   this.paidHref = "/admin/subscription?hash=" + response.data.unique_hash;
        // }
        if (response.data.subscription != null) {
          this.subscription = response.data.subscription;

          // this.plusHref = `/api/subscription/changePlan?id=1`;
          // this.proHref = "callChangePlan(2)";

          // this.planId = response.data.plan.find(
          //   (plans) => plans.name == this.subscription
          // );

          // this.paidHref = this.changePlan(this.planId.id);
        }
        if (this.subscription == "Plus") {
          this.boolVarPlus = "main-btn";
          this.linkA = `"display: inline-block;pointer-events: none;"`;
          this.linkC = `"display: inline-block;pointer-events: none;"`;
        } else if (this.subscription == "Pro") {
          this.boolVarPro = "main-btn";
          this.linkB = `"display: inline-block;pointer-events: none;"`;
          this.linkC = `"display: inline-block;pointer-events: none;"`;
        } else if (this.subscription == "Admin") {
          this.linkA = `"display: inline-block;pointer-events: none;"`;
          this.linkC = `"display: inline-block;pointer-events: none;"`;
          this.linkB = `"display: inline-block;pointer-events: none;"`;
        } else if (this.subscription == "Free") {
          this.boolVarFree = "main-btn";
          this.linkC = `"display: inline-block;pointer-events: none;"`;
        } else {
        }
        this.formData.language = response.data.languages.find(
          (language) => language.code == localStorage.getItem("language")
        );

        if (this.formData.language == null) {
          this.formData.language = this.languages[1];
        }
      } catch (err) {
        window.toastr["error"](err);
      }
    },
  },
};
</script>

<style scoped>
.navbar-nav {
  align-items: center;
}
.base-button {
  align-items: center;
  margin: 0 auto;
}
.pricing-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 23px;
  z-index: 1;
}

.single-pricing {
  position: relative;
}

.main-btn-2 {
  color: black !important;
}

.main-btn {
  background: #007bff !important;
}

button:hover {
  background: #007bff;
  color: white !important;
}

.base-button {
  background: transparent;
}

.btn-dark:not(:disabled):not(.disabled):active {
  background: #007bff;
  color: white;
}
</style>
