var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Dashboard")
    ? _c(
        "div",
        { staticClass: "main-content dashboard" },
        [
          this.sub
            ? _c(
                "div",
                {
                  staticClass: "panel panel-info",
                  attrs: { id: "copyright-wrap" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "panel-heading" },
                    [
                      _c("center", [
                        _vm._v(
                          _vm._s(_vm.$t("dashboard.subscriptionSuccessfull"))
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          this.sub ? _c("br") : _vm._e(),
          _vm._v(" "),
          !_vm.isAdmin() &&
          (!_vm.defaultSubscription || _vm.defaultSubscription.ends_at)
            ? _c("div", { staticClass: "panel panel-info" }, [
                _c(
                  "div",
                  { staticClass: "panel-heading" },
                  [
                    _c(
                      "center",
                      [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("dashboard.currentSubscription")) +
                              ' "' +
                              _vm._s(this.subscription) +
                              '".\n        '
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.defaultSubscription
                          ? _c(
                              "span",
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("dashboard.click")) +
                                    "\n          "
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "selectPlan" } } },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("dashboard.here")) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("dashboard.toUpgrade")) +
                                    "\n        "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.defaultSubscription &&
                        _vm.defaultSubscription.ends_at
                          ? _c(
                              "base-button",
                              {
                                staticStyle: { display: "inline-block" },
                                attrs: {
                                  loading: _vm.isLoading,
                                  disabled: _vm.isLoading,
                                  color: "theme",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.resumeSubscription()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("general.resume")) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          this.sub ? _c("br") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "dash-item col-md-4" },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { display: "block" },
                    attrs: { slot: "item-title", to: _vm.dashboardOrInvoices },
                    slot: "item-title",
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _c("div", [
                                _vm._v(_vm._s(_vm.getTotalDueAmount)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.due_amount")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("dollar-icon", { staticClass: "card-icon" })],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-md-2" },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { display: "block" },
                    attrs: { slot: "item-title", to: _vm.dashboardOrCustomers },
                    slot: "item-title",
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getContacts) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.customers")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("contact-icon", { staticClass: "card-icon" })],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-md-2" },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { display: "block" },
                    attrs: { slot: "item-title", to: _vm.dashboardOrProducts },
                    slot: "item-title",
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getItems) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.items")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("product-icon", { staticClass: "card-icon" })],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-md-2" },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { display: "block" },
                    attrs: { slot: "item-title", to: _vm.dashboardOrEstimates },
                    slot: "item-title",
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getEstimates) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.estimates")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("estimate-icon", { staticClass: "card-icon" })],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dash-item col-md-2" },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { display: "block" },
                    attrs: { slot: "item-title", to: _vm.dashboardOrInvoices },
                    slot: "item-title",
                  },
                  [
                    _c("div", { staticClass: "dashbox" }, [
                      _c("div", { staticClass: "desc" }, [
                        _vm.isLoaded
                          ? _c("span", { staticClass: "amount" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getInvoices) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("dashboard.cards.invoices")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "icon" },
                        [_c("invoice-icon", { staticClass: "card-icon" })],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 mt-2" }, [
              _c("div", { staticClass: "card dashboard-card" }, [
                _c("div", { staticClass: "graph-body" }, [
                  _c(
                    "div",
                    { staticClass: "card-body col-md-12 col-lg-12 col-xl-10" },
                    [
                      _c("div", { staticClass: "card-header" }, [
                        _c("h6", [
                          _c("i", {
                            staticClass: "fa fa-line-chart text-primary",
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("dashboard.monthly_chart.title")) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "year-selector" },
                          [
                            _c("base-select", {
                              attrs: {
                                options: _vm.years,
                                "allow-empty": false,
                                "show-labels": false,
                                placeholder: _vm.$t("dashboard.select_year"),
                              },
                              model: {
                                value: _vm.selectedYear,
                                callback: function ($$v) {
                                  _vm.selectedYear = $$v
                                },
                                expression: "selectedYear",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.isLoaded
                        ? _c("line-chart", {
                            attrs: {
                              "format-money": _vm.$utils.formatMoney,
                              "format-graph-money": _vm.$utils.formatGraphMoney,
                              offers: _vm.getChartOffers,
                              invoices: _vm.getChartInvoices,
                              overdue: _vm.overdue,
                              receipts: _vm.getReceiptTotals,
                              income: _vm.getNetProfits,
                              labels: _vm.getChartMonths,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-desc col-md-12 col-lg-12 col-xl-2" },
                    [
                      _c("div", { staticClass: "stats" }, [
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.total_offers")
                                ) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isLoaded
                            ? _c(
                                "span",
                                {
                                  staticClass: "total",
                                  staticStyle: { color: "#fb7178" },
                                },
                                [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.getTotalOffers)),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.total_sales")
                                ) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.isLoaded
                            ? _c(
                                "span",
                                {
                                  staticClass: "total",
                                  staticStyle: { color: "#0373ff" },
                                },
                                [_c("div", [_vm._v(_vm._s(_vm.getTotalSales))])]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("dashboard.chart_info.amountOverdue")
                                ) +
                                "\n                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "total",
                              staticStyle: { color: "#f25929" },
                            },
                            [_c("div", [_vm._v(_vm._s(_vm.getTotalDueAmount))])]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.getLoadedData ? _c("base-loader") : _vm._e(),
          _vm._v(" "),
          !_vm.$can("Roles")
            ? _c("div", { staticClass: "row table-row" }, [
                _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "table-header" },
                    [
                      _c("h6", { staticClass: "table-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("dashboard.recent_invoices_card.title")
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "invoices.index",
                              params: { val: "UNPAID" },
                            },
                          },
                        },
                        [
                          _c(
                            "base-button",
                            {
                              staticClass: "btn-sm",
                              attrs: { outline: true, color: "theme" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "dashboard.recent_invoices_card.view_all"
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dashboard-table" },
                    [
                      _c(
                        "table-component",
                        {
                          ref: "inv_table",
                          staticClass: "dashboard",
                          attrs: {
                            data: _vm.getDueInvoices,
                            "show-filter": false,
                            "table-class": "table",
                          },
                        },
                        [
                          _c("table-column", {
                            attrs: {
                              label: _vm.$t(
                                "dashboard.recent_invoices_card.due_on"
                              ),
                              show: "formattedDueDate",
                            },
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              label: _vm.$t(
                                "dashboard.recent_invoices_card.customer"
                              ),
                              show: "user.name",
                            },
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              label: _vm.$t("invoices.status"),
                              "sort-as": "status",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("invoices.status"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          class:
                                            "inv-status-" +
                                            row.status.toLowerCase(),
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.status != "PARTIALLY_PAID"
                                                ? row.status
                                                : row.status.replace("_", " ")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3845371885
                            ),
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              label: _vm.$t(
                                "dashboard.recent_invoices_card.amount_due"
                              ),
                              show: "due_amount",
                              "sort-as": "due_amount",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "dashboard.recent_invoices_card.amount_due"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.due_amount,
                                              _vm.defaultCurrency
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              798339937
                            ),
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              sortable: false,
                              filterable: false,
                              "cell-class":
                                "action-dropdown dashboard-recent-invoice-options no-click",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c(
                                        "v-dropdown",
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                href: "#/",
                                              },
                                              slot: "activator",
                                            },
                                            [_c("dot-icon")],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-dropdown-item",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "dropdown-item",
                                                  attrs: {
                                                    to: {
                                                      path:
                                                        "invoices/" +
                                                        row.id +
                                                        "/edit",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "dropdown-item-icon",
                                                    attrs: {
                                                      icon: [
                                                        "fas",
                                                        "pencil-alt",
                                                      ],
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("general.edit")
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass: "dropdown-item",
                                                  attrs: {
                                                    to: {
                                                      path:
                                                        "invoices/" +
                                                        row.id +
                                                        "/view",
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "dropdown-item-icon",
                                                    attrs: { icon: "eye" },
                                                  }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("invoices.view")
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          row.status == "DRAFT"
                                            ? _c("v-dropdown-item", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { href: "#/" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sendInvoice(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "dropdown-item-icon",
                                                      attrs: {
                                                        icon: "envelope",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.send_invoice"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.status === "DRAFT"
                                            ? _c("v-dropdown-item", [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { href: "#/" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sentInvoice(
                                                          row.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "dropdown-item-icon",
                                                      attrs: {
                                                        icon: "check-circle",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "invoices.mark_as_sent"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeInvoice(
                                                      row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "trash"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.delete")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3935665594
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-xl-6 mt-2 mob-table" },
                  [
                    _c(
                      "div",
                      { staticClass: "table-header" },
                      [
                        _c("h6", { staticClass: "table-title" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("dashboard.recent_estimate_card.title")
                              ) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          { attrs: { to: "/system/estimates" } },
                          [
                            _c(
                              "base-button",
                              {
                                staticClass: "btn-sm",
                                attrs: { outline: true, color: "theme" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "dashboard.recent_estimate_card.view_all"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dashboard-table" },
                      [
                        _c(
                          "table-component",
                          {
                            ref: "est_table",
                            attrs: {
                              data: _vm.getRecentEstimates,
                              "show-filter": false,
                              "table-class": "table",
                            },
                          },
                          [
                            _c("table-column", {
                              attrs: {
                                label: _vm.$t(
                                  "dashboard.recent_estimate_card.date"
                                ),
                                show: "formattedExpiryDate",
                              },
                            }),
                            _vm._v(" "),
                            _c("table-column", {
                              attrs: {
                                label: _vm.$t(
                                  "dashboard.recent_estimate_card.customer"
                                ),
                                show: "user.name",
                              },
                            }),
                            _vm._v(" "),
                            _c("table-column", {
                              attrs: {
                                label: _vm.$t("estimates.status"),
                                show: "status",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("estimates.status"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              "est-status-" +
                                              row.status.toLowerCase(),
                                          },
                                          [_vm._v(_vm._s(row.status))]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2745074645
                              ),
                            }),
                            _vm._v(" "),
                            _c("table-column", {
                              attrs: {
                                label: _vm.$t(
                                  "dashboard.recent_estimate_card.amount_due"
                                ),
                                show: "total",
                                "sort-as": "total",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "dashboard.recent_estimate_card.amount_due"
                                              )
                                            )
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$utils.formatMoney(
                                                row.total,
                                                _vm.defaultCurrency
                                              )
                                            ),
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3999473744
                              ),
                            }),
                            _vm._v(" "),
                            _c("table-column", {
                              attrs: {
                                sortable: false,
                                filterable: false,
                                "cell-class": "action-dropdown no-click",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (row) {
                                      return [
                                        _c(
                                          "v-dropdown",
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  slot: "activator",
                                                  href: "#/",
                                                },
                                                slot: "activator",
                                              },
                                              [_c("dot-icon")],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-dropdown-item",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: {
                                                      to: {
                                                        path:
                                                          "estimates/" +
                                                          row.id +
                                                          "/edit",
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "dropdown-item-icon",
                                                      attrs: {
                                                        icon: [
                                                          "fas",
                                                          "pencil-alt",
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("general.edit")
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-dropdown-item", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "dropdown-item",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeEstimate(
                                                        row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "dropdown-item-icon",
                                                    attrs: {
                                                      icon: ["fas", "trash"],
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("general.delete")
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "v-dropdown-item",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: {
                                                      to: {
                                                        path:
                                                          "estimates/" +
                                                          row.id +
                                                          "/view",
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "dropdown-item-icon",
                                                      attrs: { icon: "eye" },
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("general.view")
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-dropdown-item", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "dropdown-item",
                                                  attrs: { href: "#/" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.convertInToinvoice(
                                                        row.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    staticClass:
                                                      "dropdown-item-icon",
                                                    attrs: { icon: "file-alt" },
                                                  }),
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "estimates.convert_to_invoice"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            row.status === "DRAFT"
                                              ? _c("v-dropdown-item", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#/" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.onMarkAsSent(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "dropdown-item-icon",
                                                        attrs: {
                                                          icon: "check-circle",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.mark_as_sent"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.status !== "SENT"
                                              ? _c("v-dropdown-item", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#/" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.sendEstimate(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "dropdown-item-icon",
                                                        attrs: {
                                                          icon: "paper-plane",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.send_estimate"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.status !== "ACCEPTED"
                                              ? _c("v-dropdown-item", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#/" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.onMarkAsAccepted(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "dropdown-item-icon",
                                                        attrs: {
                                                          icon: "check-circle",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.mark_as_accepted"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            row.status !== "REJECTED"
                                              ? _c("v-dropdown-item", [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "dropdown-item",
                                                      attrs: { href: "#/" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target !==
                                                            $event.currentTarget
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.onMarkAsRejected(
                                                            row.id
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass:
                                                          "dropdown-item-icon",
                                                        attrs: {
                                                          icon: "times-circle",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "estimates.mark_as_rejected"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2250220146
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        staticStyle: { "margin-top": "-1.5%" },
        attrs: {
          type: "button",
          "data-target": "#copyright-wrap",
          "data-dismiss": "alert",
        },
      },
      [
        _c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")]),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Close")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }