var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content item-create" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        this.$route.name === "editor.edit"
          ? _c("span", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("editor.edit_item")) + "\n      "
              ),
            ])
          : _c("span", [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("editor.new_item")) + "\n      "
              ),
            ]),
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/system/dashboard" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$t("general.home")))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/system/editor" },
                slot: "item-title",
              },
              [_vm._v(_vm._s(_vm.$tc("editor.templates", 2)))]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [
            this.$route.name === "editor.edit"
              ? _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("editor.edit_item")) +
                      "\n          "
                  ),
                ])
              : _c("span", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("editor.new_item")) +
                      "\n          "
                  ),
                ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        !_vm.initLoading
          ? _c(
              "form",
              {
                attrs: { action: "" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.saveDesign.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-2" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                outline: true,
                                color: "theme",
                                size: "large",
                                type: "submit",
                                "right-icon": "",
                              },
                            },
                            [
                              this.$route.name === "editor.edit"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("editor.update")) +
                                        "\n              "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("editor.save")) +
                                        "\n              "
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xs-4 editor-main-line" },
                        [
                          _c("base-input", {
                            attrs: {
                              invalid: _vm.$v.formData.name.$error,
                              placeholder: _vm.$t("editor.enter_template_name"),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.formData.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.formData.name.$error
                            ? _c("div", { staticClass: "text-danger" }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.$v.formData.name.required,
                                        expression:
                                          "!$v.formData.name.required",
                                      },
                                    ],
                                    staticClass: "text-danger mt-1",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tc("validation.required")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xs-4 editor-main-line" },
                        [
                          _c("base-input", {
                            attrs: {
                              type: "color",
                              placeholder: _vm.$t("editor.enter_template_name"),
                            },
                            model: {
                              value: _vm.formData.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "color", $$v)
                              },
                              expression: "formData.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("EmailEditor", {
                      ref: "emailEditor",
                      staticStyle: { width: "100%", height: "1px" },
                      attrs: { minHeight: "650px" },
                      on: { load: _vm.editorLoaded },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _c("base-loader"),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel panel-info" }, [
      _c("div", { staticClass: "panel-heading" }, [
        _vm._v(
          "\n            Add new container by pressing (+) sign to add elements to your template.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "col-md-2 editor-main-line",
        staticStyle: { "margin-top": "8px" },
      },
      [
        _c(
          "label",
          {
            staticStyle: { "margin-left": "2%" },
            attrs: { for: "colorpicker" },
          },
          [_vm._v("Invoice Text Color:")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }