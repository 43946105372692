var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dialog", { attrs: { open: "" } }, [
    _c("h2", [_c("center", [_vm._v("Warning!!")])], 1),
    _vm._v(" "),
    _c(
      "p",
      [_c("center", [_vm._v("Your data will be lost if you change the tab")])],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "text-align": "center" } },
      [
        _c(
          "base-button",
          {
            staticClass: "btn-work",
            attrs: { tabindex: 23, color: "theme", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-dialog")
              },
            },
          },
          [_vm._v("\n      Go\n    ")]
        ),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "btn-work",
            attrs: { tabindex: 23, color: "theme", type: "button" },
            on: {
              click: function ($event) {
                return _vm.$emit("cancel-dialog")
              },
            },
          },
          [_vm._v("\n      Cancel\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }