var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Roles")
    ? _c(
        "div",
        { staticClass: "customer-create main-content" },
        [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("roles.title"))),
            ]),
            _vm._v(" "),
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "dashboard" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.home")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "#" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("roles.role", 2)) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-actions row" },
              [
                _c(
                  "div",
                  { staticClass: "col-xs-2 mr-4" },
                  [
                    _c(
                      "base-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.totalRoles || _vm.filtersApplied,
                            expression: "totalRoles || filtersApplied",
                          },
                        ],
                        attrs: {
                          outline: true,
                          icon: _vm.filterIcon,
                          size: "large",
                          color: "theme",
                          "right-icon": "",
                        },
                        on: { click: _vm.toggleFilter },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.filter")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "col-xs-2",
                    attrs: { slot: "item-title", to: "roles/create" },
                    slot: "item-title",
                  },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { size: "large", icon: "plus", color: "theme" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("roles.new_role")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilters,
                    expression: "showFilters",
                  },
                ],
                staticClass: "filter-section",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-9" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(_vm._s(_vm.$t("roles.display_name"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "name", $$v)
                          },
                          expression: "filters.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c("label", { staticClass: "form-label" }),
                      _vm._v(" "),
                      _c(
                        "base-button",
                        {
                          staticStyle: { "margin-top": "3%", height: "40px" },
                          attrs: { size: "large", color: "theme" },
                          on: { click: _vm.refreshFilterTable },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("navigation.applyFilters")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "clear-filter",
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              staticClass: "col-xs-1 no-data-info",
              attrs: { align: "center" },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "row", attrs: { align: "center" } }, [
                _c("label", { staticClass: "col title" }, [
                  _vm._v(_vm._s(_vm.$t("roles.no_roles"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "label",
                  {
                    staticClass: "description col mt-1",
                    attrs: { align: "center" },
                  },
                  [_vm._v(_vm._s(_vm.$t("roles.list_of_roles")))]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-container" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("roles/create")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("roles.add_new_role")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "table-container",
            },
            [
              _c(
                "div",
                { staticClass: "table-actions mt-5" },
                [
                  _c("p", { staticClass: "table-stats" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.roles.length))]),
                    _vm._v(" " + _vm._s(_vm.$t("general.of")) + "\n        "),
                    _c("b", [_vm._v(_vm._s(_vm.totalRoles))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.selectedRoles.length
                        ? _c(
                            "v-dropdown",
                            { attrs: { "show-arrow": false } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "table-actions-button dropdown-toggle",
                                  attrs: { slot: "activator", href: "#" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-item",
                                    on: { click: _vm.removeMultipleRoles },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: ["fas", "trash"] },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "custom-control custom-checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectAllFieldStatus,
                      expression: "selectAllFieldStatus",
                    },
                  ],
                  staticClass: "custom-control-input",
                  attrs: { id: "select-all", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.selectAllFieldStatus)
                      ? _vm._i(_vm.selectAllFieldStatus, null) > -1
                      : _vm.selectAllFieldStatus,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.selectAllFieldStatus,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.selectAllFieldStatus = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selectAllFieldStatus = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selectAllFieldStatus = $$c
                        }
                      },
                      _vm.selectAllRoles,
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label selectall",
                    attrs: { for: "select-all" },
                  },
                  [
                    _c("span", { staticClass: "select-all-label" }, [
                      _vm._v(_vm._s(_vm.$t("general.select_all")) + " "),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "no-click",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "custom-control custom-checkbox",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectField,
                                        expression: "selectField",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: { id: row.id, type: "checkbox" },
                                    domProps: {
                                      value: row.id,
                                      checked: Array.isArray(_vm.selectField)
                                        ? _vm._i(_vm.selectField, row.id) > -1
                                        : _vm.selectField,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.selectField,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = row.id,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.selectField = $$a.concat([
                                                $$v,
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.selectField = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.selectField = $$c
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("label", {
                                    staticClass: "custom-control-label",
                                    attrs: { for: row.id },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1359643308
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { label: _vm.$t("roles.name"), show: "name" },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      label: _vm.$t("roles.added_on"),
                      "sort-as": "created_at",
                      show: "formattedCreatedAt",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("roles.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-dropdown",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { slot: "activator", href: "#" },
                                      slot: "activator",
                                    },
                                    [_c("dot-icon")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path:
                                                "roles/permissions/" + row.id,
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: {
                                              icon: ["fas", "space-shuttle"],
                                            },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "permissions.assign_permission"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path: "roles/" + row.id + "/edit",
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: {
                                              icon: ["fas", "pencil-alt"],
                                            },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeRole(row.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "dropdown-item-icon",
                                          attrs: { icon: ["fas", "trash"] },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("general.delete")) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3154380655
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }