<template>
  <header class="site-header">
    <a v-if="!isAdmin()" href="/" class="brand-main">
      <img
        v-if="!flag"
        id="logo-white"
        :src="companyPicture"
        alt="Faktura Logo"
        class="d-none d-md-inline"
        style="width: 36px; height: 36px"
      />
      <object
        v-if="flag"
        data="/images/WHITE_LOGO_FAKTURA.svg"
        type="image/svg+xml"
        width="250"
        height="37"
        class="mylogo"
        href="#home"
        style="margin-left: -35px"
      >
        <img
          id="logo-white"
          :src="companyPicture"
          alt="Faktura Logo"
          class="d-none d-md-inline"
          style="width: 36px; height: 36px"
        />
      </object>
      <img
        id="logo-mobile"
        :src="companyPicture"
        alt="Laraspace Logo"
        class="d-md-none"
        style="width: 36px; height: 36px"
      />
    </a>
    <a v-if="isAdmin()" style="margin-left: -35px" href="/">
      <img
        v-if="!flag"
        id="logo-white"
        width="250px"
        height="40px"
        src="/images/WHITE_LOGO_FAKTURA.svg"
        alt="Faktura Logo"
        class="d-none d-md-inline"
      />

      <object
        v-if="flag"
        data="/images/WHITE_LOGO_FAKTURA.svg"
        type="image/svg+xml"
        width="250"
        height="37"
        class="mylogo"
        href="#home"
      >
        <img
          id="logo-white"
          :src="adminLogo"
          alt="Faktura Logo"
          class="d-none d-md-inline"
          style="width: 36px; height: 36px"
        />
      </object>
      <img
        id="logo-mobile"
        :src="adminLogo"
        alt="Laraspace Logo"
        class="d-md-none"
        style="width: 36px; height: 36px"
      />
    </a>

    <a href="#" class="nav-toggle" @click="onNavToggle">
      <div class="hamburger hamburger--arrowturn">
        <div class="hamburger-box">
          <div class="hamburger-inner" />
        </div>
      </div>
    </a>
    <ul class="action-list">
      <li>
        <v-dropdown :show-arrow="false">
          <a slot="activator" href="#">
            <font-awesome-icon icon="plus" />
          </a>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/system/invoices/create">
              <font-awesome-icon icon="file-alt" class="dropdown-item-icon" />
              <span> {{ $t("invoices.new_invoice") }} </span>
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/system/estimates/create">
              <font-awesome-icon class="dropdown-item-icon" icon="file" />
              <span> {{ $t("estimates.new_estimate") }} </span>
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link class="dropdown-item" to="/system/customers/create">
              <font-awesome-icon class="dropdown-item-icon" icon="user" />
              <span> {{ $t("customers.new_customer") }} </span>
            </router-link>
          </v-dropdown-item>
        </v-dropdown>
      </li>
      <li>
        <v-dropdown :show-arrow="false">
          <a
            slot="activator"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="avatar"
          >
            <img :src="profilePicture" alt="Avatar" />
          </a>
          <v-dropdown-item v-if="$can('Settings')">
            <router-link class="dropdown-item" to="/system/settings">
              <font-awesome-icon icon="cogs" class="dropdown-item-icon" />
              <span> {{ $t("navigation.settings") }} </span>
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <a href="#" class="dropdown-item" @click.prevent="logout">
              <font-awesome-icon icon="sign-out-alt" class="dropdown-item-icon" />
              <span> {{ $t("navigation.logout") }} </span>
            </a>
          </v-dropdown-item>
        </v-dropdown>
      </li>
    </ul>
  </header>
</template>
<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import CompanyInfo from "../../settings/CompanyInfo.vue";

export default {
  data() {
    return {
      uploadImage: null,
      flag: false,
      url: null,
      adminLogo: null,
    };
  },

  components: {
    CompanyInfo,
  },

  computed: {
    ...mapGetters("userProfile", ["user"]),

    ...mapGetters("company", {
      selectedCompany: "getSelectedCompany",
      companies: "getCompanies",
    }),

    ...mapGetters("companyInfo", {
      companies: "company",
      logo: "logo",
    }),

    profilePicture() {
      if (this.user && this.user.avatar !== null) {
        return this.user.avatar;
      } else {
        return "/images/default-avatar.jpg";
      }
    },
    companyPicture() {
      if (this.logo !== null) {
        this.url = this.logo;
        this.getExtension();
        return this.logo;
      } else if (this.selectedCompany.logo !== null) {
        this.url = this.selectedCompany.logo;
        this.getExtension();
        return this.selectedCompany.logo;
      } else {
        this.url = "/assets/images/WHITE_LOGO_FAKTURA.svg";
        this.getExtension();
        return "/assets/images/WHITE_LOGO_FAKTURA.svg";
      }
    },
  },
  created() {
    this.loadData();
    this.getAdminLogo();
  },

  methods: {
    ...mapActions("userProfile", ["loadData"]),
    ...mapActions({
      companySelect: "changeCompany",
    }),
    ...mapActions("auth", ["logout"]),
    onNavToggle() {
      this.$utils.toggleSidebar();
    },

    isAdmin() {
      if (localStorage.getItem("role") === "admin") return true;
    },

    getExtension() {
      var filename = this.url.substring(this.url.lastIndexOf("/") + 1);
      var ext = filename.split(".").pop();
      if (ext == "svg") {
        this.flag = true;
      } else {
        this.flag = false;
      }
      return this.flag;
    },
    getAdminLogo() {
      axios.get("/api/adminLogo").then((response) => {
        this.url = response.data.companies[0].logo;

        this.getExtension();
        this.adminLogo = response.data.companies[0].logo;

        return response.data.companies[0].logo;
      });
    },
  },
};
</script>

<style scoped>
object {
  pointer-events: none;
}
</style>
