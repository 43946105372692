<template>
  <div class="customer-create main-content" v-if="$can('Import Products')">
    <div class="page-header">
      <h3 class="page-title">Import</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#"> Import Products </router-link>
        </li>
      </ol>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div>
            <!--getting change input file event-->
            <div
              class="form-control"
              @click="trigInput"
              style="border: 2px dashed; position: relative"
              :style="setHeight"
            >
              <p
                style="
                  position: absolute;
                  cursor: pointer;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                "
              >
                <center><b>Choose file</b></center>
              </p>
            </div>
          </div>
        </div>
        <!--vue csv import main component-->
        <vue-csv-import
          v-model="csv"
          style="margin-top: -18%"
          :map-fields="[
            'Name',
            /*'Number',*/
            'Description',
            'Unit',
            'Price',
            'CostPrice',
            'GuidePrice',
            'Category',
            'BarCode',
            'Access',
          ]"
          ref="csv"
          headers="false"
          inputClass="form-control log"
        >
          <!--error message handling-->
          <template v-slot:error>
            <b class="text text-danger">{{ error }}</b>
          </template>

          <template slot="thead">
            <tr>
              <th>My Fields</th>
              <th>Column</th>
            </tr>
          </template>

          <template slot="next" slot-scope="{ load }">
            <base-button
              @click="loadCsv"
              color="theme"
              type="submit"
              class="collapse-button custom"
              :disabled="disabled"
              style="margin-top: 12%"
            >
              Load Csv
            </base-button>
          </template>
        </vue-csv-import>

        <base-button
          @click="saveCsv"
          :loading="isLoading"
          :disabled="importDisabled"
          icon="save"
          color="theme"
          type="submit"
          class="collapse-button"
          v-if="showImportBtn"
        >
          Import Csv
        </base-button>
        <span style="color: #0e7ea7">{{ text }}</span>
      </div>

      <!-- <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label class="control-label"><h3>Import Rules</h3></label
              ><span class="text-danger"> *</span>
              <div class="page-actions row">
                <div class="container">
                  <div class="large-12 medium-12 small-12 cell">
                    <ul>
                      <li style="padding: 5px">
                        <strong style="padding-right: 10px">Data:</strong>Data should be
                        same as per import rules.
                      </li>
                      <li style="padding: 5px">
                        <strong style="padding-right: 10px">Name:</strong>The title of the
                        Product. Can contain both numbers and text.
                      </li>
                      <li style="padding: 5px">
                        <strong style="padding-right: 10px">Product No.:</strong>Item
                        number can contain both text and numbers.
                      </li>
                      <li style="padding: 5px">
                        <strong style="padding-right: 10px">Description:</strong
                        >Descriptive text about the product.
                      </li>
                      <li style="padding: 5px">
                        <strong style="padding-right: 10px">Unit:</strong>Quantity or
                        dimensions of product (eg pcs, kg, mm). Note: The device must be
                        created in system before importing products with the specific
                        device.
                      </li>
                      <li style="padding: 5px">
                        <strong style="padding-right: 10px">Price excl. VAT:</strong>Price
                        of product. May contain numbers and periods / commas for decimal
                        numbers.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>
<script>
export default {
  data() {
    return {
      csv: null,
      isLoading: false,
      total: 0,
      limit: 500,
      offset: 0,
      timeOut: 0,
      text: "",
      disabled: true,
      showImportBtn: false,
      importDisabled: true,
      error: "",
      // height:76
      height: 120,
    };
  },
  computed: {
    /*set height of background upload image*/
    setHeight() {
      return {
        height: `${this.height}px`,
      };
    },
  },

  watch: {
    csv() {
      this.importDisabled = false;
    },
  },

  methods: {
    trigInput() {
      $("input[type=file]").click();
      const input = document.getElementsByClassName("form-control log")[0];
      input.addEventListener("input", this.inputHandler);
    },
    inputHandler() {
      if (this.$refs.csv.$refs.csv.files[0].name.split(".")[1] != "csv") {
        window.toastr["error"]("Please select a valid csv file");
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    loadCsv() {
      if (
        typeof this.$refs.csv.$refs.csv.files[0] !== "undefined" &&
        this.$refs.csv.$refs.csv.files[0].name.split(".")[1] == "csv"
      ) {
        // this.height = 29
        this.error = "";
        this.showImportBtn = true;
        this.$refs.csv.load();
        this.disabled = true;
      } else {
        this.error = "Please select a valid csv file";
      }
    },

    /*save csv file*/
    async saveCsv() {
      let fileData = this.csv;
      let formData = {
        data: fileData,
        type: "products",
      };

      try {
        this.isLoading = true;
        const res = await window.axios.post("/api/data/saveCsv", formData);
        /*import csv data function*/
        this.importCsv(res.data.data.file_name);
      } catch (error) {
        window.toastr["error"](error.message);
        this.isLoading = false;
      }
    },

    async importCsv(filename) {
      const limit = this.limit;
      const offset = this.offset;
      try {
        const res = await window.axios.post("/api/data/import", {
          limit: limit,
          offset: offset,
          filename: filename,
          type: "products",
        });

        this.total = res.data.total;
        this.offset = offset + limit;
        //import_update();
        if (res.data.status) {
          //set the timeout again till last record import
          this.text = "Importing " + this.offset + " of " + this.total;
          // this.timeOut = setTimeout(this.importCsv(filename), 1000);
          setTimeout(() => {
            this.importCsv(filename);
          }, 1000);
        } else {
          this.importFinished();
        }
        this.isLoading = false;
      } catch (error) {
        window.toastr["error"](error.message);
        this.isLoading = false;
      }
    },

    importFinished() {
      window.toastr["success"]("Data Imported Successfully.");
      clearTimeout(this.timeOut);
      this.limit = 500;
      this.offset = 0;
      this.total = 0;
      this.timeOut = 0;
      this.text = "Import Finished";
      //redirect to products page after successful import
      this.$router.push("/system/products");
    },
  },
};
</script>
<style scoped>
.image-uploader {
  position: relative;
  top: 60px;
  left: 0;
  background-image: linear-gradient(to bottom, #009a48c7, rgba(156, 253, 0, 0)),
    url("../../../../../public/assets/img/upload.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}
.vue-csv-uploader {
  /* margin-top: 42%; */
}

.custom {
  margin-top: 7%;
  position: relative;
}
</style>
