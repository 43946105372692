<template>
  <div class="setting-main-container" style="padding: 30px">
    <div class="page-header">
      <h3 class="page-title">{{ $t("settings.integration.integration_settings") }}</h3>
      <p class="page-sub-title">
        {{ $t("settings.integration.description") }}
      </p>
    </div>
    <div class="flex-box mb-4">
      <div class="left">
        <base-switch
          v-model="notify_integration"
          class="btn-switch"
          @change="setIntegrationSettings"
        />
      </div>
      <div class="right">
        <p class="box-title">
          <strong>{{ $t("settings.integration.switch_base_line") }}</strong>
        </p>
        <p class="box-desc">{{ $t("settings.integration.switch_desc") }}</p>
      </div>
    </div>
    <form action="" v-if="this.details" @submit.prevent="saveIntegration()">
      <div class="row">
        <div class="col-md-12 mb-4">
          <label class="input-label">{{ $t("integrations.basic_info") }}</label
          ><span class="text-danger"> * </span>
          <base-select
            v-model="formData.integration"
            :options="integrations"
            :class="{ error: $v.formData.integration.$error }"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
            :placeholder="$t('general.select_integration')"
            label="name"
            track-by="id"
            @select="onSelectIntegration"
          />
          <div v-if="$v.formData.integration.$error">
            <span v-if="!$v.formData.integration.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <label class="input-label">{{ $t("settings.integration.api_key") }}</label>
          <span class="text-danger"> * </span>
          <base-input
            v-model="formData.api_key"
            :invalid="$v.formData.api_key.$error"
            @input="$v.formData.api_key.$touch()"
            placeholder="API Key"
          />
          <div v-if="$v.formData.api_key.$error">
            <span v-if="!$v.formData.api_key.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <label class="input-label">{{ $t("settings.integration.api_secret") }}</label>
          <span class="text-danger"> * </span>
          <base-input
            v-model="formData.api_secret"
            :invalid="$v.formData.api_secret.$error"
            @input="$v.formData.api_secret.$touch()"
            placeholder="API Secret"
          />
          <div v-if="$v.formData.api_secret.$error">
            <span v-if="!$v.formData.api_secret.required" class="text-danger">{{
              $tc("validation.required")
            }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <base-button
            :loading="isLoading"
            :disabled="isLoading"
            icon="save"
            color="theme"
            type="submit"
          >
            {{ $tc("settings.company_info.save") }}
          </base-button>
        </div>
      </div> -->
    </form>

    <div class="row" style="float: right">
      <div class="col-md-2" style="margin-right: 29%">
        <base-button
          :loading="loadingBack"
          class="pull-right"
          icon="arrow-left"
          color="theme"
          @click="back"
        >
          {{ $t("wizard.back") }}
        </base-button>
      </div>
      <div class="col-md-3">
        <base-button
          :loading="loading"
          class="pull-right"
          icon="arrow-right"
          rightIcon
          @click="next"
          color="theme"
        >
          {{ $t("wizard.continue") }}
        </base-button>
      </div>
    </div>
  </div>

  <!--  else not authorize-->
  <!-- <div v-else>
    <not-authorize></not-authorize>
  </div> -->
</template>
<script>
import { validationMixin } from "vuelidate";
const { required, requiredIf, email } = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});

export default {
  mixins: [validationMixin],
  data() {
    return {
      loadingBack: false,
      loading: false,
      notify_integration: null,
      details: false,
      subs: false,
      formData: {
        integration: 1,
        api_key: "",
        api_secret: "",
      },
      integrations: [],
      isLoading: false,
      permissions: [],
      assigned_permissions: [],
      isActive: false,

      showCustomerNumberField: false,
      customerGroup: null,
      customerGroups: [],
      selectedCustomerGroupNumber: "",

      showPaymentTermField: false,
      paymentTerm: null,
      paymentTerms: [],
      selectedPaymentTerm: "",

      showVatZoneField: false,
      vatZone: null,
      vatZones: [],
      selectedVatZone: "",

      showProductNumberField: false,
      productGroup: null,
      productGroups: [],
      selectedProductGroupNumber: "",

      showLayoutNumberField: false,
      layoutGroup: null,
      layoutGroups: [],
      selectedLayoutGroupNumber: "",
    };
  },
  created() {
    window.hub.$on("newIntegration", this.onSelectIntegration);
  },
  watch: {
    integration(newIntegration) {
      this.formData.integration = newIntegration.id;
      if (this.isFetchingData) {
        return true;
      }
    },
  },
  validations: {
    formData: {
      integration: {
        required,
      },
      api_key: {
        required: requiredIfRoot,
      },
      api_secret: {
        required: requiredIfRoot,
      },
    },
  },
  mounted() {
    this.fetchData();
    /*this.handleGroupNumber();*/
  },
  filters: {
    uppercase: function (v) {
      return v.toUpperCase();
    },
  },
  methods: {
    onSelectIntegration(integration) {
      this.formData.integration = integration.id;
      this.assigned_permissions = [];
      this.fetchData();
    },
    setIntegrationSettings() {
      this.details = !this.details;
      if (this.details == true) {
        this.subs = true;
      } else {
        this.subs = false;
      }
    },
    async back() {
      this.loadingBack = true;
      let response = await window.axios.post("/api/admin/onboarding/notificationBack");

      if (response.data.success) {
        this.$emit("back");
        this.loadingBack = false;
      }
    },

    async next() {
      ////////////////////////adding integration settings

      this.$v.$touch();
      if (this.$v.$invalid) {
        return true;
      }

      let data = {
        notify_integration_data: this.notify_integration,
        integration: this.formData.integration.id,
        api_key: this.formData.api_key,
        api_secret: this.formData.api_secret,
        // assigned_permissions: this.assigned_permissions,
        customerGroup: this.customerGroup,
        paymentTerm: this.paymentTerm,
        vatZone: this.vatZone,
        productGroup: this.productGroup,
        layoutGroup: this.layoutGroup,
        assigned_permissions: this.permissions,
      };
      this.loading = true;
      let response = await axios.put("/api/settings/update-integration-setting", data);
      if (response.data.success) {
        // this.isLoading = false;
        // window.toastr["success"](this.$tc("general.setting_updated"));
        // this.formData.integration = data.integration;
        // this.fetchData();

        /////////////////////////////////////////////////////

        response = await window.axios.post("/api/admin/onboarding/integration");

        if (response.data) {
          localStorage.setItem("onboarding", 8);
          this.$emit("next");
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    async fetchData() {
      this.isLoading = true;
      let response = await axios.get(
        "/api/settings/get-integration-setting?integration=" + this.formData.integration
      );
      if (response.data) {
        if (response.data) {
          response.data.permissions.map((permission) => {
            if (permission.assigned) {
              this.assigned_permissions.push(permission.id);
            }
          });
          if (response.data.api_key != null && response.data.api_key != "") {
            this.notify_integration = true;
            this.details = true;
            this.subs = true;
          } else {
            this.notify_integration = null;
            this.details = false;
            this.subs = false;
          }
          this.permissions = response.data.permissions;
          this.integrations = response.data.integrations;
          this.formData.integration = response.data.integrations.find(
            (integration) => integration.id == response.data.selectedIntegration
          );

          // if (response.data.api_key == "") {
          //   this.notify_integration = false;
          // }

          this.formData.api_key = response.data.api_key;
          this.formData.api_secret = response.data.api_secret;
          this.selectedCustomerGroupNumber = response.data.selectedCustomerGroupNumber;
          this.selectedPaymentTerm = response.data.selectedPaymentTerm;
          this.selectedVatZone = response.data.selectedVatZone;
          this.selectedProductGroupNumber = response.data.selectedProductGroupNumber;
          this.selectedLayoutGroupNumber = response.data.selectedLayoutGroupNumber;
          // if (response.data.api_key != null) {
          //   this.isActive = false;
          //   this.handleGroupNumber();
          // } else {
          //   this.isActive = true;
          //   this.isLoading = false;
          // }
        }
      }
    },
    async saveIntegration() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return true;
      }
      this.isLoading = true;

      let data = {
        integration: this.formData.integration.id,
        api_key: this.formData.api_key,
        api_secret: this.formData.api_secret,
        // assigned_permissions: this.assigned_permissions,
        customerGroup: this.customerGroup,
        paymentTerm: this.paymentTerm,
        vatZone: this.vatZone,
        productGroup: this.productGroup,
        layoutGroup: this.layoutGroup,
        assigned_permissions: this.permissions,
      };
      let response = await axios.put("/api/settings/update-integration-setting", data);
      if (response.data.success) {
        this.isLoading = false;
        window.toastr["success"](this.$tc("general.setting_updated"));
        this.formData.integration = data.integration;
        this.fetchData();
      }
    },
    async handleGroupNumber(obj = null) {
      if (this.formData.api_key != "" && this.formData.api_key != null) {
        /*for customers*/
        if (
          (obj &&
            obj.name == "Send Customers" &&
            obj.assigned &&
            this.formData.integration.name == "e-conomic") ||
          (this.selectedCustomerGroupNumber != "" &&
            obj == null &&
            this.formData.integration.name == "e-conomic")
        ) {
          this.isLoading = true;
          let response = await axios.get("/api/settings/get-customer-groups");

          if (response.data.success) {
            this.customerGroups = response.data.customerGroups;
            this.selectedCustomerGroupNumber = response.data.selectedCustomerGroupNumber;

            if (
              this.selectedCustomerGroupNumber == "" ||
              this.selectedCustomerGroupNumber == null
            ) {
              this.customerGroup = response.data.customerGroups[0];
            } else {
              this.customerGroup = response.data.customerGroups.find(
                // (customerGroup) => customerGroup.id == response.data.selectedCustomerGroupNumber
                (customerGroup) => customerGroup.id == this.selectedCustomerGroupNumber
              );
            }
            this.showCustomerNumberField = true;
          } else {
            window.toastr["error"](response.data.message);
          }
        } else {
          if (obj && obj.name == "Send Customers") {
            this.selectedCustomerGroupNumber = "";
            this.customerGroup = null;
            this.showCustomerNumberField = false;
          }
        }

        if (
          (obj &&
            obj.name == "Send Customers" &&
            obj.assigned &&
            this.formData.integration.name == "e-conomic") ||
          (this.selectedCustomerGroupNumber != "" &&
            obj == null &&
            this.formData.integration.name == "e-conomic")
        ) {
          this.isLoading = true;
          let response = await axios.get("/api/settings/get-payment-terms");

          if (response.data.success) {
            this.paymentTerms = response.data.paymentTerms;
            this.selectedPaymentTerm = response.data.selectedPaymentTerm;

            if (this.selectedPaymentTerm == "" || this.selectedPaymentTerm == null) {
              this.paymentTerm = response.data.paymentTerms[0];
            } else {
              this.paymentTerm = response.data.paymentTerms.find(
                // (customerGroup) => customerGroup.id == response.data.selectedCustomerGroupNumber
                (paymentTerm) => paymentTerm.id == this.selectedPaymentTerm
              );
            }
            this.showPaymentTermField = true;
          } else {
            window.toastr["error"](response.data.message);
          }
        } else {
          if (obj && obj.name == "Send Customers") {
            this.selectedPaymentTerm = "";
            this.paymentTerm = null;
            this.showPaymentTermField = false;
          }
        }

        if (
          (obj &&
            obj.name == "Send Customers" &&
            obj.assigned &&
            this.formData.integration.name == "e-conomic") ||
          (this.selectedCustomerGroupNumber != "" &&
            obj == null &&
            this.formData.integration.name == "e-conomic")
        ) {
          this.isLoading = true;
          let response = await axios.get("/api/settings/get-vat-zones");

          if (response.data.success) {
            this.vatZones = response.data.vatZones;
            this.selectedVatZone = response.data.selectedVatZone;

            if (this.selectedVatZone == "" || this.selectedVatZone == null) {
              this.vatZone = response.data.vatZones[0];
            } else {
              this.vatZone = response.data.vatZones.find(
                // (customerGroup) => customerGroup.id == response.data.selectedCustomerGroupNumber
                (vatZone) => vatZone.id == this.selectedVatZone
              );
            }
            this.showVatZoneField = true;
          } else {
            window.toastr["error"](response.data.message);
          }
        } else {
          if (obj && obj.name == "Send Customers") {
            this.selectedVatZone = "";
            this.vatZone = null;
            this.showVatZoneField = false;
          }
        }

        /*for products*/
        if (
          (obj &&
            obj.name == "Send Products" &&
            obj.assigned &&
            this.formData.integration.name == "e-conomic") ||
          (this.selectedProductGroupNumber != "" &&
            obj == null &&
            this.formData.integration.name == "e-conomic")
        ) {
          this.isLoading = true;
          let response = await axios.get("/api/settings/get-product-groups");

          if (response.data.success) {
            this.productGroups = response.data.productGroups;
            this.selectedProductGroupNumber = response.data.selectedProductGroupNumber;

            if (
              this.selectedProductGroupNumber == "" ||
              this.selectedProductGroupNumber == null
            ) {
              this.productGroup = response.data.productGroups[0];
            } else {
              this.productGroup = response.data.productGroups.find(
                // (customerGroup) => customerGroup.id == response.data.selectedCustomerGroupNumber
                (productGroup) => productGroup.id == this.selectedProductGroupNumber
              );
            }
            this.showProductNumberField = true;
          } else {
            window.toastr["error"](response.data.message);
          }
        } else {
          if (obj && obj.name == "Send Products") {
            this.selectedProductGroupNumber = "";
            this.productGroup = null;
            this.showProductNumberField = false;
          }
        }

        /*for invoices*/
        if (
          (obj &&
            obj.name == "Send Invoices" &&
            obj.assigned &&
            this.formData.integration.name == "e-conomic") ||
          (this.selectedLayoutGroupNumber != "" &&
            obj == null &&
            this.formData.integration.name == "e-conomic")
        ) {
          this.isLoading = true;
          let response = await axios.get("/api/settings/get-layouts");

          if (response.data.success) {
            this.layoutGroups = response.data.layoutGroups;
            this.selectedLayoutGroupNumber = response.data.selectedLayoutGroupNumber;

            if (
              this.selectedLayoutGroupNumber == "" ||
              this.selectedLayoutGroupNumber == null
            ) {
              this.layoutGroup = response.data.layoutGroups[0];
            } else {
              this.layoutGroup = response.data.layoutGroups.find(
                // (customerGroup) => customerGroup.id == response.data.selectedCustomerGroupNumber
                (layoutGroup) => layoutGroup.id == this.selectedLayoutGroupNumber
              );
            }
            this.showLayoutNumberField = true;
          } else {
            window.toastr["error"](response.data.message);
          }
        } else {
          if (obj && obj.name == "Send Invoices") {
            this.selectedLayoutGroupNumber = "";
            this.layoutGroup = null;
            this.showLayoutNumberField = false;
          }
        }
      } else {
        window.toastr["error"]("Please update API Key and Secret");
      }
      this.isLoading = false;
    },
  },
  computed: {
    makeDisable() {
      if (!this.isActive) {
        return { "mt-3 mb-3": true };
      } else {
        return { "mt-3 mb-3": true, "make-disabled": true };
      }
    },
  },
};
</script>

<style scoped>
.make-disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
