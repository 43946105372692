var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page login-3" }, [
    _c("div", { staticClass: "site-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "login-box",
          staticStyle: { margin: "0 auto", "max-width": "100%" },
        },
        [
          _c(
            "div",
            { staticClass: "box-wrapper" },
            [
              _c("router-view"),
              _vm._v(" "),
              _c("div", { staticClass: "page-copyright screens" }, [
                _c("p", { staticClass: "screens1" }, [
                  _vm._v(_vm._s(_vm.$t("layout_login.copyright_crater"))),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-box", staticStyle: { display: "none" } },
        [
          _c("h1", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("layout_login.super_simple_invoicing"))
            ),
            _c("br"),
            _vm._v(
              "\n        " + _vm._s(_vm.$t("layout_login.for_freelancer"))
            ),
            _c("br"),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("layout_login.small_businesses")) +
                " "
            ),
            _c("br"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("\n        " + _vm._s(_vm.$t("layout_login.crater_help"))),
            _c("br"),
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("layout_login.invoices_and_estimates"))
            ),
            _c("br"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content-bottom" }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }