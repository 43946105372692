import * as types from './mutation-types'

export const fetchActivities = ({ commit, dispatch, state }, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/activities`, {params}).then((response) => {
      commit(types.BOOTSTRAP_ACTIVITIES, response.data.activities.data)
      commit(types.SET_TOTAL_ACTIVITIES, response.data.activities.total)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const fetchActivity = ({ commit, dispatch }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/activities/${id}/edit`).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const addActivity = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/activities', data).then((response) => {
      commit(types.ADD_ACTIVITY, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const updateActivity = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.put(`/api/activities/${data.id}`, data).then((response) => {
      if(response.data.success){
        commit(types.UPDATE_ACTIVITY, response.data)
      }
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteActivity = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.delete(`/api/activities/${id}`).then((response) => {
      commit(types.DELETE_ACTIVITY, id)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const deleteMultipleActivities = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/activities/delete`, {'id': state.selectedActivities}).then((response) => {
      commit(types.DELETE_MULTIPLE_ACTIVITIES, state.selectedActivities)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const setSelectAllState = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECT_ALL_STATE, data)
}

export const selectAllActivities = ({ commit, dispatch, state }) => {
  if (state.selectedActivities.length === state.activities.length) {
    commit(types.SET_SELECTED_ACTIVITIES, [])
    commit(types.SET_SELECT_ALL_STATE, false)
  } else {
    let allActivityIds = state.activities.map(cust => cust.id)
    commit(types.SET_SELECTED_ACTIVITIES, allActivityIds)
    commit(types.SET_SELECT_ALL_STATE, true)
  }
}

export const selectActivity = ({ commit, dispatch, state }, data) => {
  commit(types.SET_SELECTED_ACTIVITIES, data)
  if (state.selectedActivities.length === state.activities.length) {
    commit(types.SET_SELECT_ALL_STATE, true)
  } else {
    commit(types.SET_SELECT_ALL_STATE, false)
  }
}

export const resetSelectedActivity = ({ commit, dispatch, state }, data) => {
  commit(types.RESET_SELECTED_ACTIVITY)
}
