<template>
  <div class="setting-main-container" style="padding: 30px">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t("wizard.bankInformation") }}
      </h3>
      <p class="page-sub-title">
        {{ $t("settings.bank_description") }}
      </p>
    </div>
    <div class="flex-box mb-4">
      <div class="left">
        <base-switch
          v-model="notify_bank"
          class="btn-switch"
          @change="setBankInformation"
        />
      </div>
      <div class="right">
        <p class="box-desc">{{ $t("settings.bankInfo.switch_desc") }}</p>
      </div>
    </div>
    <div class="tax-type-modal" style="position: none">
      <div class="card-body">
        <form action="" v-if="this.details" @submit.prevent="submitBankInformationData">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label input-label"
              >{{ $t("bank.name") }}
              <!--              <span class="required"> *</span>-->
            </label>
            <div class="col-sm-7">
              <base-input
                ref="name"
                :invalid="$v.formData.name.$error"
                v-model="formData.name"
                type="text"
                @input="$v.formData.name.$touch()"
              />
              <div v-if="$v.formData.name.$error">
                <span
                  v-if="!$v.formData.name.required"
                  class="form-group__message text-danger"
                  >{{ $tc("validation.required") }}</span
                >
                <span
                  v-if="!$v.formData.name.minLength"
                  class="form-group__message text-danger"
                >
                  {{
                    $tc(
                      "validation.name_min_length",
                      $v.formData.name.$params.minLength.min,
                      { count: $v.formData.name.$params.minLength.min }
                    )
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-2 col-form-label input-label"
              >{{ $t("settings.bankInformation.account_no") }}
              <!--              <span class="required"> *</span>-->
            </label>
            <div class="col-sm-7">
              <base-input
                ref="name"
                :invalid="$v.formData.account_no.$error"
                v-model="formData.account_no"
                type="text"
                @input="$v.formData.account_no.$touch()"
              />
              <div v-if="$v.formData.account_no.$error">
                <span
                  v-if="!$v.formData.account_no.required"
                  class="form-group__message text-danger"
                  >{{ $tc("validation.required") }}</span
                >
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label input-label"
              >{{ $t("settings.bankInformation.IBAN_no") }}
              <!--              <span class="required"> *</span>-->
            </label>
            <div class="col-sm-7">
              <base-input
                ref="name"
                :invalid="$v.formData.IBAN_no.$error"
                v-model="formData.IBAN_no"
                type="text"
                @input="$v.formData.IBAN_no.$touch()"
              />
              <div v-if="$v.formData.IBAN_no.$error">
                <span
                  v-if="!$v.formData.IBAN_no.required"
                  class="form-group__message text-danger"
                  >{{ $tc("validation.required") }}</span
                >
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label input-label"
              >{{ $t("settings.bankInformation.swift_code") }}
              <!--              <span class="required"> *</span>-->
            </label>
            <div class="col-sm-7">
              <base-input
                ref="name"
                :invalid="$v.formData.swift_code.$error"
                v-model="formData.swift_code"
                type="text"
                @input="$v.formData.swift_code.$touch()"
              />
              <div v-if="$v.formData.swift_code.$error">
                <span
                  v-if="!$v.formData.swift_code.required"
                  class="form-group__message text-danger"
                  >{{ $tc("validation.required") }}</span
                >
              </div>
            </div>
          </div>
          <!-- <div class="form-group row">
              <label class="col-sm-4 col-form-label input-label">{{
                $t("settings.bankInformation.default")
              }}</label>
              <div class="col-sm-7" style="margin-top: 1%">
                <base-switch
                  v-model="formData.default"
                  class="btn-switch"
                  @change="setDefault"
                  style="display: flex"
                />
              </div>
            </div> -->
          <!-- <div class="row">
            <base-button :loading="isLoading" color="theme" icon="save" type="submit">
              {{ !isEdit ? $t("general.save") : $t("general.update") }}
            </base-button>
          </div> -->
        </form>
      </div>
    </div>

    <div class="row" style="float: right">
      <div class="col-md-2" style="margin-right: 29%">
        <base-button
          :loading="loadingBack"
          class="pull-right"
          icon="arrow-left"
          color="theme"
          @click="back"
        >
          {{ $t("wizard.back") }}
        </base-button>
      </div>
      <div class="col-md-3">
        <base-button
          :loading="loading"
          class="pull-right"
          icon="arrow-right"
          rightIcon
          @click="next"
          color="theme"
        >
          {{ $t("wizard.continue") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required, requiredIf, minLength } = require("vuelidate/lib/validators");
const requiredIfRoot = requiredIf(function () {
  return this.subs;
});
export default {
  mixins: [validationMixin],
  data() {
    return {
      id: null,
      isEdit: false,
      notify_bank: true,
      loading: false,
      loadingBack: false,
      setFlag: false,
      isLoading: false,
      details: true,
      subs: true,
      formData: {
        notify_bank_data: null,
        id: null,
        name: null,
        account_no: null,
        IBAN_no: null,
        swift_code: null,
        default: true,
      },
    };
  },
  computed: {
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
    ...mapGetters("currency", [
      "defaultCurrency",
      "defaultCountry",
      "currencies",
      "defaultCurrencyForInput",
    ]),
  },
  validations: {
    formData: {
      name: {
        required: requiredIfRoot,
        minLength: minLength(3),
      },
      account_no: {
        required: requiredIfRoot,
        minLength: minLength(3),
      },
      IBAN_no: {
        required: requiredIfRoot,
        minLength: minLength(3),
      },
      swift_code: {
        required: requiredIfRoot,
        minLength: minLength(3),
      },
    },
  },
  watch: {
    // modalDataID(val) {
    //   if (val) {
    //     this.isEdit = true;
    //     this.setData();
    //   } else {
    //     this.isEdit = false;
    //   }
    // },
    // modalActive(val) {
    //   if (!this.modalActive) {
    //     this.resetFormData();
    //   }
    // },
  },
  async mounted() {
    // if (this.modalDataID) {
    //   this.isEdit = true;
    //   this.setData();
    // }
    this.fetchData();
  },
  methods: {
    ...mapActions("modal", ["closeModal", "resetModalData"]),
    ...mapActions("bankInformation", ["addBankInformation", "updateBankInformation"]),
    resetFormData() {
      this.formData = {
        id: null,
        name: null,
        account_no: null,
        IBAN_no: null,
        swift_code: null,
        default: true,
      };

      this.$v.formData.$reset();
    },

    setBankInformation() {
      this.details = !this.details;
      if (this.details == true) {
        this.subs = true;
      } else {
        this.subs = false;
        this.notify_bank = null;
        // this.resetFormData();
      }
    },

    async back() {
      this.loadingBack = true;
      let response = await window.axios.post("/api/admin/onboarding/templateBack");

      if (response.data.success) {
        this.$emit("back");
        this.loadingBack = false;
      }
    },

    async next() {
      ///////////////////////submit bank data

      this.$v.formData.$touch();

      if (this.$v.$invalid) {
        return true;
      }

      this.isLoading = true;
      let response;
      this.formData.notify_bank_data = this.notify_bank;

      try {
        if (this.formData.name == null && this.notify_bank == null) {
          this.loading = true;

          response = await window.axios.post(
            "/api/admin/onboarding/bankInformation",
            this.formData
          );

          if (response.data) {
            localStorage.setItem("onboarding", 10);
            this.$emit("next");
            this.loading = false;
          }

          return true;
        } else if (this.formData.name != null && this.notify_bank == null) {
          let data = {
            id: this.formData.id,
          };

          if (localStorage.getItem("bankInfo") !== null) {
            response = await window.axios.post(
              "/api/settings/delete-bank-information",
              data
            );
            if (response.data.success) {
              localStorage.removeItem("bankInfo");
              this.loading = true;
              response = await window.axios.post(
                "/api/admin/onboarding/bankInformation",
                this.formData
              );

              if (response.data) {
                localStorage.setItem("onboarding", 10);
                this.$emit("next");
                this.loading = false;
              }

              return true;
            }
          } else {
            response = await window.axios.post(
              "/api/admin/onboarding/bankInformation",
              this.formData
            );

            if (response.data) {
              localStorage.setItem("onboarding", 10);
              this.$emit("next");
              this.loading = false;
            }

            return true;
          }
        } else if (this.notify_bank == true) {
          response = await this.addBankInformation(this.formData);
          localStorage.setItem("bankInfo", true);
        }

        if (response.data.success) {
          this.loading = true;
          response = await window.axios.post(
            "/api/admin/onboarding/bankInformation",
            this.formData
          );

          if (response.data) {
            localStorage.setItem("onboarding", 10);
            this.$emit("next");
            this.loading = false;
          }

          this.isLoading = false;
          return true;
        }
      } catch (err) {
        this.isLoading = false;
      }
    },

    setDefault() {
      this.formData.default == false ? "YES" : "NO";
    },

    async setData() {
      this.formData = {
        id: this.modalData.id,
        name: this.modalData.name,
        account_no: this.modalData.account_no,
        IBAN_no: this.modalData.IBAN_no,
        swift_code: this.modalData.swift_code,
      };
      if (this.modalData.default === "NO") {
        this.formData.default = false;
      } else {
        this.formData.default = true;
      }
    },

    async fetchData() {
      this.isLoading = true;
      let response = await axios.get("/api/settings/get-bank-information");
      if (response.data.success) {
        if (
          response.data.information.name != null &&
          response.data.information.name != ""
        ) {
          this.notify_bank = true;
          this.details = true;
          this.subs = true;
        } else {
          this.notify_bank = null;
          this.details = false;
          this.subs = false;
        }

        this.isLoading = false;
        this.formData.id = response.data.information.id;
        this.formData.name = response.data.information.name;
        this.formData.account_no = response.data.information.account_no;
        this.formData.IBAN_no = response.data.information.IBAN_no;
        this.formData.swift_code = response.data.information.swift_code;
      }
    },

    closeBankInformationModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>

<style scoped>
.tax-type-modal .required {
  position: unset;
}

.tax-type-modal .input-label {
  text-align: unset;
}
</style>
