var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Customization")
    ? _c("div", { staticClass: "setting-main-container customization" }, [
        _c(
          "div",
          { staticClass: "card setting-card" },
          [
            _c("ul", { staticClass: "tabs" }, [
              _c(
                "li",
                {
                  staticClass: "tab",
                  on: {
                    click: function ($event) {
                      return _vm.setActiveTab("INVOICES")
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      class: [
                        "tab-link",
                        { "a-active": _vm.activeTab === "INVOICES" },
                      ],
                      attrs: { href: "#" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.invoices.title"))
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "tab",
                  on: {
                    click: function ($event) {
                      return _vm.setActiveTab("ESTIMATES")
                    },
                  },
                },
                [
                  _c(
                    "a",
                    {
                      class: [
                        "tab-link",
                        { "a-active": _vm.activeTab === "ESTIMATES" },
                      ],
                      attrs: { href: "#" },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("settings.customization.estimates.title"))
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isSubAdmin()
                ? _c(
                    "li",
                    {
                      staticClass: "tab",
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("CUSTOMERS")
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          class: [
                            "tab-link",
                            { "a-active": _vm.activeTab === "CUSTOMERS" },
                          ],
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.customization.customers.title")
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin()
                ? _c(
                    "li",
                    {
                      staticClass: "tab",
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("PAYMENTS")
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          class: [
                            "tab-link",
                            { "a-active": _vm.activeTab === "PAYMENTS" },
                          ],
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.customization.payments.title")
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isSubAdmin()
                ? _c(
                    "li",
                    {
                      staticClass: "tab",
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("ITEMS")
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          class: [
                            "tab-link",
                            { "a-active": _vm.activeTab === "ITEMS" },
                          ],
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("settings.customization.items.title"))
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdmin()
                ? _c(
                    "li",
                    {
                      staticClass: "tab",
                      on: {
                        click: function ($event) {
                          return _vm.setActiveTab("INSTALLMENTS")
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          class: [
                            "tab-link",
                            { "a-active": _vm.activeTab === "INSTALLMENTS" },
                          ],
                          attrs: { href: "#" },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.customization.installment.title")
                            )
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-customize" } }, [
              _vm.activeTab === "INVOICES"
                ? _c("div", { staticClass: "invoice-tab" }, [
                    _c(
                      "form",
                      {
                        staticClass: "mt-3",
                        attrs: { action: "" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updateInvoiceSetting.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-4" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.invoices.invoice_prefix"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                staticClass: "prefix-input",
                                attrs: {
                                  invalid:
                                    _vm.$v.invoices.invoice_prefix.$error,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.invoices.invoice_prefix.$touch()
                                  },
                                  keyup: function ($event) {
                                    return _vm.changeToUppercase("INVOICES")
                                  },
                                },
                                model: {
                                  value: _vm.invoices.invoice_prefix,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.invoices,
                                      "invoice_prefix",
                                      $$v
                                    )
                                  },
                                  expression: "invoices.invoice_prefix",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.$v.invoices.invoice_prefix
                                          .required,
                                      expression:
                                        "!$v.invoices.invoice_prefix.required",
                                    },
                                  ],
                                  staticClass: "text-danger mt-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("validation.required")))]
                              ),
                              _vm._v(" "),
                              !_vm.$v.invoices.invoice_prefix.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("validation.prefix_maxlength")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.$v.invoices.invoice_prefix.alpha
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("validation.characters_only")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row pb-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: {
                                    icon: "save",
                                    color: "theme",
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("settings.customization.save")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-customize" } }, [
              _vm.activeTab === "ESTIMATES"
                ? _c("div", { staticClass: "estimate-tab" }, [
                    _c(
                      "form",
                      {
                        staticClass: "mt-3",
                        attrs: { action: "" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updateEstimateSetting.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-4" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.estimates.estimate_prefix"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                staticClass: "prefix-input",
                                attrs: {
                                  invalid:
                                    _vm.$v.estimates.estimate_prefix.$error,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.estimates.estimate_prefix.$touch()
                                  },
                                  keyup: function ($event) {
                                    return _vm.changeToUppercase("ESTIMATES")
                                  },
                                },
                                model: {
                                  value: _vm.estimates.estimate_prefix,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.estimates,
                                      "estimate_prefix",
                                      $$v
                                    )
                                  },
                                  expression: "estimates.estimate_prefix",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.$v.estimates.estimate_prefix
                                          .required,
                                      expression:
                                        "!$v.estimates.estimate_prefix.required",
                                    },
                                  ],
                                  staticClass: "text-danger mt-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("validation.required")))]
                              ),
                              _vm._v(" "),
                              !_vm.$v.estimates.estimate_prefix.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("validation.prefix_maxlength")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.$v.estimates.estimate_prefix.alpha
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("validation.characters_only")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row pb-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: {
                                    icon: "save",
                                    color: "theme",
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("settings.customization.save")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-customize" } }, [
              _vm.activeTab === "CUSTOMERS"
                ? _c("div", { staticClass: "invoice-tab" }, [
                    _c(
                      "form",
                      {
                        staticClass: "mt-3",
                        attrs: { action: "" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.updateCustomersSetting.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-4" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.customers.customer_prefix"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                staticClass: "prefix-input",
                                attrs: {
                                  invalid:
                                    _vm.$v.customers.customer_prefix.$error,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.customers.customer_prefix.$touch()
                                  },
                                  keyup: function ($event) {
                                    return _vm.changeToUppercase("CUSTOMERS")
                                  },
                                },
                                model: {
                                  value: _vm.customers.customer_prefix,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.customers,
                                      "customer_prefix",
                                      $$v
                                    )
                                  },
                                  expression: "customers.customer_prefix",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.$v.customers.customer_prefix
                                          .required,
                                      expression:
                                        "!$v.customers.customer_prefix.required",
                                    },
                                  ],
                                  staticClass: "text-danger mt-1",
                                },
                                [_vm._v(_vm._s(_vm.$t("validation.required")))]
                              ),
                              _vm._v(" "),
                              !_vm.$v.customers.customer_prefix.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("validation.prefix_maxlength")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.$v.customers.customer_prefix.alpha
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("validation.characters_only")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row pb-3" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: {
                                    icon: "save",
                                    color: "theme",
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("settings.customization.save")
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-customize" } }, [
              _vm.activeTab === "PAYMENTS"
                ? _c(
                    "div",
                    { staticClass: "payment-tab" },
                    [
                      _c("div", { staticClass: "page-header" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-8" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-4 d-flex flex-row-reverse" },
                            [
                              _c(
                                "base-button",
                                {
                                  staticClass: "add-new-tax",
                                  attrs: { outline: "", color: "theme" },
                                  on: { click: _vm.addPaymentMode },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.customization.payments.add_payment_mode"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "table-component",
                        {
                          ref: "table",
                          staticClass: "mb-3",
                          attrs: {
                            "show-filter": false,
                            data: _vm.paymentModes,
                            "table-class": "table tax-table",
                          },
                        },
                        [
                          _c("table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t(
                                "settings.customization.payments.payment_mode"
                              ),
                              show: "name",
                            },
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              sortable: false,
                              filterable: false,
                              "cell-class": "action-dropdown",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("settings.tax_types.action")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-dropdown",
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                href: "#",
                                              },
                                              slot: "activator",
                                            },
                                            [_c("dot-icon")],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editPaymentMode(
                                                      row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "pencil-alt"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.edit")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePaymentMode(
                                                      row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "trash"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.delete")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2603584903
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "pt-3",
                          attrs: { action: "" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.updatePaymentSetting.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12 mb-4" },
                              [
                                _c("label", { staticClass: "input-label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customization.payments.payment_prefix"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("base-input", {
                                  staticClass: "prefix-input",
                                  attrs: {
                                    invalid:
                                      _vm.$v.payments.payment_prefix.$error,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.payments.payment_prefix.$touch()
                                    },
                                    keyup: function ($event) {
                                      return _vm.changeToUppercase("PAYMENTS")
                                    },
                                  },
                                  model: {
                                    value: _vm.payments.payment_prefix,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.payments,
                                        "payment_prefix",
                                        $$v
                                      )
                                    },
                                    expression: "payments.payment_prefix",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          !_vm.$v.payments.payment_prefix
                                            .required,
                                        expression:
                                          "!$v.payments.payment_prefix.required",
                                      },
                                    ],
                                    staticClass: "text-danger mt-1",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("validation.required"))
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.$v.payments.payment_prefix.maxLength
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("validation.prefix_maxlength")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.$v.payments.payment_prefix.alpha
                                  ? _c("span", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("validation.characters_only")
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row pb-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-12" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: {
                                      icon: "save",
                                      color: "theme",
                                      type: "submit",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("settings.customization.save")
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("div", { staticClass: "page-header pt-3" }, [
                        _c("h3", { staticClass: "page-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "settings.customization.payments.payment_settings"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-box" }, [
                          _c(
                            "div",
                            { staticClass: "left" },
                            [
                              _c("base-switch", {
                                staticClass: "btn-switch",
                                on: { change: _vm.setPaymentSetting },
                                model: {
                                  value: _vm.paymentAutogenerate,
                                  callback: function ($$v) {
                                    _vm.paymentAutogenerate = $$v
                                  },
                                  expression: "paymentAutogenerate",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "right ml-15" }, [
                            _c("p", { staticClass: "box-title" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.payments.autogenerate_payment_number"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "box-desc" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "settings.customization.payments.payment_setting_description"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-customize" } }, [
              _vm.activeTab === "ITEMS"
                ? _c(
                    "div",
                    { staticClass: "item-tab" },
                    [
                      _c("div", { staticClass: "page-header" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-8" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-4 d-flex flex-row-reverse" },
                            [
                              _c(
                                "base-button",
                                {
                                  staticClass: "add-new-tax",
                                  attrs: { outline: "", color: "theme" },
                                  on: { click: _vm.addItemUnit },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.customization.items.add_item_unit"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "table-component",
                        {
                          ref: "itemTable",
                          staticClass: "mb-3",
                          attrs: {
                            "show-filter": false,
                            data: _vm.itemUnits,
                            "table-class": "table tax-table",
                          },
                        },
                        [
                          _c("table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t(
                                "settings.customization.items.units"
                              ),
                              show: "name",
                            },
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              sortable: false,
                              filterable: false,
                              "cell-class": "action-dropdown",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("settings.tax_types.action")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-dropdown",
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                href: "#",
                                              },
                                              slot: "activator",
                                            },
                                            [_c("dot-icon")],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editItemUnit(row)
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "pencil-alt"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.edit")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeItemUnit(
                                                      row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "trash"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.delete")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              44931527
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade-customize" } }, [
              _vm.activeTab === "INSTALLMENTS"
                ? _c(
                    "div",
                    { staticClass: "payment-tab" },
                    [
                      _c("div", { staticClass: "page-header" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-8" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-4 d-flex flex-row-reverse" },
                            [
                              _c(
                                "base-button",
                                {
                                  staticClass: "add-new-tax",
                                  attrs: { outline: "", color: "theme" },
                                  on: { click: _vm.addInstallmentMonth },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "settings.customization.installment.add_installment_month"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "table-component",
                        {
                          ref: "installmentTable",
                          staticClass: "mb-3",
                          attrs: {
                            "show-filter": false,
                            data: _vm.installments,
                            "table-class": "table tax-table",
                          },
                        },
                        [
                          _c("table-column", {
                            attrs: {
                              sortable: true,
                              label: _vm.$t(
                                "settings.customization.installment.title"
                              ),
                              show: "month",
                            },
                          }),
                          _vm._v(" "),
                          _c("table-column", {
                            attrs: {
                              sortable: false,
                              filterable: false,
                              "cell-class": "action-dropdown",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (row) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("settings.tax_types.action")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-dropdown",
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                href: "#",
                                              },
                                              slot: "activator",
                                            },
                                            [_c("dot-icon")],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editInstallmentMonth(
                                                      row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "pencil-alt"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.edit")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("v-dropdown-item", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "dropdown-item",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeInstallmentMonth(
                                                      row.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass:
                                                    "dropdown-item-icon",
                                                  attrs: {
                                                    icon: ["fas", "trash"],
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("general.delete")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1410559815
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ])
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }