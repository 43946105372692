var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Customers")
    ? _c(
        "div",
        { staticClass: "customer-create main-content" },
        [
          _c("div", { staticClass: "page-header" }, [
            _c("h3", { staticClass: "page-title" }, [
              _vm._v(_vm._s(_vm.$t("customers.title"))),
            ]),
            _vm._v(" "),
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "dashboard" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("general.home")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "#" },
                      slot: "item-title",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("customers.customer", 2)) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-actions row" },
              [
                _vm.$can("Import Users")
                  ? _c(
                      "div",
                      { staticClass: "col-xs-2 mr-4" },
                      [
                        _c(
                          "base-button",
                          {
                            attrs: {
                              outline: true,
                              size: "large",
                              color: "theme",
                              "right-icon": "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.importCustomer()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("navigation.importUsers")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-2 mr-4" },
                  [
                    _c(
                      "base-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.totalCustomers || _vm.filtersApplied,
                            expression: "totalCustomers || filtersApplied",
                          },
                        ],
                        attrs: {
                          outline: true,
                          icon: _vm.filterIcon,
                          size: "large",
                          color: "theme",
                          "right-icon": "",
                        },
                        on: { click: _vm.toggleFilter },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("general.filter")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "col-xs-2",
                    attrs: { slot: "item-title", to: "customers/create" },
                    slot: "item-title",
                  },
                  [
                    _c(
                      "base-button",
                      {
                        attrs: { size: "large", icon: "plus", color: "theme" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("customers.new_customer")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilters,
                    expression: "showFilters",
                  },
                ],
                staticClass: "filter-section",
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(_vm._s(_vm.$t("customers.display_name"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          type: "text",
                          name: "name",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.display_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "display_name", $$v)
                          },
                          expression: "filters.display_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(_vm._s(_vm.$t("customers.email"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          type: "text",
                          name: "email",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "email", $$v)
                          },
                          expression: "filters.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v(_vm._s(_vm.$t("customers.phone"))),
                      ]),
                      _vm._v(" "),
                      _c("base-input", {
                        attrs: {
                          type: "number",
                          name: "phone",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.filters.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "phone", $$v)
                          },
                          expression: "filters.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c("label", { staticClass: "form-label" }),
                      _vm._v(" "),
                      _c(
                        "base-button",
                        {
                          staticStyle: { "margin-top": "3%", height: "40px" },
                          attrs: { size: "large", color: "theme" },
                          on: { click: _vm.refreshFilterTable },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("navigation.applyFilters")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "clear-filter",
                      on: { click: _vm.clearFilter },
                    },
                    [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEmptyScreen,
                  expression: "showEmptyScreen",
                },
              ],
              staticClass: "col-xs-1 no-data-info",
              attrs: { align: "center" },
            },
            [
              _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c("div", { staticClass: "row", attrs: { align: "center" } }, [
                _c("label", { staticClass: "col title" }, [
                  _vm._v(_vm._s(_vm.$t("customers.no_customers"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "label",
                  {
                    staticClass: "description col mt-1",
                    attrs: { align: "center" },
                  },
                  [_vm._v(_vm._s(_vm.$t("customers.list_of_customers")))]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn-container" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "mt-3",
                      attrs: { outline: true, color: "theme", size: "large" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push("customers/create")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("customers.add_new_customer")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showEmptyScreen,
                  expression: "!showEmptyScreen",
                },
              ],
              staticClass: "table-container",
            },
            [
              _c(
                "div",
                { staticClass: "table-actions mt-5" },
                [
                  _c("p", { staticClass: "table-stats" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("general.showing")) + ": "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.customers.length))]),
                    _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                    _c("b", [_vm._v(_vm._s(_vm.totalCustomers))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.selectedCustomers.length
                        ? _c(
                            "v-dropdown",
                            { attrs: { "show-arrow": false } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "table-actions-button dropdown-toggle",
                                  attrs: { slot: "activator", href: "#" },
                                  slot: "activator",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("general.actions")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown-item",
                                    on: { click: _vm.removeMultipleCustomers },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: ["fas", "trash"] },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("general.delete")) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table-component",
                {
                  ref: "table",
                  attrs: {
                    "show-filter": false,
                    data: _vm.fetchData,
                    "table-class": "table",
                  },
                },
                [
                  _c("table-column", {
                    staticStyle: { "white-space": "nowrap" },
                    attrs: {
                      label: _vm.$t("customers.customer_number"),
                      show: "customer_number",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    staticStyle: { "white-space": "nowrap" },
                    attrs: {
                      label: _vm.$t("customers.display_name"),
                      show: "name",
                    },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    staticStyle: { "white-space": "nowrap" },
                    attrs: { label: _vm.$t("customers.email"), show: "email" },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    staticStyle: { "white-space": "nowrap" },
                    attrs: { label: _vm.$t("customers.phone"), show: "phone" },
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    staticStyle: { "white-space": "nowrap" },
                    attrs: {
                      label: _vm.$t("customers.amount_due"),
                      show: "due_amount",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("customers.amount_due")) +
                                    " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$utils.formatMoney(
                                      row.due_amount,
                                      row.currency
                                    )
                                  ),
                                },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      82697454
                    ),
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      sortable: false,
                      filterable: false,
                      "cell-class": "action-dropdown",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (row) {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("customers.action")) + " "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-dropdown",
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { slot: "activator", href: "#" },
                                      slot: "activator",
                                    },
                                    [_c("dot-icon")],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path:
                                                "invoices/create?customer=" +
                                                row.id,
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: {
                                              icon: ["fas", "file-alt"],
                                            },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("invoices.new_invoice")
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path:
                                                "estimates/create?customer=" +
                                                row.id,
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: { icon: ["fas", "file"] },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("estimates.new_estimate")
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: { to: { path: "payments" } },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: {
                                              icon: ["fas", "credit-card"],
                                            },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("navigation.payments")
                                              ) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-dropdown-item",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "dropdown-item",
                                          attrs: {
                                            to: {
                                              path:
                                                "customers/" + row.id + "/edit",
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "dropdown-item-icon",
                                            attrs: {
                                              icon: ["fas", "pencil-alt"],
                                            },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("general.edit")) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-dropdown-item", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCustomer(row.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "dropdown-item-icon",
                                          attrs: { icon: ["fas", "trash"] },
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("general.delete")) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      872985093
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }