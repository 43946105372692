<template>
  <div class="setting-main-container" style="padding: 30px">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t("wizard.template") }}
      </h3>
    </div>

    <!-- <div class="section-title col-sm-2">{{ $t("subAdmins.basic_info") }}</div> -->

    <div>
      <div class="form-group">
        <!-- <div>
            <label class="mt-3 mb-1 d-block"
              >{{ $t("default.invoice_template") }}
              <span class="text-danger"> * </span></label
            >
            <span class="mr-4"> -->
        <!-- {{ $t("invoices.template") }}  -->
        <!-- {{ getTemplateName ? getTemplateName : $t("invoices.template") }} -->
        <!-- {{ getTemplateName ? getTemplateName : "Template 1" }}
            </span> -->
        <!-- <base-button
              type="button"
              class="btn-template"
              icon="pencil-alt"
              right-icon
              @click="openTemplateModal"
              style="
                width: 100%;
                background-color: white;
                color: black;
                border: 1px solid #ebf1fa;
              "
            > -->

        <!-- ///////////////////// -->

        <!-- <div class="card-footer">
              <base-button outline class="mr-3" color="theme" @click="closeInvoiceModal">
                {{ $t("general.cancel") }}
              </base-button>
              <base-button
                v-if="this.invoiceTemplates.length > 0"
                :loading="isLoading"
                color="theme"
                @click="chooseTemplate()"
              >
                {{ $t("general.choose") }}
              </base-button>
            </div> -->

        <!-- ///////////////////////////// -->

        <!-- </base-button> -->
        <!-- </div> -->
        <div class="card-body">
          <div
            v-if="this.invoiceTemplates.length > 0"
            class="template-container"
            style="display: flex; flex-wrap: wrap; justify-content: space-between"
          >
            <div
              v-for="(template, index) in this.invoiceTemplates"
              :key="index"
              :class="{ 'selected-template': selectedTemplate === template.id }"
              class="template-img"
              style="max-width: 30%; position: relative; border: 1px solid #0373ff"
            >
              <img
                :src="template.path"
                alt="template-image"
                @click="chooseTemplate(template.id)"
                style="margin: 0px 0px 0px 0px"
              />
              <div
                v-if="selectedTemplate === template.id"
                style="
                  position: absolute;
                  right: -10px;
                  top: -10px;
                  z-index: 1;
                  width: 26px;
                  height: 26px;
                  border: 1px solid #fff;
                  border-radius: 50%;
                  background: #fff;
                "
              >
                <img
                  class="check-icon"
                  height="25"
                  width="25"
                  src="/assets/img/tick.png"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <p><center>No Templates Available</center></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="float: right">
      <div class="col-md-2" style="margin-right: 29%">
        <base-button
          :loading="loadingBack"
          class="pull-right"
          icon="arrow-left"
          color="theme"
          @click="back"
        >
          {{ $t("wizard.back") }}
        </base-button>
      </div>
      <div class="col-md-3">
        <base-button
          :loading="loading"
          class="pull-right"
          icon="arrow-right"
          rightIcon
          @click="next"
          color="theme"
        >
          <!--          {{ $t("wizard.save_cont") }}-->
          {{ $t("wizard.continue") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MultiSelect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import AddressStub from "../../stub/address";
const {
  required,
  requiredIf,
  sameAs,
  minLength,
  email,
  url,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  components: { MultiSelect },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      loadingBack: false,
      isLoading: false,
      invoiceTemplates: [],
      selectedTemplate: 1,
      formData: {
        invoice_template_id: 1,
      },
    };
  },

  computed: {
    ...mapGetters("currency", ["defaultCurrency", "currencies"]),
    ...mapGetters("invoice", ["getTemplateId", "selectedCustomer", "getTemplateName"]),
    ...mapGetters("subAdmin", ["getTemplateId", "getTemplateName"]),
  },

  mounted() {
    // this.fetchCountry();
    // this.fetchDefaultCountry();
    this.selectedTemplate = this.getTemplateId;
    this.docTemplates();
    // if (this.isEdit) {
    //   /*for website validation in edit*/
    //   this.formData.isValid = true;

    //   this.loadSubAdmin();
    // } else {
    //   this.currency = this.defaultCurrency;
    // }
  },
  methods: {
    ...mapActions("modal", ["openModal"]),
    ...mapActions("invoice", ["setTemplate"]),

    async chooseTemplate(id) {
      this.selectedTemplate = id;
      this.formData.invoice_template_id = id;
      this.isLoading = true;
      const template_id = id;
      let data = {
        template_id,
      };

      let resp = await this.setTemplate(data);
      if (resp) {
        this.isLoading = false;

        // this.resetModalData();
        // this.closeModal();
      }
    },

    closeInvoiceModal() {
      this.selectedTemplate = this.getTemplateId;
      // this.closeModal();
      // this.resetModalData();
    },

    async back() {
      this.loadingBack = true;
      let response = await window.axios.post("/api/admin/onboarding/integrationBack");

      if (response.data.success) {
        this.$emit("back");
        this.loadingBack = false;
      }
    },

    async next() {
      this.loading = true;
      let response = await window.axios.post(
        "/api/admin/onboarding/template",
        this.formData
      );

      if (response.data) {
        localStorage.setItem("onboarding", 9);
        this.$emit("next");
        this.loading = false;
      }
    },

    openTemplateModal() {
      this.openModal({
        title: this.$t("general.choose_template"),
        componentName: "SubAdminTemplate",
        data: this.invoiceTemplates,
      });
    },

    ...mapActions("subAdmin", [
      "addSubAdmin",
      "fetchSubAdmin",
      "updateSubAdmin",
      "fetchTemplates",
    ]),

    async docTemplates() {
      let {
        data: { invoiceTemplates, selectedTemplate },
      } = await this.fetchTemplates();
      // console.log(color);
      this.invoiceTemplates = invoiceTemplates;
      this.selectedTemplate = selectedTemplate;
    },

    async submitSubAdminData() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return true;
      }
      this.formData.invoice_template_id = this.getTemplateId;
      this.isLoading = true;

      try {
        let response = await window.axios.post(
          "/api/admin/onboarding/template",
          this.formData
        );

        // console.log(response);
        if (response.data.success) {
          window.toastr["success"](this.$t("subAdmins.created_message"));
          this.$router.push("/admin/subAdmins");
          this.isLoading = false;
          return true;
        }
      } catch (err) {
        this.isLoading = false;
        /*if (err.response.data.errors.email) {
            this.isLoading = false;
            window.toastr["error"](this.$t("validation.email_already_taken"));
          }*/
      }
    },
  },
};
</script>
