var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profit-loss-reports reports main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$tc("reports.report", 2))),
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "/system/reports/sales" },
                  slot: "item-title",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$tc("reports.report", 2)) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }, [
          _c(
            "div",
            { staticClass: "col-xs-2" },
            [
              _c(
                "base-button",
                {
                  attrs: { icon: "download", size: "large", color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.onDownload()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("reports.download_pdf")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("ul", { staticClass: "tabs" }, [
            !_vm.isAdmin()
              ? _c(
                  "li",
                  { staticClass: "tab" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "tab-link",
                        attrs: { to: "/system/reports/sales" },
                      },
                      [_vm._v(_vm._s(_vm.$t("reports.sales.sales")))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "tab" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "tab-link",
                    attrs: { to: "/system/reports/profit-loss" },
                  },
                  [_vm._v(_vm._s(_vm.$t("reports.profit_loss.profit_loss")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isAdmin()
              ? _c(
                  "li",
                  { staticClass: "tab" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "tab-link",
                        attrs: { to: "/system/reports/taxes" },
                      },
                      [_vm._v(_vm._s(_vm.$t("reports.taxes.taxes")))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view", { ref: "report" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }