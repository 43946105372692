var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper fadeInDown" }, [
    _c("div", { attrs: { id: "formContent" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "loginForm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.validateBeforeSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "fadeIn second", class: { "form-group": true } },
            [
              _c("p", { staticClass: "input-label input-fields" }, [
                _vm._v("\n          " + _vm._s(_vm.$t("login.email")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                staticClass: "input-fields",
                attrs: {
                  invalid: _vm.$v.loginData.email.$error,
                  placeholder: _vm.$t(_vm.login.login_placeholder),
                  focus: "",
                  id: "login",
                  type: "email",
                  name: "email",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.loginData.email.$touch()
                  },
                },
                model: {
                  value: _vm.loginData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginData, "email", $$v)
                  },
                  expression: "loginData.email",
                },
              }),
              _vm._v(" "),
              _vm.$v.loginData.email.$error
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "margin-left": "5%",
                      },
                    },
                    [
                      !_vm.$v.loginData.email.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tc("validation.required")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.loginData.email.email
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tc("validation.email_incorrect")) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group fadeIn third" },
            [
              _c("p", { staticClass: "input-label input-fields" }, [
                _vm._v("\n          " + _vm._s(_vm.$t("login.password")) + " "),
                _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              ]),
              _vm._v(" "),
              _c("base-input", {
                staticClass: "input-fields",
                attrs: {
                  invalid: _vm.$v.loginData.password.$error,
                  type: "password",
                  name: "password",
                  id: "password",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.loginData.password.$touch()
                  },
                },
                model: {
                  value: _vm.loginData.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.loginData, "password", $$v)
                  },
                  expression: "loginData.password",
                },
              }),
              _vm._v(" "),
              _vm.$v.loginData.password.$error
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "margin-left": "5%",
                      },
                    },
                    [
                      !_vm.$v.loginData.password.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$tc("validation.required"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.loginData.password.minLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tc(
                                    "validation.password_min_length",
                                    _vm.$v.loginData.password.$params.minLength
                                      .min,
                                    {
                                      count:
                                        _vm.$v.loginData.password.$params
                                          .minLength.min,
                                    }
                                  )
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "base-button",
            {
              staticClass: "fadeIn fourth",
              staticStyle: { "margin-left": "5%", "margin-bottom": "4%" },
              attrs: { loading: _vm.isLoading, type: "submit", color: "theme" },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("login.login")) + "\n      ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "formFooter" } }, [
        _c(
          "div",
          {
            staticClass: "col-sm-12 text-sm-center mb-4",
            staticStyle: { "margin-top": "5%" },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "forgot-link fadeIn fifth",
                staticStyle: { color: "#0373ff" },
                attrs: { to: "forgot-password" },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("login.forgot_password")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "forgot-link fadeIn fifth",
                staticStyle: { color: "#0373ff" },
                attrs: { to: "sign-up" },
              },
              [
                _c("span", { staticStyle: { "margin-top": "10%" } }, [
                  _vm._v(_vm._s(_vm.$t("login.signUp"))),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fadeIn first" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("img", {
          staticStyle: {
            "margin-top": "15%",
            "margin-bottom": "-17%",
            "padding-bottom": "30%",
            width: "30%",
          },
          attrs: { src: "/assets/img/faktura-logo.png", alt: "Faktura Logo" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }