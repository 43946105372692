var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-create main-content" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitRoleData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("language.edit_language")
                    : _vm.$t("language.add_new_language")
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/system/dashboard" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/system/language" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$tc("language.title", 2)))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("language.edit_language")
                      : _vm.$t("language.add_new_language")
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions header-button-container" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    tabindex: 23,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("language.update_language")
                          : _vm.$t("language.save_language")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "customer-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(_vm._s(_vm.$t("language.title"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm.$route.name !== "language.edit"
                      ? _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("language.parent"))),
                        ])
                      : _vm._e(),
                    _vm.$route.name !== "language.edit"
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v("\n                *"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$route.name !== "language.edit"
                      ? _c("base-input", {
                          attrs: {
                            invalid: _vm.$v.formData.parent.$error,
                            focus: "",
                            type: "text",
                            name: "parent",
                            "tab-index": "1",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.parent.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.parent,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "parent", $$v)
                            },
                            expression: "formData.parent",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.formData.parent.$error &&
                    _vm.$route.name !== "language.edit"
                      ? _c("div", [
                          !_vm.$v.formData.parent.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$route.name !== "language.edit"
                      ? _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("language.key"))),
                        ])
                      : _vm._e(),
                    _vm.$route.name !== "language.edit"
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v("\n                *"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$route.name !== "language.edit"
                      ? _c("base-input", {
                          attrs: {
                            invalid: _vm.$v.formData.key.$error,
                            focus: "",
                            type: "text",
                            name: "key",
                            "tab-index": "1",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.formData.key.$touch()
                            },
                          },
                          model: {
                            value: _vm.formData.key,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "key", $$v)
                            },
                            expression: "formData.key",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$v.formData.key.$error &&
                    _vm.$route.name !== "language.edit"
                      ? _c("div", [
                          !_vm.$v.formData.key.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("language.english"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.value_en.$error,
                        focus: "",
                        type: "text",
                        name: "value_en",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.value_en.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.value_en,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "value_en", $$v)
                        },
                        expression: "formData.value_en",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.value_en.$error
                      ? _c("div", [
                          !_vm.$v.formData.value_en.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.value_en.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.value_en.$params
                                          .minLength.min,
                                        {
                                          count:
                                            _vm.$v.formData.value_en.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("language.denmark"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.value_da.$error,
                        focus: "",
                        type: "text",
                        name: "value_da",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.value_da.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.value_da,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "value_da", $$v)
                        },
                        expression: "formData.value_da",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.value_da.$error
                      ? _c("div", [
                          !_vm.$v.formData.value_da.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.value_da.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.value_da.$params
                                          .minLength.min,
                                        {
                                          count:
                                            _vm.$v.formData.value_da.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }