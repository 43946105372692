import * as types from './mutation-types'

export const loadData = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/settings/company`).then((response) => {
      commit(types.SET_COMPANY, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const loadDataHeader = ({ commit, dispatch, state }, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/settings/company`).then((response) => {
      commit(types.SET_COMPANY, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const editCompany = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/settings/company', data).then((response) => {
      commit(types.UPDATE_COMPANY, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const uploadLogo = ({ commit, dispatch, state }, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/api/settings/company/upload-logo', data).then((response) => {
      commit(types.UPDATE_LOGO, response.data.user.company.logo)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const setSelectedCompanyLogo = ({ commit, dispatch, state }, data) => {
  commit(types.UPDATE_LOGO, data)
}
