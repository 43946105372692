<template>
  <div class="customer-create main-content" v-if="$can('Permissions')">
    <div class="page-header">
      <h3 class="page-title">{{ $t("permissions.title") }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("permissions.permission", 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row">
        <div class="col-xs-2 mr-4">
          <base-button
            v-show="totalPermissions || filtersApplied"
            :outline="true"
            :icon="filterIcon"
            size="large"
            color="theme"
            right-icon
            @click="toggleFilter"
          >
            {{ $t("general.filter") }}
          </base-button>
        </div>
        <router-link slot="item-title" class="col-xs-2" to="permissions/create">
          <base-button size="large" icon="plus" color="theme">
            {{ $t("permissions.new_permission") }}
          </base-button>
        </router-link>
      </div>
    </div>

    <transition name="fade">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
          <div class="col-sm-9">
            <label class="form-label">{{ $t("permissions.display_name") }}</label>
            <base-input
              v-model="filters.name"
              type="text"
              name="name"
              autocomplete="off"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label"></label>
            <base-button
              @click="refreshFilterTable"
              style="margin-top: 3%; height: 40px"
              size="large"
              color="theme"
            >
              {{ $t("navigation.applyFilters") }}
            </base-button>
          </div>
          <label class="clear-filter" @click="clearFilter">{{
            $t("general.clear_all")
          }}</label>
        </div>
      </div>
    </transition>

    <div v-cloak v-show="showEmptyScreen" class="col-xs-1 no-data-info" align="center">
      <astronaut-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t("permissions.no_permissions") }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t("permissions.list_of_permissions")
        }}</label>
      </div>
      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('permissions/create')"
        >
          {{ $t("permissions.add_new_permission") }}
        </base-button>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t("general.showing") }}: <b>{{ permissions.length }}</b>
          {{ $t("general.of") }} <b>{{ totalPermissions }}</b>
        </p>

        <transition name="fade">
          <v-dropdown v-if="selectedPermissions.length" :show-arrow="false">
            <span slot="activator" href="#" class="table-actions-button dropdown-toggle">
              {{ $t("general.actions") }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultiplePermissions">
                <font-awesome-icon :icon="['fas', 'trash']" class="dropdown-item-icon" />
                {{ $t("general.delete") }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <div class="custom-control custom-checkbox">
        <input
          id="select-all"
          v-model="selectAllFieldStatus"
          type="checkbox"
          class="custom-control-input"
          @change="selectAllPermissions"
        />
        <label for="select-all" class="custom-control-label selectall">
          <span class="select-all-label">{{ $t("general.select_all") }} </span>
        </label>
      </div>

      <table-component
        ref="table"
        :show-filter="false"
        :data="fetchData"
        table-class="table"
      >
        <table-column :sortable="false" :filterable="false" cell-class="no-click">
          <template slot-scope="row">
            <div class="custom-control custom-checkbox">
              <input
                :id="row.id"
                v-model="selectField"
                :value="row.id"
                type="checkbox"
                class="custom-control-input"
              />
              <label :for="row.id" class="custom-control-label" />
            </div>
          </template>
        </table-column>
        <table-column :label="$t('permissions.name')" show="name" />

        <table-column :label="$t('permissions.set_order')" :sortable="false" :filterable="false" cell-class="action-dropdown">
          <template slot-scope="row">
            <v-dropdown>
              <a slot="activator" href="#" @click="setOrder('up' ,`${row.id}`, `${row.per_order}`)">
                <arrow-up />
              </a>
              <a slot="activator" href="#" @click="setOrder('down' ,`${row.id}`, `${row.per_order}`)">
                <arrow-down />
              </a>
            </v-dropdown>
          </template>
        </table-column>

        <table-column
          :label="$t('permissions.added_on')"
          sort-as="created_at"
          show="formattedCreatedAt"
        />
        <table-column :sortable="false" :filterable="false" cell-class="action-dropdown">
          <template slot-scope="row">
            <span> {{ $t("permissions.action") }} </span>
            <v-dropdown>
              <a slot="activator" href="#">
                <dot-icon />
              </a>
              <v-dropdown-item>
                <router-link
                  :to="{ path: `permissions/${row.id}/edit` }"
                  class="dropdown-item"
                >
                  <font-awesome-icon
                    :icon="['fas', 'pencil-alt']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.edit") }}
                </router-link>
              </v-dropdown-item>
              <v-dropdown-item>
                <div class="dropdown-item" @click="removePermission(row.id)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="dropdown-item-icon"
                  />
                  {{ $t("general.delete") }}
                </div>
              </v-dropdown-item>
            </v-dropdown>
          </template>
        </table-column>
      </table-component>
    </div>
  </div>

  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import DotIcon from "../../components/icon/DotIcon";
import AstronautIcon from "../../components/icon/AstronautIcon";
import BaseButton from "../../../js/components/base/BaseButton";
import { request } from "http";
import ArrowUp from "../../components/icon/ArrowUp";
import ArrowDown from "../../components/icon/ArrowDown";

export default {
  components: {
    ArrowDown,
    ArrowUp,
    DotIcon,
    AstronautIcon,
    SweetModal,
    SweetModalTab,
    BaseButton,
  },
  data() {
    return {
      showFilters: false,
      filtersApplied: false,
      isRequestOngoing: true,
      filters: {
        name: "",
      },
    };
  },
  computed: {
    showEmptyScreen() {
      return !this.totalPermissions && !this.isRequestOngoing && !this.filtersApplied;
    },
    filterIcon() {
      return this.showFilters ? "times" : "filter";
    },
    ...mapGetters("permission", [
      "permissions",
      "selectedPermissions",
      "totalPermissions",
      "selectAllField",
    ]),
    selectField: {
      get: function () {
        return this.selectedPermissions;
      },
      set: function (val) {
        this.selectPermission(val);
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField;
      },
      set: function (val) {
        this.setSelectAllState(val);
      },
    },
  },
  watch: {
    filters: {
      handler: "setFilters",
      deep: true,
    },
  },
  destroyed() {
    if (this.selectAllField) {
      this.selectAllPermissions();
    }
  },
  methods: {
    ...mapActions("permission", [
      "fetchPermissions",
      "selectAllPermissions",
      "selectPermission",
      "deletePermission",
      "deleteMultiplePermissions",
      "setSelectAllState",
    ]),
    refreshTable() {
      this.$refs.table.refresh();
    },
    async fetchData({ page, filter, sort }) {
      let data = {
        name: this.filters.name,
        orderByField: sort.fieldName || "per_order",
        orderBy: sort.order || "desc",
        page,
      };

      this.isRequestOngoing = true;
      let response = await this.fetchPermissions(data);
      this.isRequestOngoing = false;

      return {
        data: response.data.permissions.data,
        pagination: {
          totalPages: response.data.permissions.last_page,
          currentPage: page,
        },
      };
    },

    async setOrder(order, id, order_val)
    {
      let data = {
        id: id,
        order: order,
        order_val: order_val
      };
      console.log(id, order, order_val);
      let response = await axios.put("/api/settings/update-order", data);
      if (response.data.success) {
        this.refreshTable();
        window.toastr["success"](this.$tc("general.setting_updated"));
      }
    },

    setFilters() {
      this.filtersApplied = true;
      // this.refreshTable()
    },
    refreshFilterTable() {
      this.refreshTable();
    },
    clearFilter() {
      this.filters = {
        name: "",
      };

      this.$nextTick(() => {
        this.filtersApplied = false;
      });
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter();
        this.refreshTable();
      }

      this.showFilters = !this.showFilters;
    },
    async removePermission(id) {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("permissions.confirm_delete"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deletePermission(id);
          if (res.data.success) {
            window.toastr["success"](this.$tc("permissions.deleted_message"));
            this.refreshTable();
            return true;
          } else if (request.data.error) {
            window.toastr["error"](res.data.message);
          }
        }
      });
    },
    async removeMultiplePermissions() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("permissions.confirm_delete", 2),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let request = await this.deleteMultiplePermissions();
          if (request.data.success) {
            window.toastr["success"](this.$tc("permissions.deleted_message", 2));
            this.refreshTable();
          } else if (request.data.error) {
            window.toastr["error"](request.data.message);
          }
        }
      });
    },
  },
};
</script>
