var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item-unit-modal template-container",
      staticStyle: { height: "500px" },
    },
    [
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c("div", { staticClass: "table-actions mt-5" }, [
            _c(
              "p",
              {
                staticClass: "table-stats",
                staticStyle: { "margin-left": "2%" },
              },
              [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.installments.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalInstallments))]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: {
                  label: _vm.$t("installmentPlan.invoice_number"),
                  show: "invoices.invoice_number",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("installmentPlan.amount"),
                  show: "amount",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("installmentPlan.due_date"),
                  show: "formattedDueDate",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("installmentPlan.status"),
                  show: "status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }