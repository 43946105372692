<template>
  <div class="customer-modal" :style="{ height: boolVar2 }">
    <form action="" @submit.prevent="submitCustomerData">
      <div class="card-body">
        <!-- tab-1 -->
        <tabs :options="{ defaultTabHash: 'basic-home' }" class="tabs-simple">
          <tab id="basic-home" :name="$t('customers.basic_info')">
            <div class="basic-info">
              <div class="form-group row">
                <div class="col-sm-4 col-form-label input-label">
                  {{ $t("customers.customer_type") }}
                </div>

                <div class="col-sm-3">
                  <base-button
                    color="theme"
                    type="button"
                    @click="showForm"
                    :onMouseOver="mouseIn1"
                    :onMouseOut="mouseOut2"
                    :class="{ active: isP == true }"
                    id="private_btn"
                    class="btnStyles"
                    :style="{ background: boolVar3, color: boolVar4 }"
                  >
                    Private customer
                  </base-button>
                </div>
                <div class="col-sm-3">
                  <!-- <div class="divider"/> -->
                  <base-button
                    color="theme"
                    type="button"
                    @click="hideForm"
                    :onMouseOver="mouseIn3"
                    :onMouseOut="mouseOut4"
                    :class="{ active: isB == true }"
                    id="business_btn"
                    class="btnStyles"
                    :style="{ background: boolVar5, color: boolVar6 }"
                  >
                    Business customer
                  </base-button>
                </div>
              </div>
              &nbsp;

              <div v-if="isBusiness">
                <div class="form-group row">
                  <!-- <div class="text-center section-title col-sm-12">{{ $t('customers.customer_type') }}</div> -->
                  <div class="col-sm-4 col-form-label input-labe">
                    {{ $t("customers.company_type") }}
                  </div>

                  <div class="col-sm-6">
                    <input
                      :invalid="$v.formData.removelines.$error"
                      v-model="formData.removelines"
                      :value="1"
                      checked
                      type="radio"
                      id="f-option"
                      name="selector"
                    />
                    <label for="f-option">Company With Personal Liabaility</label>

                    <div class="check"></div>

                    <input
                      v-model="formData.removelines"
                      :value="2"
                      type="radio"
                      id="s-option"
                      name="selector"
                    />
                    <label for="s-option">Capital Company</label>

                    <div class="check"></div>

                    <input
                      v-model="formData.removelines"
                      :value="3"
                      type="radio"
                      id="t-option"
                      name="selector"
                    />
                    <label for="t-option">Public Company / Authority</label>

                    <div class="check"></div>

                    <div class="col-sm-12" v-if="$v.formData.removelines.$error">
                      <span v-if="!$v.formData.removelines.required" class="text-danger">
                        {{ $tc("validation.required") }}
                      </span>
                    </div>
                    <!-- <label class="form-label">{{ $t('customers.display_name') }}</label><span class="text-danger"> *</span> -->
                    <!-- <base-input
                  :invalid="$v.formData.name.$error"
                  v-model="formData.name"
                  focus
                  type="text"
                  name="name"
                  tab-index="1"
                  @input="$v.formData.name.$touch()"
                />
                <div v-if="$v.formData.name.$error">
                  <span v-if="!$v.formData.name.required" class="text-danger">
                    {{ $tc('validation.required') }}
                  </span>
                  <span v-if="!$v.formData.name.minLength" class="text-danger">
                    {{ $tc('validation.name_min_length', $v.formData.name.$params.minLength.min, { count: $v.formData.name.$params.minLength.min }) }}
                  </span>
                </div> -->
                  </div>
                </div>
                <hr />
                <!-- second row complete  -->
              </div>

              <!-- //////////////////////////////// -->
              <div v-if="isPrivate">
                <div v-if="isBusiness" class="form-group row">
                  <label class="col-sm-4 col-form-label input-label"
                    >{{ $t("customers.cvrNumber") }}
                  </label>
                  <div class="col-sm-6">
                    <base-input
                      ref="name"
                      v-model.trim="formData.cvr_no"
                      type="text"
                      name="cvr_no"
                      @blur="getCompanyInfo"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label"
                    >{{ $t("customers.customer_number") }}
                    <span class="required">*</span></label
                  >

                  <div class="col-sm-6">
                    <base-prefix-input
                      v-model="customerNumAttribute"
                      :prefix="customerPrefix"
                      disabled
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label"
                    >{{ $t("customers.eanNumber") }}
                  </label>

                  <div class="col-sm-6">
                    <base-input
                      v-model="formData.ean_no"
                      focus
                      type="text"
                      name="ean_no"
                      tab-index="1"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label"
                    >{{ $t("customers.display_name") }}
                    <span class="required">*</span></label
                  >
                  <div class="col-sm-6">
                    <base-input
                      ref="name"
                      :invalid="$v.formData.name.$error"
                      v-model.trim="formData.name"
                      type="text"
                      name="name"
                      @input="$v.formData.name.$touch()"
                    />
                    <div v-if="$v.formData.name.$error">
                      <span v-if="!$v.formData.name.required" class="text-danger">{{
                        $tc("validation.required")
                      }}</span>
                      <span v-if="!$v.formData.name.minLength" class="text-danger">
                        {{
                          $tc(
                            "validation.name_min_length",
                            $v.formData.name.$params.minLength.min,
                            { count: $v.formData.name.$params.minLength.min }
                          )
                        }}
                      </span>
                      <span v-if="!$v.formData.name.alpha" class="text-danger">{{
                        $tc("validation.characters_only")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.primary_display_name")
                  }}</label>
                  <div class="col-sm-6">
                    <base-input v-model="formData.contact_name" type="text" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("login.email")
                  }}</label>
                  <div class="col-sm-6">
                    <base-input
                      :invalid="$v.formData.email.$error"
                      v-model.trim="formData.email"
                      type="text"
                      name="email"
                      @input="$v.formData.email.$touch()"
                    />
                    <div v-if="$v.formData.email.$error">
                      <span v-if="!$v.formData.email.required" class="text-danger">
                        {{ $tc("validation.required") }}
                      </span>
                      <span v-if="!$v.formData.email.email" class="text-danger">
                        {{ $t("validation.email_incorrect") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $tc("settings.currencies.currency")
                  }}</label>
                  <div class="col-sm-6">
                    <base-select
                      v-model="currency"
                      :options="currencies"
                      :searchable="true"
                      :allow-empty="false"
                      :show-labels="false"
                      :placeholder="$t('customers.select_currency')"
                      label="name"
                      track-by="id"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.phone")
                  }}</label>
                  <div class="col-sm-6">
                    <base-input v-model.trim="formData.phone" type="text" name="phone" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.website")
                  }}</label>
                  <div class="col-sm-6">
                    <base-input
                      v-model="formData.website"
                      type="text"
                      @input="checkWebsite($event)"
                      @change="checkWebsite($event)"
                      :invalid="!formData.isValid && formData.website != ''"
                    />
                    <div v-if="!formData.isValid && formData.website != ''">
                      <span class="text-danger"> Invalid Url </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab>

          <!-- tab-2 -->

          <div v-if="isPrivate">
            <tab id="basic-profile" :name="$t('customers.billing_address')">
              <div class="basic-info">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.name")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model="billing.name" type="text" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.phone")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model.trim="billing.phone" type="text" name="phone" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.address")
                  }}</label>
                  <div class="col-sm-7">
                    <base-text-area
                      v-model="billing.address_street_1"
                      :placeholder="$t('general.street_1')"
                      rows="2"
                      cols="50"
                      class="mb-1"
                      :invalid="$v.billing.address_street_1.$error"
                      @input="$v.billing.address_street_1.$touch()"
                    />
                    <div v-if="$v.billing.address_street_1.$error">
                      <span
                        v-if="!$v.billing.address_street_1.required"
                        class="text-danger"
                      >
                        {{ $tc("validation.required") }}
                      </span>
                      <span
                        v-if="!$v.billing.address_street_1.maxLength"
                        class="text-danger"
                        >{{ $t("validation.address_maxlength") }}</span
                      >
                    </div>

                    <base-text-area
                      v-model="billing.address_street_2"
                      :placeholder="$t('general.street_2')"
                      rows="2"
                      cols="50"
                      @input="$v.billing.address_street_2.$touch()"
                    />

                    <div v-if="$v.billing.address_street_2.$error">
                      <span
                        v-if="!$v.billing.address_street_2.maxLength"
                        class="text-danger"
                        >{{ $t("validation.address_maxlength") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.country")
                  }}</label>
                  <div class="col-sm-7">
                    <base-select
                      v-model="billingCountry"
                      :invalid="$v.billingCountry.$error"
                      :options="countryList"
                      :searchable="true"
                      :show-labels="false"
                      :placeholder="$t('general.select_country')"
                      :allow-empty="false"
                      track-by="id"
                      label="name"
                    />

                    <div v-if="$v.billingCountry.$error">
                      <span v-if="!$v.billingCountry.required" class="text-danger">
                        {{ $tc("validation.required") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div v-if="this.billingCountry.name != 'Denmark'" class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.state")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model="billing.state" type="text" name="billingState" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.city")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input
                      v-model="billing.city"
                      type="text"
                      name="billingCity"
                      :invalid="$v.billing.city.$error"
                    />

                    <div v-if="$v.billing.city.$error">
                      <span v-if="!$v.billing.city.required" class="text-danger">
                        {{ $tc("validation.required") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.zip_code")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model="billing.zip" type="text" />
                  </div>
                </div>
              </div>
            </tab>

            <!-- tab-3 -->
            <tab id="basic-message" :name="$t('customers.shipping_address')">
              <div class="basic-info">
                <div class="form-group row">
                  <div class="col-sm-12 copy-address-button">
                    <base-button
                      ref="sameAddress"
                      icon="copy"
                      class="mr-2 btn-sm"
                      color="theme"
                      @click="copyAddress(true)"
                    >
                      {{ $t("customers.copy_billing_address") }}
                    </base-button>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.name")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model="shipping.name" type="text" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.phone")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model.trim="shipping.phone" type="text" name="phone" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.address")
                  }}</label>
                  <div class="col-sm-7">
                    <base-text-area
                      v-model="shipping.address_street_1"
                      :placeholder="$t('general.street_1')"
                      rows="2"
                      cols="50"
                      class="mb-1"
                      @input="$v.shipping.address_street_1.$touch()"
                    />
                    <div v-if="$v.shipping.address_street_1.$error">
                      <span
                        v-if="!$v.shipping.address_street_1.maxLength"
                        class="text-danger"
                        >{{ $t("validation.address_maxlength") }}</span
                      >
                    </div>

                    <base-text-area
                      v-model="shipping.address_street_2"
                      :placeholder="$t('general.street_2')"
                      rows="2"
                      cols="50"
                      @input="$v.shipping.address_street_2.$touch()"
                    />
                    <div v-if="$v.shipping.address_street_2.$error">
                      <span
                        v-if="!$v.shipping.address_street_2.maxLength"
                        class="text-danger"
                        >{{ $t("validation.address_maxlength") }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.country")
                  }}</label>
                  <div class="col-sm-7">
                    <base-select
                      v-model="shippingCountry"
                      :options="countryList"
                      :searchable="true"
                      :show-labels="false"
                      :allow-empty="false"
                      :placeholder="$t('general.select_country')"
                      track-by="id"
                      label="name"
                    />
                  </div>
                </div>

                <div v-if="this.billingCountry.name != 'Denmark'" class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.state")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input
                      v-model="shipping.state"
                      type="text"
                      name="shippingState"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.city")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model="shipping.city" type="text" name="shippingCity" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label input-label">{{
                    $t("customers.zip_code")
                  }}</label>
                  <div class="col-sm-7">
                    <base-input v-model="shipping.zip" type="text" />
                  </div>
                </div>
              </div>
            </tab>
          </div>
        </tabs>

        <div v-if="isFront">
          <div class="card-footer">
            <base-button
              :outline="true"
              class="mr-3"
              color="theme"
              @click="cancelCustomer"
            >
              {{ $t("general.cancel") }}
            </base-button>
            <base-button :loading="isLoading" icon="save" color="theme" type="submit">
              {{ $t("general.save") }}
            </base-button>
          </div>
        </div>
      </div>
    </form>

    <!-- modal -->
    <transition name="modal" v-if="showmodal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">
                <h3><center>Warning!!</center></h3>
              </slot>
            </div>

            <div class="modal-body">
              <slot name="body">
                When you trade with a company, it is important to know
                <strong>EXACTLY</strong> which company you are trading with. We therefore
                recommend that you get a CVR number on the customer.
              </slot>
            </div>

            <div class="modal-footer" style="justify-content: center">
              <!-- <button class="modal-default-button" @click="popclose">
                    OK
                  </button> -->

              <base-button color="theme" type="button" class="btnStyles" @click="popback">
                Go back
              </base-button>

              <base-button
                color="theme"
                type="button"
                class="btnStyles"
                @click="popclose"
              >
                Proceed without CVR
              </base-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Tabs, Tab } from "vue-tabs-component";
import MultiSelect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import { mapActions, mapGetters } from "vuex";
import AddressStub from "../../../stub/address";
const {
  required,
  minLength,
  email,
  numeric,
  url,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  components: {
    tabs: Tabs,
    tab: Tab,
    MultiSelect,
  },
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
      customerNumAttribute: null,
      customerPrefix: null,
      isFront: false,
      mouseIn1: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseOut2: "this.style.backgroundColor='white',this.style.color='black'",
      mouseIn3: "this.style.backgroundColor='#0373ff',this.style.color='white'",
      mouseOut4: "this.style.backgroundColor='white',this.style.color='black'",
      boolVar1: null,
      boolVar2: "250px",
      boolVar3: "transparent",
      boolVar4: "black",
      boolVar5: "transparent",
      boolVar6: "black",
      cvrText: "Get Info",
      buttonClick: 0,
      popCloseBtn: 0,
      businessBtn: 0,
      isCVR: false,
      isRequired: false,
      showmodal: false,
      isPrivate: false,
      isBusiness: false,
      buttonValue: null,
      isP: false,
      isB: false,
      isY: true,
      isLoading: false,
      countryList: [],
      billingCountry: null,
      shippingCountry: null,
      isCopyFromBilling: false,
      currencyList: [],
      currency: "",
      isDisabledBillingState: true,
      isDisabledBillingCity: true,
      isDisabledShippingState: true,
      isDisabledShippingCity: true,
      formData: {
        id: null,
        c_number: null,
        name: null,
        currency_id: null,
        phone: null,
        ean_no: null,
        cvr_no: null,
        website: "",
        email: null,
        contact_name: null,
        removelines: 1,
        addresses: [],
        isValid: true,
      },
      billing: { ...AddressStub },
      shipping: { ...AddressStub },
    };
  },
  validations: {
    formData: {
      // c_number: {
      //   required,
      //   minLength: minLength(3),
      // },
      name: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },

      removelines: {
        required,
      },
    },
    billing: {
      address_street_1: {
        required,
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
      city: {
        required,
      },
    },
    shipping: {
      address_street_1: {
        maxLength: maxLength(255),
      },
      address_street_2: {
        maxLength: maxLength(255),
      },
    },
    billingCountry: {
      required,
    },
  },
  computed: {
    ...mapGetters("currency", ["defaultCurrency", "defaultCountry", "currencies"]),
    ...mapGetters("modal", ["modalDataID", "modalData", "modalActive"]),
  },
  watch: {
    modalDataID(val) {
      if (val) {
        this.isEdit = true;
        this.setData();
      } else {
        this.isEdit = false;
      }
    },
    billingCountry() {
      if (this.billingCountry) {
        this.billing.country_id = this.billingCountry.id;
        return true;
      }
    },
    shippingCountry() {
      if (this.shippingCountry) {
        this.shipping.country_id = this.shippingCountry.id;
        return true;
      }
    },
  },
  mounted() {
    // this.$refs.name.focus = true
    this.fetchCountry();
    this.showForm();

    this.fetchDefaultCountry();
    this.currency = this.defaultCurrency;
    // this.billingCountry = this.defaultCountry;
    this.fetchCountry();
    if (this.modalDataID) {
      this.setData();
    } else {
      this.loadInitialData();
    }
  },
  methods: {
    ...mapActions("invoice", {
      setInvoiceCustomer: "selectCustomer",
    }),
    ...mapActions("estimate", {
      setEstimateCustomer: "selectCustomer",
    }),
    ...mapActions("customer", [
      "fetchCustomer",
      "fetchCreateCustomer",
      "addCustomer",
      "updateCustomer",
    ]),
    ...mapActions("modal", ["closeModal"]),

    async loadInitialData() {
      this.initLoading = true;
      let response = await this.fetchCreateCustomer();
      if (response.data) {
        this.customerPrefix = response.data.customer_prefix;
        this.customerNumAttribute = response.data.nextCustomerNumberAttribute;
      }
      this.initLoading = false;
    },

    showmodalpop() {
      this.showmodal = true;
    },
    popclose() {
      this.showmodal = false;
      this.isCVR = false;
      this.popCloseBtn++;
      this.hideForm();
    },
    popback() {
      this.showmodal = false;
    },

    // checkdialog() {
    //   if (this.buttonClick > 0) {
    //     this.showModalCustomer = true;

    //     this.showForm();
    //   }

    // },

    checkWebsite(str) {
      var regex = new RegExp(
        "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
      );
      if (regex.test(str)) {
        this.formData.isValid = true;
      } else {
        this.formData.isValid = true;
      }
    },

    showForm() {
      if (this.buttonClick > 0) {
        alert("Your data will be lost..");
      }
      this.boolVar1 = "scroll";
      this.boolVar2 = "530px";
      this.boolVar3 = "#0373ff";
      this.boolVar4 = "white";

      this.mouseIn1 = "";
      this.mouseOut2 = "";
      (this.mouseIn3 = "this.style.backgroundColor='#0373ff',this.style.color='white'"),
        (this.mouseOut4 = "this.style.backgroundColor='white',this.style.color='black'"),
        (this.boolVar5 = "transparent");
      this.boolVar6 = "black";
      this.isFront = true;
      this.isPrivate = true;
      this.isBusiness = false;
      this.isCVR = false;
      this.buttonValue = 1;
      this.isRequired = false;
      this.isP = true;
      this.isB = false;
      this.isY = false;
      this.buttonClick = 0;
      // this.formData.name = ''
      // this.formData.contact_name = ''
      // this.formData.email = ''
      // this.formData.phone = ''

      // this.billing.name = ''
      // this.billing.city = ''
      // this.billing.address_street_1 = ''
      // this.billing.phone = ''
      // this.billing.zip = ''
    },

    hideForm() {
      this.boolVar1 = "scroll";
      this.boolVar2 = "530px";
      this.boolVar3 = "transparent";
      this.boolVar4 = "black";
      this.boolVar5 = "#0373ff";
      this.boolVar6 = "white";
      this.isFront = true;
      this.isBusiness = true;
      this.isPrivate = true;
      this.buttonValue = 2;
      this.isRequired = true;
      this.isP = false;
      this.isB = true;
      this.isY = true;
      this.showCVR();
    },
    showCVR() {
      // console.log(this.isEdit)
      this.businessBtn++;
      (this.mouseIn1 = "this.style.backgroundColor='#0373ff',this.style.color='white'"),
        (this.mouseOut2 = "this.style.backgroundColor='white',this.style.color='black'"),
        (this.mouseIn3 = ""),
        (this.mouseOut4 = ""),
        (this.boolVar3 = "transparent");
      this.boolVar4 = "black";
      this.boolVar5 = "#0373ff";
      this.boolVar6 = "white";
      if (this.modalDataID) {
        this.boolVar1 = "scroll";
        this.boolVar2 = "530px";

        (this.mouseIn1 = "this.style.backgroundColor='#0373ff',this.style.color='white'"),
          (this.mouseOut2 =
            "this.style.backgroundColor='white',this.style.color='black'"),
          (this.mouseIn3 = ""),
          (this.mouseOut4 = ""),
          (this.isFront = true);
        // this.formData.cvr = ''
        this.isBusiness = true;
        this.isPrivate = true;

        this.isCVR = false;
        this.buttonValue = 2;
        this.isP = false;
        this.isB = true;
        this.isY = false;
      } else {
        this.boolVar1 = "scroll";
        this.boolVar2 = "530px";

        (this.mouseIn1 = "this.style.backgroundColor='#0373ff',this.style.color='white'"),
          (this.mouseOut2 =
            "this.style.backgroundColor='white',this.style.color='black'"),
          (this.mouseIn3 = ""),
          (this.mouseOut4 = ""),
          (this.isFront = true);
        this.formData.cvr = "";
        if (this.popCloseBtn > 0) {
          this.isCVR = false;
          this.isBusiness = true;
          this.isPrivate = true;
        } else {
          this.isCVR = true;
          this.isBusiness = true;
          this.isPrivate = true;
        }
        this.buttonValue = 2;
        this.isP = false;
        this.isB = true;
        this.isY = false;

        if (this.businessBtn == 0) {
          this.formData.name = "";
          this.formData.contact_name = "";
          this.formData.email = "";
          this.formData.phone = "";

          this.billing.name = "";
          this.billing.city = "";
          this.billing.address_street_1 = "";
          this.billing.phone = "";
          this.billing.zip = "";
        }
      }

      // if(this.buttonClick > 0){
      //   this.hideForm()
      // }
    },

    // showmodalpop() {
    //   // this.showmodal = true;
    //   swal({
    //     title: this.$t("general.warning"),
    //     text: this.$t("customers.popup"),
    //     icon: "https://svg-clipart.com/svg/symbol/FcKU4jE-warning-sign-vector.svg",
    //     buttons: true,
    //     dangerMode: true,
    //     className: "red-bg",
    //   }).then(async (value) => {
    //     if (value) {
    //       this.popclose();
    //     }
    //   });
    // },
    popclose() {
      this.showmodal = false;
      this.isCVR = false;
      this.popCloseBtn++;
      this.hideForm();
    },
    popback() {
      this.showmodal = false;
    },

    closedialog() {
      this.showModalCustomer = false;
      this.buttonClick = 0;
      this.showForm();
    },
    canceldialog() {
      this.showModalCustomer = false;
      this.hideForm();
    },

    getCompanyInfo() {
      // console.log(this.formData.cvr)
      this.isLoading = true;
      if (this.formData.cvr_no == undefined || this.formData.cvr_no == "") {
        window.toastr["error"]("For business company CVR has to be given");
        this.showmodalpop();
      } else {
        window.axios
          .post(`/api/customers/company_cvr`, {
            cvr: this.formData.cvr_no,
          })
          .then((response) => {
            window.toastr["success"](this.$t("customers.datafetched"));

            this.formData.name = response.data.data.name;
            this.formData.contact_name = response.data.data.name;
            this.formData.email = response.data.data.email;
            this.formData.phone = response.data.data.phone;

            this.billing.name = response.data.data.name;
            this.billing.city = response.data.data.city;
            this.billing.address_street_1 = response.data.data.address;
            this.billing.phone = response.data.data.phone;
            this.billing.zip = response.data.data.zipcode;
            this.buttonClick++;
          })
          .catch((err) => {
            window.toastr["error"](this.$t("language.cvr_error_data_fetched"));
            this.resetForm();
          });
      }

      this.isLoading = false;
    },

    resetForm() {
      this.formData.name = "";
      this.formData.contact_name = "";
      this.formData.email = "";
      this.formData.phone = "";
      // this.formData.cvr_no = "";

      this.billing.name = "";
      this.billing.city = "";
      this.billing.address_street_1 = "";
      this.billing.phone = "";
      this.billing.zip = "";

      this.shipping.name = "";
      this.shipping.city = "";
      this.shipping.address_street_1 = "";
      this.shipping.address_street_2 = "";
      this.shipping.phone = "";
      this.shipping.zip = "";
    },

    resetData() {
      this.formData = {
        name: null,
        currency_id: null,
        phone: null,
        website: null,
        contact_name: null,
        addresses: [],
      };

      this.billingCountry = null;
      this.shippingCountry = null;

      this.billing = { ...AddressStub };
      this.shipping = { ...AddressStub };
      this.$v.formData.$reset();
    },
    cancelCustomer() {
      this.resetData();
      this.closeModal();
    },
    copyAddress(val) {
      if (val === true) {
        this.isCopyFromBilling = true;
        this.shipping = { ...this.billing, type: "shipping" };
        this.shippingCountry = this.billingCountry;
      } else {
        this.shipping = { ...AddressStub, type: "shipping" };
        this.shippingCountry = null;
      }
    },
    async loadData() {
      let response = await this.fetchCustomer();
      this.currencyList = this.currencies;
      this.formData.currency_id = response.data.currency.id;

      return true;
    },
    checkAddress() {
      const isBillingEmpty = Object.values(this.billing).every(
        (val) => val === null || val === ""
      );
      const isShippingEmpty = Object.values(this.shipping).every(
        (val) => val === null || val === ""
      );
      if (isBillingEmpty === true && isBillingEmpty === true) {
        this.formData.addresses = [];
        return true;
      }

      if (isBillingEmpty === false && isShippingEmpty === false) {
        this.formData.addresses = [
          { ...this.billing, type: "billing" },
          { ...this.shipping, type: "shipping" },
        ];
        return true;
      }

      if (isBillingEmpty === false) {
        this.formData.addresses.push({ ...this.billing, type: "billing" });
        return true;
      }

      this.formData.addresses = [{ ...this.shipping, type: "shipping" }];
      return true;
    },
    async setData() {
      this.formData.id = this.modalData.id;
      this.formData.cvr_no = this.modalData.cvr_no;
      this.formData.ean_no = this.modalData.ean_no;
      this.formData.c_number = this.modalData.customer_number;
      this.formData.name = this.modalData.name;
      this.formData.email = this.modalData.email;
      this.formData.contact_name = this.modalData.contact_name;
      this.formData.phone = this.modalData.phone;
      this.formData.website = this.modalData.website;
      this.currency = this.modalData.currency;
      this.formData.removelines = this.modalData.company_type_id;

      var myarr = this.modalData.customer_number.split("-");

      this.customerNumAttribute = myarr[1];
      this.customerPrefix = myarr[0];
      this.buttonValue = this.modalData.customer_type_id;

      if (this.buttonValue == 1) {
        this.showForm();
      } else if (this.buttonValue == 2) {
        this.hideForm();
      }

      if (this.modalData.billing_address) {
        this.billing = this.modalData.billing_address;
        this.billingCountry = this.modalData.billing_address.country;
      }
      if (this.modalData.shipping_address) {
        this.shipping = this.modalData.shipping_address;
        this.shippingCountry = this.modalData.shipping_address.country;
      }
    },
    async submitCustomerData() {
      // this.$v.formData.$touch()
      this.$v.$touch();

      if (this.$v.$invalid) {
        return true;
      }

      // this.checkAddress()
      this.formData.addresses = [
        { ...this.shipping, type: "shipping" },
        { ...this.billing, type: "billing" },
      ];

      this.formData.c_number = this.customerPrefix + "-" + this.customerNumAttribute;
      this.isLoading = true;

      if (this.currency) {
        this.formData.currency_id = this.currency.id;
      } else {
        this.formData.currency_id = this.defaultCurrency.id;
      }

      if (this.buttonValue) {
        this.formData.customer_type_id = this.buttonValue;
      }

      try {
        let response = null;
        if (this.modalDataID) {
          response = await this.updateCustomer(this.formData);
        } else {
          response = await this.addCustomer(this.formData);
        }
        if (response.data) {
          if (this.modalDataID) {
            window.toastr["success"](this.$tc("customers.updated_message"));
          } else {
            window.toastr["success"](this.$tc("customers.created_message"));
          }
          this.isLoading = false;
          if (
            this.$route.name === "invoices.create" ||
            this.$route.name === "invoices.edit"
          ) {
            this.setInvoiceCustomer(response.data.customer.id);
          }
          if (
            this.$route.name === "estimates.create" ||
            this.$route.name === "estimates.edit"
          ) {
            this.setEstimateCustomer(response.data.customer.id);
          }
          this.resetData();
          this.closeModal();
          return true;
        }
        // window.toastr['error'](response.data.error)
      } catch (err) {
        if (err.response.data.errors.email) {
          this.isLoading = false;
          // window.toastr['error'](this.$t('validation.email_already_taken'))
          return true;
        } else if (err.response.data.errors.c_number) {
          this.isLoading = false;
          // window.toastr['error'](this.$t('validation.email_already_taken'))
          return true;
        } else if (err.response.data.errors.cvr) {
          this.isLoading = false;
          this.showCVR();
          // window.toastr['error'](this.$t('validation.email_already_taken'))
          return true;
        } else if (err.response.data.errors.name) {
          this.isLoading = false;
          // if(popCloseBtn == 0){
          //   this.showCVR()
          // }

          // window.toastr['error'](this.$t('validation.email_already_taken'))
          return true;
        }
      }
    },
    async fetchCountry() {
      let res = await window.axios.get("/api/countries");
      if (res) {
        this.countryList = res.data.countries;
      }
    },
    async fetchDefaultCountry() {
      let res = await window.axios.get("/api/default_countries");
      if (res) {
        this.billingCountry = res.data.defaultCountry[0];
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px 30px;
  border-style: solid;
  border-width: thick;
  background-color: #fff;
  /* border-radius: 20px; */
  border-color: #0373ff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #0373ff;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

/* .modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.modal-enter-active {
  transition: all 0.3s ease-out;
}

.modal-leave-active {
  transition: all 0.3s ease-in;
}

.modal-enter-to,
.modal-leave-from {
  opacity: 1;
  transform: scale(1);
}
.btnStyles {
  width: 100%;
  display: inline-block;
  white-space: revert;
}
</style>
