import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
  faDollarSign,
  faTachometerAlt,
  faFileAlt,
  faCreditCard,
  faSpaceShuttle,
  faSignal,
  faCog,
  faPlus,
  faComment,
  faMoneyBill,
  faBell,
  faHashtag,
  faSave,
  faFilter,
  faEdit,
  faTrash,
  faTrashAlt,
  faBackward,
  faForward,
  faShoppingBasket,
  faTimes,
  faUserPlus,
  faCartPlus,
  faArrowLeft,
  faArrowRight,
  faSpinner,
  faSignOutAlt,
  faCogs,
  faPencilAlt,
  faPrint,
  faCheck,
  faSync,
  faSortAmountDown,
  faSortAmountUp,
  faSearch,
  faAsterisk,
  faInfoCircle,
  faCheckCircle,
  faStar,
  faFile,
  faSubscript,
  faCloudUploadAlt,
  faDownload,
  faGripVertical,
  faCalendar,
  faFilePdf,
  faTimesCircle,
  faEnvelope,
  faEye,
  faShare,
  faEllipsisH,
  faCopy,
  faPaperPlane,
  faEyeSlash,
  faSyncAlt,
  faRocket,
  faCamera,
  faBolt,
  faUniversalAccess,
  faLink,
  faIndustry,
  faIdCard,
  faIdCardAlt,
  faMarker,
} from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

window.Vue = require('vue')

library.add(
  far,
  faUser,
  faTrashAlt,
  faTachometerAlt,
  faFileAlt,
  faFile,
  faStar,
  faCreditCard,
  faSpaceShuttle,
  faSignal,
  faCog,
  faPlus,
  faPrint,
  faMoneyBill,
  faComment,
  faBell,
  faHashtag,
  faSave,
  faFilter,
  faEdit,
  faTrash,
  faBackward,
  faForward,
  faShoppingBasket,
  faTimes,
  faTimesCircle,
  faUserPlus,
  faCartPlus,
  faArrowLeft,
  faArrowRight,
  faCartPlus,
  faSpinner,
  faSignOutAlt,
  faCogs,
  faPencilAlt,
  faCheck,
  faSync,
  faSortAmountDown,
  faSortAmountUp,
  faSearch,
  faAsterisk,
  faInfoCircle,
  faCheckCircle,
  faPencilAlt,
  faCloudUploadAlt,
  faDownload,
  faGripVertical,
  faCalendar,
  faFilePdf,
  faEnvelope,
  faEye,
  faEyeSlash,
  faShare,
  faEllipsisH,
  faCopy,
  faPaperPlane,
  faSyncAlt,
  faRocket,
  faCamera,
  faBolt,
  faUniversalAccess,
  faIndustry,
  faDollarSign,
  faIdCard,
  faIdCardAlt,
  faMarker,
  faLink,
  faSubscript
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
