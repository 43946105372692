import * as types from './mutation-types'

export default {
  [types.SET_COMPANY] (state, data) {
    // console.log(data)
    state.company = data.company
  },

  [types.UPDATE_COMPANY] (state, data) {
    // console.log(data)
    state.company = data
  },

  [types.UPDATE_LOGO] (state, data) {
    // console.log(data)
    state.logo = data
  }
}
