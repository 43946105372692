<template>
  <div class="customer-create main-content" v-if="$can('Economics')">
    <div class="page-header">
      <h3 class="page-title">{{ $t("economics.sync") }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("navigation.economics", 2) }}
          </router-link>
        </li>
      </ol>
    </div>

    <div v-cloak v-show="!showEmptyScreen" class="col-xs-1 no-data-info" align="center">
      <div class="btn-container pb-20">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="getEconomicProducts"
        >
          {{ $t("economics.sync_items") }}
        </base-button>
      </div>
      <div class="btn-container pb-20">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="getEconomicCustomers"
        >
          {{ $t("economics.sync_customers") }}
        </base-button>
      </div>
      <div class="btn-container pb-20">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="getEconomicInvoices"
        >
          {{ $t("economics.sync_invoices") }}
        </base-button>
      </div>
    </div>

    <div v-cloak v-show="showEmptyScreen" class="col-xs-1 no-data-info" align="center">
      <astronaut-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t("economics.add_economic_details") }}</label>
      </div>

      <div class="btn-container">
        <base-button
          :outline="true"
          color="theme"
          class="mt-3"
          size="large"
          @click="$router.push('settings/integration')"
        >
          {{ $t("economics.sync_invoices") }}
        </base-button>
      </div>
    </div>
    <base-loader v-if="isLoading" />
  </div>

  <!--  else not authorize-->
  <div v-else>
    <not-authorize></not-authorize>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import DotIcon from "../../components/icon/DotIcon";
import AstronautIcon from "../../components/icon/AstronautIcon";
import BaseButton from "../../../js/components/base/BaseButton";
import { request } from "http";

export default {
  components: {
    DotIcon,
    AstronautIcon,
    SweetModal,
    SweetModalTab,
    BaseButton,
  },
  data() {
    return {
      apiKey: null,
      showFilters: false,
      isLoading: false,
      filtersApplied: false,
      isRequestOngoing: true,
      filters: {
        display_name: "",
        email: "",
        phone: "",
      },
    };
  },
  computed: {
    showEmptyScreen() {
      return !this.apiKey;
    },

    ...mapGetters("user", ["currentUser"]),
  },
  created() {
    this.getData(this.currentUser.company_id);
  },

  methods: {
    getData(data) {
      axios
        .get(`/api/economicsId/${data}`)
        .then((response) => {
          this.apiKey = response?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshTable() {
      this.$refs.table.refresh();
    },

    getEconomicProducts() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$t("economics.sync_economics_data"),
        icon: "/assets/icon/paper-plane-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          this.isLoading = true;
          axios
            .get(`/api/getProducts`)
            .then((response) => {
              this.isLoading = false;
              window.toastr["success"](this.$tc("economics.data_sync_successfully"));
            })
            .catch((err) => {
              console.log(err);
              window.toastr["error"](this.$tc("economics.something_went_wrong"));
            });
        }
      });
    },
    getEconomicCustomers() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$t("economics.sync_economics_data"),
        icon: "/assets/icon/paper-plane-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          this.isLoading = true;
          axios
            .get(`/api/getCustomers`)
            .then((response) => {
              this.isLoading = false;
              window.toastr["success"](this.$tc("economics.data_sync_successfully"));
            })
            .catch((err) => {
              console.log(err);
              window.toastr["error"](this.$tc("economics.something_went_wrong"));
            });
        }
      });
    },
    getEconomicInvoices() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$t("economics.sync_economics_data"),
        icon: "/assets/icon/paper-plane-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (value) => {
        if (value) {
          this.isLoading = true;
          axios
            .get(`/api/getInvoices`)
            .then((response) => {
              this.isLoading = false;
              window.toastr["success"](this.$tc("economics.data_sync_successfully"));
            })
            .catch((err) => {
              console.log(err);
              window.toastr["error"](this.$tc("economics.something_went_wrong"));
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.no-data-info {
  min-height: calc(100vh - 250px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .btn-container {
    margin-inline: 10px;
  }
}
</style>
