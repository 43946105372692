<template>
  <div class="site-wrapper">
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  // beforeCreate() {
  //   if (localStorage.getItem("onboarding") != "COMPLETED") {
  //     this.$router.push({ path: "/admin/on-boarding" });
  //   }
  // },
  watch: {
    $route: "onRouteChange",
  },
  mounted() {
    this.setLayoutBackground();
  },

  destroyed() {
    document.body.style.backgroundColor = "#EBF1FA";
  },
  methods: {
    setLayoutBackground() {
      document.body.style.backgroundColor = "#f9fbff";
    },
  },
};
</script>
