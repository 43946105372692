var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-create main-content" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitPermissionData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("permissions.edit_permission")
                    : _vm.$t("permissions.new_permission")
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/system/dashboard" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/system/permissions" },
                    slot: "item-title",
                  },
                  [_vm._v(_vm._s(_vm.$tc("permissions.permission", 2)))]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.isEdit
                      ? _vm.$t("permissions.edit_permission")
                      : _vm.$t("permissions.new_permission")
                  ) +
                  "\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions header-button-container" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    tabindex: 23,
                    icon: "save",
                    color: "theme",
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("permissions.update_permission")
                          : _vm.$t("permissions.save_permission")
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "customer-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(_vm._s(_vm.$t("permissions.basic_info"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("permissions.display_name"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.name.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("permissions.titles"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.title.$error,
                        focus: "",
                        type: "text",
                        name: "title",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.title.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "title", $$v)
                        },
                        expression: "formData.title",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.title.$error
                      ? _c("div", [
                          !_vm.$v.formData.title.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.title.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.title.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.title.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("permissions.landingPage"))),
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        focus: "",
                        type: "text",
                        name: "landingPageText",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.landingPageText.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.landingPageText,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "landingPageText", $$v)
                        },
                        expression: "formData.landingPageText",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("permissions.icon"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.icon.$error,
                        focus: "",
                        type: "text",
                        name: "icon",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.icon.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.icon,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "icon", $$v)
                        },
                        expression: "formData.icon",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.icon.$error
                      ? _c("div", [
                          !_vm.$v.formData.icon.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.icon.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.icon.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.icon.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("permissions.route"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.route.$error,
                        focus: "",
                        type: "text",
                        name: "route",
                        "tab-index": "1",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.formData.route.$touch()
                        },
                      },
                      model: {
                        value: _vm.formData.route,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "route", $$v)
                        },
                        expression: "formData.route",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.route.$error
                      ? _c("div", [
                          !_vm.$v.formData.route.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.route.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.route.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.route.$params
                                              .minLength.min,
                                        }
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("permissions.subscription_plans"))),
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.subscription_plans,
                        invalid: _vm.$v.formData.subscription_plan.$error,
                        "show-labels": false,
                        multiple: true,
                        placeholder: _vm.$t("customers.select_payment"),
                        label: "name",
                        "track-by": "id",
                      },
                      model: {
                        value: _vm.formData.subscription_plan,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "subscription_plan", $$v)
                        },
                        expression: "formData.subscription_plan",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.subscription_plan.$error
                      ? _c("div", [
                          !_vm.$v.formData.subscription_plan.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.$tc("validation.required")) +
                                    "\n                "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "align-items": "flex-end",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [
                        _c("base-switch", {
                          staticClass: "btn-switch",
                          on: { change: _vm.setSidebar },
                          model: {
                            value: _vm.formData.sidebar,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "sidebar", $$v)
                            },
                            expression: "formData.sidebar",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "right",
                        staticStyle: { margin: "-1%", "margin-left": "7%" },
                      },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(_vm._s(_vm.$t("permissions.sidebar"))),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "row",
                      "align-items": "flex-end",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [
                        _c("base-switch", {
                          staticClass: "btn-switch",
                          on: { change: _vm.setSidebar },
                          model: {
                            value: _vm.formData.show_on_landing,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "show_on_landing", $$v)
                            },
                            expression: "formData.show_on_landing",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "right",
                        staticStyle: { margin: "-1%", "margin-left": "7%" },
                      },
                      [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v(
                            _vm._s(_vm.$t("permissions.show_on_landing_page"))
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }