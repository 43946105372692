var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("Integration Settings")
    ? _c(
        "div",
        { staticClass: "setting-main-container" },
        [
          !_vm.isLoading
            ? _c("div", { staticClass: "card setting-card" }, [
                _c("div", { staticClass: "page-header" }, [
                  _c("h3", { staticClass: "page-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t("settings.integration.integration_settings")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "page-sub-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("settings.integration.integration_description")
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex-box mb-4" }, [
                    _c(
                      "div",
                      { staticClass: "left" },
                      [
                        _c("base-switch", {
                          staticClass: "btn-switch",
                          on: { change: _vm.setIntegrationSettings },
                          model: {
                            value: _vm.formData.notify_integration,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "notify_integration", $$v)
                            },
                            expression: "formData.notify_integration",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "right" }, [
                      _c("p", { staticClass: "box-title" }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.integration.switch_base_line")
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "box-desc" }, [
                        _vm._v(
                          _vm._s(_vm.$t("settings.integration.switch_desc"))
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                this.details
                  ? _c(
                      "form",
                      {
                        attrs: { action: "" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.saveIntegration()
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12 mb-4" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("integrations.basic_info"))
                                ),
                              ]),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(" * "),
                              ]),
                              _vm._v(" "),
                              _c("base-select", {
                                class: {
                                  error: _vm.$v.formData.integration.$error,
                                },
                                attrs: {
                                  options: _vm.integrations,
                                  searchable: true,
                                  "show-labels": false,
                                  "allow-empty": false,
                                  placeholder: _vm.$t(
                                    "general.select_integration"
                                  ),
                                  label: "name",
                                  "track-by": "id",
                                },
                                on: { select: _vm.onSelectIntegration },
                                model: {
                                  value: _vm.formData.integration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "integration", $$v)
                                  },
                                  expression: "formData.integration",
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.integration.$error
                                ? _c("div", [
                                    !_vm.$v.formData.integration.required
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-4" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.integration.api_key"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(" * "),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                attrs: {
                                  invalid: _vm.$v.formData.api_key.$error,
                                  placeholder: "API Key",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.api_key.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.api_key,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "api_key", $$v)
                                  },
                                  expression: "formData.api_key",
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.api_key.$error
                                ? _c("div", [
                                    !_vm.$v.formData.api_key.required
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6 mb-4" },
                            [
                              _c("label", { staticClass: "input-label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.integration.api_secret")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(" * "),
                              ]),
                              _vm._v(" "),
                              _c("base-input", {
                                attrs: {
                                  invalid: _vm.$v.formData.api_secret.$error,
                                  placeholder: "API Secret",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.formData.api_secret.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.formData.api_secret,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "api_secret", $$v)
                                  },
                                  expression: "formData.api_secret",
                                },
                              }),
                              _vm._v(" "),
                              _vm.$v.formData.api_secret.$error
                                ? _c("div", [
                                    !_vm.$v.formData.api_secret.required
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tc("validation.required")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("div", { staticClass: "page-header" }, [
                          _c("h3", { staticClass: "page-title" }, [
                            _vm._v(_vm._s(_vm.$t("activities.title"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.permissions, function (permission) {
                          return _c("div", { class: _vm.makeDisable }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "left" },
                                [
                                  _c("base-switch", {
                                    staticClass: "btn-switch",
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleGroupNumber(permission)
                                      },
                                    },
                                    model: {
                                      value: permission.assigned,
                                      callback: function ($$v) {
                                        _vm.$set(permission, "assigned", $$v)
                                      },
                                      expression: "permission.assigned",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-md-3" }, [
                                _c("p", { staticClass: "box-title" }, [
                                  _vm._v(
                                    _vm._s(_vm._f("uppercase")(permission.name))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "box-desc" }, [
                                  _vm._v(_vm._s(permission.description)),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row pt-3" }, [
                              (_vm.showCustomerNumberField &&
                                permission.name == "Send Customers" &&
                                _vm.formData.integration.name == "e-conomic") ||
                              (permission.name == "Send Customers" &&
                                _vm.selectedCustomerGroupNumber &&
                                _vm.showCustomerNumberField &&
                                _vm.formData.integration.name == "e-conomic")
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "integrations.customer_group_no"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.customerGroups,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          label: "name",
                                          "track-by": "id",
                                        },
                                        model: {
                                          value: _vm.customerGroup,
                                          callback: function ($$v) {
                                            _vm.customerGroup = $$v
                                          },
                                          expression: "customerGroup",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.showCustomerNumberField &&
                                permission.name == "Send Customers" &&
                                _vm.formData.integration.name == "e-conomic") ||
                              (permission.name == "Send Customers" &&
                                _vm.selectedCustomerGroupNumber &&
                                _vm.showCustomerNumberField &&
                                _vm.formData.integration.name == "e-conomic")
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "integrations.payment_terms"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.paymentTerms,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          label: "name",
                                          "track-by": "id",
                                        },
                                        model: {
                                          value: _vm.paymentTerm,
                                          callback: function ($$v) {
                                            _vm.paymentTerm = $$v
                                          },
                                          expression: "paymentTerm",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.showCustomerNumberField &&
                                permission.name == "Send Customers" &&
                                _vm.formData.integration.name == "e-conomic") ||
                              (permission.name == "Send Customers" &&
                                _vm.selectedCustomerGroupNumber &&
                                _vm.showCustomerNumberField &&
                                _vm.formData.integration.name == "e-conomic")
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("integrations.vat_zones")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.vatZones,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          label: "name",
                                          "track-by": "id",
                                        },
                                        model: {
                                          value: _vm.vatZone,
                                          callback: function ($$v) {
                                            _vm.vatZone = $$v
                                          },
                                          expression: "vatZone",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.showProductNumberField &&
                                permission.name == "Send Products" &&
                                _vm.formData.integration.name == "e-conomic") ||
                              (permission.name == "Send Products" &&
                                _vm.selectedProductGroupNumber &&
                                _vm.showProductNumberField &&
                                _vm.formData.integration.name == "e-conomic")
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "integrations.product_group_no"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.productGroups,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          label: "name",
                                          "track-by": "id",
                                        },
                                        model: {
                                          value: _vm.productGroup,
                                          callback: function ($$v) {
                                            _vm.productGroup = $$v
                                          },
                                          expression: "productGroup",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.showLayoutNumberField &&
                                permission.name == "Send Invoices" &&
                                _vm.formData.integration.name == "e-conomic") ||
                              (permission.name == "Send Invoices" &&
                                _vm.selectedLayoutGroupNumber &&
                                _vm.showLayoutNumberField &&
                                _vm.formData.integration.name == "e-conomic")
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "input-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("integrations.layout_no")
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("base-select", {
                                        attrs: {
                                          options: _vm.layoutGroups,
                                          "show-labels": false,
                                          "allow-empty": false,
                                          label: "name",
                                          "track-by": "id",
                                        },
                                        model: {
                                          value: _vm.layoutGroup,
                                          callback: function ($$v) {
                                            _vm.layoutGroup = $$v
                                          },
                                          expression: "layoutGroup",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-12" },
                            [
                              _c(
                                "base-button",
                                {
                                  attrs: {
                                    loading: _vm.isLoading,
                                    disabled: _vm.isLoading,
                                    icon: "save",
                                    color: "theme",
                                    type: "submit",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$tc("settings.company_info.save")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                !this.details
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-12" },
                        [
                          _c(
                            "base-button",
                            {
                              attrs: {
                                loading: _vm.isLoading,
                                disabled: _vm.isLoading,
                                icon: "save",
                                color: "theme",
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.saveIntegration()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$tc("settings.company_info.save")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ])
            : _c("base-loader"),
        ],
        1
      )
    : _c("div", [_c("not-authorize")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }