<template>
  <div class="payments main-content">
    <div class="page-header">
      <h3 class="page-title">{{ $t("printingDetails.title") }}</h3>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="dashboard">
            {{ $t("general.home") }}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link slot="item-title" to="#">
            {{ $tc("printingDetails.title", 2) }}
          </router-link>
        </li>
      </ol>
      <div class="page-actions row"></div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-show="showFilters" class="filter-section">
        <div class="row">
          <div class="col-md-3">
            <label class="form-label">{{ $t("payments.customer") }}</label>
            <base-customer-select
              ref="customerSelect"
              @select="onSelectCustomer"
              @deselect="clearCustomerSearch"
            />
          </div>
          <div class="col-sm-3">
            <label for="">{{ $t("payments.payment_number") }}</label>
            <base-input
              v-model="filters.payment_number"
              :placeholder="$t(payments.payment_number)"
              name="payment_number"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label">{{ $t("payments.payment_mode") }}</label>
            <base-select
              v-model="filters.payment_mode"
              :options="paymentModes"
              :searchable="true"
              :show-labels="false"
              :placeholder="$t('payments.payment_mode')"
              label="name"
            />
          </div>
          <div class="col-sm-3">
            <label class="form-label"></label>
            <base-button
              @click="refreshFilterTable"
              style="margin-top: 3%; height: 40px"
              size="large"
              color="theme"
            >
              {{ $t("navigation.applyFilters") }}
            </base-button>
          </div>
        </div>
        <label class="clear-filter" @click="clearFilter">{{
          $t("general.clear_all")
        }}</label>
      </div>
    </transition>

    <div v-cloak v-show="showEmptyScreen" class="col-xs-1 no-data-info" align="center">
      <capsule-icon class="mt-5 mb-4" />
      <div class="row" align="center">
        <label class="col title">{{ $t("printingDetails.no_printingDetails") }}</label>
      </div>
      <div class="row">
        <label class="description col mt-1" align="center">{{
          $t("printingDetails.list_of_printing")
        }}</label>
      </div>
    </div>

    <div v-show="!showEmptyScreen" class="table-container">
      <div class="table-actions mt-5">
        <p class="table-stats">
          {{ $t("general.showing") }}: <b>{{ payments.length }}</b>
          {{ $t("general.of") }} <b>{{ totalPrinting }}</b>
        </p>

        <transition name="fade">
          <v-dropdown v-if="selectedPayments.length" :show-arrow="false">
            <span slot="activator" href="#" class="table-actions-button dropdown-toggle">
              {{ $t("general.actions") }}
            </span>
            <v-dropdown-item>
              <div class="dropdown-item" @click="removeMultiplePayments">
                <font-awesome-icon :icon="['fas', 'trash']" class="dropdown-item-icon" />
                {{ $t("general.delete") }}
              </div>
            </v-dropdown-item>
          </v-dropdown>
        </transition>
      </div>

      <table-component
        ref="table"
        :data="fetchData"
        :show-filter="false"
        table-class="table"
      >
        <table-column
          :label="$t('payments.date')"
          sort-as="created_at"
          show="formattedCreatedAt"
        />
        <table-column :label="$t('subscriptionDetails.name')" show="user.name" />

        <table-column
          :label="$t('subscriptionDetails.status')"
          :formatter="currentlyActive"
          :sortable="false"
        >
        </table-column>

        <table-column :label="$t('invoices.paid')" sort-as="due_amount">
          <template slot-scope="row">
            <span>{{ $t("invoices.paid") }}</span>
            <!-- {{ row.due_amount_nice }} -->
            <div v-html="$utils.formatMoney(row.total, defaultCurrency)" />
          </template>
        </table-column>

        <table-column :label="$t('expenses.receipt')" :sortable="false" sort-as="status">
          <template slot-scope="row">
            <span> {{ $t("expenses.receipt") }}</span>
            <span class="inv-status-draft"
              ><a :href="row.receipt_url" target="_blank">{{
                $t("general.view")
              }}</a></span
            >
          </template>
        </table-column>
      </table-component>
      <base-loader
        style="left: 50%; top: 50%; position: absolute"
        v-if="CancelSubscription"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import CapsuleIcon from "../../components/icon/CapsuleIcon";
import BaseButton from "../../../js/components/base/BaseButton";

export default {
  components: {
    "capsule-icon": CapsuleIcon,
    SweetModal: SweetModal,
    SweetModalTab: SweetModalTab,
    BaseButton,
  },
  data() {
    return {
      showFilters: false,
      sortedBy: "created_at",
      filtersApplied: false,
      isRequestOngoing: true,
      CancelSubscription: false,
      filters: {
        customer: null,
        payment_mode: "",
        payment_number: "",
      },
    };
  },
  computed: {
    showEmptyScreen() {
      return !this.totalPrinting && !this.isRequestOngoing && !this.filtersApplied;
    },
    filterIcon() {
      return this.showFilters ? "times" : "filter";
    },
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("currency", ["defaultCurrency"]),
    ...mapGetters("printingDetails", [
      "selectedPayments",
      "totalPrinting",
      "payments",
      "selectAllField",
      "paymentModes",
    ]),

    selectField: {
      get: function () {
        return this.selectedPayments;
      },
      set: function (val) {
        this.selectPayment(val);
      },
    },
    selectAllFieldStatus: {
      get: function () {
        return this.selectAllField;
      },
      set: function (val) {
        this.setSelectAllState(val);
      },
    },
  },
  watch: {
    filters: {
      handler: "setFilters",
      deep: true,
    },
  },

  destroyed() {
    if (this.selectAllField) {
      this.selectAllPayments();
    }
  },
  methods: {
    ...mapActions("printingDetails", [
      "fetchPrintingHistory",
      "selectAllPayments",
      "selectPayment",
      "deletePayment",
      "deleteMultiplePayments",
      "setSelectAllState",
    ]),
    ...mapActions("subscription", {
      cancelSub: "cancelSubscription",
      resumeSub: "resumeSubscription",
    }),
    currentlyActive(active) {
      return active === false ? "In Active" : "Active";
    },
    ...mapActions("customer", ["fetchCustomers"]),
    async fetchData({ page, filter, sort }) {
      let data = {
        orderByField: sort.fieldName || "created_at",
        orderBy: sort.order || "desc",
        page,
      };

      this.isRequestOngoing = true;

      let response = await this.fetchPrintingHistory(data);

      this.isRequestOngoing = false;

      return {
        data: response.data.printingHistory.data,
        pagination: {
          totalPages: response.data.printingHistory.last_page,
          currentPage: page,
          count: response.data.printingHistory.scount,
        },
      };
    },

    async cancleSubscription() {
      window
        .swal({
          title: this.$t("general.are_you_sure"),
          text: "Your subscription will be cancelled",
          icon: "/assets/icon/paper-plane-solid.svg",
          buttons: true,
          dangerMode: true,
        })
        .then(async (cancel) => {
          if (cancel) {
            this.CancelSubscription = true;
            let response = await this.cancelSub();
            if (response.data.success) {
              window.toastr["success"]("Your subscription cancelled successfully");
              /*window.toastr["success"](this.$tc("estimates.send_estimate_successfully"));*/
              this.CancelSubscription = false;
              this.refreshTable();
              return true;
            }
            if (response.data.error) {
              window.toastr["error"](this.$tc("estimates.something_went_wrong"));
              this.CancelSubscription = false;
              return true;
            }
            window.toastr["error"](this.$tc("estimates.something_went_wrong"));
            this.CancelSubscription = false;
          }
        });
    },

    refreshTable() {
      this.$refs.table.refresh();
    },
    setFilters() {
      this.filtersApplied = true;
    },
    refreshFilterTable() {
      this.refreshTable();
    },
    clearFilter() {
      if (this.filters.customer) {
        this.$refs.customerSelect.$refs.baseSelect.removeElement(this.filters.customer);
      }

      this.filters = {
        customer: null,
        payment_mode: "",
        payment_number: "",
      };

      this.$nextTick(() => {
        this.filtersApplied = false;
      });
    },
    toggleFilter() {
      if (this.showFilters && this.filtersApplied) {
        this.clearFilter();
        this.refreshTable();
      }

      this.showFilters = !this.showFilters;
    },
    onSelectCustomer(customer) {
      this.filters.customer = customer;
    },
    async removePayment(id) {
      this.id = id;
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("payments.confirm_delete"),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let res = await this.deletePayment(this.id);
          if (res.data.success) {
            window.toastr["success"](this.$tc("payments.deleted_message", 1));
            this.$refs.table.refresh();
            return true;
          } else if (res.data.error) {
            window.toastr["error"](res.data.message);
          }
        }
      });
    },
    async removeMultiplePayments() {
      swal({
        title: this.$t("general.are_you_sure"),
        text: this.$tc("payments.confirm_delete", 2),
        icon: "/assets/icon/trash-solid.svg",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let request = await this.deleteMultiplePayments();
          if (request.data.success) {
            window.toastr["success"](this.$tc("payments.deleted_message", 2));
            this.$refs.table.refresh();
          } else if (request.data.error) {
            window.toastr["error"](request.data.message);
          }
        }
      });
    },
    async clearCustomerSearch(removedOption, id) {
      this.filters.customer = "";
      this.$refs.table.refresh();
    },
    showModel(selectedRow) {
      this.selectedRow = selectedRow;
      this.$refs.Delete_modal.open();
    },
  },
};
</script>
