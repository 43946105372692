var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payments main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(_vm._s(_vm.$t("subscriptionDetails.title"))),
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "dashboard" },
                  slot: "item-title",
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("general.home")) +
                      "\n          "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { slot: "item-title", to: "#" }, slot: "item-title" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$tc("subscriptionDetails.title", 2)) +
                      "\n          "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "page-actions row" }),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filter-section",
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.$t("payments.customer"))),
                  ]),
                  _vm._v(" "),
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-3" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.$t("payments.payment_number"))),
                  ]),
                  _vm._v(" "),
                  _c("base-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.payments.payment_number),
                      name: "payment_number",
                    },
                    model: {
                      value: _vm.filters.payment_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "payment_number", $$v)
                      },
                      expression: "filters.payment_number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v(_vm._s(_vm.$t("payments.payment_mode"))),
                  ]),
                  _vm._v(" "),
                  _c("base-select", {
                    attrs: {
                      options: _vm.paymentModes,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("payments.payment_mode"),
                      label: "name",
                    },
                    model: {
                      value: _vm.filters.payment_mode,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "payment_mode", $$v)
                      },
                      expression: "filters.payment_mode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-3" },
                [
                  _c("label", { staticClass: "form-label" }),
                  _vm._v(" "),
                  _c(
                    "base-button",
                    {
                      staticStyle: { "margin-top": "3%", height: "40px" },
                      attrs: { size: "large", color: "theme" },
                      on: { click: _vm.refreshFilterTable },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("navigation.applyFilters")) +
                          "\n            "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "clear-filter", on: { click: _vm.clearFilter } },
              [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          staticClass: "col-xs-1 no-data-info",
          attrs: { align: "center" },
        },
        [
          _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c("div", { staticClass: "row", attrs: { align: "center" } }, [
            _c("label", { staticClass: "col title" }, [
              _vm._v(
                _vm._s(_vm.$t("subscriptionDetails.no_subscriptionDetails"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "label",
              {
                staticClass: "description col mt-1",
                attrs: { align: "center" },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("subscriptionDetails.list_of_subscriptions"))
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "base-button",
                {
                  staticClass: "mt-3",
                  attrs: { outline: true, color: "theme", size: "large" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("selectPlan")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("subscriptionDetails.click_to_subscribe")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "table-container",
        },
        [
          _c(
            "div",
            { staticClass: "table-actions mt-5" },
            [
              _c("p", { staticClass: "table-stats" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("general.showing")) + ": "
                ),
                _c("b", [_vm._v(_vm._s(_vm.payments.length))]),
                _vm._v("\n          " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalSubscriptions))]),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.selectedPayments.length
                    ? _c(
                        "v-dropdown",
                        { attrs: { "show-arrow": false } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "table-actions-button dropdown-toggle",
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: { click: _vm.removeMultiplePayments },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n              "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table",
              },
            },
            [
              _c("table-column", {
                attrs: {
                  label: _vm.$t("payments.date"),
                  "sort-as": "created_at",
                  show: "formattedCreatedAt",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("subscriptionDetails.name"),
                  show: "name",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("subscriptionDetails.status"),
                  formatter: _vm.currentlyActive,
                  sortable: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("subscriptionDetails.active"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { class: "history-status-" + row.currentlyActive },
                          [
                            _vm._v(
                              _vm._s(
                                row.currentlyActive === true
                                  ? "Active"
                                  : "In Active"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("subscriptionDetails.renewal"),
                  sortable: false,
                  "sort-as": "renewal_at",
                  show: "formattedRenewalAt",
                },
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("invoices.paid"),
                  "sort-as": "due_amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("invoices.paid")))]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.total,
                                _vm.defaultCurrency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  label: _vm.$t("expenses.receipt"),
                  sortable: false,
                  "sort-as": "status",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("expenses.receipt"))),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "inv-status-draft" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: row.receipt_url,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("general.view")))]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.action")))]),
                        _vm._v(" "),
                        _c(
                          "v-dropdown",
                          [
                            _c(
                              "a",
                              {
                                attrs: { slot: "activator", href: "#" },
                                slot: "activator",
                              },
                              [_c("dot-icon")],
                              1
                            ),
                            _vm._v(" "),
                            _vm.defaultSubscription &&
                            row.currentlyActive &&
                            !row.user_subscription.ends_at
                              ? _c("v-dropdown-item", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { href: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancleSubscription()
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "dropdown-item-icon",
                                        attrs: { icon: "pencil-alt" },
                                      }),
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("general.cancel")) +
                                          "\n                "
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-dropdown-item",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        path:
                                          "subscriptionDetails/" +
                                          row.payment_id +
                                          "/view",
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "dropdown-item-icon",
                                      attrs: { icon: "eye" },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("general.view")) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.CancelSubscription ? _c("base-loader") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }