<template>
  <footer class="site-footer">
    <div class="text-right">
      {{ $t("general.powered_by") }}
      <a style="color: #0373ff" href="https://www.weiswise.com/" target="_blank"
        >{{ $t("general.weis_wise") }}
      </a>
    </div>
  </footer>
</template>

<script type="text/babel">
export default {
  data() {
    return {
      footer: "footer",
    };
  },
};
</script>
