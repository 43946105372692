var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "setting-main-container", staticStyle: { padding: "30px" } },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v("\n      " + _vm._s(_vm.$t("wizard.template")) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "form-group" }, [
          _c("div", { staticClass: "card-body" }, [
            this.invoiceTemplates.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "template-container",
                    staticStyle: {
                      display: "flex",
                      "flex-wrap": "wrap",
                      "justify-content": "space-between",
                    },
                  },
                  _vm._l(this.invoiceTemplates, function (template, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "template-img",
                        class: {
                          "selected-template":
                            _vm.selectedTemplate === template.id,
                        },
                        staticStyle: {
                          "max-width": "30%",
                          position: "relative",
                          border: "1px solid #0373ff",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { margin: "0px 0px 0px 0px" },
                          attrs: { src: template.path, alt: "template-image" },
                          on: {
                            click: function ($event) {
                              return _vm.chooseTemplate(template.id)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.selectedTemplate === template.id
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  right: "-10px",
                                  top: "-10px",
                                  "z-index": "1",
                                  width: "26px",
                                  height: "26px",
                                  border: "1px solid #fff",
                                  "border-radius": "50%",
                                  background: "#fff",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "check-icon",
                                  attrs: {
                                    height: "25",
                                    width: "25",
                                    src: "/assets/img/tick.png",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", [
                  _c(
                    "p",
                    [_c("center", [_vm._v("No Templates Available")])],
                    1
                  ),
                ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row", staticStyle: { float: "right" } }, [
        _c(
          "div",
          { staticClass: "col-md-2", staticStyle: { "margin-right": "29%" } },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loadingBack,
                  icon: "arrow-left",
                  color: "theme",
                },
                on: { click: _vm.back },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.back")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-3" },
          [
            _c(
              "base-button",
              {
                staticClass: "pull-right",
                attrs: {
                  loading: _vm.loading,
                  icon: "arrow-right",
                  rightIcon: "",
                  color: "theme",
                },
                on: { click: _vm.next },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }