var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-modal", style: { height: _vm.boolvar } },
    [
      _c("div", { staticClass: "card-body" }, [
        _vm.modalData.length > 0
          ? _c(
              "div",
              { staticClass: "template-container" },
              [
                _c(
                  "b-carousel",
                  {
                    attrs: {
                      id: "carousel-1",
                      interval: 0,
                      controls: "",
                      indicators: "",
                      background: "#FFFFFF",
                      "img-width": "1024",
                      "img-height": "480",
                    },
                    on: {
                      "sliding-start": _vm.onSlideStart,
                      "sliding-end": _vm.onSlideEnd,
                    },
                    model: {
                      value: _vm.slide,
                      callback: function ($$v) {
                        _vm.slide = $$v
                      },
                      expression: "slide",
                    },
                  },
                  _vm._l(this.templateArray, function (template, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("b-carousel-slide", {
                          staticClass: "image-fit",
                          class:
                            _vm.selectedTemplate == template.id ? "active" : "",
                          attrs: {
                            id: template.id.toString(),
                            contain: "",
                            "img-src": template.path,
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectedTemplate = template.id
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            )
          : _c("div", [
              _c("p", [_c("center", [_vm._v("No Templates Available")])], 1),
            ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-footer" },
        [
          _c(
            "base-button",
            {
              staticClass: "mr-3",
              attrs: { outline: "", color: "theme" },
              on: { click: _vm.closeEstimateModal },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
          ),
          _vm._v(" "),
          _vm.modalData.length > 0
            ? _c(
                "base-button",
                {
                  attrs: { loading: _vm.isLoading, color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.chooseTemplate()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("general.choose")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.modalData.length == 0
            ? _c(
                "base-button",
                {
                  attrs: { loading: _vm.isLoading, color: "theme" },
                  on: {
                    click: function ($event) {
                      return _vm.createTemplate()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("general.create")) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }