<template>
  <div class="section mt-2">
    <label class="invoice-label"> {{ tax.name }} ({{ tax.percent }}%) </label>
    <label class="invoice-amount">
      <div v-html="$utils.formatMoney(tax.amount, currency)" />

      <!-- <font-awesome-icon
        class="ml-2"
        icon="trash-alt"
        @click="$emit('remove', index)"
      /> -->
    </label>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    tax: {
      type: Object,
      required: true,
    },
    taxes: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    totalTax: {
      type: Number,
      default: 0,
    },
    currency: {
      type: [Object, String],
      required: true,
    },
    service_charge: {
      type: Number,
      default: 0,
    },
    credit_card: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    taxAmount() {
      // console.log(this.tax, this.total, this.service_charge);
      if (this.tax.compound_tax && this.total) {
        return ((this.total + this.totalTax) * this.tax.percent) / 100;
      }

      if (this.total && this.tax.percent) {
        return (
          ((this.total + this.service_charge + this.credit_card) * this.tax.percent) / 100
        );
      }

      return 0;
    },
  },

  watch: {
    total: {
      handler: "updateTax",
    },
    totalTax: {
      handler: "updateTax",
    },
    service_charge: {
      handler: "updateTax",
    },
    credit_card: {
      handler: "updateTax",
    },
  },
  methods: {
    updateTax() {
      // console.log(this.tax);
      this.$emit("update", {
        index: this.index,
        item: {
          ...this.tax,
          amount: this.taxAmount,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
