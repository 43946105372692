var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tax-type-modal" },
    [
      _c(
        "div",
        {
          staticClass: "invoice-view-page-container",
          staticStyle: { height: "50%" },
        },
        [
          _c("iframe", {
            ref: "iframeRef",
            staticClass: "frame-style",
            staticStyle: { border: "none" },
            attrs: {
              id: "iframe",
              src: "" + _vm.shareableLink,
              width: "100%",
              height: "470px",
            },
            on: {
              load: function ($event) {
                return _vm.iframeLoaded()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.ProcessSendInvoice
        ? _c("base-loader", {
            staticStyle: { left: "50%", top: "50%", position: "absolute" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }