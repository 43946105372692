export const SET_INSTALLMENTS = 'SET_INSTALLMENTS'
export const ADD_INSTALLMENT = 'ADD_INSTALLMENT'
export const UPDATE_INSTALLMENT = 'UPDATE_INSTALLMENT'
export const DELETE_INSTALLMENT = 'DELETE_INSTALLMENT'
export const DELETE_MULTIPLE_INSTALLMENTS = 'DELETE_MULTIPLE_INSTALLMENTS'
export const SET_SELECTED_INSTALLMENTS = 'SET_SELECTED_INSTALLMENTS'
export const SET_TOTAL_INSTALLMENTS = 'SET_TOTAL_INSTALLMENTS'
export const SET_SELECT_ALL_STATE = 'SET_SELECT_ALL_STATE'
export const ADD_INSTALLMENT_MODE = 'ADD_INSTALLMENT_MODE'
export const DELETE_INSTALLMENT_MODE = 'DELETE_INSTALLMENT_MODE'
export const SET_INSTALLMENT_MODES = 'SET_INSTALLMENT_MODES'
export const UPDATE_INSTALLMENT_MODE = 'UPDATE_INSTALLMENT_MODE'